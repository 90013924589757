import React, { useEffect, useState } from 'react'
import { useForm, } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Alert, Button, Col, FloatingLabel, Form, InputGroup, Modal, Offcanvas, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { listTemplateInvoicesetting, mailData } from '../../../../redux/slice/SendEmailSlice';
import i18n from '../../../../i18n';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { emailTemplate } from '../../../../redux/slice/taxationSlice';
import Swal from 'sweetalert2';
const SendEmialInvoice = (props) => {
    let { showEmail, setShowEmail, emialTypes } = props
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const handleClose = () => {
        setShowEmail(false)
    }
    const mailValidationSchema = Yup.object().shape({
        // identifier: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // variable: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        title: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
    });
    let frm = {
        resolver: yupResolver(mailValidationSchema)
    }
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<mailData>(frm);
    let [desc, setDesc] = useState<any>('');
    const [state1, setState1] = useState({
        start: "0",
        end: "100",
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await dispatch(listTemplateInvoicesetting(state1));
                if (res.payload.data.length > 0) {
                    if (emialTypes === "send") {
                        setValue("title", res?.payload?.data[0]?.ST_TITULO_MOH);
                    } else {
                        setValue("title", `(CANCELAMENTO) ${res?.payload?.data[0]?.ST_TITULO_MOH}`);
                    }

                    const newText: any = `${res?.payload?.data[0].ST_TEXTO_MOH}` || '';
                    setDesc(`${newText ? newText : `<p>
                                <p>Caro cliente <strong>%customer_name%</strong>,</p>

                                <p>TEsta é uma mensagem automática sobre a emissão da Nota Fiscal <strong>%nota_numero%</strong>, da empresa <strong>%empresa_nome%</strong> (CNPJ: %empresa_cnpj%) para <strong>%cliente_nome%</strong>.</p>

                                <p>Para imprimir a nota, acesse::</p>
                                
                                <p><a href="%link_mirror_button%" class="button">link_espelho_botao</a></p>

                                <strong>%empresa_assinatura%</strong></p>
                           </p>`}`);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [dispatch, showEmail, emialTypes]);

    const formSubmit = async (data: any) => {
        data["description"] = desc
        let res = await dispatch(emailTemplate(data))
        if (res?.payload?.type == "Success" || res?.payload?.type == "success") {
            Swal.fire({
                title: `${t("Sweetalert.Success")}`,
                text: `${t("Sweetalert.insertRecord")}`,
                showConfirmButton: false,
                timer: 1000
            })
            setShowEmail(false)
        } else {
            Swal.fire({
                position: "center",
                title: `${t("Sweetalert.Error")}`,
                text: `${t("Sweetalert.cannotInsert")}`,
                showConfirmButton: true,
                timer: 2000,
            });
        }
    }
    return (
        <Offcanvas show={showEmail} onHide={handleClose} placement='end' className="border-start-0" id="EmailSendInvoice">
            <Form className="d-flex flex-column h-100" onSubmit={handleSubmit(formSubmit)}>
                <Offcanvas.Header closeButton className="border-bottom">
                    <img src="assets/images/icons/settings-dark.svg" alt="Settings" className="h-20" />
                    <h4 className='fw-bold m-0'>{t("Invoices.EnquireaboutNFGerada")}</h4>
                    <img src="assets/images/icons/settings-dark.svg" alt="Settings" className="h-20" />
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <span className="d-block mb-3 fw-bold text-dark">{t("Invoices.EnquireaboutNFGerada")}</span>
                    <Row>
                        <Col md={12}>
                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.title')}>
                                <Form.Control type="text" placeholder={t('formLabel.title')} className={` ${errors.title ? 'is-invalid' : ''}`}  {...register('title')} />
                                <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                        <Col md={12}>
                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.variables')}>
                                <Form.Select aria-label={t('formLabel.variables')} className={` ${errors.variable ? 'is-invalid' : ''}`}  {...register('variable')}
                                    onChange={(e) => {
                                        const selectedVariable = e.target.value;
                                        const appendedContent = `${desc} %${selectedVariable}%`; setDesc(appendedContent);
                                    }}>
                                    <option value="">{t('dropdownItem.select')}</option>

                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.variable?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Control type="hidden" placeholder="description" className={`${errors.description ? 'is-invalid' : ''}`}  {...register('description')} />
                            <CKEditor
                                editor={ClassicEditor as any}
                                data={desc}
                                config={{
                                    language: {
                                        ui: i18n.language, // UI language
                                        content: i18n.language, // Content language
                                    },
                                }}
                                // onReady={editor => {
                                //     uploadPlugin(editor);
                                //     // console.log(editor.config.get('language'));
                                //     // const data = editor.getData();
                                //     // setDesc(data)
                                // }}
                                onChange={(event:any, editor:any) => {
                                    const data = editor?.getData();
                                    setDesc(data)
                                }}
                                onBlur={(event:any, editor:any) => {
                                    const data = editor?.getData();
                                    setDesc(data)

                                }}
                                onFocus={(event:any, editor:any) => {
                                    const data = editor?.getData();
                                    setDesc(data)
                                }}
                            />
                        </Col>
                    </Row>

                </Offcanvas.Body>
                <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                    <Button variant="outline-primary" >
                        {t('button.close')}
                    </Button>
                    <Button variant="primary" type="submit">
                        {t('button.toSave')}
                    </Button>
                </div>
            </Form>
        </Offcanvas >
    )
}

export default SendEmialInvoice