import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, FloatingLabel, Form, InputGroup, Modal, Offcanvas, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { getItem } from '../../../../redux/slice/AccountPlanSlice';
import i18n from '../../../../i18n';
import SendEmialInvoice from './SendEmialInvoice';
import { invoiceSetting, getInvoiceSetting } from '../../../../redux/slice/taxationSlice';
import Swal from 'sweetalert2';
const SettingInvoice = (props) => {
    const AccountPlanList = useSelector((state: RootState) => state.accountplane.accountPlane);
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const schema = yup.object().shape({
        account_category: yup.string().required(t("requiredMmessage.fieldRequired")).trim().nullable(),
    });
    const { register, reset, handleSubmit, setValue, getValues, formState: { errors }, setError, clearErrors } = useForm<any>({
        resolver: yupResolver(schema),
    });
    let { showSetting, setShowSetting, setAccountSetting } = props
    const handleClose = () => setShowSetting(false);
    const [showEmail, setShowEmail] = useState(false)
    const [emialTypes, setEmailsTypes] = useState("")
    const formSubmit = async (data: any) => {
        let newData: any = {
            account_category: data.account_category,
            copy_of_the_generated_invoice: data.copy_of_the_generated_invoice,
            copy_of_the_cancelled_invoice: data.copy_of_the_cancelled_invoice
        }
        let res = await dispatch(invoiceSetting({ newData }))
        if (res?.payload?.type == "Success" || res?.payload?.type == "success") {
            Swal.fire({
                title: `${t("Sweetalert.Success")}`,
                text: `${t("Sweetalert.insertRecord")}`,
                showConfirmButton: false,
                timer: 1000
            })
            setShowSetting(false)
            reset()
        } else {
            Swal.fire({
                position: "center",
                title: `${t("Sweetalert.Error")}`,
                text: `${t("Sweetalert.cannotInsert")}`,
                showConfirmButton: true,
                timer: 2000,
            });
        }
    }
    useEffect(() => {
        dispatch(getItem())
    }, [dispatch, i18n?.language])

    const [list, setList] = useState<any>([]);
    useEffect(() => {
        // Function to modify labels recursively
        const modifyLabels = (data: any) => {
            const updatedData: any = [];
            for (const item of data) {
                // Create an updated item with value, label, and childData
                const updatedItem: any = {
                    value: item.item,
                    label: item.description,
                    childData: item.childData ? modifyLabels(item.childData) : [],
                };
                updatedData.push(updatedItem);
            }
            return updatedData;
        };
        // Call the modifyLabels function on AccountPlanList and set the modified list
        setList(modifyLabels(AccountPlanList))
    }, [AccountPlanList])

    useEffect(() => {
        const fetchInvoiceSetting = async () => {
            if (list) {
                let getInvo = await dispatch(getInvoiceSetting());
                setValue("account_category",getInvo.payload.data[0]?.account_category)
                setValue("copy_of_the_generated_invoice",getInvo.payload.data[0]?.copy_of_the_generated_invoice == "1" ? true: false)
                setValue("copy_of_the_cancelled_invoice",getInvo.payload.data[0]?.copy_of_the_cancelled_invoice == "1" ? true: false)
                setAccountSetting(getInvo.payload.data[0]?.account_category)
            }
        };
    
        fetchInvoiceSetting();
    }, [list, dispatch,showSetting]); 
    

    const HandleEmail = (e: any, type: string) => {
        setShowEmail(true)
        setEmailsTypes(type)
    }
    return (
        <>
            <Offcanvas show={showSetting} onHide={handleClose} placement='end' className="border-start-0">
                <Form className="d-flex flex-column h-100" onSubmit={handleSubmit(formSubmit)}>
                    <Offcanvas.Header closeButton className="border-bottom">
                        <h4 className='fw-bold m-0'>{t("contractsettings.Configuration")}</h4>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <span className="d-block mb-3 fw-bold text-dark">{t("Invoices.NFEService")}</span>
                        <Row>
                            <Col md={12}>
                                <FloatingLabel className="" controlId="account" label={t("formLabel.accountCategory") + " *"}>
                                    <Form.Select className={`${errors.account_category ? 'is-invalid' : ''}`}  {...register('account_category')}>
                                        <option value="0">{t("formLabel.select")}</option>
                                        {
                                            list && list.length > 0 &&
                                            list.map((item, index) => {
                                                if ((item.childData).length > 0) {
                                                    return (
                                                        <optgroup key={index} label={`${item.value} ${item.label}`}>
                                                            {
                                                                item.childData && (item.childData).length > 0 &&
                                                                item.childData.map((firstItem, index) => {
                                                                    if ((firstItem.childData).length > 0) {
                                                                        return (
                                                                            <>
                                                                                <option key={index} value={firstItem.value}>{firstItem.value} {firstItem.label}</option>
                                                                                {
                                                                                    (firstItem.childData).map((seconditem, index) => {
                                                                                        if ((seconditem.childData).length > 0) {
                                                                                            return (
                                                                                                <>
                                                                                                    <option key={index} value={seconditem.value}>{seconditem.value} {seconditem.label}</option>
                                                                                                    {
                                                                                                        (seconditem.childData).map((thirditem, index) => {
                                                                                                            if ((thirditem.childData).length > 0) {
                                                                                                                (thirditem.childData).map((fourthitem, index) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <option key={index} value={fourthitem.value}>{fourthitem.value} {fourthitem.label}</option>
                                                                                                                        </>
                                                                                                                    )
                                                                                                                })
                                                                                                            } else {
                                                                                                                return (
                                                                                                                    <>
                                                                                                                        <option key={index} value={thirditem.value}>{thirditem.value} {thirditem.label}</option>
                                                                                                                    </>
                                                                                                                )
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                </>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <option key={index} value={seconditem.value}>{seconditem.value} {seconditem.label}</option>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <option key={index} value={firstItem.value}>{firstItem.value} {firstItem.label}</option>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </optgroup>
                                                    )
                                                }
                                            })
                                        }

                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        {/* <hr className="my-lg-4 my-3" />
                        <span className="d-block mb-3 fw-bold text-dark">{t('Invoices.NFEvalidation')}</span>
                        <div className='d-flex align-items-center mb-lg-4 mb-3'>
                            <Form.Check type="checkbox" id="cb-5" {...register('Do_not_allow_edits_to_notes_issued')} label={t("Invoices.Donotallowedits")} />
                            <img src="./assets/images/icons/info-dark.svg" alt="search icon" className="h-18 ms-2 cursor-pe" />
                        </div>
                        <div className='d-flex align-items-center mb-lg-4 mb-3'>
                            <Form.Check type="checkbox" className="mb-lg-4 mb-3" id="cb-6" {...register("Do_not_allow_generation")} label={t("Invoices.Donotallowgeneration")} />
                            <img src="./assets/images/icons/info-dark.svg" alt="search icon" className="h-18 ms-2 cursor-pe" />
                        </div> */}
                        <hr className="my-lg-4 my-3" />
                        <span className="d-block mb-3 fw-bold text-dark">{t('Invoices.Automaticsendingofinvoices')}</span>
                        <div className='d-flex align-items-center justify-content-between mb-lg-4 mb-3'>
                            <div className='d-flex'>
                                <Form.Check type="checkbox" id="cb-10" {...register("copy_of_the_generated_invoice")} label={t("Invoices.copygeneratedinvoice")} />
                                <img src="./assets/images/icons/info-dark.svg" alt="search icon" className="h-18 ms-2 cursor-pe" />
                            </div>
                            <Button variant="link" className="p-0 fw-bold text-nowrap me-lg-4 me-3" onClick={(e) => HandleEmail(e, "send")}>{t("notifications.editTemplate")}</Button>
                        </div>
                        <div className='d-flex align-items-center justify-content-between mb-lg-4 mb-3'>
                            <div className='d-flex'>
                                <Form.Check type="checkbox" id="cb-10" {...register("copy_of_the_cancelled_invoice")} label={t("Invoices.copycancelledinvoice")} />
                                <img src="./assets/images/icons/info-dark.svg" alt="search icon" className="h-18 ms-2 cursor-pe" />
                            </div>
                            <Button variant="link" className="p-0 fw-bold text-nowrap me-lg-4 me-3" onClick={(e) => HandleEmail(e, "cancel")}>{t("notifications.editTemplate")}</Button>
                        </div>
                        {/* <Row>
                            <Col md={12}>
                                <FloatingLabel controlId="total_retention" label={t("Invoices.responsiblefortheinvoices")}>
                                    <Form.Control type="text"  {...register('InvoiceEmail')} placeholder="Total after retention" />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Alert variant="warning" className="d-flex align-items-center mb-0 mt-4">
                            {t("Invoices.InvoiceAlert")} {" "}
                        </Alert> */}
                        {/* <hr className="my-lg-4 my-3" />

                        <span className="d-block mb-3 fw-bold text-dark">{t('Invoices.Servicesupplementinnotes')}</span>
                        <div className='d-flex align-items-center mb-lg-4 mb-3'>
                            <Form.Check type="checkbox" id="cb-12" {...register("Do_not_display_additional")} label={t("Invoices.Donotdisplayadditional")} />
                            <img src="./assets/images/icons/info-dark.svg" alt="search icon" className="h-18 ms-2 cursor-pe" />
                        </div>
                        <div className='d-flex align-items-center mb-lg-4 mb-3'>
                            <Form.Check type="checkbox" id="cb-13" {...register("Present_competence")} label={t("Invoices.Presentcompetenceon")} />
                            <img src="./assets/images/icons/info-dark.svg" alt="search icon" className="h-18 ms-2 cursor-pe" />
                        </div> */}
                    </Offcanvas.Body>
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        <Button variant="outline-primary" onClick={handleClose}>
                            {t('button.close')}
                        </Button>
                        <Button variant="primary" type="submit">
                            {t('button.toSave')}
                        </Button>
                    </div>
                </Form>
            </Offcanvas >
            <SendEmialInvoice showEmail={showEmail} setShowEmail={setShowEmail} emialTypes={emialTypes} />
        </>
    )
}

export default SettingInvoice


