import React, { useEffect, useState, useRef, useMemo } from 'react';
import Swal from 'sweetalert2';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { ComProps } from '../../../Interface'
import { AccountChange, Filter_bank, Main_Account, add_Bank, updateStatus } from '../../../../redux/slice/bankSlice';
import Newbankaccounts from './Newbankaccounts'
import { get_BankAccountList,getBankList, deleteSingle_Bank, delete_user_Bank_Acccount, handleEdit, handleOpnBank } from "../../../../redux/slice/bankSlice"
import Loader from '../Loader';
import { Form, Button, Row, Col, Table, Card, Dropdown } from 'react-bootstrap';
import MainAccount from './MainAccount';
import { useTranslation } from 'react-i18next';
import EditBankAccount from './EditBankAccount';
import { getFormsofReceipt } from '../../../../redux/slice/formsOfReceiptSlice';
import { PrintPDF, converter, encrypt_Decrypt } from '../../../constants/utils';
import { getBraches } from '../../../../redux/slice/branchSlice';
import { useNavigate } from 'react-router-dom';

const BankAccounts = ({ submenu }: ComProps,) => {
	// This line initializes the useDispatch hook and assigns it to the dispatch variable
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const [typeAction, setTypeAction] = useState<any>({
		main: true,
		status: false
	})
	// Initialize the useTranslation hook for internationalization
	const [t] = useTranslation()
	// usestate is created to change the data
	const [id, setId] = useState<number>()
	const myRef: any = useRef(null);
	const [currShow, setCurrShow] = useState<number>(10);
	const [allShow, setAllShow] = useState<string>('')
	const [totalAmount, setTotalAmount] = useState<any>()
	// Retrieve userbanklist, and wayofreceiving and status from the Redux state
	const { userbanklist,bankAccount, status, banklist } = useSelector((state: RootState) => state.bank)
	const { wayofreceiving } = useSelector((state: RootState) => state.forms);
	const [userJson, setUserJson] = useState<any>([]);
	useEffect(() => {
		let userResult = encrypt_Decrypt();
		if (!userResult || userResult.indexOf(3110) === -1) {
			navigate("/dashboard");
		}
		setUserJson(userResult);
	}, [])

	let obj = {
		start: "0",
		end: currShow,
		all: allShow
	}

	let all_flt_data: any = {
		tablename: "banks",
		start: "",
		end: 1000,
		status: "0",
	};

	useEffect(() => {
		dispatch(getBraches({
			start: "0",
			end: "100",
		}))
		dispatch(getBankList({
			start: "0",
			end: "1000"
		}))
		dispatch(Filter_bank(all_flt_data));
		dispatch(add_Bank())
	}, [dispatch, currShow, allShow])

	useEffect(() => {
		(async () => {
			dispatch(getFormsofReceipt());
			// dispatch(get_BankAccountList(obj));
		})()
	}, [dispatch])
	
	useEffect(() => {
		let total = 0;
		bankAccount?.forEach((item: any) => {
			const moneyAccountValue = parseFloat(item.moneyaccount);
			total += moneyAccountValue;
		});
		setTotalAmount(total.toFixed(2));
	}, [bankAccount]);

	// Pop up show of new bank account on click
	const Open_New_Bank = () =>
		dispatch(handleOpnBank(true))

	//When we first have to show the data in the input field click on edit button
	const HandleSubmits = async (id: number) => {
		dispatch(handleEdit(true))
		setId(id)
	}

	// To delete one by one bank account
	const deletebankAccount = async (data: string, id: number, mainac: number) => {
		if (data == "delete") {
			Swal.fire({
				title: mainac == 1 ? `${t("Sweetalert.deletingMainAc")}` : `${t("Sweetalert.Warning")}`,
				text: mainac == 1 ? `${t("Sweetalert.deleteMainAccount")}` : `${t("Sweetalert.deleteBankAccount")}`,
				// icon: 'success',mainac == 1 ? "warning" : "question",
				showCancelButton: true,
				confirmButtonText: mainac == 1 ? `${t("Sweetalert.yesDeleteMainAccount")}` : `${t("Sweetalert.yesDeleteIt")}`,
				cancelButtonText: t("button.cancel")
			}).then(async (result) => {
				if (result.isConfirmed == true) {
					let res = await dispatch(deleteSingle_Bank(id));
					if (res.payload.type == "Success") {
						Swal.fire({
							title: `${t('Sweetalert.Success')}`,
							text: `${t("Sweetalert.deleteRecord")}`,
							showConfirmButton: false,
							timer: 2000
						})
						dispatch(delete_user_Bank_Acccount(id))
					} else {
						Swal.fire({
							// icon: 'success','error',
							title: `${t('Sweetalert.Error')}`,
							text: res.payload.message,
							confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
							timer: 2000
						})
					}
				} else {
					console.log("no")
				};
			});
		}
	}

	const PrimaryAccount = (e: any, id) => {
		let check = e.target;
		// Uncheck all other input elements
		document.querySelectorAll('.newUserCh1 input').forEach((item) => {
			item["checked"] = false;
		})
		setTimeout(() => {
			// Check the selected input element
			check["checked"] = true;
			Swal.fire({
				title: `${t("Sweetalert.Warning")}`,
				text: `${t("Sweetalert.changeMainAccount")}`,
				// icon: 'success',"warning",
				showCancelButton: true,
				confirmButtonText: `${t("Sweetalert.changeIt")}`,
				cancelButtonText:  t('button.cancel'),
			}).then(async (res) => {
				if (res.isConfirmed) {
					// Dispatch the account change action
					let update = await dispatch(AccountChange(id))
					// dispatch(Main_Account())
					// Dispatch the Main_Account action
					if (update.payload.type == "Success") {
						dispatch(Main_Account())
					};
					// Dispatch the getBankList action
					dispatch(getBankList(obj))
					dispatch(get_BankAccountList(obj))
				} else {
					check["checked"] = false;
				}
			})
		}, 100)
	}

	const updateBankStatus = async (data, bankId) => {
		// Iterate over each input element in '.newUserCh1' class
		let checkId: any = [];
		checkId.push(bankId);
		let checkActive = wayofreceiving.filter(item => item?.["FL_PRODUCAO_FRECB"] == 0);
		let goForward = true;
		checkActive.forEach((item) => {
			if (checkId.indexOf(item["ID_CONTA_CB"]) != -1) {
				goForward = false
			}
		})
		if (!goForward && data == 1) {
			// Display a warning message if selected account is linked with ways of receiving
			Swal.fire({
				title: `${t("Sweetalert.Warning")}`,
				text: `${t("Sweetalert.selectedAccount")}`,
				// icon: 'success',"warning",
				confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
			})
		} else {
			if (checkId && checkId.length > 0) {
				let obj = {
					id: checkId,
					status: data
				}
				// Call the 'updateStatus' action using dispatch and wait for the result
				let result = await dispatch(updateStatus(obj));
				if (result.payload.type == "Success") {
					// Dispatch 'getBankList' action to fetch updated bank list
					dispatch(getBankList({
						start: "0",
						end: "1000"
					}))
					// Display success message if status is successfully updated
					Swal.fire({
						title: `${t('Sweetalert.Success')}`,
						text: `${t('Sweetalert.RecordUpdated')}`,
						// icon: 'success',"success",
						confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
						timer: 2000
					})
				} else {
					// Display error message if status update fails
					Swal.fire({
						title: `${t('Sweetalert.Error')}`,
						text: `${t('Sweetalert.cannotupdated')}`,
						// icon: 'success',"error",
						confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
					})
				}
			} else {
				// Display a warning if no bank is selected
				Swal.fire({
					title: `${t("Sweetalert.Warning")}`,
					text: `${t("Sweetalert.pleaseSelectBank")}`,
					// icon: 'success',"info",
					confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
				})
			}
		}
	}

	return (
		<section className='content-wrapper content-wrapper-primary BankAccountstsx'>
			{/* <!-- Breadcrumb section start from here --> */}
			{submenu}
			{/* <!-- /Breadcrumb section end -->
       		<!-- Main content start from here --> */}
			<main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
				<Row className="gy-3 gy-lg-4">
					{/* <!-- main account section start from here  --> */}
					<MainAccount />
					{/* <!-- /Comapny logo section end --> */}
					{/* {status == "loading" ?
            		<Loader /> : */}
					<Col lg={6} className="bancoListing">
						<Card ref={myRef}>
							<Card.Header className="d-flex align-items-center justify-content-between">
								{t("banks.otherAccounts")}
								{userJson && userJson.length > 0 && (userJson.indexOf(3111) !== -1) &&
									<div>
										<Button variant="link" className="p-0 fw-bold" type="button" onClick={Open_New_Bank}>{t("banks.newBankAccount")}</Button>
									</div>
								}
							</Card.Header>
							<Card.Body className="pb-1">
								{/* <!-- Table start from here --> */}
								<Table responsive className="table table-custom table-custom-bordered mb-0" >
									<thead>
										<tr>
											<th>{t("dropdownItem.status")}</th>
											<th>{t("Details")}</th>
											<th>{t("amount")}</th>
											<th>{t("actions")}</th>
										</tr>
									</thead>
									<tbody>
										{bankAccount && bankAccount?.length > 0 ?
											bankAccount?.map((curElem: any, index) => {
												return (
													<React.Fragment key={index}>
														{
															curElem.main_ac === 1 &&
															<tr data-idnew={curElem.id}>
																<td>
																	<img src="assets/images/icons/oval-star.svg" alt="star" className="h-20 cursor-pe" />
																</td>
																<td className="text-dark-70">
																	<span className="d-block">{curElem.nameReference}
																		{curElem.AARIN_ID != '' &&
																			<img style={{ width: '9px', marginLeft: '4px' }} alt="img" src="assets/images/icons/green-circle-icon.svg" />
																		}</span>
																	{
																		curElem.accountType !== "2" &&
																		<>
																			<span className="d-block text-secondary">
																				<b>{t("banks.name")}:</b> {
																					banklist &&
																					banklist?.["length"] > 0 &&
																					banklist.map((curElem1, index) => {
																						if (curElem1.bank_code == curElem.bankname) {
																							return (<React.Fragment key={index}>{curElem1.bank_name}</React.Fragment>)
																						}
																					})
																				}
																			</span>
																			<span className="d-block text-secondary">
																				<b> {t("banks.agency")}:</b> { curElem.branchtype.toString().length >= 4 ? curElem.branchtype : curElem.agency}
																			</span>
																			<span className="d-block text-secondary">
																				<b> {t("banks.accountNo")}:</b>  {curElem.account}
																			</span>
																		</>
																	}
																	{/* <span className="d-block text-secondary">
																		{curElem?.accountType ? <b>{t("banks.accountType")}: </b> : ""}
																		{curElem?.accountType ? <b>{t("banks.accountType")}</b> && curElem.accountType == 2 ? t("banks.box") :
																			curElem.accountType == 3 ? t("banks.investment") :
																				t("banks.checkingAccount") : ''}
																	</span> */}
																	<span className="d-block text-secondary ONEEEE">
																		{curElem?.accountType && (
																			<>
																				<b>{t("banks.accountType")}: </b>
																				{ ( curElem.accountType == '1' ) ? t("banks.checkingAccount") 
																					: ( curElem.accountType == '3' )
																						? t("banks.investment")
																						: t("banks.box")
																				}
																			</>
																		)}
																	</span>
																</td>
																<td className="text-dark-70">
																	<span className="d-block text-nowrap">R$ {curElem.moneyaccount ? converter(`${curElem.moneyaccount}`) : "0,00"}</span>
																</td>
																<td className="text-end">
																	{userJson && userJson.length > 0 && (userJson.indexOf(3111) !== -1) &&
																		<Col sm="auto">
																			<Dropdown className="radio--custom" autoClose="outside">
																				<Dropdown.Toggle variant="link" size="sm" className='mainStatus dropdown-arrow-none p-0'>
																					<img src={process.env.PUBLIC_URL + "/assets/images/icons/dots_danger.svg"} className="header-logo" alt="Logo" />
																				</Dropdown.Toggle>
																				<Dropdown.Menu>
																					<Dropdown.Item onClick={() => HandleSubmits(curElem.id)}>
																						{t("button.edit")}
																					</Dropdown.Item>
																					{curElem["status"] == 1 &&
																						<Dropdown.Item onClick={() => updateBankStatus(0, curElem.id)}>
																							{t("active")}
																						</Dropdown.Item>
																					}
																					{curElem["status"] == 0 &&
																						<Dropdown.Item onClick={() => updateBankStatus(1, curElem.id)} >
																							{t("formReceipt.disabled")}
																						</Dropdown.Item>
																					}
																					{/* <Dropdown.Item onClick={() => deletebankAccount("delete", curElem.id, curElem.main_ac)} >
                                            {t("button.delete")}
                                          </Dropdown.Item> */}
																				</Dropdown.Menu>
																			</Dropdown>
																		</Col>
																	}
																</td>
															</tr>
														} 
														{
															curElem.main_ac !== 1 &&
															<tr key={index} data-idnew={curElem.id}>
																<td>
																	{curElem["status"] == 0 &&
																		userJson && userJson.length > 0 && (userJson.indexOf(3111) !== -1) &&
																		<>
																			{
																				curElem.accountType !== "2" ?
																					<>
																						{
																							(typeAction.main) ?
																								<Form.Check type="checkbox" className="mb-lg-4 mb-3 newUserCh1" id={"newUserCh1" + index} onClick={(e) => PrimaryAccount(e, curElem.id)} />
																								:
																								<Form.Check type="checkbox" className="mb-lg-4 mb-3 newUserCh1" id={"newUserCh1" + index} data-id={curElem.id} />
																						}
																					</>
																					:
																					<Form.Check type="checkbox" className="mb-lg-4 mb-3 newUserCh1" id={"newUserCh1" + index} disabled />
																			}
																		</>
																	}
																</td>
																<td className="text-dark-70">
																	<Row>
																		{
																			curElem["status"] == 1 &&
																			<Col sm={1}>
																				<span>
																					<svg xmlns="http://www.w3.org/2000/svg" fill="red" width="20px" height="20px" viewBox="-1 0 19 19" className="cf-icon-svg">
																						<path d="M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zm-5.267 6.274a6.766 6.766 0 0 0 1.756-1.084L3.31 5.177a6.81 6.81 0 0 0 7.84 10.68zm3.624-3.624a6.808 6.808 0 0 0-10.68-7.84l9.596 9.596a6.77 6.77 0 0 0 1.084-1.756z" />
																					</svg>
																				</span>
																			</Col>
																		}
																		<Col sm={9}>
																			<span className="d-block">{curElem.nameReference}</span>
																		</Col>
																	</Row>
																	{
																		curElem.accountType !== "2" &&
																		<>
																			<span className="d-block text-secondary">
																				<b>{t("banks.name")}:</b> {
																					banklist &&
																					banklist?.["length"] > 0 &&
																					banklist.map((curElem1, index) => {
																						if (curElem1.bank_code == curElem.bankname) {
																							return (<React.Fragment key={index}>{curElem1.bank_name}</React.Fragment>)
																						}
																					})
																				} &nbsp;
																			</span>
																			<span className="d-block text-secondary">
																				<b>{t("banks.agency")}:</b>  { curElem.branchtype.toString().length>=4 ? curElem.branchtype : curElem.agency} &nbsp;
																			</span>
																			<span className="d-block text-secondary">
																				<b> {t("banks.accountNo")}:</b> {curElem.account} &nbsp;
																			</span>
																		</>
																	}
																	{/* <span className="d-block text-secondary">
																		{curElem.accountType ? <b>{t("banks.accountType")}: </b> : ""}
																		{curElem.accountType ? <b>{t("banks.accountType")}</b> &&
																			curElem.accountType == 2 ? t("banks.box") :
																			curElem.accountType == 3 ? t("banks.investment") :
																				t("banks.checkingAccount") : ''}
																	</span> */}

																	<span className="d-block text-secondary TWOOOO">
																		{curElem?.accountType && (
																			<>
																				<b>{t("banks.accountType")}: </b>
																				{ ( curElem.accountType == '1' ) ? t("banks.checkingAccount") 
																					: ( curElem.accountType == '3' )
																						? t("banks.investment")
																						: t("banks.box")
																				}
																			</>
																		)}
																	</span>
																</td>

																<td className="text-dark-70">
																	<span className="d-block text-nowrap">R$ {curElem.VL_BALANCE_CB ? converter(curElem.VL_BALANCE_CB) : "0,00"}</span>
																</td>
																<td className="text-end">
																	{userJson && userJson.length > 0 && (userJson.indexOf(3111) !== -1) &&
																		<Col sm="auto">
																			<Dropdown className="radio--custom" autoClose="outside">
																				<Dropdown.Toggle variant="link" size="sm" className='mainStatus dropdown-arrow-none p-0'>
																					<img src={process.env.PUBLIC_URL + "/assets/images/icons/dots_danger.svg"} className="header-logo" alt="Logo" />
																				</Dropdown.Toggle>
																				<Dropdown.Menu>
																					<Dropdown.Item onClick={() => HandleSubmits(curElem.id)}>
																						{t("button.edit")}
																					</Dropdown.Item>
																					{curElem["status"] == 1 &&
																						<Dropdown.Item onClick={() => updateBankStatus(0, curElem.id)}>
																							{t("active")}
																						</Dropdown.Item>
																					}
																					{curElem["status"] == 0 &&
																						<Dropdown.Item onClick={() => updateBankStatus(1, curElem.id)} >
																							{t("formReceipt.disabled")}
																						</Dropdown.Item>
																					}
																					{/* <Dropdown.Item onClick={() => deletebankAccount("delete", curElem.id, curElem.main_ac)} >
                                            {t("button.delete")}
                                          </Dropdown.Item> */}
																				</Dropdown.Menu>
																			</Dropdown>
																		</Col>
																	}
																</td>
															</tr>
														}
													</React.Fragment>
												)
											})
											: <>
												<tr>
													<td colSpan={4} className="text-center">{t("OOPS")}.</td>
												</tr>
											</>
										}
									</tbody>
								</Table>
							</Card.Body>
						</Card>

						{/* <!-- /Table end --> */}
						<div className="w-100 d-flex justify-content-between  my-lg-4 my-3">
							<p className="d-block mb-0">{t("company.listing")} {bankAccount && bankAccount?.length} {t("banks.bankAccounts")}</p>
							<p className="d-block mb-0">R$ {totalAmount ? converter(`${totalAmount}`) : "0,00"}</p>
						</div>

					</Col>
					{/* } */}
				</Row>
			</main>
			{/* <!-- /Main content start end --> */}
			{/*<!-- New bank account modal start here --> */}
			<Newbankaccounts userbanklist={bankAccount} />
			{/*<!-- /New bank account modal end --> */}
			{/* <!-- Bank account edit modal start here --> */}
			<EditBankAccount bankid={id} setId={setId} />
			{/* <!-- /Bank account edit modal end --> */}
		</section>
	)
}
export default BankAccounts