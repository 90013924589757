import { Form, ButtonGroup, InputGroup, Modal, Button, Row, Col, Dropdown, Table, Card, FloatingLabel, Collapse } from 'react-bootstrap';
import { newLiquidateOpen, setLiquidateType, setLiquidateTitle, setExpenseTitle, setExpenseType, setExpenseCheckbox, newExpenseOpen, contractsExpenseData, getAllService } from "../../../../redux/slice/contractDataSlice";
import React, { useState, MouseEvent, useEffect } from 'react';
import { setExpType, getAllSuppliers, getAllBillMethodListing } from "../../../../redux/slice/expenditureDataSlice";
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Loader from '../../../Profile/Company/Loader';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import ExpenseService from '../Common/ExpenseService';
import LiquidateService from '../Common/LiquidateService';
import { getManager } from "../../../../redux/slice/propertyDataSlice";
import { contractsData } from "../../../../redux/slice/contractDataSlice";
import Checkbox from '../Common/Checkbox';
import Swal from 'sweetalert2';
import customerService from '../../../../services/customerService';
import { expenditureForm } from '../../../Interface';

import '../../../../App.css';
import { converter, encrypt_Decrypt } from '../../../constants/utils';
import moment from "moment-timezone";

const Expenditure = (expenditureForm) => {
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    const { suppliersData, status } = useSelector((state: RootState) => state.expenditureData)
    const { contExpenseData, serviceData, expenseStatus } = useSelector((state: RootState) => state.contractData)
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { contractData } = useSelector((state: RootState) => state.contractData)
    const { managerData } = useSelector((state: RootState) => state.propertyData)
    const { register, handleSubmit, reset, formState: { errors } } = useForm<expenditureForm>();
    const [blrvalue, setBlrValue] = useState(0);
    const [listingexpenses, setListingExpenses] = useState(0);
    const [showperiodfilter, setShowPeriodFilter] = useState(false)
    const [minValue, setMinValue] = useState('')
    const getContractsExpense = async (contData) => {
        let response = await dispatch(contractsExpenseData(contData))
    }
    let newDate = new Date(dateBrazil);
    let startDt = moment(newDate).subtract(1, 'M');
    let searchDt = moment(newDate)
    let endDt = moment(newDate).add(1, 'M');
    const navigate = useNavigate();
    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(3040) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])

    /*----------New/Recurring expense popup modal function--------*/

    const expenseOpen = (evt) => {
        dispatch(setExpenseTitle(evt))
        dispatch(setExpenseType(true));
        dispatch(newExpenseOpen(true));
        dispatch(setExpType(true))
        dispatch(setExpenseCheckbox(false));
    }
    const handleShow = () => {
        setShowPeriodFilter(true)
    }
    const peridfilterClose = () => {
        setShowPeriodFilter(false)
        reset()
    }

    /*-------Checked/Unchecked multiple checkbox functionality--------*/

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState<any[]>([]);

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(contExpenseData.data.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    const noneHandleSelectAll = e => {
        setIsCheckAll(false)
        setIsCheck([]);
    }
    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseFloat(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseFloat(id)));
        }
        if (document.querySelectorAll('.check_Checkbox input').length > 0) {
            let data = document.querySelectorAll('.check_Checkbox input');
            let count_allCheck = 0;
            setTimeout(() => {
                data.forEach((item, index) => {
                    if (item["checked"]) {
                        count_allCheck++
                    }
                    if (!item["checked"]) {
                        setIsCheckAll(false);
                    }
                })
                if (count_allCheck == data.length) {
                    setIsCheckAll(true);
                }
            }, 10)
        }
    };

    /*----------Liquidate/Throw popup modal function--------*/

    const liquidateOpen = (evt) => {
        if (isCheck.length > 0) {
            if (evt === "Liquidate") {
                dispatch(setLiquidateType(true));
            }
            else {
                dispatch(setLiquidateType(false));
            }
            dispatch(setLiquidateTitle(evt));
            dispatch(newLiquidateOpen(true));
        }
        else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.Pleasecheckedthe"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    }

    /*-------Delete functionality--------*/

    const deleteExpense = () => {
        let trimmedNumbers = isCheck.map(num => {
            return num.toString().split('.')[0];
        });

        if (isCheck.length > 0) {
            Swal.fire({
                title: t("Sweetalert.Areyousure"),
                text: t("Sweetalert.Youwontbeabletorevert"),
                //// icon: 'success','warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t("newTextSweetAlert.Yes_it"),
                cancelButtonText:  t('button.cancel'),
            }).then((result) => {
                if (result.value) {
                    customerService.deleteExpenses({ id: trimmedNumbers }).then(res => {
                        /*-------Check the api response--------*/
                        if (res.data.resCode === 408) {
                            Swal.fire({
                                title: t("Sweetalert.Success"),
                                text: t("Sweetalert.successget"),
                                // icon:"success",
                                confirmButtonText: "OK",
                                timer: 2000
                            });
                            dispatch(contractsExpenseData({ expense: '', start: 0, end: 10 }))
                        }
                        else {
                            Swal.fire({
                                title: t("Sweetalert.Error"),
                                text: t("Sweetalert.somethingWent"),
                                // icon:"error",
                                confirmButtonText: "OK",
                                timer: 2000
                            });
                        }
                    }).catch(error => {
                        /*-------Error exception handling--------*/
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: t("Sweetalert.somethingWent"),
                            // icon:"error",
                            confirmButtonText: "OK",
                            timer: 2000
                        });
                    });
                }
            })
        }
        else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.Pleasecheckedthe"),
                // icon:"error",
                confirmButtonText: "OK",
                timer: 2000
            });
        }
    }
    const [state, setState] = useState({
        contract: "",
        status: "",
        manager: "",
        service: "",
        supplier: "",
        timePeriod: "",
        startDate: startDt,
        searchDate: searchDt,
        endDate: endDt,
        searchType: "",
        start: 0,
        end: 25
    })
    const searchContract = (evt) => {
        const name = evt.target.name;
        const value = evt.target.value;
        setState({
            ...state,
            [name]: value
        })
    }

    useEffect(() => {
        dispatch(getManager());
        dispatch(getAllSuppliers({ "supplier": "", "start": 0, "end": 1000 }));
        dispatch(getAllService());
        dispatch(contractsData({ "start": 0, "end": 1000 }));
        dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
    }, [dispatch])


    useEffect(() => {
        getContractsExpense(state);
    }, [state])



    let total_adm_fee = 0;
    useEffect(() => {
        if (contExpenseData?.data) {
            setListingExpenses(contExpenseData.data.length);
            contExpenseData?.data?.forEach((item: any) => {
                if (item?.value && (item["value"]).includes(",")) {
                    total_adm_fee += parseFloat(item?.value?.replace(',', ''));
                } else {
                    total_adm_fee += parseFloat(item?.value);
                }
            })
            let total: any = total_adm_fee?.toFixed(2);
            total = total?.replace(/\.?0+$/, '')
            setBlrValue(total);
        }
    }, [contExpenseData.data])
    // Filters section
    const [openFilters, setFiltersOpen] = useState(false);

    const dateSubmit = (data:any) => {
        let start2:any = data.startDate; // Assign within function
        let endDt2:any = data.endDate;
        if(start2 && endDt2){

            let monthsDiff = moment(new Date(endDt2)).diff(new Date(start2), 'months', true);
            if(Math.round(monthsDiff) <=12){
                setState({
                    ...state,
                    startDate: start2,
                    endDate: endDt2,
                    searchDate: start2,
                    searchType: "period",
                    end: 1000, // Adjust this based on your logic
                });

                setShowPeriodFilter(false);
                reset();
            }
            else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("limitExpDate"),
                    confirmButtonText: "OK",
                    timer: 2000
                })
            }
        }
        else{
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("requiredMmessage.fieldRequired"),
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    }

    const startDate = (e: any) => {
        let { value } = e.target
        setMinValue(value)
    }

    const filterCharge = (dt, tp) => {
        if (typeof tp !== "undefined") {
            let currentDate = new Date(dt);
            if (tp == "Subtract") {
                startDt = moment(currentDate).subtract(1, 'M');
                searchDt = moment(startDt).add(1, 'M');
                endDt = moment(currentDate).add(1, 'M');
            }
            else {
                startDt = moment(currentDate).subtract(1, 'M');
                searchDt = moment(startDt).add(1, 'M');
                endDt = moment(currentDate).add(1, 'M');
            }
            setState({
                ...state,
                ['startDate']: startDt,
                ['searchDate']: searchDt,
                ['searchType']: '',
                ['endDate']: endDt,
            })
        }
    }

    return (
        <section className="content-wrapper content-wrapper-primary" id="contractExpenditure">
            {/* <!-- Breadcrumb section start from here --> */}
            <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-end filters border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100 mb-xl-4">
                    <Row className="justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 5, order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <Link to={"/contracts"}><img src="./assets/images/icons/arrow-left.svg" className="h-14" alt="Arrow left" /></Link>
                                <div className="w-100 overflow-hidden ms-2 ms-xl-3">
                                    <h1 className="mb-0 h4 lh-base text-truncate">{t('expenditure.contractualExpenses')}</h1>
                                </div>
                                {/* Filter button visible in mobile devices */}
                                <div className="d-xl-none">
                                    <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                                        {t("dropdownItem.filters")}
                                        <img
                                            src="assets/images/icons/filter-icon.svg"
                                            alt="Filter"
                                            className="ms-1 h-12"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 1 }} xl={{ span: 7, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                            {
                                userJson && userJson.length > 0 && (userJson.indexOf(3041) !== -1) &&
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                        <a onClick={() => expenseOpen("New expense")} className="fw-bold cursor-pe">
                                            <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />{t('expenditure.newExpense')}
                                        </a>
                                    </li>
                                    <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                        <a onClick={() => expenseOpen("New recurring expense")} className="fw-bold cursor-pe">
                                            <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />{t('expenditure.newRecurringExpense')}
                                        </a>
                                    </li>
                                    {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" />
                                </li> */}
                                </ul>
                            }
                        </Col>
                    </Row>
                </div>
                {/* Filters */}
                <Collapse className="d-xl-block collpase-filters" in={openFilters}>
                    <Form id="collpase-filter-text" action="" method="">
                        <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
                            <h5 className="mb-0 fw-bold">Filters</h5>
                            <span className="cursor-pe" onClick={() => setFiltersOpen(!openFilters)}>
                                <img src="assets/images/icons/cross.svg" alt="Plus icon" className="ms-xxl-1 ms-auto h-14" />
                            </span>
                        </div>
                        <Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm">
                                        {t('dropdownItem.status')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="px-4">
                                        <FloatingLabel label={t('dropdownItem.status')}>
                                            <Form.Select {...register('status')} onChange={searchContract} aria-label="Status">
                                                <option value="All">{t('formLabel.all')}..</option>
                                                <option value="1">{t('customerAndEmployees.active')}</option>
                                                <option value="0">{t('dropdownItem.Inprogress')}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>

                            <Col xl="auto">
                                <ButtonGroup className="btn-date-filter" size="sm" aria-label="Date">
                                    <Button variant="primary" className="btn-arrow" onClick={() => filterCharge(state.startDate, 'Subtract')}>
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white.svg"} className="h-24" />
                                        {/* {state.startDate} */}
                                    </Button>
                                    <Button variant="primary" className="btn-date capitalize" disabled >
                                        {moment(state.searchDate).format("MMM/YYYY")}
                                    </Button>
                                    <Button variant="primary" className="btn-arrow" onClick={() => filterCharge(state.endDate, 'Add')}>
                                        {/* {state.endDate} */}
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white-right.svg"} className="h-24" />
                                    </Button>
                                </ButtonGroup>
                            </Col>

                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm">
                                        {t('contracts')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t('contracts')}>
                                            <Form.Select {...register('contract')} onChange={searchContract} aria-label="Contracts">
                                                <option value="All">{t('formLabel.all')}..</option>
                                                {contractData.data &&
                                                    contractData.data.length ?
                                                    contractData.data.map((item, index) => (
                                                        <option key={index} value={item.id}>{item.complement ? item.complement : '?'} - {item.type}</option>
                                                    ))
                                                    :
                                                    ''
                                                }
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm">
                                        {t('taxation.services')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t('taxation.services')}>
                                            <Form.Select {...register('service')} onChange={searchContract} aria-label="Services">
                                                <option value="All">{t('formLabel.all')}..</option>
                                                {serviceData.map((item, index) => (
                                                    <option key={index} value={item.id}>{item.name ? item.name : '?'}</option>
                                                ))

                                                }
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm">
                                        {t('customerAndEmployees.suppliers')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t('customerAndEmployees.suppliers')}>
                                            <Form.Select {...register('supplier')} onChange={searchContract} aria-label="Suppliers">
                                                <option value="All">{t('formLabel.all')}..</option>
                                                {suppliersData.data && suppliersData.data.length ? suppliersData.data.map((item, index) => (
                                                    <option key={index} value={item.id}>{item.name ? item.name : '?'}</option>
                                                ))
                                                    :
                                                    ''
                                                }
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm">
                                        {t('property.managers')}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t('property.managers')}>
                                            <Form.Select {...register('manager')} onChange={searchContract} aria-label="Manager">
                                                <option value="All">{t('formLabel.all')}..</option>
                                                {managerData.map((item, index) => (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                ))}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" onClick={handleShow}>
                                        {t("periodfilter")}
                                    </Dropdown.Toggle>
                                </Dropdown>
                            </Col>
                        </Row>
                    </Form>
                </Collapse>
                {/* Filters */}
            </div>
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {/* <!-- Table start from here -->*/}

                {
                    (expenseStatus == "loading") ?
                        <Loader />
                        :
                        <Card>
                            <Card.Body className="pb-1">
                                <Table responsive className="table-custom table-custom-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th className="w-24">
                                                {
                                                    contExpenseData.data && contExpenseData.data.length > 0 ?
                                                        <Checkbox type="checkbox" name="selectAll" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} />
                                                        :
                                                        ''
                                                }
                                            </th>
                                            <th>{t('expenditure.Maturity')}</th>
                                            <th>{t('expenditure.Details')}</th>
                                            <th>{t('expenditure.value')}</th>
                                            <th className="text-end"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contExpenseData.data && contExpenseData.data.length > 0 ? contExpenseData.data.map((item, index) => (
                                            <tr key={index}>
                                                <td className={"check_Checkbox"}>
                                                    <Checkbox key={index} type="checkbox" name={item.id} id={item.id} handleClick={handleClick} isChecked={isCheck.includes(item.id)} />
                                                </td>
                                                <td className="text-dark-70">
                                                    <span className="d-block capitalize"> {item.maturityDate} </span>
                                                    <span className="d-block">{t("contractlistother.Expiration")}</span>
                                                    <span className="d-block capitalize"> {item.maturity}</span>
                                                </td>
                                                <td>
                                                    <span className="text-dark-70">{item.type == "New expense" ? t("newExpense") : t("newRecurringExp")} {item?.propertyId} / {item?.contractId}</span>
                                                    <span className="mx-1">
                                                        <img src="assets/images/icons/home_g.svg" />
                                                        <span className="ps-1">
                                                            {item?.propertyType} in {item?.address}, {item?.city},{item?.state} - {item?.zipcode}
                                                        </span>
                                                    </span>
                                                    <span className="d-block">
                                                        <img src="assets/images/icons/clock.svg" />
                                                        <span className="ps-1">
                                                            {t(`${item?.debit.toLowerCase()}`)} {t("formLabel.debit").toLowerCase()}
                                                        </span>
                                                    </span>
                                                    <span className="d-block">
                                                        <img src="assets/images/icons/clock.svg" />
                                                        <span className="ps-1">
                                                            {t(`${item?.credit.toLowerCase()}`)} {t("formLabel.credit").toLowerCase()}
                                                        </span>
                                                    </span>
                                                    {item?.credit == "Third" ? item?.supName : ''}
                                                </td>
                                                <td className="text-dark-70">
                                                    <span className="d-block text-nowrap"> R$ {converter(`${item?.value}`)}</span>
                                                </td>
                                                <td className="text-end">
                                                    <div className="d-flex justify-content-end">
                                                        {
                                                            userJson && userJson.length > 0 && (userJson.indexOf(3043) !== -1) &&
                                                            <span className="icon"><img src="assets/images/icons/noun-refresh.svg" /></span>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>

                                        )) :
                                            <tr>
                                                <td colSpan={5}>
                                                    <span>{t('formLabel.noRecordsFounds')}</span>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                }
                {/* <!-- /Table end -->*/}
                <div className="mt-lg-4 mt-3"> {t("formLabel.moreItems")}</div>
                {/* <p className="text-secondary d-block py-2">
                    {t('formLabel.listing')} {contExpenseData.totalRow > 0 ? contExpenseData.totalRow : 0} {t('expenditure.expenses')}. {contExpenseData.secondTotalRow > 0 ? contExpenseData.secondTotalRow : 0} {t('expenditure.beingrecurring')}.
                </p> */}
                <p className="text-secondary d-block py-2">
                    {t('formLabel.listing')} {listingexpenses} {t('expenditure.expenses')}. {contExpenseData ? contExpenseData.totalRecExpense : 0} {t('recExpense')}. Total Adm Fee R$ {blrvalue}
                </p>
                {/* <!-- Nav listing start from here -->*/}

                <ul className="list-inline mb-0">
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        <Dropdown>
                            <Dropdown.Toggle variant="light" size="sm">
                                {t('formLabel.mark')}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={handleSelectAll} href="">{t('formLabel.all')}</Dropdown.Item>
                                <Dropdown.Item onClick={noneHandleSelectAll} href="">{t('formLabel.none')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                    {
                        userJson && userJson.length > 0 && (userJson.indexOf(3041) !== -1) &&
                        <>
                            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2"> <span className="px-1">{t('expenditure.withTagged')}:</span></li>
                            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                <Button variant="light" size="sm" onClick={() => liquidateOpen("Liquidate")} name="Liquidate">{t('expenditure.Settleonpayable')}</Button>
                            </li>
                            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                <Button variant="light" size="sm">{t('expenditure.reversePayable')}</Button>
                            </li>
                            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                <Button variant="light" size="sm" onClick={() => liquidateOpen("To throw")} name="To throw">{t('expenditure.launchLeavePending')}</Button>
                            </li>
                            <li className="list-inline-item mb-lg-0 mb-1">
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" size="sm">
                                        {t("dropdownItem.most")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href={"#"} onClick={deleteExpense}>{t('button.delete')}</Dropdown.Item>
                                        <Dropdown.Item href="#">{t('expenditure.receiptPayable')}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                        </>
                    }
                </ul>
                {/*  <!-- /Nav listing  end --> */}
            </main>
            {/* <!-- /Main content start end --> */}
            <ExpenseService />
            <LiquidateService />
            <Modal show={showperiodfilter} onHide={peridfilterClose}>
                <Form onSubmit={handleSubmit(dateSubmit)} id="expenditureForm">
                    <Modal.Header closeButton>
                        <Modal.Title>{t("TransferPage.month")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="g-3 g-xl-4">
                            <Col md>
                                <FloatingLabel label={t("TransferPage.startDate")}>
                                    <Form.Control type="date" aria-label="From"  {...register("startDate")} onChange={startDate}></Form.Control>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel label={t("TransferPage.endDate")}>
                                    <Form.Control type="date" aria-label="to" min={minValue}   {...register("endDate")} ></Form.Control>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={peridfilterClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" type='submit'>
                            {t("button.save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </section>
    )
}
export default Expenditure