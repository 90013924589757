import React, { useState, useEffect, MouseEvent, useRef } from 'react';
import { Modal, Form, Button, FloatingLabel, Accordion, Row, Col, Alert, InputGroup, ListGroup } from 'react-bootstrap';
import { getAllService, getOwner, getProperty } from "../../../../redux/slice/contractDataSlice";
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { newServiceNoteForm } from '../../../Interface';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
// import NewCustomer from './NewCustomer';
import eventBus from "../ReceivableCharges/EventBus";
import { numericOnly, openErrorbox } from '../../Contracts/Common/actionPerform';
import { incomeService } from '../../../../services/incomeServices';
import Swal from 'sweetalert2';
import { fetchService, InvoiceServices } from '../../../../redux/slice/ServiceSlice';
import { GetCityCode, Get_Deduction_list, ServiceRecord, getTaxation, post_Deduction, } from '../../../../redux/slice/taxationSlice';
import moment from "moment-timezone";
import { converter, convertToNumber, formatNumber } from '../../../constants/utils';
import { registerData } from '../../../../redux/slice/companyDataSlice';
import customerService from '../../../../services/customerService';
import { getValue } from '@testing-library/user-event/dist/utils';

const NewServiceNote = (props) => {
    const [t] = useTranslation();
    const inputRef = useRef<any>(null);
    //New service note modal close open function
    const { showServiceNote, serviceNoteClose, serviceNoteShow, getSingleInvoice, setGetInvioceList, state, setState } = props;
    const dispatch = useDispatch<AppDispatch>()
    const { serviceData } = useSelector((state: RootState) => state.contractData)
    const { branchList } = useSelector((state: RootState) => state.branch)
    const { taxadata, taxalist } = useSelector((state: RootState) => state.taxa)
    const { companydata } = useSelector((state: RootState) => state.companyData);
    //Deductions modal initialization
    const [showDeductions, setDeductionsModal] = useState(false);
    const [GetClient, setGetClient] = useState<any>([]);
    const [getServiceId, setGetServiceId] = useState<any>([])
    const [items, setItems] = useState([])
    const [deductionsItem, setDeductionsItem] = useState<any>([])
    const [deductions, setDeductions] = useState('');
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    let newDate = new Date(dateBrazil);
    let startDt = moment(newDate).subtract(1, 'M').format("MMM/YYYY");
    let searchDt = moment(newDate).format("MMM/YYYY")
    let endDt = moment(newDate).add(1, 'M').format("MMM/YYYY");
    const [double, setDouble] = useState(false);
    const [loader_on, setLoader_on] = useState(false);
    const [cityCodes, setCityCode] = useState<any>('')
    const [newServiceData, setServiceData] = useState<any>([])
    const [filterVal, setFilterVal] = useState('owners')
    const [state1, setState1] = useState<any>({
        status: "all",
        search_type: "",
        start: 0,
        end: 200,
        types: "AllSeries",
    })
    // const [state, setState] = useState<any>({
    //     status: "all",
    //     startDate: startDt,
    //     endDate: endDt,
    //     searchDate: searchDt,
    //     search_type: "",
    //     start: 0,
    //     end: 20
    // })
    const deductionsClose = () => {
        setDeductionsModal(false);
        serviceNoteShow(true);
    }
    const deductionsShow = () => {
        setDeductionsModal(true);
        serviceNoteClose(false);
    }


    // New Service Note Form 
    const validationSchema = Yup.object().shape({
        multipleTenant: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        client: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        issuanceDate: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        branch: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
        servicesetting: Yup.array().of(
            Yup.object().shape({
                // Add validations for each property within the array of objects
                service: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
                amount: Yup.string().nullable().required(t("requiredMmessage.fieldRequired")),
                unitaryValue: Yup.string().nullable().required(t("requiredMmessage.fieldRequired")),
                total: Yup.string().nullable().required(t("requiredMmessage.fieldRequired")),
                // Add more properties as needed
            })
        ).nullable().required('Array is required'),
    });
    // New Service Note Form 
    const validationSchema1 = Yup.object().shape({
    });
    const validationSchema5 = Yup.object().shape({
        series: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")),
    });

    let frm = {
        resolver: yupResolver(validationSchema1),
    };
    if (showDeductions) {
        frm = {
            resolver: yupResolver(validationSchema1),
        };
    }
    else if (items.length > 0) {
        frm = { resolver: yupResolver(validationSchema.concat(validationSchema5)) };
    } else {
        frm = { resolver: yupResolver(validationSchema) }
    }
    const { register, handleSubmit, setValue, getValues, reset, setError, clearErrors, formState: { errors } } = useForm<newServiceNoteForm>(frm);

    useEffect(() => {
        (async () => {
            let ownerData = { owner: "", start: 0, end: 1000, status: "", person: "", page: 'ownersandbenef', invoice: "invoiceList", respoType: filterVal }
            customerService.getAllOwner(ownerData).then(res => {
                if (res.data.resCode == 201) {
                    setGetClient(res.data.data);
                } else {
                    setGetClient([]);
                }
            }).catch(err => {
            });

        })()
        //dispatch(getTaxation());

        if (getSingleInvoice?.data?.length > 0) {
            const fields: string[] = ['client'];
            fields.forEach((field: any) => setValue(field, getSingleInvoice?.data[0]?.[field]));
        }
    }, [filterVal])

    //     useEffect(()=>{
    //         dispatch(getTaxation());
    //             dispatch(registerData());
    //     },[dispatch])
    //     useEffect(() =>{
    //         if(companydata.length){
    //             cityCode()
    //         }
    //     },[showServiceNote])

    //     const  cityCode  = async () =>{
    //      let res = await  dispatch(GetCityCode())
    //     if(res.payload.length>0){
    //         const filteredData = res.payload.filter(item => item?.nome == companydata[0]?.city && item?.uf == companydata[0]?.state);
    //         setCityCode(filteredData[0]?.id)
    //     }
    // }
    const formSubmit = async (data: any) => {
        let Idcode = '';
        GetClient.forEach((curElem: any, index: number) => {
            if (curElem.name == data.client) {
                Idcode = curElem.id
            }
        })
        data["client"] = Idcode
        setDouble(true);
        setLoader_on(true);

        let LimitAccessDate: any = [];
        for (var key in servicesFields) {
            var value = servicesFields[key];
            if (key.indexOf('open') >= 0) {
                LimitAccessDate.push(value);
            }
        }
        let deductionData: any = [];
        for (var key in deductionFields) {
            var value = servicesFields[key];
            if (key.indexOf('open') >= 0) {
                deductionData.push(value);
            }
        }
        // // Continue with form submission if all required fields are filled
        // let newdispatch = await dispatch(fetchService());

        // data?.servicesetting.forEach(service => {
        //     // Find matching product for the current service
        //     let product: any = newdispatch?.payload?.find(p => p.ID_PRODUTO_PRD.toString() == service.service);

        //     if (product) {
        //         // Find matching service code for the current product
        //         let serviceCode: any = taxalist.length ? taxalist.find(s => s.id == product.ID_CODIGOSERVICO_CSE) : '';

        //         if (serviceCode) {
        //             service.serviceCodeDetails = serviceCode;
        //         }else{
        //             service.serviceCodeDetails = {};
        //         }
        //     }
        // });
        data["LimitAccessDate"] = LimitAccessDate;
        let totalRetention: any = `${data.totalRetention}`;
        let totalRetention_Val: any = convertToNumber(totalRetention);
        let grandTotal: any = `${data.grandTotal}`;
        let grandTotal_Val: any = convertToNumber(grandTotal);
        let issqnValue: any = `${data.issqnValue}`;
        let issqnValue_Val: any = convertToNumber(issqnValue);

        let calculationBasis: any = `${data.calculationBasis}`;
        let calculationBasis_Val: any = convertToNumber(calculationBasis);
        let calculationBasis2: any = `${data.calculationBasis2}`;
        let calculationBasis2_Val: any = convertToNumber(calculationBasis2);
        let calculationBasis3: any = `${data.calculationBasis3}`;
        let calculationBasis3_Val: any = convertToNumber(calculationBasis3);
        let calculationBasis4: any = `${data.calculationBasis4}`;
        let calculationBasis4_Val: any = convertToNumber(calculationBasis4);
        let calculationBasis5: any = `${data.calculationBasis5}`;
        let calculationBasis5_Val: any = convertToNumber(calculationBasis5);
        let calculationBasis6: any = `${data.calculationBasis6}`;
        let calculationBasis6_Val: any = convertToNumber(calculationBasis6);
        data["totalRetention"] = totalRetention_Val
        data["grandTotal"] = grandTotal_Val
        data["issqnValue"] = issqnValue_Val

        data["calculationBasis"] = calculationBasis_Val
        data["calculationBasis2"] = calculationBasis2_Val
        data["calculationBasis3"] = calculationBasis3_Val
        data["calculationBasis4"] = calculationBasis4_Val
        data["calculationBasis5"] = calculationBasis5_Val
        data["calculationBasis6"] = calculationBasis6_Val
        // data["city_code"] = cityCodes;
        for (const key in data.servicesetting) {
            if (data.servicesetting[key].hasOwnProperty('total') && data.servicesetting[key].hasOwnProperty('unitaryValue')) {
                data.servicesetting[key].total = convertToNumber(data.servicesetting[key].unitaryValue);
                data.servicesetting[key].unitaryValue = convertToNumber(data.servicesetting[key].unitaryValue);
            }

        }
        incomeService.addInvoice(data).then((res) => {
            if (res.resCode === 201) {
                setState({
                    ...state,
                    ['start']: 0
                })
                setDouble(false);
                setLoader_on(false);

                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.insertRecord"),
                    // icon: 'success',"success",
                    confirmButtonText: "OK",
                    timer: 2000
                });
                reset();
                serviceNoteClose(false);
            }
            else if (res.resCode === 403 || res.resCode === 402) {
                setDouble(false);
                setLoader_on(false);

                Swal.fire({
                    title: `${t('Sweetalert.Error')}`,
                    text: res.message,
                    confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                    timer: 3000
                });

            }
            else {

                setDouble(false);
                setLoader_on(false);

                Swal.fire({
                    title: `${t('Sweetalert.Error')}`,
                    text: `${t("Sweetalert.somethingWent")}`,
                    // icon: 'success',"error",
                    confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                    timer: 2000
                });
            }
        });
    };

    useEffect(() => {
        if (showServiceNote) {
            dispatch(getProperty({ "start": 0, "end": 1000 }));
            eventBus.on("chargeIdis", (data) => {
            });

            let newIpitem = [{
                [`open_0`]: {
                    "service": "",
                    "complement": "",
                    "amount": "",
                    "unitaryValue": "0,00",
                    "total": "0,00"
                }
            }]
            setIPInputFields({ ...servicesFields, ...newIpitem[0] })
            setMyIpCount(1);
            let newDeduction = [{
                [`open_0`]: {
                    "deduction": "",
                    "deductionValue": "0,00",
                }
            }]
            setDeductionFields({ ...deductionFields, ...newDeduction[0] })
            setDeductionCount(1);
            dispatch(getAllService());
        }
        if (branchList?.length > 0) {
            setTimeout(() => {
                branchList?.forEach((ele: any) => {
                    if (ele?.id == 0 || ele?.id == 1) {
                        setValue("branch", ele?.id)
                    }
                });

            }, 1000)
        }

    }, [showServiceNote])
    useEffect(() => {
        const fetchDeductions = async () => {
            let res = await dispatch(Get_Deduction_list());
            if (res.payload.type == "Success" || res.payload.type == "success") {
                setDeductionsItem(res?.payload?.data)
            } else {
                setDeductionsItem([])
            }
        };
        fetchDeductions();

    }, [showServiceNote, dispatch]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const newData: any = await dispatch(InvoiceServices());
                if (newData.payload.type == "Success" || newData.payload.type == "success") {
                    setServiceData(newData.payload.data)
                } else {
                    setServiceData([])
                }
                // Do something with newData if needed
            } catch (error) {
                console.error("Error fetching invoice services:", error);
            }
        };

        fetchData();
    }, [showServiceNote, dispatch]);

    const [myIPsCount, setMyIpCount] = useState(0);
    const [servicesFields, setIPInputFields] = useState<any>({});
    const [totalAmount, setTotalAmount] = useState(0)
    const addNewService = () => {
        let newindex = myIPsCount;
        let newitem = [{
            [`open_${newindex}`]: {
                "service": "",
                "complement": "",
                "amount": "",
                "unitaryValue": "",
                "total": "0,00"
            }
        }]
        setIPInputFields({ ...servicesFields, ...newitem[0] })
        // setMyIpCount(newindex);
        setMyIpCount(myIPsCount + 1);
    }

    // culumn is change the data one by one from the opening horse
    const addAmount = (index, evnt, type) => {

        if (type == "Unitary") {
            let { value } = evnt.target;
            if (value.includes("-")) {
                const regex = /^[-]?\d*([.,]\d*)*$/;
                const minusCount = (value.match(/-/g) || []).length;
                if (regex.test(value) && minusCount == 1) {
                    value = value.replace(/[^\d,.-]/g, '');
                    let splitminux = value.split("-");
                    const cleanedNumber = (splitminux[1]).replace(/\./g, '');
                    let format = '-' + formatNumber(cleanedNumber);
                    setValue(`servicesetting.${index}.unitaryValue`, format);
                }
            } else {
                value = value.replace(/[^\d,]/g, '');
                setValue(`servicesetting.${index}.unitaryValue`, formatNumber(value));
            }
        }
        var name = evnt.target.name;
        var newName = name.replace(`servicesetting.${index}.`, '');
        // Assuming index is used to determine which object to update
        const updatedFields = { ...servicesFields };
        let { value } = evnt.target;
        value = value.replace(/[^\d,]/g, '');
        updatedFields[`open_${index}`][newName] = evnt.target.value
        Object.keys(updatedFields).forEach((key: string) => {
            // Create a copy of the item's fields
            const { amount, unitaryValue }: { amount: string, unitaryValue: string } = { ...updatedFields[`open_${index}`] };
            // Convert amount and unitaryValue to numbers
            let amt: any = convertToNumber(String(unitaryValue))
            const parsedAmount = Number(amount);
            const parsedUnitaryValue = Number(amt);
            // Calculate the total
            const total = parsedAmount * parsedUnitaryValue;
            // Update the total in the copied object
            const updatedItem = { ...updatedFields[`open_${index}`], total: total };
            // Update the total value in the form state

            setValue(`servicesetting.${index}.total`, updatedItem.total ? converter(String(updatedItem.total)) : "0,00");
            // Update the updatedFields object with the new item
            updatedFields[`open_${index}`] = updatedItem;
        });
        let overallTotal = 0;

        // Loop through each object in the data
        for (const key in updatedFields) {
            if (Object.hasOwnProperty.call(updatedFields, key)) {
                const element = updatedFields[key];
                overallTotal += parseFloat(element.total); // Add the total value to the overall total
            }
        }
        setValue("grandTotal", converter(String(overallTotal)));
        setValue("totalRetention", converter(String(overallTotal)));
        setValue("calculationBasis", converter(`${overallTotal}`))
        setValue("calculationBasis2", converter(`${overallTotal}`))
        setValue("calculationBasis3", converter(`${overallTotal}`))
        setValue("calculationBasis4", converter(`${overallTotal}`))
        setValue("calculationBasis5", converter(`${overallTotal}`))
        setValue("calculationBasis6", converter(`${overallTotal}`))
        setTotalAmount(overallTotal)
        setIPInputFields(updatedFields)

        if (type == "Unitary" || type == "amount") {
            let issuVal: any = getValues("issqn")
            let irrfVal = getValues("irrf")
            let pisVal = getValues("pis")
            let cSocialVal = getValues("cSocial")
            let inssVal = getValues("inss")
            let cofinsVal = getValues("cofins")
            if (overallTotal && issuVal) {
                let value2: any = (overallTotal * issuVal) / 100;
                setValue("issqnValue", converter(String(value2)))
            }
            if (overallTotal && irrfVal) {
                let value2: any = (overallTotal * issuVal) / 100;
                setValue("irrfValue", converter(String(value2)))
            }
            if (overallTotal && pisVal) {
                let value2: any = (overallTotal * issuVal) / 100;
                setValue("pisValue", converter(String(value2)))
            }
            if (overallTotal && cSocialVal) {
                let value2: any = (overallTotal * issuVal) / 100;
                setValue("cSocialValue", converter(String(value2)))
            }
            if (overallTotal && inssVal) {
                let value2: any = (overallTotal * issuVal) / 100;
                setValue("inssValue", converter(String(value2)))
            }

            if (overallTotal && cofinsVal) {
                let value2: any = (overallTotal * issuVal) / 100;
                setValue("cofinsValue", converter(String(value2)))
            }

        }
    };

    const deleteServiceRow = async (index) => {
        // Logging the updated array to the console
        let arr: any = getValues("servicesetting")
        const updateServ = [...arr]
        updateServ.splice(index, 1);
        setIPInputFields(updateServ);
        setValue("servicesetting", updateServ)
        setMyIpCount(updateServ.length);
    }
    // Deduction Add More
    const [deuctionCount, setDeductionCount] = useState(0);
    const [deductionFields, setDeductionFields] = useState({});
    const addNewDeduction = () => {
        let newindex = deuctionCount + 1;
        let newitem = [{
            [`open_${newindex - 1}`]: {
                "deduction": "",
                "deductionValue": "",
            }
        }]
        setDeductionFields({ ...deductionFields, ...newitem[0] })
        setDeductionCount(newindex);
    }
    // culumn is change the data one by one from the opening horse
    const setDeduction = (index, evnt) => {
        const { name, value } = evnt.target;
        var namew: any = evnt.currentTarget.getAttribute('data-ip');
        let data = { ...deductionFields[`open_${namew}`] };
        setDeductionFields({
            ...deductionFields,
            [`open_${namew}`]: {
                ...data,
                [name]: value
            }
        })
        // console.log('===== ', deductionFields);


    }
    useEffect(() => {
        const result = Object.keys(deductionFields).map(key => {
            const deductionId = deductionFields[key].deduction;
            const matchedItem = deductionsItem.find(item => item.id === parseInt(deductionId));

            // If a match is found, combine the data from both objects
            if (matchedItem) {
                return {
                    id: matchedItem.id,
                    deduction_Name: matchedItem.deduction_Name,
                    DeductINSS: matchedItem.DeductINSS,
                    DeductISSQN: matchedItem.DeductISSQN,
                    deductionValue: deductionFields[key].deductionValue
                };
            } else {
                return null;
            }
        }).filter(item => item !== null); // Filter out null results
        let inssTotal = 0;
        let issqnTotal = 0;
        if (result.length > 0) {
            result?.forEach((deduction: any) => {
                if (deduction.DeductINSS) {
                    inssTotal += Number(convertToNumber(String(deduction.deductionValue)));
                }
                if (deduction.DeductISSQN) {
                    issqnTotal += Number(convertToNumber(String(deduction.deductionValue)));
                }
            });
        }
        setValue("deductions", converter(String(issqnTotal)))
        setValue("deductions5", converter(String(inssTotal)));

        if (issqnTotal !== 0) {
            let newissqnVal = totalAmount - issqnTotal
            setValue("calculationBasis", converter(String(newissqnVal)))
        } else {
            setValue("calculationBasis", converter(String(totalAmount)))
        }
        if (inssTotal !== 0) {
            let newissqnVal = totalAmount - inssTotal
            setValue("calculationBasis5", converter(String(newissqnVal)))
        } else {
            setValue("calculationBasis5", converter(String(totalAmount)))
        }

    }, [deductionFields, totalAmount])
    const deleteDeductionRow = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        var rowid: any = 0;
        rowid = e.currentTarget.getAttribute('data-rowid');
        let dataall: any = [{ ...deductionFields }];
        let newInputField: any = Object.values(dataall[0]);
        let data: any = [];
        let i = 0;
        newInputField.forEach(async (item, index) => {
            if (index != parseInt(rowid) && rowid > 0) {
                data.push({
                    [`open_${i}`]: {
                        "deduction": item.deduction,
                        "deductionValue": item.deductionValue,
                    }
                })
                i++;
            }
        })
        let inputField = [];
        data.forEach(async (item, index) => {
            inputField[`${Object.keys(item)}`] = { ...item[`${Object.keys(item)}`] }
        })
        setDeductionFields({ ...inputField });
        setDeductionCount(deuctionCount - 1);
    }

    const handleSelectChange = (e, index) => {
        // Update the servicesFields state based on the selected value
        const updatedFields = { ...servicesFields };
        updatedFields[`open_${index}`]['service'] = e.target.value;

        setIPInputFields(updatedFields);
    };

    const handleDeductionChange = (e) => {
        // Get the entered value
        let value = e.target.value;
        // Ensure the value is a number
        value = parseFloat(value);
        // Restrict the value to be between 0 and 100
        if (isNaN(value)) {
            // Handle non-numeric input, you may want to show an error message
            value = '';
        } else {
            value = Math.min(Math.max(value, 0), 100);
        }

        // Update the state with the sanitized value
        setDeductions(value);
    };

    let totalRetention: any = 0;
    const BasicCalculation = (e, type) => {
        const fieldMapping = {
            issqn: "issqnValue",
            irrf: "irrfValue",
            pis: "pisValue",
            cSocial: "cSocialValue",
            inss: "inssValue",
            cofins: "cofinsValue",
        };
        let toRetain = getValues("toRetain")
        let toRetain2 = getValues("toRetain2")
        let toRetain3 = getValues("toRetain3")
        let toRetain4 = getValues("toRetain4")
        let toRetain5 = getValues("toRetain5")
        let toRetain6 = getValues("toRetain6")
        if (type == "issqn" && toRetain) {
            handleCheckBox(false, "toRetain")
            setValue("toRetain", false)
        }
        if (type == "irrf" && toRetain2) {
            handleCheckBox(false, "toRetain2")
            setValue("toRetain2", false)
        }
        if (type == "pis" && toRetain3) {
            handleCheckBox(false, "toRetain3")
            setValue("toRetain3", false)
        }
        if (type == "cSocial" && toRetain4) {
            handleCheckBox(false, "toRetain4")
            setValue("toRetain4", false)
        }
        if (type == "inss" && toRetain5) {
            handleCheckBox(false, "toRetain5")
            setValue("toRetain5", false)
        }
        if (type == "cofins" && toRetain6) {
            handleCheckBox(false, "toRetain6")
            setValue("toRetain6", false)
        }

        if (e.target.value !== "" && !isNaN(e.target.value)) {
            let { value } = e.target;
            value = convertToNumber(String(value));
            let value2: any = (value * totalAmount) / 100;
            totalRetention += value2;
            if (fieldMapping[type]) {
                setValue(fieldMapping[type], converter(String(value2)));
            }
            // let newVal = totalAmount - totalRetention
            // setValue("totalRetention", converter(String(newVal)));
        } else {
            let issuVal = getValues("issqnValue")
            let irrfVal = getValues("irrfValue")
            let pisVal = getValues("pisValue")
            let cSocialVal = getValues("cSocialValue")
            let inssVal = getValues("inssValue")
            let cofinsVal = getValues("cofinsValue")
            let totalRetention1 = getValues("totalRetention")
            let totalNewVal: any = 0
            if (issuVal !== undefined && type == "issqn") {
                totalNewVal = convertToNumber(String(totalRetention1)) + convertToNumber(String(issuVal))
                totalRetention = totalRetention - convertToNumber(String(issuVal))
            }
            if (irrfVal !== undefined && type == "irrf") {
                totalNewVal = convertToNumber(String(totalRetention1)) + convertToNumber(String(irrfVal))
                totalRetention = totalRetention - convertToNumber(String(irrfVal))
            }
            if (pisVal !== undefined && type == "pis") {
                totalNewVal = convertToNumber(String(totalRetention1)) + convertToNumber(String(pisVal))
                totalRetention = totalRetention - convertToNumber(String(pisVal))
            }
            if (cSocialVal !== undefined && type == "cSocial") {
                totalNewVal = convertToNumber(String(totalRetention1)) + convertToNumber(String(cSocialVal))
                totalRetention = totalRetention - convertToNumber(String(cSocialVal))
            }
            if (inssVal !== undefined && type == "inss") {
                totalNewVal = convertToNumber(String(totalRetention1)) + convertToNumber(String(inssVal))
                totalRetention = totalRetention - convertToNumber(String(inssVal))
            }
            if (cofinsVal !== undefined && type == "cofins") {
                totalNewVal = convertToNumber(String(totalRetention1)) + convertToNumber(String(cofinsVal))
                totalRetention = totalRetention - convertToNumber(String(cofinsVal))
            }
            // setValue("totalRetention", converter(String(totalNewVal)));
            if (fieldMapping[type]) {
                setValue(fieldMapping[type], converter(String("0,00")));
            }
        }
    };

    const checkTenantAddress = (evt) => {
        evt.preventDefault();
        GetClient.forEach(element => {
            if (element.id == evt.target.value) {
                if (element.zipcode == null || element.state == null || element.neighborhood == null) {
                    setError("client", {
                        type: "manual",
                        message: `${t("address")} ${t("ReceiveandReceived.Invalidate")}`,
                    });
                } else {
                    clearErrors("client");
                }
            }
        });
    }


    const checkTenantOwner = (e) => {
        if (e.target.value == 1) {
            setFilterVal("owners")
        } else
            if (e.target.value == 2) {
                setFilterVal("Tenants")
            } else
                if (e.target.value == 3) {
                    setFilterVal("supplier")
                } else if (e.target.value == 4) {
                    setFilterVal("guarantor")
                } else if (e.target.value == 5) {
                    setFilterVal("broker")
                } else if (e.target.value == 6) {
                    setFilterVal("inspectors")
                } else {
                    setFilterVal("")
                }

    }
    const [searchResults, setSearchResults] = useState<any>();
    const [isInputFocused, setIsInputFocused] = useState(false);
    useEffect(() => {
        setSearchResults(GetClient);

        const handleClickOutside = (event: MouseEvent) => {
            if (inputRef.current instanceof HTMLElement && !inputRef.current.contains(event.target as Node)) {
                setIsInputFocused(false);
            }
        };
        document.addEventListener("click", handleClickOutside as unknown as EventListener);
        // Cleanup function to remove event listener when the component unmounts
        return () => {
            document.removeEventListener("click", handleClickOutside as unknown as EventListener);
        };
    }, [GetClient]);
    const handlefilterBillmethod = async (e) => {
        let name = e.target.value;
        if (name.trim() !== "" && e.target.value !== "") {
            const lowerSearchTerm = name.toLowerCase();
            const results = GetClient.filter((curElem) => {
                // Convert the name and fantasy fields to lowercase for case-insensitive comparison
                const clientName = curElem.name?.toLowerCase() || "";
                const clientFantasy = curElem.fantasy?.toLowerCase() || "";
                return clientName.includes(lowerSearchTerm) || clientFantasy.includes(lowerSearchTerm);
            });
            setSearchResults(results);
        } else {
            setSearchResults(GetClient);
        }
    };

    const handleInputFocus = (e) => {
        setIsInputFocused(true);
    };
    const [primaryAdd, setPrimaryAdd] = useState<any>({})
    const HandleBankAccount = (e: any, item) => {
        setValue('client', item?.name)
        setPrimaryAdd({
            address: item?.address ? item?.address : "",
            number: item?.number ? item?.number : "",
            zipcode: item?.zipcode ? item?.zipcode : "",
        });
        setIsInputFocused(false);
        if (e.target.value !== "") {
            clearErrors("client");
        } else {
            setError("client", {
                type: "manual",
                message: `${t("requiredMmessage.fieldRequired")}`,
            });
            setPrimaryAdd({})
        }
    }
    useEffect(() => {
        incomeService.getSeries(state1).then((res) => {
            if (res.type == "Success" || res.type == "success") {
                setItems(res?.data)
            } else {
                setItems([])
            }
        })
    }, [showServiceNote])

    const onsubmit1 = async (data: any) => {
        let NewDedaction: any = {
            deduction_Name: data.deduction_Name,
            DeductINSS: data.DeductINSS,
            DeductISSQN: data.DeductISSQN,
        }
        let res = await dispatch(post_Deduction(NewDedaction))
        if (res?.payload?.type == "Success" || res?.payload?.type == "success") {
            Swal.fire({
                title: t("Sweetalert.Success"),
                text: t("Sweetalert.insertRecord"),
                // icon: 'success',"success",
                confirmButtonText: "OK",
                timer: 2000
            });
            setDeductionsModal(false);
            serviceNoteShow(true)
            setValue("deduction_Name", "")
            setValue("DeductISSQN", false)
            setValue("DeductINSS", false)
        } else {
            Swal.fire({
                title: `${t('Sweetalert.Error')}`,
                text: t("Sweetalert.somethingWent"),
                confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                timer: 3000
            });
        }
    }

    const [peraddress, setPerAddsres] = useState(true)
    const handleAddress = (e) => {
        if (e.target.value == 1) {
            setPerAddsres(true)
        } else {
            setPerAddsres(false)
        }
    }
    const handleCheckBox = (cmd: any, type: string) => {
        let issqnVal: any = getValues("issqn") || 0;
        let irrfVal: any = getValues("irrf") || 0;
        let pisVal: any = getValues("pis") || 0;
        let cSocialVal: any = getValues("cSocial") || 0;
        let inssVal: any = getValues("inss") || 0;
        let cofinsVal: any = getValues("cofins") || 0;

        // Calculate individual retentions
        let issqnRetention = (issqnVal * totalAmount) / 100;
        let irrfRetention = (irrfVal * totalAmount) / 100;
        let pisRetention = (pisVal * totalAmount) / 100;
        let cSocialRetention = (cSocialVal * totalAmount) / 100;
        let inssRetention = (inssVal * totalAmount) / 100;
        let cofinsRetention = (cofinsVal * totalAmount) / 100;
        let totalRetention = getValues("totalRetention") ? Number(convertToNumber(getValues("totalRetention"))) : totalAmount;
        let totalNewVal: any = 0
        if (cmd == true) {
            // Checkbox checked: Subtract the selected retention
            if (type == "toRetain") totalRetention -= issqnRetention;
            if (type == "toRetain2") totalRetention -= irrfRetention;
            if (type == "toRetain3") totalRetention -= pisRetention;
            if (type == "toRetain4") totalRetention -= cSocialRetention;
            if (type == "toRetain5") totalRetention -= inssRetention;
            if (type == "toRetain6") totalRetention -= cofinsRetention;
            setValue("totalRetention", converter(String(totalRetention)));
        } else {

            let issuVal = getValues("issqnValue")
            let irrfVal = getValues("irrfValue")
            let pisVal = getValues("pisValue")
            let cSocialVal = getValues("cSocialValue")
            let inssVal = getValues("inssValue")
            let cofinsVal = getValues("cofinsValue")
            let totalRetention1 = getValues("totalRetention")

            // Checkbox unchecked: Add back the specific retention
            if (type == "toRetain") {
                totalNewVal = convertToNumber(String(totalRetention1)) + convertToNumber(String(issuVal))
                totalRetention = totalRetention - convertToNumber(String(issuVal))
                setValue("totalRetention", converter(String(totalNewVal)));
            }
            if (type == "toRetain2") {
                totalNewVal = convertToNumber(String(totalRetention1)) + convertToNumber(String(irrfVal))
                totalRetention = totalRetention - convertToNumber(String(irrfVal))
                setValue("totalRetention", converter(String(totalNewVal)));
            }
            if (type == "toRetain3") {
                totalNewVal = convertToNumber(String(totalRetention1)) + convertToNumber(String(pisVal))
                totalRetention = totalRetention - convertToNumber(String(pisVal))
                setValue("totalRetention", converter(String(totalNewVal)));
            }
            if (type == "toRetain4") {
                totalNewVal = convertToNumber(String(totalRetention1)) + convertToNumber(String(cSocialVal))
                totalRetention = totalRetention - convertToNumber(String(cSocialVal))
                setValue("totalRetention", converter(String(totalNewVal)));
            }
            if (type == "toRetain5") {
                totalNewVal = convertToNumber(String(totalRetention1)) + convertToNumber(String(inssVal))
                totalRetention = totalRetention - convertToNumber(String(inssVal))
                setValue("totalRetention", converter(String(totalNewVal)));
            }
            if (type == "toRetain6") {
                totalNewVal = convertToNumber(String(totalRetention1)) + convertToNumber(String(cofinsVal))
                totalRetention = totalRetention - convertToNumber(String(cofinsVal))
                setValue("totalRetention", converter(String(totalNewVal)));
            }

        }
    };




    return (
        <>
            <Modal show={showServiceNote} onHide={serviceNoteClose} backdrop="static" keyboard={false} className='wtmtwtmtwtmt'>
                <Form onSubmit={handleSubmit(formSubmit)} id="newservicenotetsx">
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Invoices.newServiceNote")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert variant="warning" className="mb-lg-4 mb-3">
                            {t("Invoices.Youmayprefertopost")}
                            <span className="d-block">{t("Invoices.Todothisdd")}</span>
                        </Alert>
                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                            <Col sm={12} lg={12}>
                                <FloatingLabel controlId="client" label={t("responsible") + " *"}>
                                    <Form.Select aria-label="Client" {...register('multipleTenant')} className={`${errors.multipleTenant ? 'is-invalid' : ''}`} onChange={(e) => checkTenantOwner(e)}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        <option value="1">{t("owner")}</option>
                                        <option value="2">{t("tenant")}</option>
                                        <option value="3">{t("supplier")}</option>
                                        <option value="4">{t("customerAndEmployees.guarantors")}</option>
                                        <option value="5">{t("customerAndEmployees.brokers")}</option>
                                        <option value="6">{t("customerAndEmployees.inspectors")}</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.multipleTenant?.message}</Form.Control.Feedback >
                                </FloatingLabel>
                            </Col>
                            <Col sm={12} lg={12}>
                                {/* <FloatingLabel controlId="client" label={t("Invoices.client")+ " *"}>
                                    <Form.Select aria-label="Client" {...register('client')} className={`${errors.client ? 'is-invalid' : ''}`} onChange={(e) => checkTenantAddress(e)}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        {GetClient && GetClient.length > 0 &&
                                            GetClient.map((item, index) => (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors?.client?.message}</Form.Control.Feedback >
                                </FloatingLabel> */}
                                <Col md={12} className={`${errors.client ? 'is-invalid' : ''}`}>
                                    <InputGroup>
                                        <Form.Floating ref={inputRef} >
                                            <Form.Control
                                                type="text"
                                                placeholder="Type to search..."
                                                {...register('client')}
                                                onChange={(e) => handlefilterBillmethod(e)}
                                                onFocus={handleInputFocus}
                                                className={`${errors.client ? 'is-invalid' : ''}`} id="client"
                                                autoComplete="off"
                                            />
                                            {isInputFocused && (
                                                <ListGroup className="shadow position-absolute z-index-5 overflow-auto end-0 start-0 mt-1" style={{ maxHeight: "260px" }}   >
                                                    {searchResults?.slice(0, 10).map((item: any, index) => (
                                                        <ListGroup.Item className="px-3 cursor-pe" key={index} onClick={(e) => HandleBankAccount(e, item)}>
                                                            {item.name ? item.name : item.fantasy}
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>
                                            )}
                                            <Form.Label htmlFor="floatingInputValue">{t("Invoices.client") + " *"}</Form.Label >
                                            <Form.Control.Feedback type="invalid">{errors.client?.message}</Form.Control.Feedback >
                                        </Form.Floating>
                                    </InputGroup>
                                </Col>
                            </Col>
                            <Col sm={6} lg={7}>
                                <FloatingLabel controlId="branch" label={t("Branch") + " *"}>
                                    <Form.Select aria-label="Branch" {...register('branch')} className={`${errors.branch ? 'is-invalid' : ''}`} disabled>
                                        {branchList && branchList.length > 0 &&
                                            branchList.map((curElem: any, index: number) =>
                                                <option key={index} value={curElem?.id}>{curElem.name}</option>
                                            )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors?.branch?.message}</Form.Control.Feedback >
                                </FloatingLabel>
                            </Col>
                            <Col sm={6} lg={5}>
                                <FloatingLabel controlId="Series" label={t("Invoices.series")}>
                                    <Form.Select aria-label="Branch" {...register("series")} className={`${errors.series ? 'is-invalid' : ''}`}>
                                        <option value="">{t('dropdownItem.select')}..</option>
                                        {items && items.length > 0 ? (
                                            items.map((curElem: any, index: number) => (
                                                <>
                                                    <option key={index} value={curElem.id}>
                                                        {curElem.serie}
                                                    </option>
                                                </>
                                            ))
                                        ) : (
                                            <option disabled>{t("NoRecords")}</option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors?.series?.message}</Form.Control.Feedback >
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <span className="fw-bold mb-3 d-block">{t("Invoices.gradetotal")}</span>
                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                            <Col md={6}>
                                <FloatingLabel controlId="grade_total" label={t("Invoices.gradetotal")}>
                                    <Form.Control type="text" disabled {...register('grandTotal')} placeholder="Grade Total" />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="total_retention" label={t("Invoices.Totalafterretention")}>
                                    <Form.Control type="text" disabled {...register('totalRetention')} placeholder="Total after retention" />
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Accordion className="accordion-custom" defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>{t("Invoices.Delivery")}</Accordion.Header>
                                <Accordion.Body>
                                    <FloatingLabel controlId="address" label={t("address")}>
                                        <Form.Select aria-label="Address" {...register('deliveryAddress')} onChange={(e) => handleAddress(e)}>
                                            <option value="1">{t("Invoices.Mainaddress")}</option>
                                            <option value="2">{t("Invoices.Secondaryaddress")}</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                    <span>{peraddress ? primaryAdd?.address ? primaryAdd?.address : "" + " " + primaryAdd?.number ? primaryAdd?.number : "" + " " + primaryAdd?.zipcode ? primaryAdd?.zipcode : "" : ""}</span>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>{t("Invoices.Withholdings")}</Accordion.Header>
                                <Accordion.Body>
                                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3 align-items-center">
                                        <Col md={6}>
                                            <FloatingLabel controlId="issqn" label={`${t("taxation.ISSQN")} (%)`}>
                                                <Form.Control
                                                    type="text"
                                                    {...register('issqn')}
                                                    placeholder="ISSQN(%)"
                                                    onChange={(e) => { BasicCalculation(e, "issqn"); }} onInput={(e: any) => { e.target.value = e.target.value.replace(/[^0-9.]/g, ''); }} onKeyPress={(e) => {
                                                        if (!/[0-9.]/.test(e.key)) {
                                                            e.preventDefault(); // Sirf numbers aur dot allow
                                                        }
                                                    }}
                                                />
                                            </FloatingLabel>
                                        </Col>

                                        <Col md={6}>
                                            <FloatingLabel controlId="deductions" label={t("Invoices.deductions")}>
                                                <Form.Control type="text" {...register('deductions')} placeholder="Deductions" disabled />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="calculation_basis" label={t("Invoices.calculationbasis")}>
                                                <Form.Control type="text" {...register('calculationBasis')} disabled placeholder="Calculation basis" />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="issqn_value" label={t("Invoices.ISSQNvalue")}>
                                                <Form.Control type="text" {...register('issqnValue')} disabled placeholder="ISSQN value" />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Check type="checkbox" {...register('toRetain')} id="retain_1" label={t("Invoices.Toretain")} onChange={(e) => handleCheckBox(e.target.checked, "toRetain")} />
                                        </Col>
                                    </Row>
                                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3 align-items-center">
                                        <Col md={6}>
                                            <FloatingLabel controlId="irrf" label={t("Invoices.IRRF")}>
                                                <Form.Control type="text" placeholder="IRRF(%)" {...register('irrf')} onChange={(e) => BasicCalculation(e, "irrf")} onInput={(e: any) => { e.target.value = e.target.value.replace(/[^0-9.]/g, ''); }} onKeyPress={(e) => {
                                                    if (!/[0-9.]/.test(e.key)) {
                                                        e.preventDefault(); // Sirf numbers aur dot allow
                                                    }
                                                }} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="deductions" label={t("Invoices.deductions")}>
                                                <Form.Control type="text" placeholder="Deductions" {...register('deductions2')} disabled />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="calculation_basis" label={t("Invoices.calculationbasis")}>
                                                <Form.Control type="text" disabled placeholder="Calculation basis" {...register('calculationBasis2')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="irrf_value" label={`${t("Invoices.IRRF")} (%)`}>
                                                <Form.Control type="text" disabled placeholder="IRRF value" {...register('irrfValue')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Check type="checkbox" id="retain_2" {...register('toRetain2')} label={t("Invoices.Toretain")} onChange={(e) => handleCheckBox(e.target.checked, "toRetain2")} />
                                        </Col>
                                    </Row>
                                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3 align-items-center">
                                        <Col md={6}>
                                            <FloatingLabel controlId="pis" label={`${t("Invoices.PIS")} (%)`}>
                                                <Form.Control type="text" placeholder="PIS(%)" {...register('pis')} onChange={(e) => BasicCalculation(e, "pis")} onInput={(e: any) => { e.target.value = e.target.value.replace(/[^0-9.]/g, ''); }} onKeyPress={(e) => {
                                                    if (!/[0-9.]/.test(e.key)) {
                                                        e.preventDefault(); // Sirf numbers aur dot allow
                                                    }
                                                }} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="deductions" label={t("Invoices.deductions")}>
                                                <Form.Control type="text" placeholder="Deductions" {...register('deductions3')} disabled />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="calculation_basis" label={t("Invoices.calculationbasis")}>
                                                <Form.Control type="text" disabled placeholder="Calculation basis" {...register('calculationBasis3')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="pis_value" label={`${t("Invoices.PIS")} ${t("Invoices.value")}`} >
                                                <Form.Control type="text" disabled placeholder="PIS value" {...register('pisValue')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Check type="checkbox" id="retain_3" label={t("Invoices.Toretain")} {...register('toRetain3')} onChange={(e) => handleCheckBox(e.target.checked, "toRetain3")} />
                                        </Col>
                                    </Row>
                                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3 align-items-center">
                                        <Col md={6}>
                                            <FloatingLabel controlId="social" label={t("Invoices.CSocial")}>
                                                <Form.Control {...register('cSocial')} type="text" placeholder="C. Social(%)" onChange={(e) => BasicCalculation(e, "cSocial")} onInput={(e: any) => { e.target.value = e.target.value.replace(/[^0-9.]/g, ''); }} onKeyPress={(e) => {
                                                    if (!/[0-9.]/.test(e.key)) {
                                                        e.preventDefault(); // Sirf numbers aur dot allow
                                                    }
                                                }} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="deductions" label={t("Invoices.deductions")}>
                                                <Form.Control type="text" placeholder="Deductions" {...register('deductions4')} disabled />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="calculation_basis" label={t("Invoices.calculationbasis")}>
                                                <Form.Control type="text" disabled placeholder="Calculation basis" {...register('calculationBasis4')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="social_value" label={`${t("Invoices.CSocial")} ${t("Invoices.value")}`} >
                                                <Form.Control type="text" disabled placeholder="C. Social value" {...register('cSocialValue')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Check type="checkbox" id="retain_4" label={t("Invoices.Toretain")} {...register('toRetain4')} onChange={(e) => handleCheckBox(e.target.checked, "toRetain4")} />
                                        </Col>
                                    </Row>
                                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3 align-items-center">
                                        <Col md={6}>
                                            <FloatingLabel controlId="inss" label={t("Invoices.INSS")}>
                                                <Form.Control type="text" placeholder="INSS(%)"  {...register('inss')} onChange={(e) => BasicCalculation(e, "inss")} onInput={(e: any) => { e.target.value = e.target.value.replace(/[^0-9.]/g, ''); }} onKeyPress={(e) => {
                                                    if (!/[0-9.]/.test(e.key)) {
                                                        e.preventDefault(); // Sirf numbers aur dot allow
                                                    }
                                                }} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="deductions" label={t("Invoices.deductions")}>
                                                <Form.Control type="text" placeholder="Deductions" {...register('deductions5')} disabled />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="calculation_basis" label={t("Invoices.calculationbasis")}>
                                                <Form.Control type="text" disabled placeholder="Calculation basis" {...register('calculationBasis5')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="inss_value" label={`${t("Invoices.INSS")} ${t("Invoices.value")}`}>
                                                <Form.Control type="text" disabled placeholder="INSS value" {...register('inssValue')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Check type="checkbox" id="retain_5" label={t("Invoices.Toretain")}  {...register('toRetain5')} onChange={(e) => handleCheckBox(e.target.checked, "toRetain5")} />
                                        </Col>
                                    </Row>
                                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3 align-items-center">
                                        <Col md={6}>
                                            <FloatingLabel controlId="cofins" label={t("Invoices.COFINS")} >
                                                <Form.Control {...register('cofins')} type="text" placeholder="COFINS(%)" onChange={(e) => BasicCalculation(e, "cofins")} onInput={(e: any) => { e.target.value = e.target.value.replace(/[^0-9.]/g, ''); }} onKeyPress={(e) => {
                                                    if (!/[0-9.]/.test(e.key)) {
                                                        e.preventDefault(); // Sirf numbers aur dot allow
                                                    }
                                                }} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="deductions" label={t("Invoices.deductions")}>
                                                <Form.Control type="text" placeholder="Deductions" {...register('deductions6')} disabled />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="calculation_basis" label={t("Invoices.calculationbasis")}>
                                                <Form.Control type="text" disabled placeholder="Calculation basis" {...register('calculationBasis6')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="cofins_value" label={`${t("Invoices.COFINS")} ${t("Invoices.value")} `}>
                                                <Form.Control type="text" disabled placeholder="COFINS value" {...register('cofinsValue')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Check type="checkbox" id="retain_6" label={t("Invoices.Toretain")}  {...register('toRetain6')} onChange={(e) => handleCheckBox(e.target.checked, "toRetain6")} />
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>{t("formLabel.moreFields")}</Accordion.Header>
                                <Accordion.Body>
                                    <FloatingLabel className="mb-lg-4 mb-3" controlId="issuance" label={t("Invoices.issuance") + " *"} >
                                        <Form.Control type="date" className={`${errors.issuanceDate ? 'is-invalid' : ''}`} placeholder="Issuance" {...register("issuanceDate")} />
                                        <Form.Control.Feedback type="invalid">{errors?.issuanceDate?.message}</Form.Control.Feedback >
                                    </FloatingLabel>
                                    <FloatingLabel controlId="additional_data" label={t("Invoices.Additionaldata")}>
                                        <Form.Control as="textarea" placeholder="Additional data" {...register("additionData")} maxLength={500} />
                                    </FloatingLabel>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>{t("contractsettings.Service")}</Accordion.Header>
                                <Accordion.Body>
                                    {myIPsCount && [...Array(myIPsCount)].map((ip: any, index) => (
                                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3 align-items-center" key={index}>
                                            <Col md={6}>
                                                {/* <FloatingLabel controlId="service" label="Service">
                                                <Form.Control data-ip={index} onChange={(evnt) => addAmount(index, evnt)} type="text" name="service" placeholder="Service" value={`${servicesFields?.[`open_${index}`]?.['service']}`} />
                                            </FloatingLabel> */}
                                                <FloatingLabel controlId="service" label={t("contractsettings.Service") + " *"}>
                                                    <Form.Select
                                                        {...register(`servicesetting.${index}.service`)}
                                                        // value={servicesFields?.[`open_${index}`]?.['service']}
                                                        onChange={(e) => handleSelectChange(e, index)}
                                                        className={`${errors?.servicesetting?.[index]?.service ? 'is-invalid' : ''}`}
                                                    >
                                                        <option value="">{t('dropdownItem.select')}..</option>
                                                        {newServiceData && newServiceData.length > 0 && newServiceData.map((item, i) => (
                                                            <option key={i} value={item.ID_PRODUTO_PRD}>{item.ST_DESCRICAO_PRD}</option>
                                                        ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{errors?.servicesetting?.[index]?.service?.message}</Form.Control.Feedback >
                                                </FloatingLabel>

                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="complement" label={t("formLabel.complement")}>
                                                    <Form.Control type="text"  {...register(`servicesetting.${index}.complement`)} data-ip={index} onChange={(evnt) => addAmount(index, evnt, "")} placeholder="Complement" maxLength={500} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="unitary_value" label={t("Invoices.Unitaryvalue") + " *"}>
                                                    <Form.Control className={`${errors?.servicesetting?.[index]?.unitaryValue ? 'is-invalid' : ''}`} type="text" data-ip={index} {...register(`servicesetting.${index}.unitaryValue`)} onChange={(evnt) => addAmount(index, evnt, "Unitary")} placeholder="Unitary value" />
                                                    <Form.Control.Feedback type="invalid">{errors?.servicesetting?.[index]?.unitaryValue?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="Amount" label={t("contractlistother.Amount") + " *"}>
                                                    <Form.Control className={`${errors?.servicesetting?.[index]?.amount ? 'is-invalid' : ''}`} type="text" data-ip={index} {...register(`servicesetting.${index}.amount`)} onChange={(evnt) => addAmount(index, evnt, "amount")} placeholder="Amount" onInput={(e: any) => { e.target.value = e.target.value.replace(/[^0-9]/g, ''); }}
                                                        onKeyPress={(e) => {
                                                            if (!/[0-9]/.test(e.key)) {
                                                                e.preventDefault();
                                                            }
                                                        }} />
                                                    <Form.Control.Feedback type="invalid">{errors?.servicesetting?.[index]?.amount?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md>
                                                <FloatingLabel controlId="item_total" label={t("Invoices.Itemtotal") + " *"}>
                                                    <Form.Control className={`${errors?.servicesetting?.[index]?.total ? 'is-invalid' : ''}`} type="text" {...register(`servicesetting.${index}.total`)} placeholder="Item total" disabled data-ip={index} />
                                                    <Form.Control.Feedback type="invalid">{errors?.servicesetting?.[index]?.total?.message}</Form.Control.Feedback >
                                                </FloatingLabel>
                                            </Col>
                                            <Col md="auto">
                                                {myIPsCount > 1 &&
                                                    <Button variant="link" className="bg-light border-light" data-rowid={index} onClick={(e) => deleteServiceRow(index)}>
                                                        <img src="assets/images/icons/delete-light.svg" alt="Delete icon" className="h-16 cursor-pe" /></Button>
                                                }
                                            </Col>
                                        </Row>
                                    ))}
                                    {
                                        myIPsCount && myIPsCount < 5 &&
                                        <Button variant="link" className="p-0 fw-bold me-2 me-lg-3" onClick={addNewService}>{t('userModule.addNew')}</Button>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>{t("Invoices.deductions")}</Accordion.Header>
                                <Accordion.Body>
                                    {deuctionCount && [...Array(deuctionCount)].map((ip: any, index) => (
                                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3" key={`deduction_${index}`}>
                                            <Col md={12}>
                                                <InputGroup>
                                                    <FloatingLabel controlId="deduction" label={t("Invoices.deductions")}>
                                                        <Form.Select data-ip={index} onChange={(evnt) => setDeduction(index, evnt)} name="deduction" value={`${deductionFields?.[`open_${index}`]?.['deduction']}`} >
                                                            <option value="">{t('dropdownItem.select')}..</option>
                                                            {deductionsItem && deductionsItem.length > 0 && deductionsItem.map((item, ind: number) => (
                                                                <option key={ind} value={item.id}>{item.deduction_Name}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                    <InputGroup.Text>
                                                        <img src="assets/images/icons/documents-add-light.svg" alt="Documents add" className="h-20 cursor-pe" onClick={deductionsShow} />
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Col>
                                            <Col md>
                                                <FloatingLabel controlId="value" label={t("Invoices.value")}>
                                                    <Form.Control
                                                        type="text"
                                                        data-ip={index}
                                                        onChange={(evnt) => setDeduction(index, evnt)}
                                                        name="deductionValue"
                                                        value={deductionFields?.[`open_${index}`]?.['deductionValue'] ?? ""}
                                                        onInput={(e: any) => { e.target.value = e.target.value.replace(/[^0-9.,]/g, ''); }} onKeyPress={(e) => {
                                                            if (!/[0-9.,]/.test(e.key)) {
                                                                e.preventDefault(); // Sirf numbers aur dot allow
                                                            }
                                                        }}
                                                    />
                                                </FloatingLabel>
                                            </Col>
                                            <Col md="auto">
                                                {deuctionCount > 1 &&
                                                    <Button variant="link" className="bg-light border-light" data-rowid={index} onClick={deleteDeductionRow}>
                                                        <img src="assets/images/icons/delete-light.svg" alt="Delete icon" className="h-16 cursor-pe" />
                                                    </Button>
                                                }
                                            </Col>
                                        </Row>
                                    ))}
                                    {
                                        deuctionCount && deuctionCount < 5 &&
                                        <Button variant="link" className="p-0 fw-bold me-2 me-lg-3" onClick={addNewDeduction}>{t('userModule.addNew')}</Button>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer className="text-end">
                        <Button variant="outline-primary" className="me-2 me-lg-3" onClick={serviceNoteClose}>
                            {t('button.close')}
                        </Button>
                        {
                            loader_on ?
                                <Button className="btn-width" disabled={double}>
                                    <span className="spinner-border spinner-border-sm"></span>
                                    <span className="px-1">{t('button.toSave')}</span>
                                </Button>
                                :
                                <Button type="submit" variant="primary" disabled={double} name="save" onClick={openErrorbox}>
                                    {t('button.toSave')}
                                </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* New deductions modal start from here */}
            <Modal show={showDeductions} onHide={deductionsClose} backdrop="static" keyboard={false}>
                <Form onSubmit={handleSubmit(onsubmit1)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Invoices.Newdeduction")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control
                            type="text"
                            placeholder="Deduction"
                            {...register("deduction_Name")}
                            onChange={handleDeductionChange}
                            maxLength={25}
                        />
                        <div>
                            <Form.Check type="checkbox" className='mt-4' inline id="deduct" label={t("Invoices.DeductISSQN")} {...register("DeductISSQN")} />
                            <Form.Check type="checkbox" className='mt-4' inline id="deduct" label={t("Invoices.DeductINSS")} {...register("DeductINSS")} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={deductionsClose}>
                            {t('button.close')}
                        </Button>
                        <Button variant="primary" type="submit">
                            {t('button.toSave')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /New deductions modal end */}
        </>
    )
}

export default NewServiceNote



