
import {
  FloatingLabel,
  Dropdown,
  Col,
  Row,
  Form,
  Button,
  Card,
  Collapse,
  ButtonGroup,
} from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";

import { encrypt_Decrypt } from "../../constants/utils";
import { Link, useNavigate, useLocation } from "react-router-dom";
import EditExpense from "./edit-expense";
import ChangeMaturity from "./change-maturity";

import { setFinancialExpOpen } from "../../../redux/slice/financialDataSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import FinancialExpenseService from "../Contracts/Common/FinancialExpenseService";
import BankTransactionsListing from "./BankTransactionsListing";
import { fetchBankTransaction } from "../../../redux/slice/BankTransactionDataSlice";
import { useForm } from "react-hook-form";
import Pagination from "../../Pagination";
import moment from "moment-timezone";
import { getAllAccounts } from "../../../redux/slice/expenditureDataSlice";
import { TransferAccountDrawer } from "../../../newComponents/components/TransferAccountDrawer";
import { useTransferAccountDrawer } from "../../../newComponents/components/TransferAccountDrawer/TransferAccountDrawerStore";
import { TransferAccountNewRecipeDrawer } from "../../../newComponents/components/TransferAccountNewRecipeDrawer";
import { useTransferAccountNewRecipeDrawer } from "../../../newComponents/components/TransferAccountNewRecipeDrawer";

let getChargesSummery;
export { getChargesSummery };

const BankTransactions = () => {
  let dateBrazil = moment.tz("America/Sao_Paulo").format("YYYY-MM-DD HH:mm");
  const [t] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [userJson, setUserJson] = useState<any>([]);
  useEffect(() => {
    let userResult = encrypt_Decrypt();
    if (!userResult || userResult.indexOf(3010) === -1) {
      navigate("/dashboard");
    }
    setUserJson(userResult);
  }, [navigate]);

  const { bank_transaction } = useSelector(
    (state: RootState) => state.bankTransaction
  );
  const { accountData } = useSelector(
    (state: RootState) => state.expenditureData
  );
  const { register, setValue } = useForm();
  const dispatch = useDispatch<AppDispatch>();
  const { transferAccountDrawerAction } = useTransferAccountDrawer();
  const [bankTransList, setbankTransList] = useState<any>([]);
  const [originalData, setOriginalData] = useState<any>([]);
  const [isCheckId, setIsCheckId] = useState<any>([]);

  const financialExpOpened = () => {
    dispatch(setFinancialExpOpen(true));
  };

  //Edit expense modal
  const [showEditExpenseModal, setEditExpenseModal] = useState(false);
  const editExpenseModalClose = () => setEditExpenseModal(false);

  //Change expiration modal initialization
  const [showchangeExpirationModal, setchangeExpirationModal] = useState(false);
  const changeExpirationModalClose = () => setchangeExpirationModal(false);

  let newDate = new Date(dateBrazil);
  let startDt = moment(newDate).subtract(1, "M");
  let searchDt = moment(newDate);
  let endDt = moment(newDate).add(1, "M");

  const [state, setState] = useState<any>({
    start: 0,
    end: 20,
    bankAccount: "",
    status: "all",
    startDate: startDt,
    endDate: endDt,
    searchDate: searchDt,
    filterby: "",
    processingstatus: "",
    oftheType: "",
    from: "banktransaction",
  });

  let PageSize = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalpage, setTotalPage] = useState(0);
  const [bankFilter, setbankFilter] = useState("");
  useEffect(() => {
    const diff = state["end"] - state["start"];
    state["start"] = (currentPage - 1) * diff;
    state["end"] = currentPage * diff;

    dispatch(fetchBankTransaction(state));
  }, [state, dispatch, currentPage]);

  const filterCharge = (dt, tp) => {
    if (typeof tp !== "undefined") {
      let currentDate = moment(dt);
      if (tp === "Subtract") {
        startDt = moment(currentDate).subtract(1, "M");
        searchDt = moment(startDt).add(1, "M");
        endDt = moment(currentDate).add(1, "M");
      } else {
        startDt = moment(currentDate).subtract(1, "M");
        searchDt = moment(startDt).add(1, "M");
        endDt = moment(currentDate).add(1, "M");
      }
      setState({
        ...state,
        startDate: startDt,
        endDate: endDt,
        searchDate: searchDt,
        start: 0,
      });
      setCurrentPage(1);
    }
  };

  const isInitialRender = useRef(true);

  //ao fechar drawer de transferência a tela atualiza.
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (transferAccountDrawerAction === undefined) {
      dispatch(fetchBankTransaction(state));
    }
  }, [transferAccountDrawerAction, dispatch, state]);

  useEffect(() => {
    (async () => {
      if (location?.state?.ID_CONTRATO_CON) {
        let data_array: any = [];
        if (originalData && originalData.data && originalData.data.length > 0) {
          data_array = originalData.data.filter(
            (element) => element.ID_CONTRATO_CON === state.ID_CONTRATO_CON
          );
        }
        setbankTransList({ data: data_array });
        window.history.replaceState(null, "");
      } else {
        setbankTransList(bank_transaction?.list);
        setOriginalData(bank_transaction?.list);
      }
    })();
  }, [bank_transaction.list, location.state, state, originalData]);

  const handleAllCheck = (e, type) => {
    let value: any = "";
    if (type !== "all") {
      if (type === "every") {
        value = true;
      }
      if (type === "none") {
        value = false;
      }
    } else {
      value = e.target.checked;
    }

    if (document.querySelectorAll(".check_Checkbox input").length > 0) {
      let data = document.querySelectorAll(".check_Checkbox input");
      setTimeout(() => {
        let checkedId: any = [];
        data.forEach((item, index) => {
          item["checked"] = value;
          if (value) {
            checkedId.push(item.getAttribute("data-id"));
          } else {
            checkedId = [];
          }
        });
        setIsCheckId(checkedId);
      }, 10);
    }
  };

  const footerAction = (e, type) => {
    if (isCheckId.length > 0) {
      if (type === "change_account") {
        setEditExpenseModal(true);
      }

      if (type === "change_expire") {
        setchangeExpirationModal(true);
      }
    } else {
      Swal.fire({
        title: t("Sweetalert.Warning"),
        text: t("Sweetalert.Pleaseselectatleasttransaction"),
        confirmButtonText: "OK",
      });
    }
  };

  const filterBy = (e: any) => {
    const val = e.target.value;
    let data_array: any = [];
    setState({
      ...state,
      filterby: val,
    });
    if (val === "all") {
      // If "all" is selected, reset to the original data.
      if (originalData && originalData.data && originalData.data.length > 0) {
        data_array = originalData.data;
      }
    } else if (val === "banktransactions") {
      // Filtro de transferência entre contas.
      if (originalData && originalData.data && originalData.data.length > 0) {
        data_array =
          originalData?.data?.filter(
            (element) => element.type === "banktransaction"
          ) || [];
      }
    } else if (val === "receipts") {
      // If "receipts" is selected, filter the data.
      if (originalData && originalData.data && originalData.data.length > 0) {
        data_array = originalData.data.filter(
          (element) => element.VL_VALOR_MOV < 0
        );
      }
    } else if (val === "payments") {
      // If "payments" is selected, filter the data.
      if (originalData && originalData.data && originalData.data.length > 0) {
        data_array = originalData.data.filter(
          (element) => element.VL_VALOR_MOV > 0
        );
      }
    } else {
      if (originalData && originalData.data && originalData.data.length > 0) {
        data_array = originalData;
      }
    }

    // Update the state with the filtered data.
    setbankTransList({ data: data_array });
  };

  const filterBankTrastions = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    setState({
      ...state,
      [name]: value,
    });
    var selectElement: any = document.querySelector(
      'select[name="bankAccount"]'
    );
    var selectedIndex = selectElement?.selectedIndex;
    var selectedOption = selectElement?.options[selectedIndex];
    // Get the value of the data-action attribute
    var dataAction: any = selectedOption?.getAttribute("label");
    setbankFilter(dataAction);
  };

  useEffect(() => {
    if (bankTransList) {
      setTotalPage(bankTransList["totalRow"]);
      dispatch(getAllAccounts({ start: 0, end: 1000 }));
    }
  }, [bankTransList, dispatch]);
  // Filters section
  const [openFilters, setFiltersOpen] = useState(false);
  const filterReset = () => {
    setValue("status", "");
    setValue("filerby", "");
    setValue("processingstatus", "all");
    setValue("bankAccount", "all");
    setValue("oftheType", "all");
    setState({
      start: 0,
      end: 20,
      bankAccount: "",
      status: "all",
      startDate: startDt,
      endDate: endDt,
      searchDate: searchDt,
      filterby: "",
      processingstatus: "",
      oftheType: "",
      from: "banktransaction",
    });
    setbankFilter("");
  };

  const { setShowTransferAccountDrawer } = useTransferAccountDrawer();
  const { setShowTransferAccountNewRecipeDrawer } =
    useTransferAccountNewRecipeDrawer();

  return (
    <>
      <section className="content-wrapper content-wrapper-primary">
        {/*<!-- Breadcrumb section start from here -->*/}
        <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
          <div className="w-100 mb-xl-4">
            <Row className="gx-xl-5 justify-content-between align-items-center">
              <Col
                xs={{ order: 12 }}
                xl={{ span: "auto", order: 0 }}
                className="mb-xl-0 mb-3"
              >
                <div className="d-flex align-items-center justify-content-between me-xl-1">
                  <h1 className="mb-0 lh-base text-truncate">
                    {t("bankTransaction.Banktransactionson")}{" "}
                    <span className="capitalize">
                      {state?.["searchDate"].format("MMM/YYYY")}
                    </span>
                  </h1>
                  {/* Filter button visible in mobile devices */}
                  <div className="d-xl-none">
                    <Button
                      variant="light"
                      size="sm"
                      className="text-nowrap"
                      onClick={() => setFiltersOpen(!openFilters)}
                      aria-controls="collpase-filter-text"
                      aria-expanded={openFilters}
                    >
                      {t("dropdownItem.filters")}
                      <img
                        src="assets/images/icons/filter-icon.svg"
                        alt="Filter"
                        className="ms-1 h-12"
                      />
                    </Button>
                  </div>
                </div>
              </Col>
              <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                <div className="input-group">
                  <input
                    type="text"
                    id="search"
                    className="form-control"
                    placeholder={t("formLabel.typeSearch")}
                    aria-describedby="basic-addon2"
                  />
                  <span className="input-group-text" id="basic-addon2">
                    <img src="assets/images/icons/search.svg" alt="Search" />
                  </span>
                </div>
              </Col>
              <Col
                xs={{ span: 12, order: 2 }}
                xl={{ order: 4 }}
                className="text-xl-end col-xl mb-xl-0 mb-2"
              >
                <ul className="list-inline mb-0 fw-bold text-primary d-flex justify-content-end gap-3">
                  {userJson &&
                    userJson.length > 0 &&
                    userJson.indexOf(3041) !== -1 && (
                      <>
                        <li className="list-inline-item">
                          <Link
                            to={"#"}
                            onClick={() =>
                              setShowTransferAccountDrawer({ action: "new" })
                            }
                          >
                            <img
                              src="assets/images/icons/plus-light.svg"
                              alt="Plus icon"
                              className="ms-1 h-20 pe-1"
                            />
                            {t("newTransferSameOwner")}
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link to={"#"} onClick={financialExpOpened}>
                            <img
                              src="assets/images/icons/plus-light.svg"
                              alt="Plus icon"
                              className="ms-1 h-20 pe-1"
                            />
                            {t("newExpense")}
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link
                            to={"#"}
                            onClick={() =>
                              setShowTransferAccountNewRecipeDrawer({
                                action: "new",
                              })
                            }
                          >
                            <img
                              src="assets/images/icons/plus-light.svg"
                              alt="Plus icon"
                              className="ms-1 h-20 pe-1"
                            />
                            Nova receita
                          </Link>
                        </li>
                      </>
                    )}
                </ul>
              </Col>
            </Row>
          </div>
          {/* Filters start here */}
          <Collapse className="d-xl-block collpase-filters" in={openFilters}>
            <Form id="collpase-filter-text" action="" method="">
              <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
                <h5 className="mb-0 fw-bold">Filters</h5>
                <span
                  className="cursor-pe"
                  onClick={() => setFiltersOpen(!openFilters)}
                >
                  <img
                    src="assets/images/icons/cross.svg"
                    alt="Plus icon"
                    className="ms-xxl-1 ms-auto h-14"
                  />
                </span>
              </div>
              <Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
                <Col xl="auto">
                  <ButtonGroup
                    className="btn-date-filter"
                    size="sm"
                    aria-label="Date"
                  >
                    <Button
                      variant="primary"
                      className="btn-arrow"
                      onClick={() => filterCharge(state.startDate, "Subtract")}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/arrow-white.svg"
                        }
                        alt="Details"
                        className="h-24"
                      />
                    </Button>
                    <Button
                      variant="primary"
                      className="btn-date capitalize"
                      disabled
                    >
                      {state.searchDate.format("MMM/YYYY")}
                    </Button>
                    <Button
                      variant="primary"
                      className="btn-arrow"
                      onClick={() => filterCharge(state.endDate, "Add")}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/arrow-white-right.svg"
                        }
                        alt="Details"
                        className="h-24"
                      />
                    </Button>
                  </ButtonGroup>
                </Col>
                <Col xl="auto">
                  <Dropdown autoClose="outside">
                    <Dropdown.Toggle
                      variant="light"
                      size="sm"
                      className={`${state?.["filterby"] ? "show" : ""}`}
                    >
                      {state?.["filterby"] === "all"
                        ? t("TransferPage.banktransactions")
                        : state?.["filterby"] === "receipts"
                        ? t("TransferPage.receipts")
                        : state?.["filterby"] === "payments"
                        ? t("TransferPage.payments")
                        : t("TransferPage.Filterby")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdwon-lg px-4">
                      <FloatingLabel label={t("TransferPage.Filterby")}>
                        <Form.Select
                          aria-label="Filter by"
                          {...register("filerby")}
                          onChange={filterBy}
                        >
                          <option value="all">{t("formLabel.all")}</option>
                          <option value="banktransactions">
                            {t("TransferPage.banktransactions")}
                          </option>
                          <option value="receipts">
                            {t("TransferPage.receipts")}
                          </option>
                          <option value="payments">
                            {t("TransferPage.payments")}
                          </option>
                        </Form.Select>
                      </FloatingLabel>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col xl="auto">
                  <Dropdown autoClose="outside">
                    <Dropdown.Toggle
                      variant="light"
                      size="sm"
                      className={state?.["status"] !== "all" ? "show" : ""}
                    >
                      {state?.["status"] === "all"
                        ? t("dropdownItem.status")
                        : state?.["status"] === "0"
                        ? t("pending")
                        : state?.["status"] === "1"
                        ? t("settled")
                        : state?.["status"] === "2"
                        ? t("checkstoclear")
                        : t("dropdownItem.status")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdwon-lg px-4">
                      <FloatingLabel label="Status">
                        <Form.Select
                          aria-label="Status"
                          {...register("status")}
                          onChange={filterBankTrastions}
                        >
                          <option value="">{t("formLabel.all")}</option>
                          <option value="0">{t("pending")}</option>
                          <option value="1">{t("settled")}</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col xl="auto">
                  <Dropdown autoClose="outside">
                    <Dropdown.Toggle
                      variant="light"
                      size="sm"
                      className={state?.["bankAccount"] ? "show" : ""}
                    >
                      {bankFilter ? bankFilter : t("banks.bankAccounts")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdwon-lg px-4">
                      <FloatingLabel label={t("bankAccount")}>
                        <Form.Select
                          aria-label="Bank account"
                          {...register("bankAccount")}
                          onChange={filterBankTrastions}
                        >
                          <option value="all">{t("formLabel.all")}..</option>
                          {accountData.data && accountData.data.length > 0
                            ? accountData.data.map((item, index) => (
                                <option
                                  key={index}
                                  value={Number(item.id)}
                                  label={item.accountRef}
                                >
                                  {item.account} -- {item.accountRef}
                                </option>
                              ))
                            : ""}
                        </Form.Select>
                      </FloatingLabel>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col xl="auto">
                  <Dropdown autoClose="outside">
                    <Dropdown.Toggle
                      variant="light"
                      size="sm"
                      className={state?.["oftheType"] ? "show" : ""}
                    >
                      {state?.["oftheType"]
                        ? t("all")
                        : state?.["oftheType"] === "1"
                        ? t("Dashboard.Overdue")
                        : state?.["oftheType"] === "2"
                        ? t("bankTransaction.Inday")
                        : t("bankTransaction.Offthetype")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdwon-lg px-4">
                      <FloatingLabel label={t("bankTransaction.Offthetype")}>
                        <Form.Select
                          aria-label="Off the type"
                          {...register("oftheType")}
                          onChange={filterBankTrastions}
                        >
                          <option value="all">{t("formLabel.all")}</option>
                          <option value="1">{t("Dashboard.Overdue")}</option>
                          <option value="2">
                            {t("bankTransaction.Inday")}
                          </option>
                        </Form.Select>
                      </FloatingLabel>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                {state?.["status"] !== "all" ||
                state?.["oftheType"] ||
                state?.["bankAccount"] ||
                state?.["processingstatus"] ||
                state?.["filterby"] ? (
                  <Col xl="auto">
                    <Dropdown autoClose="outside" className="moreFilter">
                      <Dropdown.Toggle
                        variant="light"
                        size="sm"
                        className="d-flex align-items-center"
                        onClick={filterReset}
                      >
                        {t("button.resetfilter")}
                      </Dropdown.Toggle>
                    </Dropdown>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Form>
          </Collapse>
          {/* /Filters end */}
        </div>
        {/*<!-- /Breadcrumb section end -->*/}
        {/*<!-- Main content start from here -->*/}
        <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
          <Card>
            <Card.Body className="pb-1">
              {/*<!-- Table start from here -->*/}
              <BankTransactionsListing
                bankTransList={bankTransList}
                setbankTransList={setbankTransList}
                isCheckId={isCheckId}
                setIsCheckId={setIsCheckId}
                handleAllCheck={handleAllCheck}
                currentPage={currentPage}
                totalpage={totalpage}
                PageSize={PageSize}
                setCurrentPage={setCurrentPage}
              />
            </Card.Body>
          </Card>

          {/*<!-- /Table end -->*/}
          {totalpage > 0 && (
            <div className="d-flex justify-content-center mt-lg-4 mt-3">
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalpage}
                pageSize={PageSize}
                onPageChange={(page: any) => setCurrentPage(page)}
              />
            </div>
          )}
          <p className="my-lg-4 my-3">
            {" "}
            {t("formLabel.listing")} {bankTransList.totalRow}{" "}
            {t("TransferPage.Transactionsmade")}.
          </p>
          {/* <!-- Nav listing start from here -->*/}
          {userJson && userJson.length > 0 && userJson.indexOf(3011) !== -1 && (
            <ul className="list-inline mb-0">
              <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                <div className="dropdown">
                  <Button
                    variant="light"
                    size="sm"
                    className="dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {t("formLabel.mark")}
                  </Button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a
                        href="!#"
                        className="dropdown-item"
                        onClick={(e) => handleAllCheck(e, "every")}
                      >
                        {t("formLabel.all")}
                      </a>
                    </li>
                    <li>
                      <a
                        href="!#"
                        className="dropdown-item"
                        onClick={(e) => handleAllCheck(e, "none")}
                      >
                        {t("formLabel.none")}
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                {" "}
                <span className="px-1">{t("formLabel.withTagged")}:</span>
              </li>
              <li className="list-inline-item mb-lg-0 mb-1">
                <div className="dropdown dropup">
                  <Button
                    variant="light"
                    size="sm"
                    className="dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {t("more")}
                  </Button>
                  <ul
                    className="dropdown-menu mh-456 overflow-auto"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a
                        href="!#"
                        onClick={(e) => footerAction(e, "change_account")}
                        type="button"
                        className="dropdown-item"
                      >
                        {t("paidTobepaid.Changebankaccount")}
                      </a>
                    </li>
                    <li>
                      <a
                        href="!#"
                        onClick={(e) => footerAction(e, "change_expire")}
                        type="button"
                        className="dropdown-item"
                      >
                        {t("paidTobepaid.Changeexpiration")}
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          )}
          {/*  <!-- /Nav listing  end -->*/}
        </main>
        {/*<!-- /Main content end -->*/}
      </section>
      <EditExpense
        showEditExpense={showEditExpenseModal}
        editExpenseClose={editExpenseModalClose}
      />
      <FinancialExpenseService state={state} type="bankTransaction" />
      <ChangeMaturity
        showchangeExpiration={showchangeExpirationModal}
        changeExpirationClose={changeExpirationModalClose}
      />
      <TransferAccountDrawer />
      <TransferAccountNewRecipeDrawer />
    </>
  );
};
export default BankTransactions;
