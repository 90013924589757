import { FloatingLabel, Dropdown, Col, Row, Modal, Form, Button, Card, Collapse } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PrintPDF, encrypt_Decrypt } from '../../../constants/utils';
// const [t] = useTranslation();

import { getGenerateDashboard } from "../../../../redux/slice/transferDataSlice";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useForm } from 'react-hook-form';
import { contractSearchForm } from '../../../Interface';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { MultiSelect } from 'react-multi-select-component';
import { getManager, getGenerateContract } from "../../../../redux/slice/propertyDataSlice";
import { getContractCount, getContractTenants } from "../../../../redux/slice/contractDataSlice";
import { getAllAccounts, getGenerateCharge } from "../../../../redux/slice/expenditureDataSlice";
import { getPropertyOwner } from "../../../../redux/slice/propertyDataSlice";
import Swal from "sweetalert2";
import moment, { weekdays } from "moment-timezone";
let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");

const GenerateCharges = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const [show3, setShow3] = useState(false);
    const [moreFilterShow, setMoreFilterShow] = useState(false);
    const [dateFilterShow, setDateFilterShow] = useState(false);
    const [moreFilter, setMoreFilter] = useState<Boolean>(false)
    const { dashGenerateData } = useSelector((state: RootState) => state.transferData);
    const { contractCount, contTenantsData, status } = useSelector((state: RootState) => state.contractData);
    const { managerData, propertyOwnerData, generateContractData } = useSelector((state: RootState) => state.propertyData);
    const { accountData, accountStatus } = useSelector((state: RootState) => state.expenditureData);
    let generateChargeValidation = Yup.object().shape({});
    if (dateFilterShow) {
        generateChargeValidation = Yup.object().shape({
            startDate: Yup.string().trim().nullable()
                .required(t('requiredMmessage.fieldRequired')),
            endDate: Yup.date()
                .typeError(t('requiredMmessage.fieldRequired'))
                .when(
                    'startDate',
                    (startDate, schema) => (moment(startDate).isValid() ? schema.min(startDate, "End date can't be before start date.") : schema),
                )
        });
    }
    let frm = {
        resolver: yupResolver(generateChargeValidation)
    }
    const { register, reset, handleSubmit, setValue, getValues, formState: { errors }, setError, clearErrors } = useForm<contractSearchForm>(frm);
    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        //dispatch(getGenerateCharge([]))
        let userResult = encrypt_Decrypt();
        setUserJson(userResult);
    }, [])

    //Generate all modal
    const generateModalClose = () => setShow3(false);
    const generateModalShow = () => setShow3(true);
    // Filters section
    const [openFilters, setFiltersOpen] = useState(false);
    const [state, setState] = useState({
        startDate: moment(dateBrazil).startOf('month').format("YYYY-MM-DD"),
        endDate: moment(dateBrazil).endOf('month').format("YYYY-MM-DD"),

        manager: "",
        tenants: "",
        bankAccount: "",
        owner: "",
        contract: "",
        contractType: "",
        propertyType: "",
        billingMethod: "",
        deliveryMethod: "",
        financialStatus: "",
        userId: 1
    })
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(getManager());
        dispatch(getGenerateContract());
        dispatch(getContractTenants());
        dispatch(getContractCount());
        dispatch(getGenerateDashboard({}));
        dispatch(getAllAccounts({ start: 0, end: 1000 }));
        dispatch(getPropertyOwner({ "id": '', "start": 0, "end": 1000 }))
    }, [dispatch])

    const [tenentOption, setTenentOption] = useState<any>([]);
    const [mananerOption, setManagerOption] = useState<any>([]);
    const [bankAccountOption, setBankAccountOption] = useState<any>([]);
    const [ownerOption, setOwnerOption] = useState<any>([]);
    const [contractOption, setContractOption] = useState<any>([])

    const [listTenentData, setTenentData] = useState<any>([]);
    const [listManagerData, setManagerData] = useState<any>([]);
    const [listBankAccountData, setBankAccountData] = useState<any>([]);
    const [listOwnerData, setOwnerData] = useState<any>([]);
    const [listContractData, setContractData] = useState<any>([])

    useEffect(() => {
        let allContractTenantsData: any = [];
        let allMangerData: any = [];
        let allBankAccount: any = [];
        let allOwner: any = [];
        let allContract: any = [];

        contTenantsData &&
            contTenantsData?.forEach((item, index) => {
                allContractTenantsData.push(
                    { value: item.id, label: `${item.name ? item.name : ''}`, name: "tenants" }
                )
            })
        setTenentOption(allContractTenantsData);
        managerData &&
            managerData?.forEach((item, index) => {
                allMangerData.push(
                    { value: item.id, label: `${item.name ? item.name : ''}`, name: "manager" }
                )
            })
        setManagerOption(allMangerData);

        accountData.data &&
            accountData.data?.forEach((item, index) => {
                allBankAccount.push(
                    { value: item.id, label: `${item.accountRef ? item.accountRef : ''}`, name: "bankAccount" }
                )

            })
        setBankAccountOption(allBankAccount);

        propertyOwnerData &&
            propertyOwnerData?.forEach((item, index) => {
                allOwner.push(
                    { value: item.id, label: `${item.name ? item.name : ''}`, name: "owner" }
                )
            })
        setOwnerOption(allOwner);

        generateContractData &&
            generateContractData?.forEach((item, index) => {
                allContract.push(
                    { value: item.id, label: `${item.indentifier ? item.indentifier + ' / ' + item.id : ''}`, name: "contract" }
                )
            })
        setContractOption(allContract);

    }, [contTenantsData, managerData, accountData, propertyOwnerData, generateContractData])

    const searchContract = (evt: any, name_string: any) => {
        let searchId: string[] = [];
        if (evt.length > 0) {
            evt?.forEach((item, index) => {
                searchId.push(item.value)
            })
        }
        if (name_string == "tenants") {
            setTenentData(evt)
        }
        if (name_string == "owner") {
            setOwnerData(evt)
        }
        if (name_string == "contract") {
            setContractData(evt)
        }
        const name = name_string;
        const value = searchId;
        setState({
            ...state,
            [name]: value,
        });
        let Close: any = document.querySelector('.dropdown-content');
        if (Close) {
            Close.setAttribute("hidden", "");
            Close.click();
        }
    };

    const dateFilterClosed = () => {
        reset();
        setDateFilterShow(false)

    }
    const dateFilterOpened = () => {
        setDateFilterShow(true)
    }
    const dateFilterSubmit = (data: any) => {
        let startDt = moment(data.startDate).format("YYYY-MM-DD");
        let endDt = moment(data.endDate).format("YYYY-MM-DD");
        setState({
            ...state,
            ['startDate']: startDt,
            ['endDate']: endDt,
        })
        dateFilterClosed();
    }

    // More filters modal
    const moreFilterClosed = () => {
        setMoreFilterShow(false);
        reset()
        setTenentData([]);
        setManagerData([]);
        setBankAccountData([]);
        setOwnerData([]);
        setContractData([]);
        setState({
            startDate: moment(dateBrazil).startOf('month').format("YYYY-MM-DD"),
            endDate: moment(dateBrazil).endOf('month').format("YYYY-MM-DD"),
            manager: "",
            tenants: "",
            bankAccount: "",
            owner: "",
            contract: "",
            contractType: "",
            propertyType: "",
            billingMethod: "",
            deliveryMethod: "",
            financialStatus: "",
            userId: 1
        })
    }
    const moreFilterOpened = () => {
        setMoreFilterShow(true);
    }
    const moreContract = (evt: any, name_string: any) => {
        let searchId: string[] = [];
        if (evt.length > 0) {
            evt?.forEach((item, index) => {
                searchId.push(item.value)
            })
        }
        if (name_string == "bankAccount") {
            setBankAccountData(evt)
        }
        if (name_string == "manager") {
            setManagerData(evt)
        }
        const name = name_string;
        const value = searchId;
        setState({
            ...state,
            [name]: value,
        });
        let Close: any = document.querySelector('.dropdown-content');
        if (Close) {
            Close.setAttribute("hidden", "");
            Close.click();
        }
    };
    const moreSubmit = (data: any) => {
        setState({
            ...state,
            ['billingMethod']: data.billingMethod,
            ['contractType']: data.contractType,
            ['financialStatus']: data.financialStatus,
            ['propertyType']: data.propertyType,
            ['deliveryMethod']: data.deliveryMethod
        })
        setMoreFilter(true)
        moreFilterClosed();
    }
    const generateChargeSimulate = async ({ hasSplitPayment }: { hasSplitPayment: "0" | "1" }) => {
        const monthsDiff = moment(new Date(state.endDate)).diff(new Date(state.startDate), 'months', true);

        if (Math.round(monthsDiff) <=6) {
            navigate('/generateChargeSimulate', { state: { ...state, hasSplitPayment } });
        }

        else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("limitDate"),
                confirmButtonText: "OK",
                timer: 2000,
            });
        }
    }

    const handleRoutClick = (e: any, href: any) => {
        e.preventDefault();
        let attr = document.querySelector("body");
        // attr?.setAttribute("readjusFirst", "1yeardta");
        navigate(href);
    }

    return (
        <section className="content-wrapper content-wrapper-primary">
            {/*<!-- Breadcrumb section start from here -->*/}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100 mb-xl-4">
                    <Row className="gx-2 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <h1 className="mb-0 lh-base text-truncate">{t("generateCharges")}</h1>
                                {/* Filter button visible in mobile devices */}
                                <div className="d-xl-none">
                                    <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                                        {t("dropdownItem.filters")}
                                        <img
                                            src="assets/images/icons/filter-icon.svg"
                                            alt="Filter"
                                            className="ms-1 h-12"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 1 }} xl={{ order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20 cursor-pe" onClick={PrintPDF} />
                                </li>
                                {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <img src="assets/images/icons/settings-light.svg" alt="Settings" className="h-20" />
                                </li> */}
                            </ul>
                        </Col>
                    </Row>
                </div>
                {/* Filters start here */}
                <Collapse className="d-xl-block collpase-filters" in={openFilters}>
                    <Form id="collpase-filter-text" action="" method="">
                        <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
                            <h5 className="mb-0 fw-bold">{t("dropdownItem.filters")}</h5>
                            <span className="cursor-pe" onClick={() => setFiltersOpen(!openFilters)}>
                                <img src="assets/images/icons/cross.svg" alt="Plus icon" className="ms-xxl-1 ms-auto h-14" />
                            </span>
                        </div>
                        <Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={`${listContractData.length > 0 ? "show" : ""}`}>
                                        {t("contract")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <MultiSelect
                                            options={contractOption}
                                            value={listContractData}
                                            onChange={(e) => searchContract(e, "contract")}
                                            labelledBy={t("formLabel.select")}
                                            overrideStrings={{
                                                selectSomeItems: `${t("formLabel.select")}`,
                                                selectAll: `${t("selectAll")}`,
                                                search: `${t("formLabel.typeSearch")}`,
                                                allItemsAreSelected: `${t("selectAll")}`,
                                            }} // <- to override strings
                                        />

                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={`${listTenentData.length > 0 ? "show" : ""}`}>
                                        {t("tenant")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <MultiSelect
                                            options={tenentOption}
                                            value={listTenentData}
                                            onChange={(e) => searchContract(e, "tenants")}
                                            labelledBy={t("formLabel.select")}
                                            overrideStrings={{
                                                selectSomeItems: `${t("formLabel.select")}`,
                                                selectAll: `${t("selectAll")}`,
                                                search: `${t("formLabel.typeSearch")}`,
                                                allItemsAreSelected: `${t("selectAll")}`,
                                            }} // <- to override strings
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" onClick={dateFilterOpened} className={`${state?.["startDate"] && state?.["endDate"] ? "show" : ""}`}>
                                        {t("TransferPage.Duetotheduedatebetween")}
                                    </Dropdown.Toggle>
                                    {/* <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t("TransferPage.Duetotheduedatebetween")}>
                                            <Form.Control type="date" aria-label="Due to the due date between" name="Duetotheduedatebetween" onChange={(e) => handleChange(e)}></Form.Control>
                                        </FloatingLabel>
                                    </Dropdown.Menu> */}
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={`${listOwnerData.length > 0 ? "show" : ""}`}>
                                        {t("owner")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <MultiSelect
                                            options={ownerOption}
                                            value={listOwnerData}
                                            onChange={(e) => searchContract(e, "owner")}
                                            labelledBy={t("formLabel.select")}
                                            overrideStrings={{
                                                selectSomeItems: `${t("formLabel.select")}`,
                                                selectAll: `${t("selectAll")}`,
                                                search: `${t("formLabel.typeSearch")}`,
                                                allItemsAreSelected: `${t("selectAll")}`,
                                            }} // <- to override strings
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside" className="moreFilter">
                                    <Dropdown.Toggle variant="light" size="sm" className={`d-flex align-items-center ${moreFilter ? "show" : ""}`} onClick={moreFilterOpened}>
                                        {t("moreFilters")}
                                        <img
                                            src={`assets/images/icons/${moreFilter ? "icons8-plus-24.png" : "plus-light.svg"}`}
                                            alt="Plus icon"
                                            className="ms-xl-1 ms-auto h-20"
                                        />
                                    </Dropdown.Toggle>
                                </Dropdown>
                            </Col>
                        </Row>
                    </Form>
                </Collapse>
                {/* /Filters end */}
            </div>
            {/*<!-- /Breadcrumb section end -->*/}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                <div>
                    <Row className="gy-lg-4 gy-3 h-100">
                        <Col xs={12} md={6} xl={4}>
                            <Card className="h-100">
                                <Card.Body>
                                    {/* <Link className="text-reset stretched-link" to={'/contracts_readjust'}>
                                        <span className="d-flex align-items-center mb-1">
                                            <img src="assets/images/icons/circle-check-light.svg" alt="Charges generated icon" className="me-1 h-20" />
                                            <h3 className="lh-base mb-0">
                                                {dashGenerateData && dashGenerateData.length > 0 ? dashGenerateData[0].totalReadjust : '0'}
                                            </h3>
                                        </span>
                                        <span className="text-secondary d-block">{t("contractToReadjust")}</span>
                                    </Link> */}
                                    <Link to={"/readjustContracts"} onClick={(e) => handleRoutClick(e, "/readjustContracts")}>
                                        <div className="d-flex flex-row align-items-center justify-content-start mb-2">
                                            <img
                                                src="assets/images/icons/chart-line2.svg"
                                                alt="Readjust"
                                                className="flex-shrink-0 me-1 h-20"
                                            />
                                            <span className="d-block text-dark h3 mb-0 lh-1">
                                                {
                                                    contractCount && contractCount.length > 0 ?
                                                        contractCount[0].toReadjust
                                                        :
                                                        0
                                                }
                                            </span>

                                        </div>
                                        <span className="text-secondary d-block">{t("contractToReadjust")}</span>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                            <Card className="h-100">
                                <Card.Body>
                                    <Link className="stretched-link" to={'/received'}>
                                        <span className="d-flex align-items-center mb-1">
                                            <img src="assets/images/icons/money-light.svg" alt="Charges generated icon" className="me-1 h-20" />
                                            <h3 className="lh-base text-dark mb-0">
                                                {dashGenerateData && dashGenerateData.length > 0 ? dashGenerateData[0].totalGenerate : '0'}
                                            </h3>
                                        </span>
                                        <span className="text-secondary d-block mb-1">{t("chargesGenerated")}</span>
                                        <span className="fw-bold text-primary">{t("validateIR")}</span>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                            <Card className="h-100">
                                <Card.Body>
                                    <span className="d-flex align-items-center mb-1">
                                        <img src="assets/images/icons/file-light.svg" alt="Charges generated icon" className="me-1 h-20" />
                                        <h3 className="lh-base mb-1">
                                           Gerar cobranças
                                        </h3>
                                    </span>
                                    <div className="d-flex flex-column gap-2">
                                      {
                                          userJson && userJson.length > 0 && (userJson.indexOf(3020) !== -1) &&
                                          <>
                                              <span className="fw-bold text-primary cursor-pe" onClick={() => generateChargeSimulate({ hasSplitPayment: "0" })}>Simular sem split</span>
                                              <span className="fw-bold text-primary cursor-pe" onClick={() => generateChargeSimulate({ hasSplitPayment: "1" })}>Simular com split</span>
                                          </>
                                      }
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </main>
            {/*<!-- /Main content end -->*/}
            {/* More filters modal start from here */}
            <Modal show={moreFilterShow} onHide={moreFilterClosed}>
                <Form onSubmit={handleSubmit(moreSubmit)} id="templateGenerateCarges">
                    <Modal.Header closeButton>
                        <Modal.Title>{t("property.moreFilters")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12} className="mb-3 mb-lg-4">
                                <MultiSelect
                                    options={bankAccountOption}
                                    value={listBankAccountData}
                                    onChange={(e) => moreContract(e, "bankAccount")}
                                    labelledBy={t("formLabel.select")}
                                    overrideStrings={{
                                        selectSomeItems: `${t("formLabel.select")}`,
                                        selectAll: `${t("selectAll")}`,
                                        search: `${t("formLabel.typeSearch")}`,
                                        allItemsAreSelected: `${t("selectAll")}`,
                                    }} // <- to override strings
                                />
                            </Col>
                            <Col md={6}>
                                <FloatingLabel className="mb-3 mb-lg-4" label={t("contractType")}>
                                    <Form.Select {...register('contractType')} aria-label={t('contractType')}>
                                        <option value="All">{t("formLabel.all")}..</option>
                                        <option value="1">{t('residential')}</option>
                                        <option value="2">{t('nonResidential')}</option>
                                        <option value="3">{t('commercial')}</option>
                                        <option value="4">{t('industry')}</option>
                                        <option value="5">{t('season')}</option>
                                        <option value="6">{t('byOrder')}</option>
                                        <option value="7">{t('mixed')}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel className="mb-3 mb-lg-4" label={t("formLabel.propertyType")}>
                                    <Form.Select {...register('propertyType')}>
                                        <option value="All">{t("formLabel.all")}..</option>
                                        <option value="1" label="Casa">Casa</option>
                                        <option value="4" label="Apartamento">Apartamento</option>
                                        <option value="33" label="Apartamento Duplex">Apartamento Duplex</option>
                                        <option value="38" label="Apartamento Triplex">Apartamento Triplex</option>
                                        <option value="9" label="Área comum">Área comum</option>
                                        <option value="42" label="Andar corporativo">Andar corporativo</option>
                                        <option value="29" label="Bangalô">Bangalô</option>
                                        <option value="13" label="Barracão">Barracão</option>
                                        <option value="40" label="Box/Garagem">Box/Garagem</option>
                                        <option value="3" label="Casa comercial">Casa comercial</option>
                                        <option value="2" label="Casa em condomínio">Casa em condomínio</option>
                                        <option value="44" label="Casa Assobradada">Casa Assobradada</option>
                                        <option value="5" label="Cobertura">Cobertura</option>
                                        <option value="14" label="Chácara">Chácara</option>
                                        <option value="25" label="Conjunto">Conjunto</option>
                                        <option value="39" label="Edícula">Edícula</option>
                                        <option value="10" label="Escritório">Escritório</option>
                                        <option value="20" label="Fazenda">Fazenda</option>
                                        <option value="6" label="Flat">Flat</option>
                                        <option value="16" label="Galpão">Galpão</option>
                                        <option value="8" label="Garagem">Garagem</option>
                                        <option value="43" label="Garden">Garden</option>
                                        <option value="21" label="Haras">Haras</option>
                                        <option value="35" label="Hotel">Hotel</option>
                                        <option value="31" label="Ilha">Ilha</option>
                                        <option value="7" label="Kitnet">Kitnet</option>
                                        <option value="27" label="Laje">Laje</option>
                                        <option value="30" label="Loft">Loft</option>
                                        <option value="12" label="Loja">Loja</option>
                                        <option value="41" label="Pavilhão">Pavilhão</option>
                                        <option value="32" label="Penthouse">Penthouse</option>
                                        <option value="22" label="Prédio">Prédio</option>
                                        <option value="28" label="Pousada">Pousada</option>
                                        <option value="19" label="Ponto">Ponto</option>
                                        <option value="18" label="Quiosque">Quiosque</option>
                                        <option value="36" label="Rancho">Rancho</option>
                                        <option value="34" label="Resort">Resort</option>
                                        <option value="15" label="Salão">Salão</option>
                                        <option value="11" label="Sala comercial">Sala comercial</option>
                                        <option value="23" label="Sítio">Sítio</option>
                                        <option value="24" label="Sobrado">Sobrado</option>
                                        <option value="37" label="Studio">Studio</option>
                                        <option value="17" label="Terreno">Terreno</option>
                                        <option value="26" label="Village">Village</option>
                                        <option value="45" label="Consultório">Consultório</option>
                                        <option value="1000" label="Outro">Outro</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FloatingLabel className="mb-3 mb-lg-4" label={t("deliveryMethod")}>
                                    <Form.Select aria-label={t("deliveryMethod")} {...register("deliveryMethod")}>
                                        <option value="All">{t("formLabel.all")}..</option>
                                        <option value="1">{t('mailOnly')}</option>
                                        <option value="2">{t('digitalOnly')}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel className="mb-3 mb-lg-4" label={t("billingMethod")}>
                                    <Form.Select aria-label={t("billingMethod")} {...register("billingMethod")}>
                                        <option value="All">{t("formLabel.all")}..</option>
                                        <option value="0">{t('expiredMonth')}</option>
                                        <option value="1">{t('monthDue')}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={12} className="mb-3 mb-lg-4">
                                <MultiSelect
                                    options={mananerOption}
                                    value={listManagerData}
                                    onChange={(e) => moreContract(e, "manager")}
                                    labelledBy={t("formLabel.select")}
                                    overrideStrings={{
                                        selectSomeItems: `${t("formLabel.select")}`,
                                        selectAll: `${t("selectAll")}`,
                                        search: `${t("formLabel.typeSearch")}`,
                                        allItemsAreSelected: `${t("selectAll")}`,
                                    }} // <- to override strings
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={moreFilterClosed}>
                            {t('button.resetfilter')}
                        </Button>
                        <Button variant="primary" type='submit'>
                            {t("button.done")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /More filters modal end */}
            {/* Generate all start here */}
            <Modal show={show3} onHide={generateModalShow}>
                <Form>
                    <Modal.Body>
                        {t("generateCharges")}?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={generateModalClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" onClick={generateModalClose}>
                            {t("button.done")}
                        </Button>
                    </Modal.Footer>
                </Form>

            </Modal>
            {/* /Generate all end */}
            <Modal show={dateFilterShow} onHide={dateFilterClosed}>
                <Form onSubmit={handleSubmit(dateFilterSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("TransferPage.month")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="g-3 g-lg-4">
                            <Col sm>
                                <FloatingLabel label={t("TransferPage.startDate")}>
                                    <Form.Control type="date" aria-label="From" className={`${errors.startDate ? 'is-invalid' : ''}`} {...register("startDate")}></Form.Control>
                                    <Form.Control.Feedback type="invalid">{errors.startDate?.message}</Form.Control.Feedback >
                                </FloatingLabel>
                            </Col>
                            <Col sm>
                                <FloatingLabel label={t("TransferPage.endDate")}>
                                    <Form.Control type="date" aria-label="to" className={`${errors.endDate ? 'is-invalid' : ''}`} {...register("endDate")}></Form.Control>
                                    <Form.Control.Feedback type="invalid">{errors.endDate?.message}</Form.Control.Feedback >
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={dateFilterClosed}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" type='submit'>
                            {t("button.save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </section>
    )
}
export default GenerateCharges;
