import React from "react";

import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useFormContext, useFormState } from "react-hook-form";
import { z } from "zod";

export const SafraSchema = z.object({
  ContaCodigoBeneficiario: z.string().min(1, "Campo obrigatório"),
  ConvenioNumero: z.string().min(1, "Campo obrigatório"),
  ConvenioDescricao: z.string().min(1, "Campo obrigatório"),
  ConvenioCarteira: z.string().min(1, "Campo obrigatório"),
  ConvenioPadraoCNAB: z.string().min(1, "Campo obrigatório"),
  TituloDocEspecie: z.string().min(1, "Campo obrigatório"),
});

type SafraFormType = z.infer<typeof SafraSchema>;

export function SafraFields() {
  const { register } = useFormContext();
  const { errors } = useFormState<SafraFormType>();

  return (
    <>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel
            controlId="ContaCodigoBeneficiario"
            label="Código beneficiário"
          >
            <Form.Control
              placeholder="Código beneficiário"
              {...register("ContaCodigoBeneficiario")}
              className={`${errors.ContaCodigoBeneficiario && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ContaCodigoBeneficiario?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel controlId="ConvenioNumero" label="Convênio">
            <Form.Control
              placeholder="Convênio"
              {...register("ConvenioNumero")}
              className={`${errors.ConvenioNumero && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ConvenioNumero?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel
            controlId="ConvenioDescricao"
            label="Descrição do convênio"
          >
            <Form.Control
              placeholder="Descrição do convênio"
              {...register("ConvenioDescricao")}
              className={`${errors.ConvenioDescricao && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ConvenioDescricao?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel controlId="ConvenioPadraoCNAB" label="CNAB">
            <Form.Select {...register("ConvenioPadraoCNAB")}>
              <option disabled>CNAB</option>
              <option value="240" defaultChecked>
                240 (Recomendado)
              </option>
              <option value="400">400</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel controlId="ConvenioCarteira" label="Tipo de carteira">
            <Form.Select {...register("ConvenioCarteira")}>
              <option disabled>Tipo de carteira</option>
              <option value="1" defaultChecked>
                1 - Cobrança simples
              </option>
              <option value="2">2 - Cobrança vinculada</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel controlId="TituloDocEspecie" label="Espécie DOC">
            <Form.Select 
              {...register("TituloDocEspecie")}
              className={`${errors.TituloDocEspecie && "is-invalid"}`}
            >
              <option disabled>Espécie DOC</option>
              <option value="01" defaultChecked>
                DM - DUPLICATA MERCANTIL
              </option>
              <option value="02">NP - NOTA PROMISSÓRIA</option>
              <option value="03">NS - NOTA DE SEGURO</option>
              <option value="04">DS - DUPLICATA DE SERVIÇOS</option>
              <option value="05">RC - RECIBO</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.TituloDocEspecie?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
    </>
  );
}
