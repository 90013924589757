import { FloatingLabel, ButtonGroup, Accordion, InputGroup, Dropdown, Col, Row, Modal, Form, Button, Alert, Collapse } from 'react-bootstrap';
import { setEndDate, setStartDate, transferToSwitchOff, getTransferContractsCount, setCheckedId, transferContracts, setTransferId, setMaturityOpen, setFilterType, setTansferFilterOpen } from '../../../redux/slice/transferDataSlice';
import React, { useState, MouseEvent, useEffect } from 'react';
import { useNavigate, Outlet, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { contractSearchForm } from '../../Interface';
import Checkbox from '../Contracts/Common/Checkbox';
import Loader from '../../Profile/Company/Loader';
import Swal from 'sweetalert2';
import { getAllProperty, getOwner } from "../../../redux/slice/contractDataSlice";
import { AppDispatch, RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import TransferService from '../Contracts/Common/TransferService';
import TransferMaturityService from '../Contracts/Common/TransferMaturityService';
import { getBraches } from '../../../redux/slice/branchSlice';
import { getManager } from "../../../redux/slice/propertyDataSlice";
//import moment from "moment";
import moment from "moment-timezone";
import Pagination from "../../Pagination";
import { MultiSelect } from 'react-multi-select-component';
import { encrypt_Decrypt } from '../../constants/utils';
const Transfers = (contractSearchForm) => {
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    const { register, handleSubmit, reset, formState: { errors } } = useForm<contractSearchForm>();
    const { proprtyData, Property_Type } = useSelector((state: RootState) => state.contractData)
    const { branchList } = useSelector((state: RootState) => state.branch)
    const { managerData } = useSelector((state: RootState) => state.propertyData)
    const [show, setShow] = useState(false);
    const [datepickershow, setDatePickerShow] = useState(false);
    const [double, setDouble] = useState(false);
    const [branchState, setBranchState] = useState({
        start: "0",
        end: "100"
    });

    const navigate = useNavigate();
    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(2090) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])

    const filterShow = () => {
        setShow(true);
        setFiltersOpen(false);
    }
    const filterClose = () => {
        dispatch(transferContracts(state));
        setShow(false);
        setDouble(false);
        reset();
        setDatePickerShow(false)
        setListPropertydata([])
        setListPropertyTypeData([])
        setListBranchListData([])
        setListManagerData([])
    }

    /*----------------Harjinder-----------------*/

    const { transferContractCountData, transferCountStatus, transferContractData, transferStatus } = useSelector((state: RootState) => state.transferData)
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState<any[]>([]);
    let newDate = new Date(dateBrazil);
    let startDt = moment(newDate).format("MMM/YYYY");
    let searchDt = moment(newDate).format("MMM/YYYY")
    let endDt = moment(newDate).add(1, 'M').format("MMM/YYYY");

    const getTransferContracts = async (postData) => {
        dispatch(setStartDate(postData.startDate));
        dispatch(setEndDate(postData.endDate));
        let response = await dispatch(transferContracts(postData));
    }
    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(transferContractData.data.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    const noneHandleSelectAll = e => {
        setIsCheckAll(false);
        setIsCheck([]);
    }
    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }
        if (document.querySelectorAll('.check_Checkbox input').length > 0) {
            let data = document.querySelectorAll('.check_Checkbox input');
            let count_allCheck = 0;
            setTimeout(() => {
                data.forEach((item, index) => {
                    if (item["checked"]) {
                        count_allCheck++
                    }
                    if (!item["checked"]) {
                        setIsCheckAll(false);
                    }
                })
                if (count_allCheck == data.length) {
                    setIsCheckAll(true);
                }
            }, 10)
        }
    };
    const transferOpened = async (id) => {
        if (typeof id !== "undefined" && id) {
            dispatch(setTransferId(id));
        }
        else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    }
    const maturityOpened = () => {
        if (isCheck.length > 0) {
            dispatch(setMaturityOpen(true));
            dispatch(setCheckedId(isCheck))
        }
        else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.Pleasecheckedthe"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    }
    const transferSimulate = async () => {
        if (isCheck.length > 0) {
            Swal.fire({
                title: t("Sweetalert.Areyousure"),
                text: t("Sweetalert.Youwontbeabletorevert"),
                // icon: 'success','warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t("newTextSweetAlert.Yes_it"),
                cancelButtonText: t('button.cancel'),
            }).then(async (result) => {
                if (result.value) {
                    let data = {
                        expenseId: isCheck
                    }
                    navigate('/transferSimulate', { state: data });
                }
            })
        }
        else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.Pleasecheckedthe"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    }
    const transferToSwitch = async () => {
        if (isCheck.length > 0) {
            Swal.fire({
                title: t("Sweetalert.Areyousure"),
                text: t("Sweetalert.Youwontbeabletorevert"),
                // icon: 'success','warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t("newTextSweetAlert.Yes_it"),
                cancelButtonText: t('button.cancel'),
            }).then(async (result) => {
                if (result.value) {
                    let id = isCheck;
                    let data = await dispatch(transferToSwitchOff(id));
                    if (data.payload.resCode === 200) {
                        Swal.fire({
                            title: t("Sweetalert.Success"),
                            text: data.payload.message,
                            // icon: 'success',"success",
                            confirmButtonText: "OK",
                            timer: 2000
                        });
                        dispatch(transferContracts(state));
                    }
                    else {
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: data.payload.message,
                            // icon: 'success',"error",
                            confirmButtonText: "OK",
                            timer: 2000
                        });
                    }
                }
            })

        }
        else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.Pleasecheckedthe"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    }

    const transferFilterOpened = () => {
        dispatch(setTansferFilterOpen(true))
        dispatch(setFilterType(true));
    }

    const [state, setState] = useState({
        filterby: "",
        owner: "",
        Withpaidcharges: "",
        Withchargessettledbetween: "",
        Duetotheduedatebetween: "",
        startDate: startDt,
        endDate: endDt,
        filteSearchDate: "",
        filterEndDate: "",
        start: 0,
        end: 30
    })

    const searchContract = (evt) => {
        const name = evt.target.name;
        const value = evt.target.value;
        setState({
            ...state,
            [name]: value
        })
    }


    /*----------Pagination---------*/

    let PageSize = 20;
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        setState({
            ...state,
            ['start']: (currentPage > 1 ? currentPage - 1 : 0) * 20,
            ['end']: 20
        })
    }, [currentPage])

    const filterCharge = (dt, tp) => {
        if (typeof tp !== "undefined") {
            let currentDate = new Date(dt);
            if (tp == "Subtract") {
                startDt = moment(currentDate).subtract(2, 'M').format("MMM/YYYY");
                endDt = moment(currentDate).subtract(1, 'M').format("MMM/YYYY");
            }
            else {
                startDt = moment(currentDate).add(1, 'M').format("MMM/YYYY");
                endDt = moment(currentDate).add(2, 'M').format("MMM/YYYY");
            }
            setState({
                ...state,
                ['startDate']: startDt,
                ['endDate']: endDt
            })
        }
    }


    useEffect(() => {
        dispatch(getBraches(branchState));
        dispatch(getManager());
        dispatch(getOwner());
        dispatch(getTransferContractsCount({ start: 0, end: 100 }));
        getTransferContracts(state);
    }, [dispatch, state]);

    const [listProperty, setListProperty] = useState<any>([]);
    const [listPropertydata, setListPropertydata] = useState<any>([]);
    const [ListPropertyType, setListPropertyType] = useState<any>([]);
    const [listPropertytypeData, setListPropertyTypeData] = useState<any>([]);
    const [listBranchlist, setListBranchList] = useState<any>([]);
    const [listBranchlistData, setListBranchListData] = useState<any>([]);
    const [listManagerlist, setListManagerList] = useState<any>([]);
    const [listManagerData, setListManagerData] = useState<any>([]);
    const onSubmit = async (data: contractSearchForm, e: any) => {
        e.preventDefault();
        setDouble(true)
        data.start = 0;
        data.end = 10;
        data.startDate = startDt;
        data.endDate = endDt;
        data["contractType"] = listPropertydata[0].value;
        data["propertyType"] = listPropertytypeData[0].value;
        data["branch"] = listBranchlistData[0].value;
        data["manager"] = listManagerData[0].value;
        let response = await dispatch(transferContracts(data))
        if (typeof response.payload !== "undefined" && (response.payload.resCode === 201 || response.payload.resCode === 200)) {
            setDouble(false);
            Swal.fire({
                // icon: 'success','success',
                title: t("Sweetalert.Success"),
                text: t("Sweetalert.successget"),
                confirmButtonText: "OK",
                timer: 2000
            })
            setShow(false);

        }
        else {
            setDouble(false)
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    }

    useEffect(() => {
        let moreFilterData = {
            owners: "",
            condominium: "",
            propertyType: "",
            business: "",
            realEstate: "",
            property: "",
            branch: "",
            city: "",
            tenants: "",
            neighborhood: "",
            administrator: "",
            manager: "",
            status: "",
            start: 0,
            end: 1000
        }
        dispatch(getAllProperty(moreFilterData))
    }, [dispatch,])

    // Filters section
    const [openFilters, setFiltersOpen] = useState(false);
    /*----------------End-----------------*/

    const handleChange = (e: any) => {
        let name = e.target.name
        let value = e.target.value
        setState({
            ...state,
            [name]: value
        })
    }

    const propertyOwner = (evt, cmd) => {
        if (cmd === "contractType") {
            setListPropertydata(evt);
        } else if (cmd === "propertyType") {
            setListPropertyTypeData(evt);
        } else if (cmd === "branchType") {
            setListBranchListData(evt)
        } else if (cmd === "manager") {
            setListManagerData(evt)
        }

        let Close: any = document.querySelector('.dropdown-container[aria-labelledby="Immobile"] .dropdown-content');

        if (Close) {
            Close.setAttribute("hidden", "");
            Close.click();
        }
    }

    useEffect(() => {
        if (proprtyData && proprtyData.data && proprtyData.data.length > 0) {
            let allPropertyOption: any = [];
            proprtyData.data.filter(item => item.property_identifier !== '').forEach((item, index) => {
                allPropertyOption.push({
                    value: item.id, label: `${item.property_identifier ? item.property_identifier : ''}`,
                });
            });
            setListProperty(allPropertyOption);
        }
        if (Property_Type && Property_Type && Property_Type.length > 0) {
            let allProperty_Type: any = [];
            Property_Type.forEach((item, index) => {
                allProperty_Type.push({
                    value: item.value, label: `${item.label ? item.label : ''}`,
                });
            });
            setListPropertyType(allProperty_Type);
        }
        if (branchList && branchList && branchList.length > 0) {
            let allBranchlistOption: any = [];
            branchList.forEach((item, index) => {
                allBranchlistOption.push({
                    value: item.id, label: `${item.name ? item.name : ''}`,
                });
            });
            setListBranchList(allBranchlistOption);
        }
        if (managerData && managerData && managerData.length > 0) {
            let allManagerOption: any = [];
            managerData.forEach((item, index) => {
                allManagerOption.push({
                    value: item.id, label: `${item.name ? item.name : ''}`,
                });
            });
            setListManagerList(allManagerOption);
        }
    }, [proprtyData, branchList, managerData]);

    const handleShow = () => {
        setDatePickerShow(true)
    }

    const dateSubmit = (data: any) => {
        startDt = moment(data.startDate).format("YYYY-MM-DD");
        endDt = moment(data.endDate).format("YYYY-MM-DD");
        setState({
            ...state,
            ['filteSearchDate']: startDt,
            ['filterEndDate']: endDt,
        })

        setDatePickerShow(false)
        reset()
    }
    return (
        <section className="content-wrapper content-wrapper-primary">
            {/*<!-- Breadcrumb section start from here -->*/}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100 mb-xl-4">
                    <Row className="gx-2 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 2, order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <h1 className="mb-0 lh-base text-truncate">{t("Dashboard.Transfers")}</h1>
                                {/* Filter button visible in mobile devices */}
                                <div className="d-xl-none">
                                    <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                                        {t("dropdownItem.filters")}
                                        <img
                                            src="assets/images/icons/filter-icon.svg"
                                            alt="Filter"
                                            className="ms-1 h-12"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                            <div className="input-group">
                                <InputGroup>
                                    <Form.Control {...register('contract')} onKeyUp={searchContract} placeholder={t('formLabel.typeSearch')} />
                                    <InputGroup.Text id="search_icon">
                                        <img src="./assets/images/icons/search.svg" alt="Search icon" />
                                    </InputGroup.Text>
                                </InputGroup>
                            </div>
                        </Col>
                        <Col xs={{ order: 1 }} xl={{ span: 7, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                {
                                    userJson && userJson.length > 0 && (userJson.indexOf(2091) !== -1) &&
                                    <li className="list-inline-item">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="link" size="sm" className="p-0 fw-bold dropdown-arrow-none" id="dropdownMenuButton1">
                                                <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />
                                                {t("Dashboard.Transfers")}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="report-transfer">{t("simulate")}</Dropdown.Item>
                                                <Dropdown.Item href={"#"}>{t("TransferPage.Batchprocess")}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </li>
                                }
                                {/*<li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <a href={"#"} className="fw-bold">
                                        <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />
                                        {t("Links.invoices")}
                                    </a>
                                </li>
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <a href="transfers-to-be-carried-out" className="fw-bold">
                                        <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />
                                        {t("TransferPage.Accesscurrentaccounttransfer")}
                                    </a>
                                </li>
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <div className="dropdown">
                                        <a data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" />
                                        </a>
                                        <ul className="dropdown-menu mh-456 overflow-auto" >
                                            <li>
                                                <a className="dropdown-item" href={"#"}>
                                                    {t("formLabel.printScreen")}
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="report-transfer">
                                                    {t("TransferPage.Transfersmade")}
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="report-guaranteed-realized">
                                                    {t("TransferPage.Guaranteedandrealizedtransfer")}
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="report-guaranteed-realized">
                                                    {t("TransferPage.Transferstobecarriedout")}
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="report">
                                                    {t("TransferPage.Ownersextract")}
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="report-simplified-group">
                                                    {t("TransferPage.Simplifiedownerstatement")}
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="report-transfer-by-bank">
                                                    {t("TransferPage.Transfersbybank")}
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="report">
                                                    {t("TransferPage.Transferinvoices")}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <img src="assets/images/icons/settings-light.svg" alt="Settings" className="h-20" />
                                </li>
                                <li className="list-inline-item mb-md-0 mb-1">
                                    <a href="#">
                                        <img src="assets/images/icons/question_icon.svg" alt="Question icon" className="h-20" />
                                    </a>
                                </li> */}
                            </ul>
                        </Col>
                    </Row>
                </div>
                {/* Filters start here */}
                <Collapse className="d-xl-block collpase-filters" in={openFilters}>
                    <Form id="collpase-filter-text" action="" method="">
                        <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
                            <h5 className="mb-0 fw-bold">Filters</h5>
                            <span className="cursor-pe" onClick={() => setFiltersOpen(!openFilters)}>
                                <img src="assets/images/icons/cross.svg" alt="Plus icon" className="ms-xxl-1 ms-auto h-14" />
                            </span>
                        </div>
                        <Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={state.filterby !== "" ? "show" : ""}>
                                        {t("TransferPage.Filterby")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t("TransferPage.Filterby")} >
                                            <Form.Select aria-label="Filter by" name="filterby" onChange={(e) => handleChange(e)}>
                                                <option value="1">{t("TransferPage.Transfertocarryout")}</option>
                                                <option value="2">{t("TransferPage.performed")}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={state.owner !== "" ? "show" : ""}>
                                        {t("Dashboard.Owner")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t("Dashboard.Owner")}>
                                            <Form.Select aria-label="Owner" name="owner" onChange={(e) => handleChange(e)} >
                                                <option value="All">{t('selectAll')}..</option>
                                                {/* {ownerData.map((item, index) => (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            ))} */}
                                                <option value="1">Physical</option>
                                                <option value="2">Legal</option>
                                                <option value="3">Physical and Legal</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={state.Withpaidcharges !== "" ? "show" : ""}>
                                        {t("TransferPage.Withpaidcharges")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t("TransferPage.Withpaidcharges")}>
                                            <Form.Select aria-label="With paid charges" name="Withpaidcharges" onChange={(e) => handleChange(e)}>
                                                <option value="All">{t('selectAll')}..</option>
                                                <option value="1">Overdue</option>
                                                <option value="2">In day</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            {/* <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={state.Withchargessettledbetween !== "" ? "show" : ""} onClick={handleShow}>
                                        {t("TransferPage.Withchargessettledbetween")}
                                    </Dropdown.Toggle>

                                </Dropdown>
                            </Col> */}
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={state.Duetotheduedatebetween !== "" ? "show" : ""} onClick={handleShow}>
                                        {t("TransferPage.Duetotheduedatebetween")}
                                    </Dropdown.Toggle>
                                    {/* <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t("TransferPage.Duetotheduedatebetween")}>
                                            <Form.Control type="date" aria-label="Due to the due date between" name="Duetotheduedatebetween" onChange={(e) => handleChange(e)}></Form.Control>
                                        </FloatingLabel>
                                    </Dropdown.Menu> */}
                                </Dropdown>
                            </Col>
                            <Col xl="auto" >
                                <Button className="d-flex align-items-center" variant="light" size="sm" onClick={filterShow}>
                                    {t("property.moreFilters")}
                                    <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="ms-xl-1 ms-auto h-20" />
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Collapse>
                {/* /Filters end */}
            </div>
            {/*<!-- /Breadcrumb section end -->*/}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                <div>
                    <Row className="gy-lg-4 gy-3 h-100">
                        <Col xl={12}>
                            <div className="card">
                                <div className="card-header">
                                    {t("TransferPage.Pendingfrom")} {searchDt}
                                </div>
                                <div className="card-body text-center">
                                    <Row className="gy-3">
                                        <Col sm={4} className="border-end border-light border-xs-none">
                                            <div className="d-flex flex-row align-items-center justify-content-center mb-2">
                                                <span className="d-block text-center text-dark h3 mb-0 lh-1">{transferContractCountData.data ? transferContractCountData.data.transferOut : '0,00'}</span>
                                            </div>
                                            <span className="text-secondary d-block text-center">{t("TransferPage.Transfertocarryout")}</span>
                                        </Col>
                                        <Col sm={4} className="border-end border-light border-xs-none">
                                            <div className="d-flex flex-row align-items-center justify-content-center mb-2">
                                                <span className="d-block text-center text-dark h3 mb-0 lh-1">0</span>
                                            </div>
                                            <a href="#" className="text-secondary d-block text-center">{t("TransferPage.Invoicestobegenerated")}</a>
                                            <Button variant="light" size="sm">{t("TransferPage.Generateinvoices")}</Button>
                                        </Col>
                                        <Col sm={4}>
                                            <div className="d-flex flex-row align-items-center justify-content-center mb-2">
                                                <span className="d-block text-center text-dark h3 mb-0 lh-1">{transferContractCountData.data ? transferContractCountData.data.transferComming : '0,00'}</span>
                                            </div>
                                            <span className="text-secondary d-block text-center">{t("pending")}</span>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12}>
                            <div className="card">
                                <div className="card-body">
                                    {/* <!-- Table start from here -->*/}
                                    <div className="table-responsive">
                                        <div className="text-center mb-lg-4 mb-3">
                                            <ButtonGroup size="sm" aria-label="Date">
                                                <Button variant="light" onClick={() => filterCharge(state.startDate, 'Subtract')}>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/chevron-sm-left.svg"} className="me-1" />
                                                    {state.startDate}
                                                </Button>
                                                <Button variant="light" onClick={() => filterCharge(state.endDate, 'Add')}>
                                                    {state.endDate}
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/chevron-sm-right.svg"} className="ms-1" />
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                        {
                                            (transferStatus == "loading") ?
                                                <Loader />
                                                :
                                                <table className="table table-custoproprtyData.data.filter(item => item.property_identifier !== '')m table-custom-bordered mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                {
                                                                    transferContractData.data && transferContractData.data.length > 0 ?
                                                                        <Checkbox type="checkbox" name="selectAll" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} />
                                                                        : ''
                                                                }
                                                            </th>
                                                            <th></th>
                                                            <th>{t("formLabel.transfer")}</th>
                                                            <th>{t("contract")}/{t("owner")}</th>
                                                            {/*<th></th>*/}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {transferContractData.data &&
                                                            transferContractData.data.length > 0 ?
                                                            transferContractData.data.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td className={"check_Checkbox"}>
                                                                        <Checkbox key={item.id} type="checkbox" name={item.id} id={item.id} handleClick={handleClick} isChecked={isCheck.includes(item.id)} />
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            userJson && userJson.length > 0 && (userJson.indexOf(2091) !== -1) &&
                                                                            <img src="assets/images/icons/edit-light.svg" alt="Edit" className="cursor-pe h-16 me-2" onClick={() => transferOpened(item.id)} />
                                                                        }
                                                                    </td>
                                                                    <td className="text-dark-70">{item.maturity}</td>
                                                                    <td>
                                                                        <span className="text-dark-70">
                                                                            {item.type ?
                                                                                <Link to={"/contract_details/" + item.contractId} target="_blank">
                                                                                    {item.type}
                                                                                </Link>
                                                                                : ''
                                                                            }
                                                                            {item.propertIndentifier ? ' / ' + item.propertIndentifier + ' ' : ''}
                                                                        </span>
                                                                        <span className="mx-1">
                                                                            <img src="assets/images/icons/home_g.svg" />
                                                                            <span className="ps-1">
                                                                                {item.propertyType ?
                                                                                    <Link to={"/property_detail/" + item.propertyId} target="_blank">
                                                                                        {item.propertyType + ' : '}
                                                                                    </Link>
                                                                                    : ''
                                                                                }
                                                                                {item.address ? item.address + ' - ' : ''}
                                                                                {item.number ? item.number + ', ' : ''}
                                                                                {item.zipcode ? 'CEP: ' + item.zipcode : ''}
                                                                            </span>
                                                                            <br />
                                                                            <span className="ps-1">
                                                                                {t("TransferPage.Collection")} {item.id} {t("TransferPage.dueon")} {item.recMaturity}, {t("TransferPage.generatedintheamountof")} <span className="fw-bold text-danger">R$ {item.total} </span> {t("TransferPage.on")} {item.recMaturity2}
                                                                            </span>
                                                                        </span>
                                                                        <span className="d-block">
                                                                            {
                                                                                item.owner.length > 0 ? item.owner.map((items, indx) => (
                                                                                    <>
                                                                                        &nbsp;
                                                                                        <img src="assets/images/icons/user_g.svg" />
                                                                                        <span className="ps-1" key={indx}>
                                                                                            {items.name}
                                                                                        </span>
                                                                                    </>
                                                                                ))
                                                                                    : ''
                                                                            }
                                                                            <span className="ps-1">
                                                                                {item.credit != 3 && item.owner.length > 0 ? item.owner[0].type : ''}
                                                                            </span>
                                                                        </span>
                                                                    </td>
                                                                    {/*<td><span className="badge bg-danger-30">{ item.guarantee2 }</span></td>*/}
                                                                </tr>
                                                            )) :
                                                            <tr>
                                                                <td colSpan={5}>
                                                                    <span>{t('formLabel.noRecordsFounds')}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                        }
                                    </div>
                                    <div className="d-flex justify-content-center my-1">
                                        {
                                            transferContractData.totalRow > 0 ?
                                                <Pagination
                                                    className="pagination-bar"
                                                    currentPage={currentPage}
                                                    totalCount={transferContractData.totalRow > 0 ? transferContractData.totalRow : 0}
                                                    pageSize={PageSize}
                                                    onPageChange={(page) => setCurrentPage(page)}
                                                />
                                                :
                                                ''
                                        }
                                    </div>
                                    {/* <!-- /Table end -->*/}
                                    <p className="text-secondary d-block py-2">
                                        {t('formLabel.listing')}  {state.start} to {transferContractData.data && transferContractData.data.length > 0 ? state.start + transferContractData.data.length : state.start + 0} of
                                        {transferContractData.totalRow > 0 ? transferContractData.totalRow : 0} {t("TransferPage.transfertobecarriedout")}.
                                    </p>
                                    {/* <!-- Nav listing start from here -->*/}
                                    {
                                        userJson && userJson.length > 0 && (userJson.indexOf(2091) !== -1) &&
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="light" size="sm">
                                                        {t('formLabel.mark')}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={handleSelectAll} href="">{t('formLabel.all')}</Dropdown.Item>
                                                        <Dropdown.Item onClick={noneHandleSelectAll} href="">{t('formLabel.none')}</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                                <span className="px-1">
                                                    {t('formLabel.withTagged')}
                                                </span>
                                            </li>
                                            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                                <Button variant="light" size="sm" name="Simulate transfer" onClick={transferSimulate}>{t("TransferPage.Simulatetransfer")}</Button>
                                            </li>
                                            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                                <Button variant="light" size="sm" onClick={transferToSwitch} name="transfer" >{t("formLabel.transfer")}</Button>
                                            </li>
                                            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                                                <Button variant="light" size="sm" name="Mailing label">{t("TransferPage.Groupedtranfer")}</Button>
                                            </li>
                                            <li className="list-inline-item mb-lg-0 mb-1">
                                                <div className="dropdown dropup">
                                                    <Button variant="light" size="sm" className="dropdown-toggle" type="button"
                                                        id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                        {t("button.more")}
                                                    </Button>
                                                    <ul className="dropdown-menu mh-456 overflow-auto"
                                                        aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" onClick={maturityOpened} >{t("TransferPage.Changeexpiration")}</a></li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    }
                                    {/*  <!-- /Nav listing  end -->*/}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </main>
            {/*<!-- /Main content end -->*/}
            <TransferService />
            <TransferMaturityService state={state} />
            <Modal show={show} onHide={filterClose}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("property.moreFilters")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <InputGroup>
                                    <FloatingLabel controlId="floatingInput" label={t('ofthecontract')} hidden>
                                        <Form.Select className={`form-control ${errors.contractType ? 'is-invalid' : ''}`} {...register('contractType')} onChange={(e) => propertyOwner(e, "contractType")}>
                                            <option value="">{t('dropdownItem.select')}..</option>
                                            <option value="">{t('selectAll')}</option>
                                            {proprtyData && proprtyData.data && proprtyData.data.length > 0
                                                ? proprtyData.data.filter(item => item.property_identifier !== '').map((item, index) => (
                                                    <option key={index} value={item.id}>
                                                        {item.property_identifier ? item.property_identifier : ''}
                                                    </option>
                                                ))
                                                : ""}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.contractType?.message}</Form.Control.Feedback >
                                    </FloatingLabel>
                                    <FloatingLabel controlId="floatingInput" label={''} className="mb-3 mb-lg-4">
                                        <MultiSelect
                                            options={listProperty}
                                            value={listPropertydata}
                                            onChange={(e) => propertyOwner(e, "contractType")}
                                            labelledBy={"ofthecontract"}
                                            overrideStrings={{
                                                selectSomeItems: t('ofthecontract'), // Set the placeholder text here
                                            }}
                                        />
                                    </FloatingLabel>
                                </InputGroup>
                            </Col>
                        </Row>
                        <FloatingLabel className="mb-3 mb-lg-4" label={t("TransferPage.Withoutcontractstatus")}>
                            <Form.Select {...register('status')} aria-label="Without contract status">
                                <option value="All">{t('selectAll')}..</option>
                                <option value="active">{t('active')}</option>
                                <option value="terminated">{t('terminated')}</option>
                                <option value="pending">{t('pending')}</option>
                                <option value="paused">{t('paused')}</option>
                                <option value="finished">{t('finished')}</option>
                            </Form.Select>
                        </FloatingLabel>
                        <Row>
                            <Col md={12}>
                                <InputGroup>
                                    <FloatingLabel className="mb-3 mb-lg-4" label={t("formLabel.propertyType")} hidden>
                                        <Form.Select className={`form-control ${errors.propertyType ? 'is-invalid' : ''}`} {...register('propertyType')} onChange={(e) => propertyOwner(e, "propertyType")}>
                                            <option value="">{t('dropdownItem.select')}..</option>
                                            <option value="">{t('selectAll')}</option>
                                            {Property_Type && Property_Type && Property_Type.length > 0
                                                ? Property_Type.map((item, index) => (
                                                    <option key={index} value={item.value}>
                                                        {item.label ? item.label : ''}
                                                    </option>
                                                ))
                                                : ""}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.propertyType?.message}</Form.Control.Feedback >
                                    </FloatingLabel>
                                    <FloatingLabel controlId="floatingInput" label={''} className="mb-3 mb-lg-4">
                                        <MultiSelect
                                            options={ListPropertyType}
                                            value={listPropertytypeData}
                                            onChange={(e) => propertyOwner(e, "propertyType")}
                                            labelledBy={t('immobile')}
                                            overrideStrings={{
                                                selectSomeItems: t('formLabel.propertyType'), // Set the placeholder text here
                                            }}
                                        />
                                    </FloatingLabel>
                                </InputGroup>
                            </Col>
                        </Row>
                        {/* <FloatingLabel className="mb-3 mb-lg-4" label={t("TransferPage.Ofthebranches")}>
                            <Form.Select {...register('branch')} aria-label="Of the branches">
                                <option value="All">{t('selectAll')}..</option>
                                {branchList.map((item) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </Form.Select> */}
                        {/* </FloatingLabel> */}
                        <Row>
                            <Col md={12}>
                                <InputGroup>
                                    <FloatingLabel className="mb-3 mb-lg-4" label={t("TransferPage.Ofthebranches")} hidden>
                                        <Form.Select className={`form-control ${errors.branch ? 'is-invalid' : ''}`} {...register('branch')} onChange={(e) => propertyOwner(e, "branchType")}>
                                            <option value="">{t('dropdownItem.select')}..</option>
                                            <option value="">{t('selectAll')}</option>
                                            {branchList && branchList && branchList.length > 0
                                                ? branchList.map((item, index) => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))
                                                : ""}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.branch?.message}</Form.Control.Feedback >
                                    </FloatingLabel>
                                    <FloatingLabel controlId="floatingInput" label={''} className="mb-3 mb-lg-4">
                                        <MultiSelect
                                            options={listBranchlist}
                                            value={listBranchlistData}
                                            onChange={(e) => propertyOwner(e, "branchType")}
                                            labelledBy={t('immobile')}
                                            overrideStrings={{
                                                selectSomeItems: t('TransferPage.Ofthebranches'), // Set the placeholder text here
                                            }}
                                        />
                                    </FloatingLabel>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <InputGroup>
                                    <FloatingLabel className="mb-3 mb-lg-4" label={t("TransferPage.OftheManagers")} hidden>
                                        <Form.Select className={`form-control ${errors.manager ? 'is-invalid' : ''}`} {...register('manager')} onChange={(e) => propertyOwner(e, "manager")}>
                                            <option value="">{t('formLabel.propertyType')}..</option>
                                            <option value="">{t('selectAll')}</option>
                                            {managerData && managerData && managerData.length > 0
                                                ? managerData.map((item, index) => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))
                                                : ""}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.manager?.message}</Form.Control.Feedback >
                                    </FloatingLabel>
                                    <FloatingLabel controlId="floatingInput" label={''} className="mb-3 mb-lg-4">
                                        <MultiSelect
                                            options={listManagerlist}
                                            value={listManagerData}
                                            onChange={(e) => propertyOwner(e, "manager")}
                                            labelledBy={t('TransferPage.OftheManagers')}
                                            overrideStrings={{
                                                selectSomeItems: t('TransferPage.OftheManagers'), // Set the placeholder text here
                                            }}
                                        />
                                    </FloatingLabel>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={filterClose}>
                            {t("button.close")}
                        </Button>
                        <Button disabled={double} className="min-w-auto" type="submit" variant="primary">
                            {t("button.done")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* filter from date */}
            <Modal show={datepickershow} onHide={filterClose}>
                <Form onSubmit={handleSubmit(dateSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("TransferPage.month")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <FloatingLabel label={t("TransferPage.startDate")} className="mb-3 mb-lg-4">
                                <Form.Control type="date" aria-label="From"  {...register("startDate")}></Form.Control>
                            </FloatingLabel>
                        </Row>
                        <Row>
                            <FloatingLabel label={t("TransferPage.endDate")} className="mb-3 mb-lg-4">
                                <Form.Control type="date" aria-label="to"  {...register("endDate")}></Form.Control>
                            </FloatingLabel>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={filterClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" type='submit'>
                            {t("button.save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </section>
    )
}
export default Transfers;