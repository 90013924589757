import React, { useEffect, useMemo } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Drawer } from "../Drawer";
import {
  Button,
  Form,
  Row,
  Col,
  FloatingLabel,
  Accordion,
} from "react-bootstrap";
import { useTransferAccountNewRecipeDrawer } from "./TransferAccountNewRecipeStore";
import { createNewRecipe } from "./services/createNewRecipe";

export function TransferAccountNewRecipeContainer() {
  // const [calculatedTotal, setCalculatedTotal] = useState(0);
  const {
    transferAccountNewRecipeDrawerAction,
    setShowTransferAccountNewRecipeDrawer,
    setListAccountBank,
    listAccountBank,
    setListBankCategory,
    listBankCategory,
  } = useTransferAccountNewRecipeDrawer();

  useEffect(() => {
    setListAccountBank();
  }, [setListAccountBank]);

  useEffect(() => {
    setListBankCategory();
  }, [setListBankCategory]);
  const showBankAccountDrawer = !!transferAccountNewRecipeDrawerAction;

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      vencimento: "",
      emitida: "",
      contaBancaria: "",
      valores: [{ categoria: "", complemento: "", valor: "" }],
      descontos: [{ categoria: "", complemento: "", valor: "" }],
      observacoes: "",
      valorTotal: "",
      historico: "",
      jaFoiPago: false,
    },
  });

  const {
    fields: valoresFields,
    append: appendValor,
    remove: removeValor,
  } = useFieldArray({
    control,
    name: "valores",
  });

  const {
    fields: descontosFields,
    append: appendDesconto,
    remove: removeDesconto,
  } = useFieldArray({
    control,
    name: "descontos",
  });

  const valores = watch("valores");
  const descontos = watch("descontos");

  const calculatedTotal = useMemo(() => {
    const totalValores = valores.reduce(
      (acc, item) =>
        acc +
        parseFloat(item.valor.replace(/\./g, "").replace(",", ".") || "0"),
      0
    );
    const totalDescontos = descontos.reduce(
      (acc, item) =>
        acc -
        parseFloat(item.valor.replace(/\./g, "").replace(",", ".") || "0"),
      0
    );
    return totalValores + totalDescontos;
  }, [valores, descontos]);

  useEffect(() => {
    setValue("valorTotal", calculatedTotal.toFixed(2));
  }, [calculatedTotal, setValue]);

  const formatCurrency = (value) => {
    if (!value) return "";
    return value
      .replace(/\D/g, "")
      .replace(/(\d)(\d{2})$/, "$1,$2")
      .replace(/(?=(\d{3})+(\D))\B/g, ".");
  };

  const onSubmit = (data) => {
    createNewRecipe(data);
    setShowTransferAccountNewRecipeDrawer({ action: undefined });
  };

  return (
    <Drawer.Root
      show={showBankAccountDrawer}
      onHide={() => {
        setShowTransferAccountNewRecipeDrawer({ action: undefined });
      }}
    >
      <Drawer.Header>Nova Receita</Drawer.Header>
      <Drawer.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Col>
              <FloatingLabel controlId="floatingVencimento" label="Vencimento*">
                <Controller
                  name="vencimento"
                  control={control}
                  rules={{ required: "Vencimento é obrigatório" }}
                  render={({ field }) => (
                    <Form.Control
                      type="date"
                      isInvalid={!!errors.vencimento}
                      {...field}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.vencimento?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="floatingHistorico" label="Histórico">
                <Controller
                  name="historico"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      placeholder="Histórico"
                      isInvalid={!!errors.historico}
                      {...field}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.historico?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>
          </Row>

          <Row>
            <Col>
              <FloatingLabel controlId="floatingEmitida" label="Emitida*">
                <Controller
                  name="emitida"
                  control={control}
                  rules={{ required: "Emitida é obrigatório" }}
                  render={({ field }) => (
                    <Form.Control
                      type="date"
                      isInvalid={!!errors.emitida}
                      {...field}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.emitida?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>

            <Col>
              <FloatingLabel
                controlId="floatingContaBancaria"
                label="Conta bancária*"
              >
                <Controller
                  name="contaBancaria"
                  control={control}
                  rules={{ required: "Conta bancária é obrigatório" }}
                  render={({ field }) => (
                    <Form.Select isInvalid={!!errors.contaBancaria} {...field}>
                      <option>Selecionar...</option>
                      {listAccountBank.map((account, index) => (
                        <option key={index} value={account.id}>
                          {account.accountRef}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.contaBancaria?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Col>
          </Row>

          <Accordion className="accordion-custom mt-lg-4 mt-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Valores</Accordion.Header>
              <Accordion.Body>
                {valoresFields.map((item, index) => (
                  <div key={item.id} className="position-relative mb-3">
                    <Row>
                      <Col>
                        <FloatingLabel
                          controlId={`floatingCategoria${index}`}
                          label="Categoria"
                        >
                          <Controller
                            name={`valores.${index}.categoria`}
                            control={control}
                            rules={{ required: "Categoria é obrigatório" }}
                            render={({ field }) => (
                              <Form.Select
                                isInvalid={!!errors.valores?.[index]?.categoria}
                                {...field}
                              >
                                <option>Selecionar...</option>
                                {listBankCategory.map((account, index) => (
                                  <option
                                    key={index}
                                    value={account.ST_CONTA_CONT}
                                  >
                                    {account.ST_CONTA_CONT}{" "}
                                    {account.ST_DESCRICAO_CONT}
                                  </option>
                                ))}
                              </Form.Select>
                            )}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.valores?.[index]?.categoria?.message}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col>
                        <FloatingLabel
                          controlId={`floatingComplemento${index}`}
                          label="Complemento"
                        >
                          <Controller
                            name={`valores.${index}.complemento`}
                            control={control}
                            render={({ field }) => (
                              <Form.Control
                                placeholder="Complemento"
                                isInvalid={
                                  !!errors.valores?.[index]?.complemento
                                }
                                {...field}
                              />
                            )}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.valores?.[index]?.complemento?.message}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                    </Row>

                    <Row className="mt-1">
                      <Col>
                        <FloatingLabel
                          controlId={`floatingValor${index}`}
                          label="Valor (R$)"
                        >
                          <Controller
                            name={`valores.${index}.valor`}
                            control={control}
                            rules={{ required: "Valor é obrigatório" }}
                            render={({ field }) => (
                              <Form.Control
                                className={`form-control ${
                                  errors.valores?.[index]?.valor
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Valor (R$)"
                                {...field}
                                value={field.value}
                                onChange={(e) => {
                                  const formattedValue = formatCurrency(
                                    e.target.value
                                  );
                                  field.onChange(formattedValue);
                                }}
                              />
                            )}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.valores?.[index]?.valor?.message}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      {valoresFields.length > 1 && (
                        <Col md="3">
                          <Button
                            variant="outline-danger"
                            size="sm"
                            className="mb-1 w-full"
                            onClick={() => removeValor(index)}
                          >
                            Excluir
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </div>
                ))}
                <Button
                  variant="link"
                  onClick={() =>
                    appendValor({ categoria: "", complemento: "", valor: "" })
                  }
                >
                  Mais um
                </Button>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>Descontos</Accordion.Header>
              <Accordion.Body>
                {descontosFields.map((item, index) => (
                  <div key={item.id} className="position-relative mb-3">
                    <Row>
                      <Col>
                        <FloatingLabel
                          controlId={`floatingDescontoCategoria${index}`}
                          label="Categoria"
                        >
                          <Controller
                            name={`descontos.${index}.categoria`}
                            control={control}
                            rules={{ required: "Categoria é obrigatório" }}
                            render={({ field }) => (
                              <Form.Select
                                isInvalid={
                                  !!errors.descontos?.[index]?.categoria
                                }
                                {...field}
                              >
                                <option>Selecionar...</option>
                                {listBankCategory.map((account, index) => (
                                  <option
                                    key={index}
                                    value={account.ST_CONTA_CONT}
                                  >
                                    {account.ST_CONTA_CONT}{" "}
                                    {account.ST_DESCRICAO_CONT}
                                  </option>
                                ))}
                              </Form.Select>
                            )}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.descontos?.[index]?.categoria?.message}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col>
                        <FloatingLabel
                          controlId={`floatingDescontoComplemento${index}`}
                          label="Complemento"
                        >
                          <Controller
                            name={`descontos.${index}.complemento`}
                            control={control}
                            render={({ field }) => (
                              <Form.Control
                                placeholder="Complemento"
                                isInvalid={
                                  !!errors.descontos?.[index]?.complemento
                                }
                                {...field}
                              />
                            )}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.descontos?.[index]?.complemento?.message}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                    </Row>

                    <Row className="mt-1">
                      <Col>
                        <FloatingLabel
                          controlId={`floatingDescontoValor${index}`}
                          label="Valor (R$)"
                        >
                          <Controller
                            name={`descontos.${index}.valor`}
                            control={control}
                            rules={{ required: "Valor é obrigatório" }}
                            render={({ field }) => (
                              <Form.Control
                                className={`form-control ${
                                  errors.descontos?.[index]?.valor
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Valor (R$)"
                                {...field}
                                value={field.value}
                                onChange={(e) => {
                                  const formattedValue = formatCurrency(
                                    e.target.value
                                  );
                                  field.onChange(formattedValue);
                                }}
                              />
                            )}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.descontos?.[index]?.valor?.message}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      {descontosFields.length > 1 && (
                        <Col md="3">
                          <Button
                            variant="outline-danger"
                            size="sm"
                            className="mb-1 w-full"
                            onClick={() => removeDesconto(index)}
                          >
                            Excluir
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </div>
                ))}
                <Button
                  variant="link"
                  onClick={() =>
                    appendDesconto({
                      categoria: "",
                      complemento: "",
                      valor: "",
                    })
                  }
                >
                  Mais um
                </Button>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>Mais Campos</Accordion.Header>
              <Accordion.Body>
                <FloatingLabel
                  controlId="floatingObservacoes"
                  label="Observações internas"
                >
                  <Controller
                    name="observacoes"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        as="textarea"
                        isInvalid={!!errors.observacoes}
                        {...field}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.observacoes?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>

                <Row className="mt-3">
                  <Col>
                    <FloatingLabel
                      controlId="floatingValorTotal"
                      label="Valor Total"
                    >
                      <Controller
                        name="valorTotal"
                        control={control}
                        render={({ field }) => (
                          <Form.Control
                            placeholder="Valor total"
                            {...field}
                            value={calculatedTotal.toFixed(2)}
                            readOnly
                          />
                        )}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.valorTotal?.message}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>

                  <Col>
                    <Form.Group className="mb-3 mt-3">
                      <Controller
                        name="jaFoiPago"
                        control={control}
                        render={({ field }) => (
                          <Form.Check
                            type="checkbox"
                            label="Já foi pago"
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                          />
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer mt-3">
            <Button
              variant="outline-primary"
              type="button"
              onClick={() =>
                setShowTransferAccountNewRecipeDrawer({ action: undefined })
              }
            >
              Cancelar
            </Button>
            <Button variant="primary" type="submit">
              Salvar
            </Button>
          </div>
        </Form>
      </Drawer.Body>
    </Drawer.Root>
  );
}
