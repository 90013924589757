import { create } from "zustand";
import { config, gettoken } from "../../../services/apiConfig";

type TransferAccountNewRecipeDrawerState = {
  transferAccountNewRecipeDrawerAction: "new" | undefined;
  transferData: any;
  listAccountBank: any[];
  listBankCategory: any[];
  setListAccountBank: () => Promise<void>;
  setListBankCategory: () => Promise<void>;
  setShowTransferAccountNewRecipeDrawer: ({
    action,
    transferData,
  }: {
    action: "new" | undefined;
    transferData?: any;
  }) => void;
};

const useTransferAccountNewRecipeStore =
  create<TransferAccountNewRecipeDrawerState>((set) => ({
    transferAccountNewRecipeDrawerAction: undefined,
    transferData: undefined,
    listAccountBank: [],
    listBankCategory: [],

    setListAccountBank: async () => {
      let obj = {
        start: 0,
        end: 1000,
        user_id: gettoken.userId,
      };

      const token = localStorage.getItem("tokenV2") ?? "";
      const connectionName = localStorage.getItem("userData") ?? "";
      const response = await fetch(
        `${config.customerV2}/v2/contract/allAccounts`,
        {
          method: "POST",
          body: JSON.stringify(obj),
          headers: {
            "x-auth-token": token,
            "Content-type": "application/json",
            "connection-name": connectionName,
          },
        }
      );

      const data = await response.json();
      set({ listAccountBank: data.data });
    },

    setListBankCategory: async () => {
      let obj = {
        start: 0,
        end: 1000,
        user_id: gettoken.userId,
      };

      const token = localStorage.getItem("tokenV2") ?? "";
      const connectionName = localStorage.getItem("userData") ?? "";
      const response = await fetch(
        `${config.companyV2_URL}/company/coa/SainigetCOA`,
        {
          method: "GET",
          headers: {
            "x-auth-token": token,
            "Content-type": "application/json",
            "connection-name": connectionName,
          },
        }
      );

      const data = await response.json();

      set({ listBankCategory: data.data });
    },
    setShowTransferAccountNewRecipeDrawer: ({ action, transferData }) =>
      set({ transferAccountNewRecipeDrawerAction: action, transferData }),
  }));

export function useTransferAccountNewRecipeDrawer() {
  return useTransferAccountNewRecipeStore((state) => state);
}
