import React, { useEffect } from 'react';
import { Modal, Form, Button, FloatingLabel, Row, Col } from 'react-bootstrap';
import { useForm, } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { InsertCategory, acc_category, getCategory } from '../../../../redux/slice/AccountPlanSlice';
import { useDispatch, useSelector, } from 'react-redux';
import { AppDispatch, RootState, } from '../../../../redux/store';
import { add_category } from "../../../../redux/slice/AccountPlanSlice"
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { get_bank_slip } from '../../../../redux/slice/formsOfReceiptSlice';
import { getAccountCategory } from '../../../../redux/slice/financialDataSlice';


const AddSingleCate = (props) => {
    const { show1, CloseSingle, tabactive } = props;

    const [t] = useTranslation()
    // Initialize the 'dispatch' function from the Redux store

    const dispatch = useDispatch<AppDispatch>();
    const Schema = yup.object().shape({
        category: yup.string().required(t("requiredMmessage.fieldRequired")),
    })
    useEffect(() => {
        if(show1){
        (async () => {
            let firstcat = await dispatch(getCategory());
            if (firstcat?.payload?.data && firstcat.payload.data.length > 0) {
                const maxSTContaCont = Math.max(...firstcat.payload.data.map(item => Number(item.ST_CONTA_CONT))) + 1;
               setValue('value', maxSTContaCont)
            }
        })();
    }
    }, [dispatch, show1])
    // Using a resolver for form validation
    // Destructuring assignment to extract properties from the useForm hook result
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<acc_category>({
        resolver: yupResolver(Schema)
    });
    // Destructure 'show1' and 'CloseSingle' from the 'props' object
    // Use the 'useTranslation' hook and assign the first element to 't'

    // Define a schema using Yup for validation

    const onSubmit = async (data: any) => {

        // Create an object with the necessary data
        let obj = {
            ST_DESCRICAO_CONT: data.category,
            ST_CONTA_CONT: data.value,
            account: "insert"
        }
        // Dispatch an action to insert the category
        let res = await dispatch(InsertCategory(obj))
        if (res.payload.type == "Success") {
            // Show success message if insertion is successful
            dispatch(get_bank_slip())
            dispatch(getAccountCategory());
            
            
            Swal.fire({
                // icon: 'success','success',
                title: t("Sweetalert.Success"),
                text: t("Sweetalert.successget"),
                timer: 2000,
                showConfirmButton: false,
            })
            // Dispatch actions to update the category state
            dispatch(add_category(data))
            dispatch(getCategory())
        } else if (res.payload.resCode == 401) {
            // Show error message if something goes wrong
            Swal.fire({
                // icon: 'success',"error",
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.duplicate"),
            });
        } else {
            Swal.fire({
                // icon: 'success',"error",
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
            });
        }
        // Reset the form data
        reset()
        CloseSingle()
    }

    return (
        <>
            {/* {/ New account modal start from here /} */}
            <Modal show={show1} onHide={CloseSingle}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("chartofaccount.newAccount")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {t("chartofaccount.categoryheading")}
                        <Row className="gy-3 mt-1">
                            <Col md={3} xs={4}>
                                <FloatingLabel controlId="item" label={t("formLabel.item")+ " *"}>
                                    <Form.Control type="text" className={`${errors.value ? 'is-invalid' : ''}`} {...register("value")} placeholder="Item"/>
                                    <Form.Control.Feedback type="invalid">{errors.value?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md xs={8}>
                                <FloatingLabel controlId="description" label={t("formLabel.description")+ " *"}>
                                    <Form.Control type="text" className={`${errors.category ? 'is-invalid' : ''}`} {...register("category")} placeholder="Description" />
                                    <Form.Control.Feedback type="invalid">{errors.category?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={CloseSingle}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" type="submit">
                            {t("button.createAccount")}
                        </Button>

                    </Modal.Footer>
                </Form>
            </Modal>
            {/* {/ /New account modal end /} */}
        </>
    )
}
export default AddSingleCate;


