import React, { useEffect, useState } from 'react'
import { Col, Row, Form, Button, Card, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { handleAction, handleSubmitData } from './notification';
import { useForm, } from "react-hook-form";
import { modal_reducer, contract_reducer } from "../../../../redux/slice/notificationSlice";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Contract = (props: any) => {
    const inputElement : any = React.useRef(null);

    let { dispatch, stateData,notificationData } = props;
    const navigate = useNavigate();


    // The useForm hook is used to handle form registration, submission, and validation.
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({});
    const [object, setObject] = useState([]);
    // Initialize the useTranslation hook for internationalization
    const [t] = useTranslation();
    const { contract } = stateData;
    let basicdata = {
        dispatch,
        stateData,
        modal_reducer,
        contract_reducer
    }

    useEffect(() => {
        // Set the initial value of 'object' to contract.basic_data
        setObject(contract.basic_data);
    }, [])

    useEffect(() => {
        // Get the fields from the 'birthday.basic_data' object
        let fields: string[] = Object.keys(contract.basic_data);
        // Set the values of the fields using the 'setValue' function from the form library
        fields.forEach((field: any) => setValue(field, contract?.basic_data?.[field]));
    }, [object, stateData])
    useEffect(() => {
        if(notificationData){
          setValue("send_notification_before", Number(notificationData["contract_send_notifications_before"]))
          setValue("days_before", Number(notificationData["contract_days_before"]))
          setValue("tenants_before", Number(notificationData["contract_tenants_before"]))
          setValue("send_notification_after", Number(notificationData["contract_send_notifications_after"]))
          setValue("days_after", Number(notificationData["contract_days_after"]))
          setValue("tenants_after", Number(notificationData["contract_tenants_after"]))
        }
      },[notificationData])
    
    const resetNoteSettingForm = () => { 
		setValue("send_notification_before", Number(notificationData["contract_send_notifications_before"]))
        setValue("days_before", Number(notificationData["contract_days_before"]))
        setValue("tenants_before", Number(notificationData["contract_tenants_before"]))
        setValue("send_notification_after", Number(notificationData["contract_send_notifications_after"]))
        setValue("days_after", Number(notificationData["contract_days_after"]))
        setValue("tenants_after", Number(notificationData["contract_tenants_after"]))
	}
    let page = "contract";
    // Function called when the form is submitted
    const onSubmit = async (data: any) => {
        let checkSubmition = true;
        document.querySelectorAll(`.${page}.removeInsertDisable`).forEach(item => {
            if (item["value"].includes('-')) {
                checkSubmition = false;
            }
        })
        if (checkSubmition) {
            // If no negative numbers found, submit the data
            handleSubmitData(data);
        } else {
            Swal.fire({
                position: 'center',
                title: `${t('Sweetalert.Error')}`,
                text: `${t('Sweetalert.negativeNumberNotAllowed')}`,
                showConfirmButton: true,
                timer: 1000
            })
        }
        inputElement.current?.click()
    }

    const handleEmailName = (id,NOME, LABEL) => {
        // console.log("template id", id);
        document.querySelector('#root')?.setAttribute('st_nome_moh', NOME);
        document.querySelector('#root')?.setAttribute('st_label_moh', LABEL);
        localStorage.setItem("YhGUFY_hygft",id);
        let data = {
          notification: "notification"
        }
        navigate("/message", { state: data });
    }

    return (
        <>
            <Col lg={6}>
                <Card className="h-100">
                    <Card.Header className="d-flex justify-content-between">
                        <div className="w-80 me-2">{t("notifications.contractExpiration")}
                            <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip id="tooltip-property-id">{t('notifiToolTip.termination')}</Tooltip>}
                            >
							<img src="assets/images/icons/info-light.svg" alt="Info icon" className="h-18 ms-2" />
							</OverlayTrigger>
                        </div>
                        <span className='d-block TOEDIT'>
                            <span>
                                <span>
                                    <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "toedit", "contract", basicdata)}>{t("button.edit")}</Button>
                                </span>
                            </span>
                        </span>
                        <div className="d-none ACTION">
                            <Row>
                                <Col xl={4} sm={4}>
                                    <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "save", "contract", basicdata)} >{t("button.save")}</Button>
                                </Col> 
                                <Col xl={4} sm={4}>
                                    <Dropdown className="radio--custom" autoClose="outside">
                                        <Dropdown.Toggle className='p-0 text-nowrap' variant='link' size="sm">
                                            <b>{t("button.edit")}</b>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <label className="d-block cursor-pe" htmlFor="ft1" onClick={() => handleEmailName("17","Seu contrato de locação vence em breve", "notificacoescontratuais")}>
                                                <input type="radio" name="statusFilter" id="ft1" />
                                                <div className="radio-inner px-4 py-2">
                                                    {t("notifications.tenant")} {t("notifications.beforeExpiration")}
                                                </div>
                                            </label>
                                            <label className="d-block cursor-pe" htmlFor="ft2" onClick={() => handleEmailName("18","Seu contrato de locação venceu", "notificacoescontratuais")}>
                                                <input type="radio" name="statusFilter" id="ft2" />
                                                <div className="radio-inner px-4 py-2">
                                                    {t("notifications.tenant")} {t("notifications.afterExpiration")}
                                                </div>
                                            </label>
                                            <label className="d-block cursor-pe" htmlFor="ft3" onClick={() => handleEmailName("15","Seu contrato de locação vence em breve", "notificacoescontratuais")}>
                                                <input type="radio" name="statusFilter" id="ft3" />
                                                <div className="radio-inner px-4 py-2">
                                                    {t("notifications.owner")} {t("notifications.beforeExpiration")}
                                                </div>
                                            </label>
                                            <label className="d-block cursor-pe" htmlFor="ft4" onClick={() => handleEmailName("16","Seu contrato de locação venceu", "notificacoescontratuais")}>
                                                <input type="radio" name="statusFilter" id="ft4" />
                                                <div className="radio-inner px-4 py-2">
                                                    {t("notifications.owner")} {t("notifications.afterExpiration")}
                                                </div>
                                            </label>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>

                                <Col xl={4} sm={4}>
                                    <Button variant="link" className="p-0 fw-bold text-nowrap" ref={inputElement} onClick={(e) => ( resetNoteSettingForm(),  handleAction(e, "cancel", "contract", basicdata) )} >{t("button.cancel")}</Button>
                                </Col>
                            </Row>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={handleSubmit(onSubmit)} >
                            <Form.Check className="mb-3 mb-lg-4 removeInsertDisable" type="checkbox" {...register("send_notification_before")} id={`default_3`} label={t("notifications.checkNotification")} disabled />
                            <Row className="gx-3 gy-xl-4 gy-3 align-items-center">
                                <Col sm={3} xl={2}>
                                    <Form.Control type="number" className={`${page} removeInsertDisable`}  {...register("days_before")} aria-label="days" min="0" disabled />
                                </Col>
                                <Col sm={4} xl={3}>{t("notifications.daysBeforeExpiration")}</Col>
                                <Col sm xl={5} xxl={4}>
                                    <Form.Select className='removeInsertDisable' {...register("tenants_before")} disabled aria-label="Tenants">
                                        <option value="">{t("formLabel.select")}</option>
                                        <option value="1">{t("notifications.tenant")}</option>
                                        <option value="2">{t("notifications.owner")}</option>
                                        <option value="3">{t("notifications.both")}</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Form.Check className="my-3 my-lg-4 removeInsertDisable" type="checkbox" {...register("send_notification_after")} id={`default_4`} label={t("notifications.checkNotification")} disabled />
                            <Row className="gx-3 gy-xl-4 gy-3 align-items-center">
                                <Col sm={3} xl={2}>
                                    <Form.Control type="number" className={`${page} removeInsertDisable`} {...register("days_after")} aria-label="days" min="0" disabled />
                                </Col>
                                <Col sm={4} xl={3}>{t("notifications.daysAfterExpiration")}</Col>
                                <Col className="col-sm col-xl-5 col-xxl-4">
                                    <Form.Select className='removeInsertDisable' {...register("tenants_after")} disabled aria-label="Tenants">
                                        <option value="">{t("formLabel.select")}</option>
                                        <option value="1">{t("notifications.tenant")}</option>
                                        <option value="2">{t("notifications.owner")}</option>
                                        <option value="3">{t("notifications.both")}</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Form.Check className="mt-lg-4 mt-3 removeInsertDisable" type="checkbox" {...register("not_notify")} id={`default_5`} label={t("notifications.notNotifyOwner")} disabled />
                            <Button className='submitForm' type="submit" variant="primary" hidden>{t("button.toSave")}</Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </>
    )
}

export default Contract