import axios from 'axios';
import { gettoken, config } from './apiConfig';
const transferApiBaseUrl = config.TRANSFER_API;
const CustomerApiBaseUrl = config.CUSTOMER__API;
const companyV2_URL       = config.companyV2_URL;
const AARIN_API_URL       = config.AARIN_API;
const Token = gettoken.Token;
axios.defaults.headers.common['connection-name'] = localStorage.getItem("userData");
const customerV2 = config.customerV2;
class transferService {

    /*-------------Reverse charge api-------------*/
    insertReverseCharge(reverseData) {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        // axios.defaults.headers.common['x-auth-token'] = Token;
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.put(transferApiBaseUrl + '/insertReverseCharge', reverseData);
    }

    /*-------------Transfer made count api-------------*/

    getTransferMadeCount(transferContractData) {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl + '/transferMadeContractsCount', transferContractData);
    }

    /*-------------Transfer contract count api-------------*/

    getTransferContractsCount(transferContractData) {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl + '/transferContractsCount', transferContractData);
    }

    /*-------------Expense Charge Data api-------------*/

    getAllExpenseChargeData(transferContractData) {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl+'/expenseChargeData', transferContractData);
    }

    /*-------------Owner Transfer Data api-------------*/

    getAllOwnerTransferData(transferContractData) {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl+'/allOwnerTransferData', transferContractData);
    }
    
     /*-------------Owner Property data api-------------*/

    getAllOwnerPropertyData(transferContractData) {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl+'/allOwnerPropertyData', transferContractData);
    }

    /*-------------Transfer sumilate listing api-------------*/

    getTransferSimulate(transferData) {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl+'/allTransferSimulate', transferData);
    }

    /*-------------Transfer contract listing api-------------*/

    getAllTransferContracts(transferContractData) {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        //return axios.post(transferApiBaseUrl + '/allTransferContractsV1', transferContractData);
        return axios.post(transferApiBaseUrl + '/M1/allTransferContractsV1', transferContractData);
    }
    /*-------------Transfer contract listing api-------------*/

    getAllTransferToBePaided(transferData) {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        // return axios.post(transferApiBaseUrl + '/allTransferToPaid', transferData);
        return axios.post(transferApiBaseUrl+'/M1/allTransferToPaid', transferData);
    }

    getPaidExpenseData(transferData) {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        // return axios.post(transferApiBaseUrl +'/singlePaidExpense', transferData);
        return axios.post(transferApiBaseUrl +'/M1/singlePaidExpense', transferData);
    }

    /*-------------Transfer contract listing api-------------*/

    getAllTransferToBePaid(transferToBePaidData) {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        //return axios.post(transferApiBaseUrl + '/allTransferToBePaid', transferToBePaidData);
        return axios.post(transferApiBaseUrl +'/M1/allTransferToBePaid', transferToBePaidData);
    }


    toBePaidDelete(transferData) {
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        // return axios.post(transferApiBaseUrl+"/deleteToBePaid", transferData);
        return axios.post(transferApiBaseUrl+"/M1/deleteToBePaid", transferData);
    }

    /*-------------Dashboard count api-------------*/

    getDashboard(dashboardData) {
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl + '/dashboard', dashboardData);
    } 

    /*-------------Dashboard charges api-------------*/

    getDashboardCharges(dashChargesData) {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl + '/dashboardCharges', dashChargesData);
    }

    /*-------------Dashboard income api-------------*/

    getDashboardIncome(dashIncomeData) {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl + '/dashboardIncome', dashIncomeData);
    }

    getGenerateDashboard(dashIncomeData) {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common['x-auth-token'] = localStorage.getItem("tokenV2");
        return axios.post(customerV2 + '/v2/contract/GenerateCharge', dashIncomeData);
    }
    /*-------------Dashboard main source api-------------*/

    getDashboardMainSource(dashMainSourceData) {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl + '/dashboardMainSources', dashMainSourceData);
    }

    /*-------------Dashboard ticket source api-------------*/

    getTickets(dashMainSourceData) {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(customerV2 + '/v1/request/allRequestTicket', dashMainSourceData);
    }

    /*-------------Dashboard Pendencies listing api-------------*/

    getPendenciesData(pendenciesData) {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl + '/dasboardContracts', pendenciesData);
    }

     /*-------------Dashboard PendenciesData Internal listing api-------------*/

     getPendenciesData_internal(pendenciesData) {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(config.CUSTOMER_API + '/nonpayment/dasboardContracts_internal', pendenciesData);
    }

    /*-------------Dashboard Transfers count api-------------*/

    getDashboardTransfers = async (param) => {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        const response = await axios.request({
            url: transferApiBaseUrl + '/transfer/dashboard/getTransfers',
            method: "POST",
            data: param
        })
        return response.data
    }

    getAgencyInsuranceStatus = async (param) => {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        const response = await axios.request({
            // url: AARIN_API_URL + '/api/v1/kenlo_insurance/checkAgencyStatus',
            url: transferApiBaseUrl + '/transfer/kenlo_insurance/checkAgencyStatus',
            method: "POST",
            data: param
        })
        return response.data
    }

    getDashboardExpenses = async (param) => {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        const response = await axios.request({
            url: transferApiBaseUrl + '/transfer/dashboard/getExpenses',
            method: "POST",
            data: param
        })
        return response.data
    }

    /*-------------Transfer made contract listing api-------------*/

    getAllTransferMadeContracts(transferMadeContractData) {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl + '/allTransferMadeContracts', transferMadeContractData);
    }

    /*-------------Transfer contract listing api-------------*/

    getSingleTransferContracts(transferContractData) {
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl + '/singleTransferContracts', transferContractData);
    }

    /*-------------Tranfer contract maturity data update api-------------*/ 

    insertMaturity(transferData){
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.put(transferApiBaseUrl+'/insertMaturity', transferData);
    }

    /*-------------Insert/Update financial cost center data-------------*/ 

    insertCostCenter(costCenterData){
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.put(transferApiBaseUrl+'/insertCostCenter', costCenterData);
    }

    /*-------------Tranfer contract maturity data update api-------------*/ 

    insertTransfer(transferData){
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.put(transferApiBaseUrl+'/insertTransfer', transferData);
    }
    /*-------------Tranfer contract maturity data update api-------------*/ 
    transferToSwitchOff(transferData){
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        // return axios.post(transferApiBaseUrl+'/transferToSwitchOffV1', transferData);
        return axios.post(transferApiBaseUrl+'/M1/transferToSwitchOffV1', transferData);
    }
    /*-------------Tranfer contract to Postin Financial -------------*/ 
    transferToPostinFinancial(transferData){
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        // return axios.post(transferApiBaseUrl+'/transferToPostFinancial', transferData);
        return axios.post(transferApiBaseUrl+'/M1/transferToPostFinancial', transferData);
    }
    GenerateInvoce(InvoceData){
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl+'/insertInvoice', InvoceData);
    }
    getInvoices(transferData){
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl+'/getInvoice', transferData);
    }
    transferToPaid(transferData){
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl+'/transferToPaid', transferData);
    }
    getAccountCategory(accountData){
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.get(companyV2_URL+'/company/coa/getCOA');
    }

    getAccountCategory1(accountData){
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.get(companyV2_URL+'/company/coa/SainigetCOA');
    }
    /*-------------Tax api-------------*/ 

    getTax(taxData){
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl+'/getTax',taxData);
    }

    /*-------------Cost center api-------------*/ 

    getCostCenter(costCenterData){
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl+'/getCostCenter',costCenterData);
    }

    /*-------------Cost center api-------------*/ 

    getCompareToLastMonth(compareData){
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl+'/financial/compareToLastMonth',compareData);
    }

    /*-------------Insert expense api-------------*/ 

    insertFinancialExpense(financialExpenseData){
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        // return axios.put(transferApiBaseUrl+'/insertFinancialExpense',financialExpenseData);
        return axios.put(transferApiBaseUrl+'/M1/insertFinancialExpense',financialExpenseData);
    }

    updateFinancialExpense(financialExpenseData){
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.put(transferApiBaseUrl+'/insertFinancialExpense',financialExpenseData);
    }
    getDashboardSearch(searchQuery) {
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl + '/dashboardSearch', {
          searchQuery: searchQuery
        });
    }
    updateTransferStatus = async (param) => {
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        const response = await axios.request({
            url: transferApiBaseUrl + '/transfer/updateTransferStatus',
            method: "POST",
            data: param
        })
        return response.data
    }
    updateTransactionAndAddCharge = async (param) => {
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        const response = await axios.request({
            url: transferApiBaseUrl + '/transfer/updateTransaction/addnewitem',
            method: "POST",
            data: param
        })
        return response.data
    }
    insertTax(costCenterData){
        axios.defaults.headers.common['Content-Type'] = "application/json";
        axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
        return axios.post(transferApiBaseUrl+'/createTaxWitheld', costCenterData);
    }
    
}
export default new transferService();