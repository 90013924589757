import { Dropdown, Button, Form, Modal, Row, Col, FloatingLabel, Accordion, Card, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import React, { useState, MouseEvent, useEffect, useRef } from 'react';
import { CustomersAndEmployeesForm } from '../../../Interface';
import customerService from '../../../../services/customerService';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import '../../../../App.css';
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../../../redux/store';
import { showSuppliers, hideSuppliers } from "../../../../redux/slice/contractDataSlice"
import { numericOnly, openErrorbox } from '../Common/actionPerform';
import { add_Bank, getBankList } from '../../../../redux/slice/bankSlice';
import { useSelector } from 'react-redux';
import { getAllBillMethodListing, getAllSuplierCate } from '../../../../redux/slice/expenditureDataSlice';
import { CNJP_CPFValidation, encrypt_Decrypt, handleKeyDown, checkDuplicateCNJP_CPF } from '../../../constants/utils';
import { setFinancialExpOpen } from '../../../../redux/slice/financialDataSlice';
import { getManager } from '../../../../redux/slice/propertyDataSlice';
const NewSuppliers = (props) => {
    let { type, getAllSuppliers } = props
    const dispatch = useDispatch<AppDispatch>()
    const { showSupplier } = useSelector((state: RootState) => state.contractData)
    const { category } = useSelector((state: RootState) => state.expenditureData)
    const { ownerData } = useSelector((state: RootState) => state.contractData)
    const { managerData } = useSelector((state: RootState) => state.propertyData)

    const [t] = useTranslation();
    const [hide, setHide] = useState(true);
    const [hide2, setHide2] = useState(false);
    const [hide3, setHide3] = useState(false);
    const [hide4, setHide4] = useState(false);
    const [headingText, setHeadingText] = useState(t("customerAndEmployees.consultSupplier"));
    const [AllStates, setAllStates] = useState([]);
    const [LegalOwner, setLegalOwner] = useState<number>(0)
    const [LegalTenant, setLegalTenant] = useState<number>(0)
    const [pixTypeHide, setPixTypeHide] = useState(true)
    const [showHideField, setShowHideField] = useState<any>("")
    const navigate = useNavigate();
    const [userJson, setUserJson] = useState<any>([]);
    const [PageSize, setPageSize] = useState<any>(20);
    const inputRef = useRef<any>(null);
    const [searchResults, setSearchResults] = useState<any>();
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [bankNotRequeire, setbankNotRequeire] = useState(false)
    const [HidePixAlso, setHidePixAlso] = useState(false)
    const [state, setState] = useState({
        supplier: "",
        status: "",
        person: "",
        start: 0,
        end: PageSize,
    });

    useEffect(() => {
        dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
        dispatch(add_Bank())
        dispatch(getAllSuplierCate({ "start": 0, "end": 1000 }))
        dispatch(getManager());
    }, [dispatch]);
    const [update, setUpdate] = useState(1);
    useEffect(() => {
        (async () => {
            let resp = await customerService.getAddressBasedOnZipcode(`states`);
            if (resp.data.status == "success" || resp.data.type == "Success") {
                let loop = resp.data.data;
                let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
                // Sort the stateData alphabetically by label
                stateData.sort((a, b) => a.label.localeCompare(b.label));
                setAllStates(stateData);
            }
        })()
    }, [update]);
    const { banklist } = useSelector((state: RootState) => state.bank)

    useEffect(() => {
        const filterId = ["1", "341", "237", "33", "104", "756", "748", "77", "336"];
        const filteredBanks = banklist.filter(bank => filterId.includes(bank.bank_code));
        setSearchResults(filteredBanks);

        const handleClickOutside = (event: MouseEvent) => {
            if (inputRef.current instanceof HTMLElement && !inputRef.current.contains(event.target as Node)) {
                setIsInputFocused(false);
            }
        };
        document.addEventListener("click", handleClickOutside as unknown as EventListener);
        // Cleanup function to remove event listener when the component unmounts
        return () => {
            document.removeEventListener("click", handleClickOutside as unknown as EventListener);
        };
    }, [banklist]);
    const formClose = () => {
        setloader_on(false);
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        zipCodeCheck?.["removeAttribute"]('disabled');
        reset();
        dispatch(showSuppliers(false));
        setHide(true);
        setHide2(false); setHide3(false); dispatch(hideSuppliers(false)); setspouse(false);
        if (type && type == "supplierexpense") {
            dispatch(setFinancialExpOpen(true));
        }
    }
    // Retrieve banklist, show1, and status from the Redux state

    /*------------------Form validation------------------*/
    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    let regexPattern = /^[^0-9./-]*$/;
    if(showHideField == "cnpj"){
        regexPattern = /^[a-zA-Z0-9 ]*$/;
    }
    const formValidationSchemaStep0 = Yup.object().shape({
    });
    const formValidationSchemaStep1 = Yup.object().shape({
        cnpj: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
    });

    const [addState, setAddState] = useState({
        retentionn: [
            { Tax: "", Aliquot: "" },
        ],
        beneficiaries: [
            { name: "", transfer: "" }
        ],
        representative: [
            { name: "" }
        ]
    });

    const [loader_on, setloader_on] = useState(false);
    let formValidationSchemaStep5:any = Yup.object().shape({
        name: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
    });
    if(showHideField!= "cpf"){
        formValidationSchemaStep5 = Yup.object().shape({
            fantasy: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        });
    }
    let formValidationSchemaStep6:any = Yup.object().shape({
        name: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
    });
    if(showHideField!= "cpf"){
        formValidationSchemaStep6 = Yup.object().shape({
            fantasy: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        });
    }

    // Initialize with default schema
    let frm = { resolver: yupResolver(formValidationSchemaStep1) };
    
    if (bankNotRequeire && hide2 && (headingText === "New Supplier" || headingText === "Novo Fornecedor")) {
        frm = { resolver: yupResolver(formValidationSchemaStep6) };
        openErrorbox();
    } else if (hide2 && (headingText === "New Supplier" || headingText === "Novo Fornecedor")) {
        frm = { resolver: yupResolver(formValidationSchemaStep5) };
        openErrorbox();
    } else if (bankNotRequeire && (headingText === "New Supplier" || headingText === "Novo Fornecedor")) {
        frm = { resolver: yupResolver(formValidationSchemaStep6) };
        openErrorbox();
    }

    // Rest of your code


    // Rest of your code

    const { register, handleSubmit, reset, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<CustomersAndEmployeesForm>(frm);

    /*------------Step of form functions & validation--------*/
    const [cnpjcpferrormessage, setcnpjcpferrormessage] = useState({});
    const step1 = () => {
        setHide(true); setHide2(false); setHide3(false);
    }
    const step2 = () => {
        if (errors?.cnpj?.message) {
            setError(cnpjcpferrormessage?.["name"], {
                type: "manual",
                message: cnpjcpferrormessage?.["message"]
            })
            setHide(true);
        } else {
            setHide(false); setHide2(true); setHide3(false);
        }
    }
    const step3 = (data: CustomersAndEmployeesForm) => {
        setHide(false); setHide2(false); setHide3(true);
    }

    /*------------Call the insert api & set the data fields--------*/

    const formSubmit = async (data: CustomersAndEmployeesForm) => {
        setloader_on(true);
        let bankName = '';
        banklist.forEach((curElem: any, index: number) => {
            if (curElem.bank_code == data.bankname) {
                bankName = curElem.bank_name;
            }
        })
        let owner = {
            type: "New Supplier",
            cnpj: data.cnpj,
            name: data.name,
            payment: data.payment,
            fantasy:data.fantasy,
            mobile: data.mobile,
            telephone: data.telephone,
            email: data.email,
            password: data.password,
            mother: data.mother,
            father: data.father,
            rg: data.rg,
            issue_agency: data.issue_agency,
            shipping_date: data.shipping_date,
            sex: data.sex,
            birth: data.birth,
            marital_status: data.marital_status,
            nationality: data.nationality,
            zipcode: data.zipcode,
            address: data.address,
            number: data.number,
            complement: data.complement,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state,
            employment_company: data.employment_company,
            employment_profession: data.employment_profession,
            employment_field_activity: data.employment_field_activity,
            employment_monthly_income: data.employment_monthly_income,
            employment_zipcode: data.employment_zipcode,
            employment_address: data.employment_address,
            employment_number: data.employment_number,
            employment_complement: data.employment_complement,
            employment_neighborhood: data.employment_neighborhood,
            employment_city: data.employment_city,
            employment_state: data.employment_state,
            representative_name: data.representative_name,
            retention: data.retention,
            observation: data.observation,
            is_vendor: data.is_vendor,

            bank_Name: bankName,
            bankname: data.bankname,
            bank_acocunt: data.bank_acocunt,
            bank_agency: data.bank_agency,
            bank_accountType: data.bank_accountType,
            bank_branchCode: data.bank_branchCode,
            name_of: data.name_of,
            cnpjcpf: data.cnpj,

            spouse_name: data.spouse_name,
            spouse_cpf: data.spouse_cpf,
            spouse_rg: data.spouse_rg,
            spouse_issue_agency: data.spouse_issue_agency,
            spouse_nationality: data.spouse_nationality,
            spouse_sex: data.spouse_sex,
            spouse_profession: data.spouse_profession,
            spouse_cellphone: data.spouse_cellphone,
            spouse_telephone: data.spouse_telephone,
            spouse_email: data.spouse_email,
            spouse_observation: data.spouse_observation,
            manager: data.manager,
            identifier: data.identifier,
            Organ_exp: data.Organ_exp,
            inss: data.inss,
            rpa: data.rpa,
            category: data.category ?? 1,
            accounting_system: data.accounting_system,
            sup_KeyType: data.pixKeyType,
            Keyfor_addressing: data.pixKey,
            country: data.country,
            tax: data.Tax,
            aliquot: data.Aliquot ?? 123456,
            ID_SACADO_SAC: data.ID_SACADO_SAC,
            ID_FAVORECIDO_FAV: data.ID_FAVORECIDO_FAV,
            corporateReason:data.corporateReason,
            stateRegistration:data.stateRegistration,
            municipalRegistration:data.municipalRegistration,
            
            new_added: {
                addState: addState,
                Renda: {
                    FL_SEMRENDA_PES: data.possiu ?? "",
                    FL_VINCULOEMPREGO_PES: data.vinculo ?? "",
                    ST_BENEFICIO_PES: data.beneficio ?? "",
                    VL_OUTROSRENDIMENTOS_PES: data.rendimentous ?? ""
                },
                Retencao_de_impostos: {
                    FL_DESCONTOSIMPLIFICADOIR_PES: data.enableSimplifiedDeduction ?? "",
                },
                Mais_opcoes: {
                    NM_DEPENDENTES_PES: data.dependentsNo ?? "",
                    FL_NAODOMICILIADO_PES: data.non_recedential ?? "",
                    FL_NAONOTIFICAR_PES: data.donotnotify ?? ""
                },
                Fornecedor: {
                    FL_SINCRONIZARSACADO_FAV: data.supplieradv ?? ""
                },
                Pagemento: {
                    DT_DIAREPASSE_PES: data.Repassedia ?? "",
                    VL_SALDOBLOQUEADO_PES: data.bloquendo ?? ""
                }
            }
        }
        let customer = {
            cnpj_cpf: data.cnpj,
            person_name: data.name,
            address: data.address,
            neighborhood: data.neighborhood,
            zipcode: data.zipcode,
            city_name: data.city,
            state: data.state,
        }
        customerService.createOwner(owner).then(res => {
            if (res.data.resCode === 202 || res.data.resCode === "202" || res.data.type === "success") {
                formClose();
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.successget"),
                    // icon: 'success',"success",
                    confirmButtonText: "OK",
                    timer: 2000
                });
                dispatch(showSuppliers(false))
                if (type && type == "supplierexpense") {
                    getAllSuppliers();
                } else {
                    getAllSuppliers(state)
                }
            } else {
                let msg = (res.data.data.length || res.data.data) ? res.data.data : '';
                if (res.data.resCode === 409) {
                    msg = res.data.message;
                }
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: msg ? msg : t("Sweetalert.somethingWent"),
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000
                });
                 formClose();
            }
            setloader_on(false);
        }).catch(error => {
            /*-------Error exception handling--------*/
            console.log(error, "error")
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            });
            setloader_on(false);
            formClose();
        });
    }


    //Client collaborators setting modal initialization
    const [showClientSettingModal, setClientSettingModal] = useState(false);
    const clientSettingModalClose = () => setClientSettingModal(false);
    const clientSettingModalShow = () => setClientSettingModal(true);

    let [zipvalue, setZipvalue] = useState({
        zip: "",
        empzip: "",
    });

    useEffect(() => {
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        if (zipvalue.zip == "" && zipvalue.empzip == "") {
            zipCodeCheck?.["removeAttribute"]('disabled');
        } else {
            zipCodeCheck?.["setAttribute"]('disabled', "")
        }
    }, [zipvalue])

    const getLocation = async (e, inputType, forwhich) => {
        let str = e.target.value
        let newStr = str.replace('-', ''); // Removes the hyphen

        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
        let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];

        // Error validation
        // Retrieve address based on zipcode using customerService
        let resp = await customerService.getAddressBasedOnZipcode(
            `${inputType}/${newStr}`
        );

        let querySelfirst: any = document.querySelector('.accordion-custom [name="zipcode"]');
        let querySelsecond: any = document.querySelector('.accordion-custom [name="employment_zipcode"]');

        if (forwhich == "first") {
            setZipvalue({
                ...zipvalue,
                ["zip"]: str.trim(),
            })
        } else {
            setZipvalue({
                ...zipvalue,
                ["empzip"]: str.trim()
            })
        }

        if (newValue !== "" || newValue !== null || newValue !== undefined) {
            if (resp?.["data"]["status"] == "success" || resp?.["data"]["type"] == "Success") {
                let res = resp["data"]["data"];
                if (forwhich == "first") {
                    setZipvalue({
                        ...zipvalue,
                        ["zip"]: '',
                    })
                    querySelfirst.style.border = "0px solid red";
                    if (res[0]["city"]) {
                        setValue("city", res[0]["city"])
                    }

                    if (res[0]["neigbohood"]) {
                        setValue("neighborhood", res[0]["neigbohood"])
                    }

                    if (res[0]["state"]) {
                        setValue("state", res[0]["state"])
                    }

                    if (res[0]["street"] || res[0]["streetType"]) {
                        let data = (res[0]["streetType"] ? res[0]["streetType"] + " " : "") + (res[0]["street"] ?? "");
                        setValue("address", data)
                    }
                } else {
                    setZipvalue({
                        ...zipvalue,
                        ["empzip"]: ''
                    })
                    querySelsecond.style.border = "0px solid red";
                    if (res[0]["city"]) {
                        setValue("employment_city", res[0]["city"])
                    }

                    if (res[0]["neigbohood"]) {
                        setValue("employment_neighborhood", res[0]["neigbohood"])
                    }

                    if (res[0]["state"]) {
                        setValue("employment_state", res[0]["state"])
                    }

                    if (res[0]["street"] || res[0]["streetType"]) {
                        let data = (res[0]["streetType"] ? res[0]["streetType"] + ", " : "") + (res[0]["street"] ?? "");
                        setValue("employment_address", data)
                    }
                }

            } else {
                if (forwhich == "first") {
                    querySelfirst.style.border = "2px solid red"
                    setValue("city", "")
                    setValue("neighborhood", "")
                    setValue("state", "")
                    setValue("address", "")
                } else {
                    querySelsecond.style.border = "2px solid red"
                    setValue("employment_city", "")
                    setValue("employment_neighborhood", "")
                    setValue("employment_state", "")
                    setValue("employment_address", "")
                }
            }
        } else {
            if (forwhich == "first") {
                querySelfirst.style.border = "0px solid red";
            } else {
                querySelsecond.style.border = "0px solid red"
            }
        }
    }

    const getBankCode = (bankCode) => {
        if (bankCode !== "") {
            clearErrors("bankname")

        } else {
            setError("bankname", {
                type: "manual",
                message: `${(t('requiredMmessage.fieldRequired'))}`
            })
        }
        setValue("bank_branchCode", bankCode);
    }

    const HandleInput = (e: any) => {
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
        if (newValue == "" || newValue == null || newValue == undefined) {
            setError("telephone", {
                type: "manual",
                message: `${t("requiredMmessage.TelephoneError")}`
            });
        } else {
            clearErrors('telephone')
        }
    }
    const HandleInputMob = (e: any) => {
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');

        // Error validation
        if (newValue == "" || newValue == null || newValue == undefined) {
            // Set an error message using setError or update your error state
            setError("mobile", {
                type: "manual",
                message: `${t("requiredMmessage.mobileError")}`
            });
        } else {
            // Clear the telephone error if the value is not empty
            clearErrors('mobile');
        }
    }
    const handleCPF_CNJPVaue = async (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        if ((checkCNJP?.["status"] || e.target.value == "")) {
            setShowHideField(checkCNJP?.["type"])
            clearErrors(name)

            let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
            zipCodeCheck?.["setAttribute"]('disabled', "")
            if (e.target.value !== "") {
                let boolVal = false
                let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, "");
                if (headingText == "New Supplier" || headingText == "Novo Fornecedor") {
                    let fav: any = duplicateData["data"][0]?.ID_FAVORECIDO_FAV
                    if (fav) {
                        boolVal = true
                    } else {
                        boolVal = false
                    }
                }
                const fieldsMapping: any = {
                    email: "ST_EMAIL_PES",
                    name: "ST_NOME_PES",
                    mobile: "ST_CELULAR_PES",
                    fantasy:"ST_FANTASIA_PES",
                    complement: "ST_COMPLEMENTO_PES",
                    zipcode: "ST_CEP_PES",
                    city: "ST_CIDADE_PES",
                    address: "ST_ENDERECO_PES",
                    state: "ST_ESTADO_PES",
                    number: "ST_NUMERO_PES",
                    neighborhood: "ST_BAIRRO_PES",
                    telephone: "ST_TELEFONE_PES",
                    observation: "ST_OBSERVACAO_PES",
                    nationality: "ST_NACIONALIDADE_PES",
                    sex: "ST_SEXO_PES",
                    birth: "DT_NASCIMENTO_PES",
                    marital_status: "ST_ESTADOCIVIL_PES",
                    employment_company: "ST_TRABALHOCEP_PES",
                    employment_profession: "ST_PROFISSAO_PES",
                    employment_field_activity: "ST_RAMOATIVIDADE_PES",
                    employment_address: "ST_TRABALHOENDERECO_PES",
                    employment_number: "ST_TRABALHONUMERO_PES",
                    employment_complement: "ST_TRABALHOCOMPLEMENTO_PES",
                    employment_neighborhood: "ST_TRABALHOBAIRRO_PES",
                    employment_city: "ST_TRABALHOCIDADE_PES",
                    employment_state: "ST_TRABALHOESTADO_PES",
                    employment_monthly_income: "VL_RENDAMENSAL_PES",
                    rg: "ST_RG_COJ",
                    retention: "VL_TXISSQN_PES",
                    mother: "ST_NOMEMAE_PES",
                    father: "ST_NOMEPAI_PES",
                    shipping_date: "DT_EXPEDICAORG_PES",
                    block_balance: "VL_SALDOBLOQUEADO_PES",
                    manager: "ID_GESTOR_GES",
                    issue_agency: "ST_ORGAO_PES",
                    corporateReason: "ST_NOME_PES",
                    stateRegistration: "ST_IE_PES",
                    municipalRegistration: "ST_INSCMUNICIPAL_PES"
                };
                if (duplicateData["data"].length > 0) {
                    if (boolVal) {
                        zipCodeCheck?.["setAttribute"]('disabled', "")
                        setcnpjcpferrormessage({
                            name: name,
                            message: t("formLabel.ErrorCNPJCPF")
                        })
                        setError(name, {
                            type: "manual",
                            message: t("formLabel.ErrorCNPJCPF")
                        });
                    } else {
                        zipCodeCheck?.["removeAttribute"]('disabled');

                        // Assuming `duplicateData` is correctly populated
                        const data: any = duplicateData?.["data"]?.[0];
                        if (data) {
                            Object.entries(fieldsMapping).forEach(([fieldName, dataKey]) => {
                                setValue(fieldName as keyof CustomersAndEmployeesForm, data?.[dataKey as keyof typeof data]);
                                setValue('ID_FAVORECIDO_FAV',data?.ID_FAVORECIDO_FAV)
                                setValue('ID_SACADO_SAC',data?.ID_SACADO_SAC)
                            });
                        }
                      
                    }
                } else {
                    Object?.entries(fieldsMapping)?.forEach(([fieldName, dataKey]) => {
                        setValue(fieldName as keyof CustomersAndEmployeesForm, "");
                        setValue('ID_FAVORECIDO_FAV', "")
                        setValue('ID_SACADO_SAC',"")
                    });
                  
                    zipCodeCheck?.["removeAttribute"]('disabled');
                }
            }
        } else {
            // errorCNPJCPF(e.target.value)
            setcnpjcpferrormessage({
                name: name,
                message: checkCNJP?.["message"]
            })
            setError(name, {
                type: "manual",
                message: checkCNJP?.["message"]
            });
        };
    }
    const handlePrevious = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (hide2) {
            step1();
        } else if (hide3) {
            step2();
        } else {
            step1();
        }
    }
    const [spouse, setspouse] = useState(false);
    const handleChangeMaritail = (e) => {
        if (e.target.value == "1") {
            setspouse(true);
        } else {
            setspouse(false);
        }
    }
    const handleNameof = (e) => {
        let { value } = e.target
        setValue("name_of", value)
        // Error validation
        if (value == "" || value == null || value == undefined) {
            setError("name", {
                type: "manual",
                message: (t('requiredMmessage.fieldRequired'))
            });
        } else {
            clearErrors('name');
        }
    }
    const handlefilterBillmethod = async (e) => {
        let name = e.target.value;
        if (name.trim() !== "" && e.target.value !== "") {
            const lowerSearchTerm = name.toLowerCase();
            const results = banklist.filter((bank) => {
                // Convert the bank name to lowercase for case-insensitive comparison
                const bankName = bank.bank_name.toLowerCase();
                return bankName.includes(lowerSearchTerm);
            });
            setSearchResults(results)
        } else {
            const filterId = ["1", "341", "237", "33", "104", "756", "748", "77", "336"];
            const filteredBanks = banklist.filter(bank => filterId.includes(bank.bank_code))
            setSearchResults(filteredBanks);
        }
    }
    const handleInputFocus = (e) => {
        setIsInputFocused(true);
    };
    const ownerPaymentHide = (evt) => {
        let payment = evt.target.value
        if (payment !== "") {
            clearErrors("payment")
        } else {
            setError(payment, {
                type: "manual",
                message: `${(t('requiredMmessage.fieldRequired'))}`
            })
        }
        if (evt.target.value == 12) {
            setPixTypeHide(false);
            setHidePixAlso(false)
        }else
         if(evt.target.value == 8 || evt.target.value == 0){
            setPixTypeHide(true)
        }else{
            setPixTypeHide(false)
            setHidePixAlso(true)
        }
        if (evt.target.value !== "0") {
            setbankNotRequeire(true)
            clearErrors('bankname')
            clearErrors('bank_acocunt')
            clearErrors('bank_agency')
            clearErrors('bank_accountType')
        } else {
            setbankNotRequeire(false)
        }
    }
    const HandleBankAccount = (e: any, item) => {
        setValue('bankname', item?.bank_name)
        setValue("bank_branchCode", item.bank_code);
        setIsInputFocused(false);
    }

    const handleChangeInput = (e: any, index: any, type: string, field: string) => {
        let value = e.target.value;
        addState[type][index][field] = value;
        let allState: any = addState;
        setAddState(allState);
    }

    const addAnother = (e: any, type: string) => {
        e.preventDefault();
        if (type == "retentionn") {
            let dataAdd: any = {
                ...addState,
                retentionn: [
                    ...addState["retentionn"],
                    { Tax: "", Aliquot: "" }
                ]
            }
            setAddState(dataAdd)
        }

        if (type == "beneficiaries") {
            let dataAdd: any = {
                ...addState,
                beneficiaries: [
                    ...addState["beneficiaries"],
                    { name: "", transfer: "" }
                ]
            }
            setAddState(dataAdd)
        }

        if (type == "representative") {
            let dataAdd: any = {
                ...addState,
                representative: [
                    ...addState["representative"],
                    { name: "" }
                ]
            }
            setAddState(dataAdd)
        }
    }

    const removeAddAnother = (index: any, array: any, type: any) => {
        array.splice(index, 1);
        let TypeData = type;
        // reset(TypeData);
        if (type == "retentionn") {
            array.forEach((item: any, index: number) => {
                let Tax: any = `retentionn.${index}.tax`;
                let Aliquot: any = `retentionn.${index}.Aliquot`;
                setValue(Tax, item.Tax);
                setValue(Aliquot, item.Aliquot);
            })
            setAddState({
                ...addState,
                retentionn: array
            })
        }

        if (type == "beneficiaries") {
            array.forEach((item: any, index: number) => {
                let name: any = `beneficiaries.${index}.name`;
                let transfer: any = `beneficiaries.${index}.transfer`;
                setValue(name, item.name);
                setValue(transfer, item.transfer);
            })
            setAddState({
                ...addState,
                retentionn: array
            })
        }

        if (type == "representative") {
            array.forEach((item: any, index: number) => {
                let name: any = `representative.${index}.name`;
                setValue(name, item.name);
            })
            setAddState({
                ...addState,
                retentionn: array
            })
        }
    }

    return (
        <Modal show={showSupplier} onHide={formClose} className=''>
            <Modal.Header closeButton>
                <Modal.Title>{headingText}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(formSubmit)} id="newSuppliersTsx">
                <Modal.Body>
                <input type="text" {...register('ID_SACADO_SAC')} hidden/>
                <input type="text" {...register('ID_FAVORECIDO_FAV')} hidden/>
                    <div data-step="1" style={{ display: hide ? "block" : "none" }}>
                        <input type="hidden" value={headingText} {...register('type')} />
                        <FloatingLabel controlId="floatingInput" label={t('formLabel.cnpjCeiCpf')}>
                            <Form.Control type="text" placeholder={t('formLabel.cnpjCeiCpf')} className={` ${errors.cnpj ? 'is-invalid' : ''}`}  {...register('cnpj')} onChange={(e) => handleCPF_CNJPVaue(e, "both")} />
                            <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                    </div>

                    <div data-step="2" style={{ display: hide2 ? "block" : "none" }}>
                        {showHideField == "cnpj" && (headingText == "New Supplier" || headingText == "Novo Fornecedor") ?
                            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                <Col md={6}>
                                    <FloatingLabel controlId="floatingInput" label={t("formLabel.corporateReason")}>
                                        <Form.Control type="text" placeholder="Corporate reason" className={`form-control ${errors.corporateReason ? 'is-invalid' : ''}`} {...register('corporateReason')} />
                                        <Form.Control.Feedback type="invalid">{errors.corporateReason?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="floatingInput" label={t("formLabel.fantasyName")+ " *"}>
                                        <Form.Control type="text" placeholder="Fantasy name" className={`form-control ${errors.fantasy ? 'is-invalid' : ''}`} {...register('fantasy')} onChange={(e) => handleNameof(e)} />
                                        <Form.Control.Feedback type="invalid">{errors.fantasy?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="floatingInput" label={t("formLabel.stateregistration")}>
                                        <Form.Control type="text" placeholder="State registration" className={`form-control ${errors.stateRegistration ? 'is-invalid' : ''}`} {...register('stateRegistration')} />
                                        <Form.Control.Feedback type="invalid">{errors.stateRegistration?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="floatingInput" label={t("formLabel.municipalregistration")}>
                                        <Form.Control type="text" placeholder="Municipal registration" className={`form-control ${errors.municipalRegistration ? 'is-invalid' : ''}`} {...register('municipalRegistration')} />
                                        <Form.Control.Feedback type="invalid">{errors.municipalRegistration?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="manager" label={t('formLabel.manager')} >
                                        <Form.Select className={`form-control mb-4 ${errors.manager ? 'is-invalid' : ''}`}  {...register('manager')} >
                                            <option value="">{t('formLabel.select')}</option>
                                            {managerData?.map((item) => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row> :
                            <FloatingLabel controlId="floatingInput" label={t('formLabel.name')+ " *"} className="mb-3" >
                                <Form.Control type="text" placeholder={t('formLabel.name')} className={` ${errors.name ? 'is-invalid' : ''}`}  {...register('name')} onChange={(e) => handleNameof(e)} />
                                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                        }
                        {showHideField == "cpf" ?
                            <>
                                <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingInput" label={t("RG")}>
                                            <Form.Control type="text" placeholder={t("RG")} className={`form-control`} {...register('rg')} />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingInput" label={t("formLabel.Organexp")}>
                                            <Form.Control type="text" placeholder={t("formLabel.Organexp")} className={`form-control `} {...register('Organ_exp')} />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingInput" label={t("formLabel.INSS")}>
                                            <Form.Control type="text" placeholder={t("formLabel.INSS")} className={`form-control`} {...register('inss')} />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md={6}>
                                        <FloatingLabel controlId="floatingInput" label={t("formLabel.RPA")}>
                                            <Form.Control type="text" placeholder="Municipal registration" className={`form-control`} {...register('rpa')} />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row>
                                    <FloatingLabel controlId="manager" label={t('formLabel.manager')} >
                                        <Form.Select className={`form-control mb-4 ${errors.manager ? 'is-invalid' : ''}`}  {...register('manager')} >
                                            <option value="">{t('formLabel.select')}</option>
                                            {managerData?.map((item) => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Row>
                            </>
                            : ""}
                        <div>
                            <span className="d-block mb-3 fw-bold">{t('formLabel.payment')}</span>
                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.defaultPaymentMethod')}>
                                <Form.Select className={` ${errors.payment ? 'is-invalid' : ''}`}  {...register('payment')} aria-label={t('formLabel.defaultPaymentMethod')} onChange={(e) => ownerPaymentHide(e)}>
                                    <option value="">{t('formLabel.select')}</option>
                                    <option value="0">{t('customerAndEmployees.boleto')}</option>
                                    <option value="1">{t('customerAndEmployees.Cheque')}</option>
                                    <option value="2" >{t('customerAndEmployees.money')}</option>
                                    <option value="3" >{t('customerAndEmployees.creditcard')}</option>
                                    <option value="4" >{t('reportBanking.DebitCard')}</option>
                                    <option value="7" >{t('reportBanking.AutomaticDebit')}</option>
                                    <option value="8" >{t('customerAndEmployees.Transferbanking')}</option>
                                    <option value="9" >{t('reportBanking.DOC/TED')}</option>
                                    <option value="10" >{t('customerAndEmployees.others')}</option>
                                    <option value="12" >{t("customerAndEmployees.pix")}</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.payment?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                            <>

                            {(!pixTypeHide && !HidePixAlso) ?
                                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3" >
                                            <Col md={6}>
                                                <FloatingLabel controlId="pixKeyType" label={t("banks.pixkeyType")}>
                                                    <Form.Select className={` ${errors.pixKeyType ? 'is-invalid' : ''}`} {...register('pixKeyType')} aria-label={t("banks.pixkeyType")}>
                                                        <option value="">{t('dropdownItem.select')}</option>
                                                        <option value="1">CPF</option>
                                                        <option value="2">CNPJ</option>
                                                        <option value="3">Celular</option>
                                                        <option value="4">E-mail</option>
                                                        <option value="5">Aleatoria</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{errors.pixKeyType?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                            <Col md={6}>
                                                <FloatingLabel controlId="pixKey" label={t("banks.pixKey")}>
                                                    <Form.Control type="text" placeholder={t('customerAndEmployees.PixKey')} className={` ${errors.pixKey ? 'is-invalid' : ''}`}  {...register('pixKey')} />
                                                    <Form.Control.Feedback type="invalid">{errors.pixKey?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        : ""
                                    }

                                    {/* Bank Form Here */}
                                    {pixTypeHide ?
                                        <>
                                                <Form.Floating className="mb-4" ref={inputRef} >
                                {/* <Form.Select className={`${errors.bankname ? 'is-invalid' : ''}`} id="bank"  {...register("bankname")} onChange={(e) => getBankCode(e.target.value)} >
                                        <option value=''>{t("formLabel.select")}</option>
                                        {banklist.map((curElem: any, index: number) =>
                                            <option key={index} value={curElem.bank_code}>{curElem.bank_name}</option>
                                        )}
                                    </Form.Select >
                                    <Form.Label htmlFor="floatingInputValue">{t("banks.name")}</Form.Label >
                                    <Form.Control.Feedback type="invalid">{errors.bankname?.message}</Form.Control.Feedback > */}
                                <Form.Control
                                    type="text"
                                    placeholder="Type to search..."
                                    {...register('bankname')}
                                    onChange={(e) => handlefilterBillmethod(e)}
                                    onFocus={handleInputFocus}
                                    className={`${errors.bankname ? 'is-invalid' : ''}`} id="bank"
                                    autoComplete="off"
                                />
                                {isInputFocused && (
                                    <ListGroup className="shadow position-absolute z-index-5 overflow-auto end-0 start-0 mt-1" style={{ maxHeight: "260px" }}>
                                        {searchResults?.slice(0, 10).reverse().map((item: any, index) => (
                                            <ListGroup.Item className="px-3 cursor-pe" key={index} onClick={(e) => HandleBankAccount(e, item)}>
                                                {item.bank_code} - {item.bank_name}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                )}
                                <Form.Label htmlFor="floatingInputValue">{t("banks.name")}</Form.Label>
                                <Form.Control.Feedback type="invalid">{errors.bankname?.message}</Form.Control.Feedback>
                            </Form.Floating>
                            <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3">
                                <Col md={6}>
                                    <Form.Floating>
                                        <Form.Control type="text" className={`${errors.bank_acocunt ? 'is-invalid' : ''}`} id="account"
                                            placeholder="Account Number (With digit)" {...register("bank_acocunt")} />
                                        <Form.Control.Feedback type="invalid">{errors.bank_acocunt?.message}</Form.Control.Feedback >
                                        <Form.Label htmlFor="floatingInputValue">{t("banks.accountNo")} {t("banks.withDigit")}</Form.Label>
                                    </Form.Floating>
                                </Col>
                                <Col md={6}>
                                    <Form.Floating>
                                        <Form.Control type="text" className={`${errors.bank_agency ? 'is-invalid' : ''}`} id="bank_agency"
                                            placeholder={t("banks.agency")} {...register("bank_agency")} maxLength={8} />
                                        <Form.Control.Feedback type="invalid">{errors.bank_agency?.message}</Form.Control.Feedback >
                                        <Form.Label htmlFor="floatingInputValue">{t("banks.agency")}  </Form.Label>
                                    </Form.Floating>
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel controlId="floatingSelect" label={t('banks.accountType')}>
                                        <Form.Select className={`${errors.bank_accountType ? 'is-invalid' : ''}`}  {...register('bank_accountType')} aria-label="Account Type">
                                            <option value="">{t('dropdownItem.select')}</option>
                                            <option value="0">{t('currentAccount')}</option>
                                            <option value="1">{t('SavingAccount')}</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.bank_accountType?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                {/* <Col md={6}>
                                    <FloatingLabel controlId="bank_branchCode" label={t("customerAndEmployees.BranchCode")}>
                                        <Form.Control disabled type="text" placeholder={t("customerAndEmployees.BranchCode")} className={` ${errors.bank_branchCode ? 'is-invalid' : ''}`}  {...register('bank_branchCode')} />
                                        <Form.Control.Feedback type="invalid">{errors.bank_branchCode?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col> */}
                                <Col md={6}>
                                    <FloatingLabel controlId="name_of" label={t("formLabel.operation")}>
                                        <Form.Control type="text" placeholder={t("formLabel.operation")} {...register('operation')} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                                        </>
                                        : ""}

                                </>
                            {/* Bank Form Here */}
                       
                        </div>
                        <span className="d-block mb-3 fw-bold">{t('formLabel.contact')}</span>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.mobileNumber')}>
                                    <Form.Control type="tel" placeholder={t('formLabel.mobileNumber')} className={` ${errors.mobile ? 'is-invalid' : ''}`}  {...register('mobile')} onChange={HandleInputMob} />
                                    <Form.Control.Feedback type="invalid">{errors.mobile?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                    <Form.Control type="text" placeholder={t('formLabel.telephone')} className={` ${errors.telephone ? 'is-invalid' : ''}`}  {...register('telephone')} onChange={HandleInput} />
                                    <Form.Control.Feedback type="invalid">{errors.telephone?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                            <Form.Control type="email" placeholder={t('formLabel.email')} className={` ${errors.email ? 'is-invalid' : ''}`}  {...register('email')} />
                            <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                        </FloatingLabel>

                        {(headingText != 'New Tenant' && headingText != 'Novo Locatário' && headingText !== "New Supplier" && headingText !== "Novo Fornecedor") &&
                            <FloatingLabel controlId="floatingInput" label={t('formLabel.password')} className="mb-3" >
                                <Form.Control type="password" placeholder={t('formLabel.password')} className={` ${errors.password ? 'is-invalid' : ''}`}  {...register('password')} />
                                <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                        }
                        {(headingText == 'New Tenant' || headingText == 'Novo Locatário') &&
                            <FloatingLabel controlId="floatingInput" label={t('formLabel.mobilePassword')} className="mb-3" >
                                <Form.Control type="password" placeholder={t('formLabel.password')} className={` ${errors.password ? 'is-invalid' : ''}`}  {...register('password')} />

                                <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                        }
                        <Accordion className="accordion-custom">
                            {
                                spouse &&
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header>{t('formLabel.spouse')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.name')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.name')}  {...register('spouse_name')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cpf')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.cpf')}  {...register('spouse_cpf')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={"RG"}>
                                                    <Form.Control type="text" placeholder={"RG"}  {...register('spouse_rg')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.issuingAgency')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.issuingAgency')}  {...register('spouse_issue_agency')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.profession')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.profession')}  {...register('spouse_profession')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.sex')}>
                                                    <Form.Select {...register('spouse_sex')} aria-label={t('formLabel.sex')}>
                                                        <option value="">{t('formLabel.select')}</option>
                                                        <option value="1">{t('masculine')}</option>
                                                        <option value="2">{t('feminine')}</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cellPhone')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.cellPhone')}  {...register('spouse_cellphone')} onChange={HandleInputMob}/>
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.telephone')}  {...register('spouse_telephone')} onChange={HandleInput}/>

                                                </FloatingLabel>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                                                    <Form.Control type="email" placeholder={t('formLabel.email')}  {...register('spouse_email')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.nationality')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.nationality')}  {...register('spouse_nationality')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.observation')}>
                                                <Form.Control type="text" placeholder={t('formLabel.observation')}  {...register('spouse_observation')} />
                                            </FloatingLabel>
                                        </Col>
                                    </Accordion.Body>
                                </Accordion.Item>
                            }
                            <Accordion.Item eventKey="3" style={{ display: hide4 ? 'none' : 'block' }}>
                                <Accordion.Header>{t('formLabel.address')} </Accordion.Header>
                                <Accordion.Body>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.zipCode')} >
                                        <Form.Control type="text" placeholder={t('formLabel.zipCode')}   {...register('zipcode')} maxLength={9} onChange={(e) => getLocation(e, "zipcode", "first")} />
                                    </FloatingLabel>
                                    <Row>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.address')}>
                                                <Form.Control type="text" placeholder={t('formLabel.address')}  {...register('address')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.number')}>
                                                <Form.Control type="number" placeholder={t('formLabel.number')}  {...register('number')} />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.complement')}>
                                                <Form.Control type="text" placeholder={t('formLabel.complement')}  {...register('complement')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                                <Form.Control type="text" placeholder={t('formLabel.neighborhood')}  {...register('neighborhood')} />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.city')}>
                                                <Form.Control type="text" placeholder={t('formLabel.city')}  {...register('city')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                                                <Form.Select {...register('state')} aria-label={t('formLabel.state')}>
                                                    <option value="">{t('formLabel.select')}</option>
                                                    {
                                                        AllStates.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                            )
                                                        })
                                                    }
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* <Accordion.Item eventKey="4">
                                <Accordion.Header>{t('formLabel.Retention')} </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="contractlistother" label={t('contractlistother.Tax')} >
                                                <Form.Control type="text" placeholder={t('contractlistother.Tax')}   {...register('Tax')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="Aliquot" label={t('contractsettings.Aliquot')} >
                                                <Form.Control type="text" placeholder={t('contractsettings.Aliquot')}   {...register('Aliquot')} />
                                            </FloatingLabel>
                                        </Col>

                                    </Row>
                                    <Button variant="link" className="fw-bold p-0" onClick={(e) => addAnother(e, "type")}  >
                                        {t("newContractDev.AddAnother")}
                                    </Button>
                                </Accordion.Body>
                            </Accordion.Item> */}
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>{t('formLabel.Retention')} </Accordion.Header>
                                <Accordion.Body>
                                    {
                                        Object.keys(addState.retentionn).map((item: any, index: number) => {
                                            return (
                                                <>
                                                    <Row>
                                                        <Col md={5}>
                                                            <FloatingLabel className="mb-3" controlId="contractlistother" label={t('contractlistother.Tax')} >
                                                                <Form.Select {...register(`retentionn.${index}.Tax`)} onChange={(e) => handleChangeInput(e, index, "retentionn", "Tax")} aria-label={t('contractlistother.Tax')}>
                                                                    <option value="slct">{t("formLabel.select")}</option>
                                                                    <option value="1">{t('IR')}</option>
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col md={5}>
                                                            <FloatingLabel className="mb-3" controlId="Aliquot" label={t('contractsettings.Aliquot')} >
                                                                <Form.Control type="text" placeholder={t('contractsettings.Aliquot')} {...register(`retentionn.${index}.Aliquot`)} onChange={(e) => handleChangeInput(e, index, "retentionn", "Aliquot")} />
                                                                {/* className={` ${errors.Aliquot ? 'is-invalid' : ''}`}
                                                            <Form.Control.Feedback type="invalid">{errors.Aliquot?.message}</Form.Control.Feedback> */}
                                                            </FloatingLabel>
                                                        </Col>
                                                        {
                                                            Object.keys(addState.retentionn) &&
                                                            Object.keys(addState.retentionn).length > 1 &&
                                                            <Col md={2}>
                                                                <Button variant="link" className="p-0 fw-bold" onClick={() => removeAddAnother(index, addState.retentionn, "retentionn")}>  <img src="./assets/images/icons/delete-light.svg" alt="Delete" className="h-16" /></Button>
                                                            </Col>
                                                        }
                                                    </Row>
                                                </>
                                            )
                                        })
                                    }
                                    {
                                        Object.keys(addState.retentionn) &&
                                        Object.keys(addState.retentionn).length < 5 &&
                                        <Button variant="link" className="fw-bold p-0" onClick={(e) => addAnother(e, "retentionn")}  >
                                            {t("newContractDev.AddAnother")}
                                        </Button>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>{t('formLabel.Accountingintegration')}</Accordion.Header>
                                <Accordion.Body>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="tooltip-bottom">{t("accountingcontable")}</Tooltip>}
                                    >
                                        <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.accountingsystem')}>
                                            <Form.Control type="text" placeholder={t('formLabel.accountingsystem')} {...register('accounting_system')} />
                                        </FloatingLabel>
                                    </OverlayTrigger>

                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6" >
                                <Accordion.Header>{t('formLabel.identifier')}</Accordion.Header>
                                <Accordion.Body>
                                    <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.identifier')}>
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip id="tooltip-bottom">{t("Supplieridentificationcode")}</Tooltip>}
                                        >
                                            <Form.Control type="text" placeholder={t('formLabel.identifier')} {...register('identifier')} />
                                        </OverlayTrigger>
                                    </FloatingLabel>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7" >
                                <Accordion.Header>{t('formLabel.category')}</Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col xs={10}>
                                            <FloatingLabel className="mb-3" controlId="category" label={t('formLabel.category')}>
                                                <Form.Select className={`form-control mb-4`}  {...register('category')} >
                                                    {category?.map((item) => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </FloatingLabel>
                                            <Form.Group>
                                                <Form.Check type="checkbox"  {...register('supplieradv')} label={t("formLabel.Supplieradv")} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* Bank Form End Here */}
                        </Accordion>
                    </div>
                    <div data-step="3" style={{ display: hide3 ? "block" : "none" }}>
                        <div className="text-center">
                            <h3 className="mt-3 mb-2">{t('label.registeredSurveyor')}</h3>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-sm-flex justify-content-end w-100 align-items-center">
                        <div>
                            <Button variant="outline-primary" onClick={formClose}>
                                {t('button.close')}
                            </Button>
                            {hide ? '' : <Button variant="outline-primary" onClick={(e) => handlePrevious(e)} className="js-btn-step">
                                {t('button.previous')}
                            </Button>}

                            {!hide3 ?
                                <Button variant="primary" onClick={handleSubmit(hide ? step2 : step3)} className="min-w-auto js-btn-step zipCodeCheck btn-primary-focus-none" >
                                    {t('button.next')}
                                </Button>
                                :
                                <>
                                    {
                                        loader_on ?
                                            <Button className="btn-width">
                                                <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                            </Button>
                                        :
                                            <Button variant="primary" type="submit" onClick={openErrorbox} className="btn-primary-focus-none">
                                                {t('button.save')}
                                            </Button>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </Modal.Footer>
            </Form>
        </Modal >
    )
}

export default NewSuppliers