import React, { useEffect, useMemo, useState } from 'react';
import { Offcanvas, Form, Button, FloatingLabel, Row, Col, Accordion, Badge, InputGroup } from 'react-bootstrap';
import AgreementSuccessfully from './AgreementSuccessfully';
import Delete from './Delete';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/store';
import { useDispatch } from 'react-redux';
import { getAllSuppliers, getTenants } from '../../../../../redux/slice/contractDataSlice';
import { getAllAccounts, getAllBillMethodListing } from '../../../../../redux/slice/expenditureDataSlice';
import { nonPaymentCharge, nonPaymentPost_RecordAgreement } from '../../../../../redux/slice/NonPaymentSlice';
import { convertToNumber, formatNumber, formatNumberPortuges, minDate } from '../../../../constants/utils';
import moment from 'moment';
import { nonpayment_recordField } from '../../../../Interface';
import Swal from 'sweetalert2';
import customerService from '../../../../../services/customerService';
import { useBankAccounts } from '../../../../Profile/Company/FormsOfReceipt/BankAccounts/hooks/useBankAccounts';
import { get_bank_slip } from '../../../../../redux/slice/formsOfReceiptSlice';
import { createHomologateBankSlip } from '../../../../../newComponents/features/Billing/services/createHomologateBankSlip';
import { useNavigate } from 'react-router-dom';
let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");

const RecordInstallments = (props) => {
    const [t] = useTranslation();
    let SERVICELIST: any = useSelector((state: RootState) => state.service);
    const { contractDatail, suppliersData, tenantsData } = useSelector((state: RootState) => state.contractData);
    const { billingData, accountData } = useSelector((state: RootState) => state.expenditureData);
    const { nonPaymentExpenseChargeData, nonPaymentSetting_Data } = useSelector((state: RootState) => state.nonPayment)
    const { Bankslip } = useSelector((state: RootState) => state.forms);

    const { showRecordInstallments, setRecordAgreementModal, setRecordInstallmentsModal,
        recordInstallmentsClose, recordAgreementClose, handleCloseagrement, payment, payment_install,
        setPayment_install, formdata, currentContract, data_insert, setStateClear,
        state, setLoading, loading, monetory, setMonetory, fineId, interestId, interestFromContract, delayDay } = props;
    let { newitem } = data_insert;
    const [objectbackup, setobjectBackup] = useState({});

    const expenseValidation = Yup.object().shape({
        name: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        duedate: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        bankAccount: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        payment_install: Yup.lazy((value) => {
            const newItemSchema = Yup.object().shape({
                newitem: Yup.array().of(
                    Yup.object().shape({
                        service: Yup.string().trim().nullable().required(t("requiredMmessage.fieldRequired")).test(
                            "not-empty",
                            t("requiredMmessage.fieldRequired"),
                            (val) => val?.trim() !== ""
                        ),
                        amount: Yup.string().nullable().required(t("requiredMmessage.fieldRequired")).test(
                            "not-empty",
                            t("requiredMmessage.fieldRequired"),
                            (val) => val?.trim() !== ""
                        ),
                        credit: Yup.string().nullable().required(t("requiredMmessage.fieldRequired")).test(
                            "not-empty",
                            t("requiredMmessage.fieldRequired"),
                            (val) => val?.trim() !== ""
                        ),
                    })
                ).min(1, t("requiredMmessage.fieldRequired")) // Ensure at least one item is present
            });

            if (Array.isArray(value)) {
                return Yup.array().of(newItemSchema).min(1, t("requiredMmessage.fieldRequired")).required(t("requiredMmessage.fieldRequired"));
            } else if (typeof value === 'object' && value !== null) {
                return newItemSchema.required(t("requiredMmessage.fieldRequired"));
            }
            return Yup.mixed().required(t("requiredMmessage.fieldRequired"));
        })
    });

    const { register, handleSubmit, setValue, getValues, reset, clearErrors, setError, formState: { errors } } = useForm<nonpayment_recordField>({ resolver: yupResolver(expenseValidation) });

    //Delete modal close open function
    const [showDeleteyModal, setDeleteModal] = useState(false);
    const deleteModalClose = () => setDeleteModal(false);
    const deleteModalShow = () => setDeleteModal(true);
    const [serviceList, setServiceList] = useState<any>([]);
    const [startDate, setStartDate] = useState<any>('')
    const dispatch = useDispatch<AppDispatch>()
    //Agreement successfully modal close open function
    const [showAgreementSuccessfullyModal, setAgreementSuccessfullyModal] = useState(false);
    const agreementSuccessfullyModalClose = () => {
        setAgreementSuccessfullyModal(false);
    }
    const agreementSuccessfullyModalShow = () => {
        recordInstallmentsClose(true);
        setAgreementSuccessfullyModal(true);
    }

    useEffect(() => {
        dispatch(get_bank_slip());
        dispatch(getTenants());
    }, [dispatch])

    const navigate = useNavigate();

    const [runOnce, setRunonce] = useState(true);
    const [firstDataPayment_install, setFirstDataPayment_install] = useState({
        service: "", amount: "",
        credit: "", addition: "",
        supplier: "", fee: "",
        amortization: "", totalAmount: "",
        installment: "", observation: "",
        status: 0
    })

    const recordInstallmentsCloseThis = (data: any) => {
        reset();
        setRunonce(true);
        recordInstallmentsClose(data && data !== "" ? data : false);
        setPayment_install({
            0: {
                newitem: {
                    0: {
                        expense_id: "",
                        service: "",
                        amount: "",
                        credit: "",
                        addition: "",
                        supplier: "",
                        fee: "",
                        amortization: "",
                        totalAmount: "",
                        installment: "",
                        observation: "",
                        status: 0,
                    }
                }
            }
        });
        reset({ payment_install: {} });
    }

    const [totalSumValue, setTotalSumValue] = useState({
        total: 0
    });

    useEffect(() => {
        if (showRecordInstallments) {
            (async () => {
                setServiceList(SERVICELIST.servicelist);
            })()
        }
    }, [dispatch, SERVICELIST, showRecordInstallments])

    const [deleteExpenseArr, setDeleteExpenseArr] = useState([]);
    const [properties_id, setProperties_id] = useState("");
    useEffect(() => {
        if (runOnce && showRecordInstallments && nonPaymentExpenseChargeData) {
            setRunonce(false);
            let totalAMount: number = Number(currentContract?.["vl_emitido_recb"]) + (((Number(currentContract?.["vl_emitido_recb"]) * ((interestFromContract.fees || formdata.fine_rate) / 100))) + ((Number(currentContract?.["vl_emitido_recb"]) * ((interestFromContract.interest || formdata.monthly_interest) / 100)) * Number(delayDay)));

            if (monetory && String(monetory) !== "0") {
                totalAMount = Number(currentContract?.["vl_emitido_recb"]) + (((Number(currentContract?.["vl_emitido_recb"]) * ((interestFromContract.fees || formdata.fine_rate) / 100))) + ((Number(currentContract?.["vl_emitido_recb"]) * ((interestFromContract.interest || formdata.monthly_interest) / 100)) * Number(delayDay)) + (Number(currentContract?.["vl_emitido_recb"]) * (monetory / 100)));
            }
            if (nonPaymentSetting_Data && nonPaymentSetting_Data.length > 0 && nonPaymentSetting_Data?.[0]?.["BL_ALLOWCHANGES_NON"]) {
                totalAMount = Number(currentContract?.["vl_emitido_recb"]) + (((Number(currentContract?.["vl_emitido_recb"]) * (nonPaymentSetting_Data?.[0]?.["TX_INTERESTRATE_NUMBER_NON"] / 100))) + ((Number(currentContract?.["vl_emitido_recb"]) * ((interestFromContract.interest || formdata.monthly_interest) / 100)) * Number(delayDay)));
                if (monetory && String(monetory) !== "0") {
                    totalAMount =
                        Number(currentContract?.["vl_emitido_recb"]) +
                        ((Number(currentContract?.["vl_emitido_recb"]) * (nonPaymentSetting_Data?.[0]?.["TX_INTERESTRATE_NUMBER_NON"] / 100))) +
                        ((Number(currentContract?.["vl_emitido_recb"]) * ((interestFromContract.interest || formdata.monthly_interest) / 100)) * Number(delayDay)) +
                        (Number(currentContract?.["vl_emitido_recb"]) * (monetory / 100));
                }
            }

            let totalAMountDiscount: any = (totalAMount).toFixed(2);
            let interestDta: any = totalAMountDiscount - Number(currentContract?.["vl_emitido_recb"]);
            let feeOnly = Number(currentContract?.["vl_emitido_recb"]) * ((interestFromContract.fees || formdata.fine_rate) / 100);
            let interestOnly = (Number(currentContract?.["vl_emitido_recb"]) * ((interestFromContract.interest || formdata.monthly_interest) / 100)) * Number(delayDay);

            if (nonPaymentExpenseChargeData?.["res"] && nonPaymentExpenseChargeData["res"].length > 0) {
                let allDeleteExpenseId: any = [];
                let payment: any = {
                    0: {
                        newitem: {
                            0: newitem
                        }
                    }
                }

                nonPaymentExpenseChargeData["res"].forEach((item: any, index: number) => {
                    let newItem: any = {
                        service: "", amount: "",
                        credit: "", addition: "",
                        supplier: "", fee: "",
                        amortization: "", totalAmount: "",
                        installment: "", observation: "",
                        status: 0
                    };

                    let amountFilter: any = item?.["value"] ?? "";
                    if (amountFilter && amountFilter.includes(",")) {
                        amountFilter = String(amountFilter).replaceAll(",", "");
                        amountFilter = Number(amountFilter);
                    }

                    setProperties_id(item["propertyId"]);
                    allDeleteExpenseId.push(item["id"])
                    newItem["service"] = item["serviceid"] ?? "";
                    newItem["amount"] = Number(amountFilter ?? 0);
                    newItem["credit"] = item["creditId"] ?? "";
                    payment["0"]["newitem"][`${index}`] = newItem
                })

                payment["0"]["newitem"][`${nonPaymentExpenseChargeData["res"].length}`] = {
                    service: fineId,
                    amount: Number(feeOnly).toFixed(2),
                    credit: "1",
                    addition: "",
                    supplier: "",
                    fee: "",
                    amortization: "", totalAmount: Number(feeOnly).toFixed(2),
                    installment: "", observation: "",
                    status: 0
                }

                payment["0"]["newitem"][`${nonPaymentExpenseChargeData["res"].length + 1}`] = {
                    service: interestId,
                    amount: Number(interestOnly).toFixed(2),
                    credit: "1",
                    addition: "",
                    supplier: "",
                    fee: "",
                    amortization: "", totalAmount: Number(interestOnly).toFixed(2),
                    installment: "", observation: "",
                    status: 0
                }

                setFirstDataPayment_install(payment["0"]);
                let setupnew: any = {
                    ...payment_install,
                    [`0`]: {
                        ...payment_install[`0`],
                        ["newitem"]: payment["0"]["newitem"]
                    }
                }
                setPayment_install(setupnew)
                setDeleteExpenseArr(allDeleteExpenseId);
                setTimeout(() => {
                    calculateTotalSum();
                }, 300);
            } else {
                let value = currentContract?.["vl_emitido_recb"] // .replace(/[^\d,]/g, '');
                let service = currentContract?.["ID_PRODUTO_PRD"] // .replace(/[^\d,]/g, '');
                let credit = currentContract?.["ID_CREDITO_IMOD"] // .replace(/[^\d,]/g, '');
                let payment: any = {
                    0: {
                        "expense_id": "",
                        "service": service,
                        "amount": value,
                        "credit": credit,
                        "addition": "",
                        "supplier": "",
                        "fee": "",
                        "amortization": "",
                        "totalAmount": value,
                        "installment": "",
                        "observation": "",
                        "status": 0
                    },
                    1: {
                        "expense_id": "",
                        "service": fineId,
                        "amount": Number(feeOnly).toFixed(2),
                        "credit": "1",
                        "addition": "",
                        "supplier": "",
                        "fee": "",
                        "amortization": "",
                        "totalAmount": Number(feeOnly).toFixed(2),
                        "installment": "",
                        "observation": "",
                        "status": 0
                    },
                    2: {
                        "expense_id": "",
                        "service": interestId,
                        "amount": Number(interestOnly).toFixed(2),
                        "credit": "1",
                        "addition": "",
                        "supplier": "",
                        "fee": "",
                        "amortization": "",
                        "totalAmount": Number(interestOnly).toFixed(2),
                        "installment": "",
                        "observation": "",
                        "status": 0
                    },
                }

                let setupnew: any = {
                    ...payment_install,
                    [`0`]: {
                        ...payment_install[`0`],
                        ["newitem"]: payment
                    }
                }
                setPayment_install(setupnew)

                Object.entries(payment).forEach(([key_parent, value_parent]: any) => {
                    Object.entries(value_parent).forEach(([key_child, value_child]: any) => {
                        let keys: any = `payment_install.0.newitem.${key_parent}.${key_child}`;
                        if (key_child == "amount" || key_child == "totalAmount") {
                            setValue(keys, formatNumberPortuges(value_child));
                        } else {
                            setValue(keys, value_child);
                        }
                    })
                })

                setTimeout(() => {
                    calculateTotalSum();
                }, 400);
            }
        }
    }, [nonPaymentExpenseChargeData && showRecordInstallments && runOnce]);

    const [emailData, setEmailData] = useState({});
    useEffect(() => {
        if (contractDatail && contractDatail?.[0]) {
            (async () => {
                const templateDta: any = await customerService.listTemplateContent_setting({ title: "New Agreement" });
                let content: any = templateDta["data"]?.["data"]?.[0]?.["ST_TEXTO_MOH"] ?? "";
                let title: any = templateDta["data"]?.["data"]?.[0]?.["ST_NOME_MOH"] ?? "New Agreement";
                let { datatenants } = (contractDatail && contractDatail?.[0]) ? contractDatail[0] : [];
                let { tenates } = datatenants;
                let tenant_email: any = [];
                if (tenates && tenates.length > 0) {
                    tenates.forEach((item: any, index: number) => {
                        tenant_email.push(item.tenantEmail);
                    })
                }

                let emailData = {
                    "status": "new",
                    "subject": title,
                    "content": content ?? "",
                    "layout": "default",
                    "sender": "noreply@dev.kenlo.io",
                    "to": [
                        ...tenant_email
                    ],
                    "title": title
                };
                setEmailData(emailData);
            })()
        }
    }, [contractDatail && contractDatail?.[0]]);

    const handelAddNew_payment = (e: any, forwhich: string, object: string, parent_index: number) => {
        let lengthNewItem: any = "";
        let ObjectLength: any = 1;
        if (object !== "add_installment") {
            lengthNewItem = payment_install?.[`${forwhich}`]?.[`${object}`] ?? [];
            ObjectLength = Object.keys(lengthNewItem);
        }

        if (object == "newitem") {
            let setupnew: any = {
                ...payment_install,
                [`${forwhich}`]: {
                    ...payment_install[`${forwhich}`],
                    [`${object}`]: {
                        ...payment_install[`${forwhich}`][`${object}`],
                        [`${ObjectLength.length}`]: newitem
                    }
                }
            };
            setPayment_install(setupnew);
            calculateTotalSum();
        }

        if (object == "add_installment") {
            let payment_number = Object.keys(getValues("payment_install")).length;
            let newItemadd: any = {
                ...firstDataPayment_install["newitem"]
            }

            let setupnew = {
                ...getValues("payment_install"),
                [`${payment_number}`]: {
                    newitem: newItemadd
                }
            };
            setPayment_install(setupnew);
            calculateTotalSum();
        }
    }

    const handelDelete_payment = (e: any, forwhich: string, object: string, index: number) => {
        if (object == "newitem") {
            let objectDta: any = getValues("payment_install")?.[forwhich]?.[object];
            let removeObject = Object.values(objectDta);
            removeObject.splice(index, 1);
            reset();
            let newData: any = {};
            removeObject.forEach((item, index) => {
                newData[`${index}`] = item;
            })
            payment_install[forwhich][object] = newData
            // setValue("payment_install", payment_install);
            Object.entries(payment_install).forEach(([parent_key, parent_value]: any, index) => {
                Object.entries(parent_value["newitem"]).forEach(([child_key, child_value]: any, index) => {
                    let service: any = child_value["service"];
                    let amount: any = child_value["amount"];
                    let credit: any = child_value["credit"];
                    let addition: any = child_value["addition"];
                    let supplier: any = child_value["supplier"];
                    let fee: any = child_value["fee"];
                    let amortization: any = child_value["amortization"];
                    let totalAmount: any = child_value["totalAmount"];

                    setValue(`payment_install.${parent_key}.newitem.${child_key}.service`, service);
                    setValue(`payment_install.${parent_key}.newitem.${child_key}.amount`, amount);
                    setValue(`payment_install.${parent_key}.newitem.${child_key}.credit`, credit);
                    setValue(`payment_install.${parent_key}.newitem.${child_key}.addition`, addition);
                    setValue(`payment_install.${parent_key}.newitem.${child_key}.supplier`, supplier);
                    setValue(`payment_install.${parent_key}.newitem.${child_key}.fee`, fee);
                    setValue(`payment_install.${parent_key}.newitem.${child_key}.amortization`, amortization);
                    setValue(`payment_install.${parent_key}.newitem.${child_key}.totalAmount`, totalAmount);
                })
            })
            setPayment_install(payment_install);
        }

        if (object == "remove_installment") {
            let removeObject = Object.values(getValues("payment_install"));
            let freshObjectPayment: any = {};
            removeObject.splice(index, 1);
            reset();
            removeObject.forEach((item: any, index) => {
                freshObjectPayment[`${index}`] = item;
            })
            // setValue("payment_install", freshObjectPayment);
            Object.entries(freshObjectPayment).forEach(([parent_key, parent_value]: any, index) => {
                Object.entries(parent_value["newitem"]).forEach(([child_key, child_value]: any, index) => {
                    let service: any = child_value["service"];
                    let amount: any = child_value["amount"];
                    let credit: any = child_value["credit"];
                    let addition: any = child_value["addition"];
                    let supplier: any = child_value["supplier"];
                    let fee: any = child_value["fee"];
                    let amortization: any = child_value["amortization"];
                    let totalAmount: any = child_value["totalAmount"];

                    setValue(`payment_install.${parent_key}.newitem.${child_key}.service`, service);
                    setValue(`payment_install.${parent_key}.newitem.${child_key}.amount`, amount);
                    setValue(`payment_install.${parent_key}.newitem.${child_key}.credit`, credit);
                    setValue(`payment_install.${parent_key}.newitem.${child_key}.addition`, addition);
                    setValue(`payment_install.${parent_key}.newitem.${child_key}.supplier`, supplier);
                    setValue(`payment_install.${parent_key}.newitem.${child_key}.fee`, fee);
                    setValue(`payment_install.${parent_key}.newitem.${child_key}.amortization`, amortization);
                    setValue(`payment_install.${parent_key}.newitem.${child_key}.totalAmount`, totalAmount);
                })
            })
            setPayment_install(freshObjectPayment);
        }
        calculateTotalSum();
    }

    const handelChange_payment = (props: any) => {
        let { e, ...other } = props;
        let { parent_item, newitem, parent_index, child_item, child_index } = other;
        let name: any = e.target.name;
        let value: any = e.target.value;
        let splitName: any = name.split(".");
        let nameValue: any = splitName[splitName.length - 1];
        if (nameValue == "amount") {
            objectbackup[`${parent_item}.${newitem}.${child_item}.amount`] = value;
            value = value.replace(/[^\d,]/g, '');
            setValue(name, formatNumber(String(value)))
        } else {
            setValue(name, value);
        }
        Object.entries(getValues("payment_install")).forEach(([p1_key, p1_value]: any, p1_index: number) => {
            payment_install[`${p1_key}`] = getValues("payment_install")[`${p1_key}`];
        })
        payment_install[`${parent_item}`][`${newitem}`][`${child_item}`][`${nameValue}`] = value;
        calculateTotalSum();
    }

    const calculateTotalSum = () => {
        let totalSum: any = 0;
        Object.entries(getValues("payment_install")).forEach(([payment, paymentData]: any, index_parentt: number) => {
            let sum: any = 0;
            Object.entries(paymentData.newitem).forEach(([payment_name, dataItem]: any) => {
                let amount: any = `payment_install.${payment}.newitem.${payment_name}.amount`;
                amount = Number(convertToNumber(String(getValues(amount))));
                let fee: any = `payment_install.${payment}.newitem.${payment_name}.fee`;
                let amortization: any = `payment_install.${payment}.newitem.${payment_name}.amortization`;
                sum += (amount || 0) + (Number((getValues(fee))) || 0) + (Number((getValues(amortization)) || 0));
                let curVal: any = (amount || 0) + (Number((getValues(fee))) || 0) + (Number((getValues(amortization)) || 0));
                dataItem.totalAmount = curVal.toFixed(2);
                if (payment_install?.[`${payment}`]?.[`newitem`]?.[`${payment_name}`]?.[`totalAmount`]) {
                    payment_install[`${payment}`][`newitem`][`${payment_name}`][`totalAmount`] = Number(curVal).toFixed(2);
                    payment_install[`${payment}`][`newitem`][`${payment_name}`][`installment`] = Number(index_parentt + 1);
                    let MakeString: any = `payment_install.${payment}.newitem.${payment_name}.totalAmount`;
                    setValue(MakeString, curVal);
                    // totalSum += curVal; 
                }
            });

            let changeHtmlSpans = document.querySelectorAll(`.valueChange_${payment}`);
            if (changeHtmlSpans && changeHtmlSpans.length > 0) {
                changeHtmlSpans.forEach(span => {
                    span.textContent = formatNumberPortuges(sum);
                });
            }

            totalSum += Object.values(paymentData.newitem).reduce((acc: any, dataItem: any) => {
                return acc + (Number(dataItem.totalAmount) || 0)
            }, 0);
        });

        let allSumOfSpan = document.querySelector('.AllSumOf');
        if (allSumOfSpan) {
            allSumOfSpan.textContent = formatNumberPortuges(totalSum);
        }
        totalSumValue["total"] = totalSum.toFixed(2);
    };

    const [respCharge, setrespCharge] = useState({});
    const onSubmit = async (data: any) => {
        setTimeout(() => {
            calculateTotalSum();
        }, 50)
        setLoading(true)
        data["properties_id"] = properties_id;
        Object.entries(data.payment_install).forEach(([payment_install, dataItem_install]: any, index_install: any) => {
            Object.entries(dataItem_install.newitem).forEach(([payment_name, dataItem]: any, index: any) => {
                let amountt: any = dataItem.amount;
                data["payment_install"][index_install]["newitem"][index]["amount"] = convertToNumber(amountt);
            });
        });
        let allDataInOne = {
            form_data: {
                payment_install: data,
                totalSumValue: totalSumValue["total"],
                installment: Object.keys(payment_install).length,
                delete_expense: deleteExpenseArr,
                debit: 2
            },
            payment_type: payment,
            user_contract: currentContract,
            single_contract: (contractDatail && contractDatail?.[0]) ? contractDatail[0] : [],
            stored_data: payment_install,
            emailData: {
                email_content: emailData
            }
        }

        let response = await dispatch(nonPaymentPost_RecordAgreement({ submitData: allDataInOne }));
        let respData = response?.payload?.data;
        if (
            respData &&
            respData.length > 0 &&
            respData[0]["type"] == "success"
        ) {
            if (data.name === "28") {
                try {
                    const homologatedBankAccount = bankAccounts.find(
                        (bankAccount) => bankAccount.id.toString() === data.bankAccount
                    );

                    const homologatedAccount =
                        homologatedBankAccount?.homologatedAccount;

                    if (!homologatedAccount) {
                        setLoading(false);
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: t("Sweetalert.successfailed"),
                            confirmButtonText: "OK",
                        });

                        return;
                    }

                    const sacadoInfos: any = tenantsData.find(
                        (tenant) => tenant.id === currentContract.ID_PESSOA_PES
                    );

                    if (!sacadoInfos) {
                        setLoading(false);
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: t("Sweetalert.successfailed"),
                            confirmButtonText: "OK",
                        });

                        return;
                    }

                    const getSacadoAddress = () => {
                        if (contractDatail[0].billing_address === 1) {
                            return {
                                numero: contractDatail[0].number ?? "",
                                bairro:
                                    contractDatail[0].neighborhood?.replace(
                                        /[^a-zA-Z0-9\s]/g,
                                        ""
                                    ) ?? "",
                                cep:
                                    contractDatail[0].zipcode?.replace(
                                        /[^a-zA-Z0-9\s]/g,
                                        ""
                                    ) ?? "",
                                cidade: contractDatail[0].city ?? "",
                                complemento:
                                    contractDatail[0].complement?.replace(
                                        /[^a-zA-Z0-9\s]/g,
                                        ""
                                    ) ?? "",
                                endereco:
                                    contractDatail[0].address?.replace(
                                        /[^a-zA-Z0-9\s]/g,
                                        ""
                                    ) ?? "",
                                pais: "Brasil",
                                uf: contractDatail[0].state ?? "",
                            };
                        }

                        return {
                            numero: sacadoInfos.number ?? "",
                            bairro:
                                sacadoInfos.neighborhood?.replace(/[^a-zA-Z0-9\s]/g, "") ??
                                "",
                            cep:
                                sacadoInfos.zipcode?.replace(/[^a-zA-Z0-9\s]/g, "") ?? "",
                            cidade: sacadoInfos.city ?? "",
                            complemento:
                                sacadoInfos.complement?.replace(/[^a-zA-Z0-9\s]/g, "") ??
                                "",
                            endereco:
                                sacadoInfos.address?.replace(/[^a-zA-Z0-9\s]/g, "") ?? "",
                            pais: "Brasil",
                            uf: sacadoInfos.state ?? "",
                        };
                    };

                    const {
                        numero,
                        bairro,
                        cep,
                        cidade,
                        complemento,
                        endereco,
                        pais,
                        uf,
                    } = getSacadoAddress();

                    const { Billing_Instructions, messageticket } = Bankslip[0];

                    const dataEmissao = new Date();
                    dataEmissao.setDate(dataEmissao.getDate() - 1);

                    const dataCustomizada = (
                        dataString: string,
                        dias: number = 1
                    ) => {
                        const [dia, mes, ano] = dataString.split("/").map(Number);
                        const data = new Date(ano, mes - 1, dia);

                        data.setDate(data.getDate() + dias);

                        const novoDia = String(data.getDate()).padStart(2, "0");
                        const novoMes = String(data.getMonth() + 1).padStart(2, "0");
                        const novoAno = data.getFullYear();

                        return `${novoDia}/${novoMes}/${novoAno}`;
                    };

                    const dataDeAplicacaoDoDesconto = (dataString: string) => {
                        if (formdata.give_discount === 0) return dataString;

                        return dataCustomizada(dataString, formdata.give_discount);
                    };

                    await createHomologateBankSlip({
                        CedenteContaNumero: homologatedAccount.conta,
                        CedenteContaNumeroDV: homologatedAccount.conta_dv,
                        CedenteConvenioNumero:
                            homologatedAccount.convenios?.at(0)?.numero_convenio ?? "",
                        CedenteContaCodigoBanco: homologatedAccount.codigo_banco,
                        SacadoCPFCNPJ: sacadoInfos.cnpj?.replace(/[.\-/]/g, "") ?? "",
                        SacadoEmail: sacadoInfos.email,
                        SacadoEnderecoNumero: numero,
                        SacadoEnderecoBairro: bairro,
                        SacadoEnderecoCEP: cep,
                        SacadoEnderecoCidade: cidade,
                        SacadoEnderecoComplemento: complemento,
                        SacadoEnderecoLogradouro: endereco,
                        SacadoEnderecoPais: pais,
                        SacadoEnderecoUF: uf,
                        SacadoNome: sacadoInfos.name,
                        SacadoTelefone: sacadoInfos.telephone
                            ? sacadoInfos.telephone.replace(/[\s(),-]/g, "")
                            : sacadoInfos.mobile.replace(/[\s(),-]/g, ""),
                        SacadoCelular: sacadoInfos.mobile.replace(/[\s(),-]/g, ""),
                        TituloDataEmissao: dataEmissao.toLocaleDateString("pt-BR"),
                        TituloDataVencimento: data.duedate
                            .toString()
                            .split("-")
                            .reverse()
                            .join("/"),
                        TituloMensagem01: Billing_Instructions || "-",
                        TituloNossoNumero:
                            respData[0].data[0].ID_RECEBIMENTO_RECB.toString(),
                        TituloNumeroDocumento:
                            respData[0].data[0].ID_RECEBIMENTO_RECB.toString(),
                        TituloValor: Number(totalSumValue["total"])
                            .toFixed(2)
                            .replace(".", ","),
                        TituloLocalPagamento: messageticket || "-",
                        IdRecebimento: respData[0].data[0].ID_RECEBIMENTO_RECB,
                        ...(formdata.discount_rate > 0 && {
                            TituloCodDesconto: formdata.use_percentage ? 2 : 1,
                            TituloDataDesconto: dataDeAplicacaoDoDesconto(
                                data.DT_VENCIMENTO_RECB.toLocaleDateString("pt-BR")
                            ),
                            TituloValorDescontoTaxa: data.discount_rate
                                .toFixed(2)
                                .replace(".", ","),
                            TituloValorDesconto: data.discount_rate
                                .toFixed(2)
                                .replace(".", ","),
                        }),
                        ...(data.fine_rate > 0 && {
                            TituloCodigoMulta: 2,
                            TituloDataMulta: dataCustomizada(
                                data.DT_VENCIMENTO_RECB.toLocaleDateString("pt-BR")
                            ),
                            TituloValorMultaTaxa: data.fine_rate
                                .toFixed(2)
                                .replace(".", ","),
                        }),
                        ...(data.monthly_interest > 0 && {
                            TituloCodigoJuros: 2,
                            TituloDataJuros: dataCustomizada(
                                data.DT_VENCIMENTO_RECB.toLocaleDateString("pt-BR")
                            ),
                            TituloValorJuros: (data.monthly_interest)
                                .toFixed(2)
                                .replace(".", ","),
                        }),
                        ...(homologatedBankAccount.titulo_variacao_carteira && {
                            TituloVariacaoCarteira:
                                homologatedBankAccount.titulo_variacao_carteira,
                        }),
                        ...(homologatedBankAccount.titulo_doc_especie && {
                            TituloDocEspecie: homologatedBankAccount.titulo_doc_especie,
                        }),
                        ...(homologatedBankAccount.titulo_aceite && {
                            TituloAceite: homologatedBankAccount.titulo_aceite,
                        }),
                        ...(homologatedBankAccount.titulo_codigo_baixa_devolucao && {
                            TituloCodBaixaDevolucao:
                                homologatedBankAccount.titulo_codigo_baixa_devolucao,
                        }),
                        ...(homologatedBankAccount.titulo_prazo_baixa && {
                            TituloPrazoBaixa: homologatedBankAccount.titulo_prazo_baixa,
                        }),
                        CNAB: homologatedAccount.convenios[0].padraoCNAB,
                    });
                } catch (error: any) {
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        html: `
                    <p>Ocorreu um erro ao gerar o boleto homologado. Por favor, acesse a página de cobranças a receber para editar as informações da cobrança e tente novamente.</p>
                    <br />
                    <strong>${error.message}</strong>
                `,
                        confirmButtonText: "OK",
                        showDenyButton: true,
                        denyButtonText: "Ir para cobranças a receber",
                    }).then((result) => {
                        if (result.isDenied) {
                            navigate("/receivable_charges");
                        }
                    });
                } finally {
                    recordInstallmentsClose(false);
                    recordInstallmentsCloseThis("");
                    handleCloseagrement();
                    setProperties_id("");
                    setAgreementSuccessfullyModal(true);
                    setrespCharge({
                        respData: respData,
                        value: getValues("payment_install"),
                    });
                    let dataResp = await dispatch(nonPaymentCharge(state));
                    if (dataResp) {
                        setLoading(false);
                    }
                }
            } else {
                recordInstallmentsClose(false);
                recordInstallmentsCloseThis("");
                handleCloseagrement();
                setProperties_id("");
                setAgreementSuccessfullyModal(true);
                setrespCharge({
                    respData: respData,
                    value: getValues("payment_install"),
                });
                let dataResp = await dispatch(nonPaymentCharge(state));
                if (dataResp) {
                    setLoading(false);
                }
            }
        } else {
            setLoading(false);
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.successfailed"),
                confirmButtonText: "OK",
            });
        }
    }

    useEffect(() => {
        if (showRecordInstallments) {
            paymentrun();
        }
    }, [payment_install])

    const hanldMaturity = (e) => {
        let { value } = e.target;
        const account = accountData.data?.find(acc => acc?.id == value);
        if (account) {
            setStartDate(account.date)
            const accountDate = new Date(account.date);
            // Get the value and convert to Date if it's a string
            const vencimentoRecbValue: any = getValues('duedate');
            const vencimentoDate = new Date(vencimentoRecbValue);
            // Compare the dates
            if (vencimentoDate < accountDate) {
                setError("duedate", {
                    type: "manual",
                    message: `${t("requiredMmessage.maturityDate")}`,
                });
            } else {
                clearErrors('duedate')
            }
        } else {
            setStartDate('')
        }
        setTimeout(() => {
            calculateTotalSum();
        }, 100)
    }

    const handlDate = (e: any) => {
        const accountDate = new Date(startDate);
        // Get the value and convert to Date if it's a string
        const vencimentoRecbValue: any = e.target.value;
        const vencimentoDate = new Date(vencimentoRecbValue);
        // Compare the dates
        if (vencimentoDate < accountDate) {
            setError("duedate", {
                type: "manual",
                message: `${t("requiredMmessage.maturityDate")}`,
            });
        } else {
            clearErrors('duedate')
        }
        setTimeout(() => {
            calculateTotalSum();
        }, 100)
    }
    const paymentrun = () => {
        if (firstDataPayment_install && firstDataPayment_install?.["newitem"] &&
            Object.keys(firstDataPayment_install["newitem"]).length > 0) {
            Object.entries(firstDataPayment_install["newitem"]).forEach(([parent_key, parent_value]: any, index: number) => {
                let amountDivide: number = Number(parent_value["amount"]) / (Object.keys(payment_install).length);
                let service: string = parent_value["service"] ?? "";
                let credit: string = parent_value["credit"] ?? "";
                Object.entries(getValues("payment_install")).forEach(([p1_key, p1_value]: any, index: number) => {
                    Object.entries(p1_value["newitem"]).forEach(([p2_key, p2_value]: any, index: number) => {
                        Object.entries(p2_value).forEach(([p3_key, p3_value]: any, index: number) => {
                            if (p3_key == "amount") {
                                let key: any = `payment_install.${p1_key}.newitem.${parent_key}.amount`;
                                setValue(key, formatNumberPortuges(amountDivide) ?? 0);
                            }
                            if (p3_key == "service") {
                                let key: any = `payment_install.${p1_key}.newitem.${parent_key}.service`;
                                if (getValues(key)) {
                                    setValue(key, getValues(key));
                                } else {
                                    setValue(key, service);
                                }
                            }
                            if (p3_key == "credit") {
                                let key: any = `payment_install.${p1_key}.newitem.${parent_key}.credit`;
                                if (getValues(key)) {
                                    setValue(key, getValues(key));
                                } else {
                                    setValue(key, credit);
                                }
                            }
                        })
                    })
                })
            })
        }
        calculateTotalSum();
    };

    const Element = () => {
        return (
            <>
                {
                    Object.keys(payment_install) && Object.keys(payment_install).length > 0 &&
                    Object.keys(payment_install).map((parent_item: any, parent_index: number) => {
                        // let sumData: number = 0;
                        // Object.keys(payment_install[`${parent_item}`]["newitem"]) &&
                        // Object.keys(payment_install[`${parent_item}`]["newitem"]).length > 0 &&
                        // Object.keys(payment_install[`${parent_item}`]["newitem"]).map((child_item, child_index) => { 
                        //     let numdta: any = getValues(`payment_install.${parent_index}.newitem.${child_index}.amount`);
                        //     sumData += (numdta && numdta !== "") ? Number(numdta) : 0;
                        //     console.log(numdta, ">>>>>>", parent_index);
                        // })

                        // console.log(sumData, "This is latedssss")
                        return (
                            <Accordion key={parent_index} className="accordion-border mt-2" defaultActiveKey={['0', '1']} alwaysOpen>
                                <Accordion.Item eventKey="0" className="position-relative">
                                    {
                                        payment !== "one_payment" &&
                                        Object.keys(payment_install).length &&
                                        Object.keys(payment_install).length > 1 &&
                                        <img src="assets/images/icons/delete-danger.svg" alt="Delete" className="h-18 position-absolute top-0 z-index-5 end-0 me-6 mt-4 cursor-pe pe-md-3" onClick={(e) => handelDelete_payment(e, parent_item, "remove_installment", parent_index)} />
                                    }
                                    <Accordion.Header>
                                        {
                                            payment == "one_payment" ?
                                                <>
                                                    {t("NonPayment.inOnePayment")}
                                                </>
                                                :
                                                <>
                                                    {t("otherstran.installment")} {parent_index + 1}
                                                    {/* <Badge bg="white" className="border text-dark ms-xl-4 ms-lg-3 ms-2">R$ <span className={`valueChange_${parent_item}`}>{formatNumberPortuges(sumData)}</span></Badge> */}
                                                    {/* <Badge bg="white" className="border text-dark ms-xl-4 ms-lg-3 ms-2">R$ <span>{formatNumberPortuges(sumData)}</span></Badge> */}
                                                    <Badge bg="white" className="border text-dark ms-xl-4 ms-lg-3 ms-2">R$ <span className={`valueChange_${parent_item}`}>{formatNumberPortuges(currentContract?.["vl_emitido_recb"])}</span></Badge>
                                                </>
                                        }
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {
                                            Object.keys(payment_install[`${parent_item}`]["newitem"]) &&
                                            Object.keys(payment_install[`${parent_item}`]["newitem"]).length > 0 &&
                                            Object.keys(payment_install[`${parent_item}`]["newitem"]).map((child_item, child_index) => {
                                                return (
                                                    <React.Fragment key={child_index}>
                                                        <Row className="g-3 mb-3">
                                                            <Col md={6}>
                                                                <FloatingLabel label={t("NonPayment.productOrService") + " *"}>
                                                                    <Form.Select className={`mh-336 overflow-auto ${errors?.payment_install?.[parent_index]?.newitem?.[child_index]?.service ? 'is-invalid' : ''}`} aria-label="product_service" {...register(`payment_install.${parent_index}.newitem.${child_index}.service`)} onChange={(e) => handelChange_payment({ e, parent_item, newitem: "newitem", parent_index, child_item, child_index })}>
                                                                        <option value="">{t('dropdownItem.select')}..</option>
                                                                        {serviceList && serviceList.length > 0 &&
                                                                            serviceList.map((item: any, index: number) => (
                                                                                <option key={index} value={item.ID_PRODUTO_PRD}>{item.ST_DESCRICAO_PRD}</option>
                                                                            ))}
                                                                    </Form.Select>
                                                                    <Form.Control.Feedback type="invalid">{errors?.payment_install?.[parent_index]?.newitem?.[child_index]?.service?.message}</Form.Control.Feedback>
                                                                </FloatingLabel>
                                                            </Col>
                                                            <Col xs>
                                                                <FloatingLabel controlId="amount" label={t("propertyDev.amount") + " *"}>
                                                                    <Form.Control type="text" className={`${errors?.payment_install?.[parent_index]?.newitem?.[child_index]?.amount ? 'is-invalid' : ''}`} placeholder={t("propertyDev.amount") + " *"} {...register(`payment_install.${parent_index}.newitem.${child_index}.amount`)} onChange={(e) => handelChange_payment({ e, parent_item, newitem: "newitem", parent_index, child_item, child_index })} />
                                                                    <Form.Control.Feedback type="invalid">{errors?.payment_install?.[parent_index]?.newitem?.[child_index]?.amount?.message}</Form.Control.Feedback>
                                                                </FloatingLabel>
                                                            </Col>
                                                            {
                                                                Object.keys(payment_install[`${parent_item}`]["newitem"]) &&
                                                                Object.keys(payment_install[`${parent_item}`]["newitem"]).length > 1 &&
                                                                <Col xs="auto">
                                                                    <Button variant="light" onClick={(e) => handelDelete_payment(e, parent_item, "newitem", child_index)} className="bg-light min-w-auto border-light rounded-4">
                                                                        <img src="assets/images/icons/delete-danger.svg" alt="Delete" className="h-18" />
                                                                    </Button>
                                                                </Col>
                                                            }
                                                            <Col md={6}>
                                                                <FloatingLabel label={t("reportBanking.Credited") + " *"}>
                                                                    <Form.Select className={`mh-336 overflow-auto ${errors?.payment_install?.[parent_index]?.newitem?.[child_index]?.credit ? 'is-invalid' : ''}`} aria-label="credited" {...register(`payment_install.${parent_index}.newitem.${child_index}.credit`)} onChange={(e) => handelChange_payment({ e, parent_item, newitem: "newitem", parent_index, child_item, child_index })} >
                                                                        <option value="">{t('dropdownItem.select')}..</option>
                                                                        <option value="1">{t('notifications.owner')}</option>
                                                                        <option value="2">{t('notifications.tenant')}</option>
                                                                        <option value="3">{t('realEstate')}</option>
                                                                        <option value="4">{t('services.third')}</option>
                                                                    </Form.Select>
                                                                    <Form.Control.Feedback type="invalid">{errors?.payment_install?.[parent_index]?.newitem?.[child_index]?.credit?.message}</Form.Control.Feedback>
                                                                </FloatingLabel>
                                                            </Col>

                                                            {/* {
                                                                payment !== "one_payment" && payment === "karan_permission_restricted" && */}
                                                            <>
                                                                <Col md={6} hidden>
                                                                    <FloatingLabel label={t("NonPayment.addition")}>
                                                                        <Form.Control type="text" className={`${errors?.payment_install?.[parent_index]?.newitem?.[child_index]?.addition ? 'is-invalid' : ''}`} placeholder={t("NonPayment.addition")} {...register(`payment_install.${parent_index}.newitem.${child_index}.addition`)} onChange={(e) => handelChange_payment({ e, parent_item, newitem: "newitem", parent_index, child_item, child_index })} />
                                                                        <Form.Control.Feedback type="invalid">{errors?.payment_install?.[parent_index]?.newitem?.[child_index]?.addition?.message}</Form.Control.Feedback>
                                                                    </FloatingLabel>
                                                                </Col>
                                                                <Col md={12} hidden>
                                                                    <FloatingLabel controlId="supplier" label={t("formLabel.Supplier")}>
                                                                        <Form.Select className={`mh-336 overflow-auto ${errors?.payment_install?.[parent_index]?.newitem?.[child_index]?.supplier ? 'is-invalid' : ''}`} aria-label={t("formLabel.Supplier")} {...register(`payment_install.${parent_index}.newitem.${child_index}.supplier`)} onChange={(e) => handelChange_payment({ e, parent_item, newitem: "newitem", parent_index, child_item, child_index })}>
                                                                            <option value="">{t('dropdownItem.select')}..</option>
                                                                            {suppliersData && suppliersData.length > 0 && suppliersData.map((item, index) => (
                                                                                <option key={index} value={item.id}>{item.name}</option>
                                                                            ))}
                                                                        </Form.Select>
                                                                        <Form.Control.Feedback type="invalid">{errors?.payment_install?.[parent_index]?.newitem?.[child_index]?.supplier?.message}</Form.Control.Feedback>
                                                                        {/* <img src="assets/images/icons/user-edit.svg" alt="User" className="h-20" /> */}
                                                                    </FloatingLabel>
                                                                </Col>
                                                                <Col md hidden>
                                                                    <FloatingLabel label={t("formReceipt.fees") + "(R$)"}>
                                                                        <Form.Control type="text" className={`${errors?.payment_install?.[parent_index]?.newitem?.[child_index]?.fee ? 'is-invalid' : ''}`} placeholder={t("formReceipt.fees") + "(R$)"} {...register(`payment_install.${parent_index}.newitem.${child_index}.fee`)} onChange={(e) => handelChange_payment({ e, parent_item, newitem: "newitem", parent_index, child_item, child_index })} />
                                                                        <Form.Control.Feedback type="invalid">{errors?.payment_install?.[parent_index]?.newitem?.[child_index]?.fee?.message}</Form.Control.Feedback>
                                                                    </FloatingLabel>
                                                                </Col>
                                                                <Col md hidden>
                                                                    <FloatingLabel label={t("NonPayment.amortization") + "(R$)"}>
                                                                        <Form.Control type="text" className={`${errors?.payment_install?.[parent_index]?.newitem?.[child_index]?.amortization ? 'is-invalid' : ''}`} placeholder={t("NonPayment.amortization") + "(R$)"} {...register(`payment_install.${parent_index}.newitem.${child_index}.amortization`)} onChange={(e) => handelChange_payment({ e, parent_item, newitem: "newitem", parent_index, child_item, child_index })} />
                                                                        <Form.Control.Feedback type="invalid">{errors?.payment_install?.[parent_index]?.newitem?.[child_index]?.amortization?.message}</Form.Control.Feedback>
                                                                    </FloatingLabel>
                                                                </Col>
                                                                <Col md={5} hidden>
                                                                    <FloatingLabel label={t("Report-NonPayment.UpdatedValue") + "(R$)"}>
                                                                        <Form.Control type="text" className={`${errors?.payment_install?.[parent_index]?.newitem?.[child_index]?.totalAmount ? 'is-invalid' : ''}`} placeholder={t("Report-NonPayment.UpdatedValue") + "(R$)"} {...register(`payment_install.${parent_index}.newitem.${child_index}.totalAmount`)} onChange={(e) => handelChange_payment({ e, parent_item, newitem: "newitem", parent_index, child_item, child_index })} disabled readOnly />
                                                                        <Form.Control.Feedback type="invalid">{errors?.payment_install?.[parent_index]?.newitem?.[child_index]?.totalAmount?.message}</Form.Control.Feedback>
                                                                    </FloatingLabel>
                                                                </Col>
                                                            </>
                                                            {/* } */}
                                                        </Row>
                                                        {
                                                            Object.keys(payment_install[`${parent_item}`]["newitem"]).length > 0 &&
                                                            child_index !== Object.keys(payment_install[`${parent_item}`]["newitem"]).length - 1 &&
                                                            < div className="d-block"><hr className="w-20" /></div>
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                        {/* {
                                            Object.keys(payment_install[`${parent_item}`]["newitem"]) &&
                                            Object.keys(payment_install[`${parent_item}`]["newitem"]).length < 5 && */}
                                        <div className="text-center d-flex align-items-center justify-content-center my-3">
                                            <Button variant="link" className="p-0" onClick={(e) => handelAddNew_payment(e, parent_item, "newitem", parent_index)}>
                                                <img src="assets/images/icons/plus-light.svg" alt="Documents add" className="h-20 me-2 fw-bold" />
                                                {t("chartofaccount.newitem")}
                                            </Button>

                                            {/* <Button variant="link" className="p-0"><img src="assets/images/icons/plus-light.svg" alt="Documents add" className="h-20 me-2 fw-bold" onClick={addNewItem} />{t("chartofaccount.newitem")} lllllll</Button> */}
                                        </div>
                                        {/* } */}
                                    </Accordion.Body >
                                </Accordion.Item>
                            </Accordion >
                        )
                    })
                }
            </>
        )
    }

    const { bankAccounts } = useBankAccounts();
    const [paymentMethod, setPaymentMethod] = useState("");
    const filteredBankAccounts = useMemo(() => {
        if (paymentMethod !== "28") return accountData?.data ?? [];

        return accountData.data.filter((accountData) =>
            bankAccounts.find(
                (bankAccount) =>
                    bankAccount.id === accountData.id && bankAccount.isHomologated
            )
        );
    }, [accountData.data, bankAccounts, paymentMethod]);

    return (
        <>
            <Offcanvas className="border-sm-none" show={showRecordInstallments} onHide={recordInstallmentsCloseThis} placement="end">
                <Form className="d-flex flex-column h-100" onSubmit={handleSubmit(onSubmit)}>
                    <Offcanvas.Header className="border-bottom" closeButton>
                        {/* <img src="assets/images/icons/arrow-left.svg" className="h-14 me-2" alt="Arrow left" />*/}{t("NonPayment.recordAgreement")}
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Element />
                        <Row className="g-3 mb-3 mt-2">
                            <Col md={12}>
                                <FloatingLabel label={t("bankAccount") + " *"}>
                                    <Form.Select className={`mh-336 overflow-auto ${errors?.bankAccount ? 'is-invalid' : ''}`} aria-label={t("bankAccount")} {...register("bankAccount")} onChange={(e) => hanldMaturity(e)}>
                                        <option value="">{t('dropdownItem.select')}</option>
                                        {
                                            filteredBankAccounts.length > 0 ? filteredBankAccounts.map((item, index) => (
                                                <option key={index} value={Number(item.id)}>{item.account} -- {item.accountRef}</option>
                                            ))
                                                :
                                                ''
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors?.bankAccount?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel label={t("bankTransaction.Paymentmethod") + " *"}>
                                    <Form.Select className={`mh-336 overflow-auto ${errors?.name ? 'is-invalid' : ''}`} aria-label={t("bankTransaction.Paymentmethod")} {...register("name")} onChange={(e) => {
                                        setPaymentMethod(e.target.value)
                                        setTimeout(() => {
                                            calculateTotalSum();
                                        }, 100)
                                        }}>
                                        <option value="">{t('dropdownItem.select')}</option>
                                        {
                                            billingData.data && billingData.data.length > 0 ? billingData.data.map((item, index) => (
                                                <option key={index} value={Number(item.id)}>{item.name}</option>
                                            ))
                                                : ''
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors?.name?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col xs md={4}>
                                <FloatingLabel controlId="amount" label={t("dueDate") + " *"}>
                                    <Form.Control className={`${errors?.duedate ? 'is-invalid' : ''}`} type="date" min={startDate} placeholder={t("dueDate")} {...register("duedate")} onChange={(e) => handlDate(e)} />
                                    <Form.Control.Feedback type="invalid">{errors?.duedate?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>

                            {/* <Col xs="auto">
                                <Button variant="light" onClick={deleteModalShow} className="bg-light min-w-auto border-light rounded-4">
                                    <img src="assets/images/icons/delete-danger.svg" alt="Delete" className="h-18" />
                                </Button>
                            </Col> */}
                        </Row>
                        {
                            payment !== "one_payment" && Object.keys(payment_install) &&
                            Object.keys(payment_install).length < 5 &&
                            <>
                                <div className="border-2 border border-dashed py-4 px-2 position-relative text-center rounded-4 mt-lg-4 mt-3 cursor-pe" onClick={(e) => handelAddNew_payment(e, "", "add_installment", 0)}>
                                    {/* <Form.Control type="file" className="position-absolute cursor-pe end-0 start-0 top-0 bottom-0 h-100 w-100 opacity-0 z-index-1" /> */}
                                    <div className="text-primary fw-bold mb-0 fs-6">{t("NonPayment.addInstallment")}</div>
                                </div>
                            </>
                        }
                    </Offcanvas.Body>
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        <div className="border-end pe-2 pe-md-3 pe-xl-4 me-2 me-lg-3 me-xl-4 text-lg-end">
                            {
                                payment !== "one_payment" ?
                                    <span className="text-secondary">{t("formLabel.payment")} {t("in")} {Object.keys(payment_install).length} {t("formLabel.installments")}</span>
                                    :
                                    <span className="text-secondary">{t("formLabel.payment")}</span>
                            }
                            <span className="d-block fw-bold">R$ <span className='AllSumOf'>0</span></span>
                        </div>
                        {
                            loading ?
                                <Button className="btn-width">
                                    <span className="spinner-border spinner-border-sm"></span> <span className="px-1">{t("Processing")}</span>
                                </Button>
                                :
                                <Button variant="primary" type="submit">{t("NonPayment.continue")}</Button>
                        }
                    </div>
                </Form>
            </Offcanvas>
            {/* modal start */}
            <AgreementSuccessfully showAgreementSuccessfully={showAgreementSuccessfullyModal}
                agreementSuccessfullysClose={agreementSuccessfullyModalClose}
                currentContract={currentContract}
                respCharge={respCharge} />
            <Delete showDelete={showDeleteyModal} deleteClose={deleteModalClose} />
        </>
    )
}

export default RecordInstallments