import React from "react";

import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useFormContext, useFormState } from "react-hook-form";
import { z } from "zod";

export const BradescoSchema = z.object({
  ContaCodigoBeneficiario: z.string().min(1, "Campo obrigatório"),
  ConvenioNumero: z.string().min(1, "Campo obrigatório"),
  ConvenioDescricao: z.string().min(1, "Campo obrigatório"),
  ConvenioCarteira: z.string().min(1, "Campo obrigatório"),
  ConvenioPadraoCNAB: z.string().min(1, "Campo obrigatório"),
  ContaCodigoEmpresa: z.string().min(1, "Campo obrigatório"),
  ConvenioReiniciarDiariamente: z.boolean().optional().default(false),
  ConvenioDensidaDeRemessa: z.string().min(1, "Campo obrigatório"),
  ConvenioNumeroRemessa: z.string().min(1, "Campo obrigatório"),
});

type BradescoFormType = z.infer<typeof BradescoSchema>;

export function BradescoFields() {
  const { register } = useFormContext();
  const { errors } = useFormState<BradescoFormType>();

  return (
    <>
      <Row className="mb-4">
        <Col sm={6}>
          <FloatingLabel
            controlId="ContaCodigoBeneficiario"
            label="Código beneficiário"
          >
            <Form.Control
              placeholder="Código beneficiário"
              {...register("ContaCodigoBeneficiario")}
              className={`${errors.ContaCodigoBeneficiario && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ContaCodigoBeneficiario?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
        <Col sm={6}>
          <FloatingLabel
            controlId="ContaCodigoEmpresa"
            label="Código da empresa"
          >
            <Form.Control
              placeholder="Código da empresa"
              {...register("ContaCodigoEmpresa")}
              className={`${errors.ContaCodigoEmpresa && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ContaCodigoEmpresa?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel controlId="ConvenioNumero" label="Convênio">
            <Form.Control
              placeholder="Convênio"
              {...register("ConvenioNumero")}
              className={`${errors.ConvenioNumero && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ConvenioNumero?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel
            controlId="ConvenioDescricao"
            label="Descrição do convênio"
          >
            <Form.Control
              placeholder="Descrição do convênio"
              {...register("ConvenioDescricao")}
              className={`${errors.ConvenioDescricao && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ConvenioDescricao?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel controlId="ConvenioPadraoCNAB" label="CNAB">
            <Form.Select {...register("ConvenioPadraoCNAB")}>
              <option disabled>CNAB</option>
              <option value="240" defaultChecked>
                240 (Recomendado)
              </option>
              <option value="400">400</option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel
            controlId="ConvenioDensidaDeRemessa"
            label="Código da densidade da Remessa"
          >
            <Form.Control
              placeholder="Código da densidade da Remessa"
              {...register("ConvenioDensidaDeRemessa")}
              className={`${errors.ConvenioDensidaDeRemessa && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ConvenioDensidaDeRemessa?.message?.toString()}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
      <FloatingLabel
        controlId="ConvenioReiniciarDiariamente"
        label="Reiniciar diariamente"
        className="d-none"
      >
        <Form.Check
          type="checkbox"
          {...register("ConvenioReiniciarDiariamente")}
        />
      </FloatingLabel>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel controlId="ConvenioCarteira" label="Tipo de carteira">
            <Form.Select {...register("ConvenioCarteira")}>
              <option disabled>Tipo de carteira</option>
              <option value="02" defaultChecked>
                02 - Cobrança com registro
              </option>
              <option value="04">04 - Cobrança com registro</option>
              <option value="06">06 - Cobrança sem registro</option>
              <option value="09">
                09 - Cobrança com registro (Modalidade escritural)
              </option>
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <FloatingLabel
            controlId="ConvenioNumeroRemessa"
            label="Número atual (remessa)"
          >
            <Form.Control
              placeholder="Número atual (remessa)"
              {...register("ConvenioNumeroRemessa")}
              className={`${errors.ConvenioNumeroRemessa && "is-invalid"}`}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ConvenioNumeroRemessa?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Col>
      </Row>
    </>
  );
}
