import React, { useEffect, useRef, useState } from 'react';
import { Form, Row, Col, InputGroup, FloatingLabel, Card, OverlayTrigger, Tooltip, Badge, Accordion, Button, Modal, Table, ListGroup } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Share from './Share';
import EditFolder from './EditFolder';
import PrintFolderDetail from './PrintFolderDetail';
import { useForm } from 'react-hook-form';
import { contractForm } from '../../../../Interface';
import Swal from 'sweetalert2';
import customerService from '../../../../../services/customerService';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/store';
import { allEmailTemplate, emailPost, listTemplate, listTemplateContent, listTemplatesInsideFolder } from '../../../../../redux/slice/SendEmailSlice';
import { config } from '../../../../../services/apiConfig';
import { newElectronicEnvelopeOpen } from '../../../../../redux/slice/contractDataSlice';
import ElectronicEnvelopeService from '../../../Contracts/Common/ElectronicEnvelopeService';
import moment from 'moment';

const FolderDetail = () => {
    const navigate = useNavigate();
    useEffect(() => {
        let user_id: any = 1
        dispatch(allEmailTemplate(user_id))
        dispatch(listTemplate())
    }, [])

    const { emailTemplateLst, templatevariable, listTemplatefolder } = useSelector((state: RootState) => state.emailTemplate)
    const [templateId, setTemplateId] = useState<any>("");
    const [logoPath, setLogoPath] = useState<any>("/logos/logo.png");
    const [functionState, setFunctionState] = useState<any>(0);
    const { getlogo, status } = useSelector((state: RootState) => state.companyData);
    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>()
    const buttonRef = useRef<any>();
    const editorRef: any = useRef();
    let userEmailId = localStorage.getItem("userEmailId");
    const [t] = useTranslation();
    let [desc, setDesc] = useState('');
    const [variableData, setVariableData] = useState<any>({});
    const [contractVariableData, setContractVariableData] = useState<any>({});
    const [propertyVariableData, setPropertyVariableData] = useState<any>({});
    const [ownerVariableData, setOwnerVariableData] = useState<any>({});
    const [tenantVariableData, setTenantVariableData] = useState<any>({});
    const [dateVariableData, setDateVariableData] = useState<any>({});
    const [realEstateVariableData, setRealEstateVariableData] = useState<any>({});
    const [getAmendmant, setGetAmendmant] = useState<any>([])
    const [selectionData, setSelectionData] = useState<any>([]);
    const [firstSelection, setFirstSelection] = useState('');
    const [secondSelection, setSecondSelection] = useState('');
    const [thirdSelection, setThirdSelection] = useState('');
    const [templateName, setTemplateName] = useState<any>("");
    const [identifier, setIdentifier] = useState<any>("");
    const [allVariablesOnContractSelect, setAllVariablesOnContractSelect] = useState<any>(false);
    const [allVariablesOnPropertySelect, setAllVariablesOnPropertySelect] = useState<any>(false);

    let diff: any = [];
    const [identifierStatus, setIdentifierStatus] = useState<any>("");
    // const { id } = useParams();
    let id = "";
    if (location.state && location.state.contract_id !== "" && location.state.contract_id !== null && typeof location.state.contract_id !== "undefined") {
        id = location.state.contract_id;
    }

    const [printState, setPrintState] = useState<any>(false);
    const [envelopeState, setEnvelopeState] = useState<any>(false);
    const [documentSaveState, setDocumentSaveState] = useState<any>(false);
    const [show, setShow] = useState(false);

    //Edit watermark modal close open function
    const [showShareModal, setShareModal] = useState(false);
    const shareModalClose = () => setShareModal(false);
    const shareModalShow = () => setShareModal(true);

    //Edit folder modal close open function
    const [showEditFolderModal, setEditFolderModal] = useState(false);
    const editFolderModalClose = () => setEditFolderModal(false);
    const editFolderModalShow = () => setEditFolderModal(true);

    //Print folder detail modal close open function
    const [showPrintDetailModal, setPrintDetailModal] = useState(false);
    const printDetailModalClose = () => setPrintDetailModal(false);
    const printDetailModalShow = () => setPrintDetailModal(true);
    const electronicEnvelopeOpen = () => {
        dispatch(newElectronicEnvelopeOpen(true));
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await customerService.getTemplates();
                const variableResult = response.data.data[1];
                const propertyVariableResult = response.data.data[2];
                const ownerVariableResult = response.data.data[3];
                const tenantVariableResult = response.data.data[4];
                const realEstateVariableResult = response.data.data[5];
                const dateVariableResult = response.data.data[6];


                setOwnerVariableData(ownerVariableResult);
                setTenantVariableData(tenantVariableResult);
                setRealEstateVariableData(realEstateVariableResult);
                setDateVariableData(dateVariableResult);
                // setVariableData(variableResult);
                setContractVariableData(variableResult)
                setPropertyVariableData(propertyVariableResult);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        // Check if location.state and template_id exist before using them
        if (location && location?.state &&
            location?.state?.template_id &&
            location?.state?.template_id !== null &&
            location?.state?.template_id !== undefined &&
            location?.state?.template_id !== "") {
            getTemplatesData(location.state.template_id);
        } else {
            setFunctionState(0);
        }
    }, []);

    useEffect(() => {
        if (location.state && location.state.readjustId !== null && location.state.readjustId !== undefined && location.state.readjustId !== "") {
            const getAllElectronicEnvelopeTemplateWithIdentifierAndFolder = async () => {
                const response = await customerService.getAllElectronicEnvelopeTemplateWithIdentifierAndFolder();
                // console.log("getAllElectronicEnvelopeTemplateWithIdentifierAndFolder", response.data.data);
                setSelectionData(response.data.data)
            }
            getAllElectronicEnvelopeTemplateWithIdentifierAndFolder();
            customerService.getAmendmant((location.state.readjustId)).then((res) => {
                if (res.data.type == "success") {
                    setGetAmendmant(res?.data.data)
                    // diff = compareJSON(res?.data.data[0]?.ST_OLD_DATA_ALT, res?.data.data[0]?.ST_NEW_DATA_ALT)
                }
            })
        }
    }, [])

    const options = [
        { value: 'Contract', label: t('contract') },
        { value: 'Property', label: t('Property') },
        { value: 'Owner', label: t('owner') },
        { value: 'Tenant', label: t('tenant') }
    ]

    const formSubmit = async (data: contractForm, e: any) => {
        data.userEmailId = userEmailId;
        if (data.title !== "" && data.title !== undefined && data.title !== null && data.description !== "" && data.description !== null && data.description !== undefined) { // Check if title and description are filled
            if (documentSaveState === true) { // If saving the document
                if (data.documentIdentifier && data.documentIdentifier !== "" && data.documentIdentifier !== null && data.documentIdentifier != "undefined") { // Ensure documentIdentifier is filled
                    let folder_id = location.state.folder_id;
                    let template_id = location.state.template_id || null;

                    data.model = template_id;
                    data.folder = folder_id;

                    let response = await customerService.updateTemplate(data);
                    if (response.data.resCode == 202 || response.data.resCode == 200) {
                        Swal.fire({
                            title: t("Sweetalert.Success"),
                            text: t('templateSaved'),
                            confirmButtonText: "OK",
                            timer: 2000
                        });
                        navigate(`/folder_listing/${folder_id}`);
                    } else {
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: t('recordFailed'),
                            confirmButtonText: "OK",
                            timer: 2000
                        });
                    }
                    setDocumentSaveState(false);
                } else {
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: "Please Fill Parameters",
                        confirmButtonText: "OK",
                        timer: 2000
                    });
                }
            } else { // For other operations where documentIdentifier is not needed
                if (printState === true && id && id !== "" && id !== null) {
                    let res = await customerService.renderCKEditorTemplateVariables(data, id);
                    let template = res.data.data[0];

                    template = `
                        <h4>${t("DocumentTemplate.amendmantTitle")}: ${data.title}</h4>
                        <h4>${t("DocumentTemplate.amendmantSubTitle")}: ${data.subtitle}</h4>
                        ${template}
                    `;

                    const response: any = await customerService.createPDFTemplate(template);

                    //  const url = response.data.data.result;

                    const url = `${config.TRANSFER___API}/api/v1/download`;
                    window.open(url, '_blank');
                    setPrintState(false);
                } else if (printState === true) {
                    let template = data.description;
                    template = `
                        <h4>${t("DocumentTemplate.documentTitle")}: ${data.title}</h4>
                        <h4>${t("DocumentTemplate.documentSubtitle")}: ${data.subtitle}</h4>
                        ${template}
                    `;
                    const response: any = await customerService.createPDFTemplate(template);
                    // const url = response.data.data.result;
                    const url = `${config.TRANSFER___API}/api/v1/download`;
                    window.open(url, '_blank');
                    setPrintState(false);
                } else if (envelopeState == true) {
                    electronicEnvelopeOpen();
                    setEnvelopeState(false);
                } else {
                    let res = await customerService.renderCKEditorTemplateVariables(data, id);
                    let template = res.data.data[0];
                    let allEmails = res.data.data[1];

                    let emaildata: any = {
                        status: "new",
                        subject: "Email vindo do POSTMAN",
                        content: template,
                        layout: "default",
                        sender: "noreply@dev.kenlo.io",
                        to: allEmails,
                        "cc": ["vikash@wegile.com"],
                        "bcc": ["karan@wegile.com"],
                        identifier: "identifier",
                        model: 1,
                        caption: "caption",
                        title: data.title,
                    };

                    // Show loading spinner
                    Swal.fire({
                        title: 'Loading...',
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });

                    try {
                        let response = await dispatch(emailPost(emaildata));

                        // Close loading spinner
                        Swal.close();

                        if (response.payload.resCode === 201) {
                            Swal.fire({
                                title: "Success",
                                text: "Email Sent",
                                icon: "success",
                                timer: 2000
                            });
                        } else {
                            Swal.fire({
                                title: "Error",
                                text: "Something went wrong",
                                icon: "error",
                                timer: 2000
                            });
                        }
                    } catch (error) {
                        // Handle error if dispatch fails
                        console.error('Error sending email:', error);
                        Swal.fire({
                            title: "Error",
                            text: "Something went wrong",
                            icon: "error",
                            timer: 2000
                        });
                    }
                }
            }
        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: "Please Fill Parameters",
                confirmButtonText: "OK",
                timer: 2000
            });
        }
    };

    const { register, reset, handleSubmit, setValue, getValues, formState: { errors }, setError, clearErrors } = useForm<contractForm>();
    const handleClick = () => {
        // Trigger click event on the button
        buttonRef.current.click();
    };

    const downloadPDF = async () => {
        await setPrintState(true);
        buttonRef.current.click();
    }

    const handleDeleteAmendmant = async () => {
        let readjusteId = location.state.readjustId;
        if (readjusteId && readjusteId !== "" && readjusteId !== null && readjusteId !== undefined) {
            Swal.fire({
                title: t("Sweetalert.Areyousure"),
                text: t("newTextSweetAlert.Youablerevert"),
                // icon: 'success','warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t("Sweetalert.yesDeleteIt"),
                cancelButtonText: t("button.cancel")
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await customerService.deleteAmendmant(readjusteId);
                    //console.log(response, "response");

                    if (response.data.resCode === 408) {
                        Swal.fire({
                            // icon: 'success','success',
                            title: t("Sweetalert.Success"),
                            text: "Amendmant Deleted",
                            confirmButtonText: "OK",
                            timer: 2000
                        })
                    } else {
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: "Record Not Deleted",
                            // icon: 'success',"error",
                            confirmButtonText: "OK",
                            timer: 2000
                        })
                    }
                }
            });

        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: "Please Access Amendmant In Contract Detail Page",
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    }

    const handleSaveTemplate = async () => {
        await setDocumentSaveState(true);
        //console.log("Template Saved");
        buttonRef.current.click();
    }

    const HandleModule = (e) => {
        const selectedModelId = e.target.value;
        setTemplateId(selectedModelId);
        let selectedModel: any = null;

        for (let i = 0; i < emailTemplateLst.length; i++) {
            if (emailTemplateLst[i].ID_MODELO_MOH == selectedModelId) {
                selectedModel = emailTemplateLst[i];
                break;
            }
        }

        if (selectedModel) {
            // const newText :any = 
            // `
            // <!DOCTYPE html>
            //             <html>
            //               <head>
            //                 <meta charset="UTF-8">
            //               </head>
            //               <body style="font-family:sans-serif;margin-left:2em;">
            //                <header>
            //                <img className="form-control" src="${config.API_URL}/logos/${getlogo?.logo_name}" alt="logo">
            //                </header>
            //                   ${selectedModel.ST_TEXTO_MOH}
            //                   <div style="background-color:#F5F5F5; width: 100%; margin: 0 !important; padding: 0 !important;">
            //                   <div style="max-width: 600px; margin:auto;">
            //                     <div style="margin: 0 15px;  padding:32px 10px 32px 10px">
            //                     <img src="${config.API_URL}${logoPath}" alt="Logo">
            //                     </div>
            //                   </div>
            //                </div>
            //               </body>
            //             </html>
            // `
            // || ''; // Use the actual property name if needed

            let newText: any =
                `
                ${selectedModel.ST_TEXTO_MOH}
            `
                || ''; // Use the actual property name if needed


            const newTitle = selectedModel.ST_TITULO_MOH || ''
            newText = newText.toLowerCase();
            setDesc(newText);
            //   setValue("title", `${newTitle}`)
        } else {
            setDesc(''); // or set to a default value
            //   setValue("title", ``)
        }
    };

    const handleEditClick = () => {
        setFunctionState(0);
        // setShow(true);
    }

    const handleClose = () => setShow(false);
    const handleIdentifierChange = (event) => {
        let status = event.target.value

        if (status) {
            Swal.fire({
                title: t("Sweetalert.Areyousure"),
                text: t('confirmTemplateSelection', { type: options.find(e => e.value === status)?.label }),
                // icon: 'success','warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t('yesConfirm'),
                cancelButtonText: t('button.cancel')
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if (status == "Contract") {
                        setAllVariablesOnContractSelect(true);
                        setVariableData(contractVariableData);
                    }
                    if (status == "Property") {
                        setAllVariablesOnPropertySelect(true);
                        setVariableData(propertyVariableData)
                    } if (status == "Owner") {
                        setVariableData(ownerVariableData);
                    }
                    if (status == "Tenant") {
                        setVariableData(tenantVariableData);
                    }
                    setIdentifierStatus(event.target.value);
                    event.target.disabled = true;
                }
            })
        }
    }

    const getTemplatesData = async (template_id) => {
        if (typeof template_id !== "undefined" && template_id) {
            let data = await dispatch(listTemplateContent(template_id));
            const response = await customerService.getTemplates();
            const variableResult = response.data.data[1];
            const propertyVariableResult = response.data.data[2];
            if (data.payload.data.length > 0) {
                setFunctionState(1);
                let setData = data.payload.data[0];
                let newText = setData.ST_TEXTO_MOH
                newText = newText.toLowerCase();
                setDesc(newText);
                setValue('templateName', setData.ST_NOME_MOH);
                setValue('title', setData.ST_TITULO_MOH);
                setValue('subtitle', setData.ST_SUBTITULO_MOH);
                setValue('description', setData.ST_TEXTO_MOH);
                setValue('documentIdentifier', setData.ST_IDENTIFICADOR_MOH)
                setDesc(setData.ST_TEXTO_MOH);
                if (setData.ST_IDENTIFICADOR_MOH == "Contract") {
                    setAllVariablesOnContractSelect(true);
                } else if (setData.ST_IDENTIFICADOR_MOH == "Property") {
                    setAllVariablesOnPropertySelect(true);
                }
                setIdentifierStatus(setData.ST_IDENTIFICADOR_MOH);

                if (setData.ST_IDENTIFICADOR_MOH == "Contract") {
                    setVariableData(variableResult);
                }
                if (setData.ST_IDENTIFICADOR_MOH == "Property") {
                    setVariableData(propertyVariableResult)
                }
                return 1
            } else {
                return 0
            }
        }
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (inputRef.current instanceof HTMLElement && !inputRef.current.contains(event.target as Node)) {
            setIsInputFocused(false);
        }
    };

    useEffect(() => {
        if (desc) { setValue('description', desc) }
        document.addEventListener("click", handleClickOutside as unknown as EventListener);
        return () => {
            document.removeEventListener("click", handleClickOutside as unknown as EventListener);
        };
    }, [desc]);

    const createElectronicEnvelope = async () => {
        await setEnvelopeState(true);
        //console.log("createElectronicEnvelope");
        buttonRef.current.click();
    }

    function compareJSON(old, newJson) {
        for (let [k, v] of Object.entries(old)) {
            if (typeof v === "object" && v !== null) {
                if (!newJson.hasOwnProperty(k)) {
                    newJson[k] = v; // 2
                } else {
                    compareJSON(v, newJson?.[k]);
                }
            } else {
                if (Object.is(v, newJson?.[k])) {
                    delete newJson?.[k]; // 1
                }
            }
        }
        return JSON.stringify(newJson);

    }

    const inputRef = useRef<any>(null);
    const [searchResults, setSearchResults] = useState<any>();
    const [isInputFocused, setIsInputFocused] = useState(false);

    const handleInputFocus = (e) => {
        setIsInputFocused(true);
    };

    const HandleVariableSearch = (e: any, item) => {
        setIsInputFocused(false);
        let selectedVariable = item;
        selectedVariable = selectedVariable.toLowerCase()
        if (editorRef.current) {
            const editor = editorRef.current.editor;
            editor.execute('insertText', { text: `%${selectedVariable}%` });
        }
    }

    const handlefilterSearchVariable = async (e) => {
        let name = e.target.value;
        let resultDta: any
        if (name.trim() !== "" && e.target.value !== "") {
            resultDta = Object.entries(variableData).filter(([key, value]) => {
                let keyIntoSmall: any = key.toLowerCase();
                let searchIntoSmall: any = (name.trim()).toLowerCase();
                return keyIntoSmall.includes(`${searchIntoSmall}`)
            })
        } else {
            resultDta = Object.entries(variableData)
        }
        setSearchResults(resultDta);
    }

    const AdmantBody = (props: any) => {
        let { getAmendmant } = props;
        let BodyAdm = getAmendmant;
        let bodyAdmnInside = getAmendmant[0];
        let ST_OLD_DATA_ALT = bodyAdmnInside?.["ST_OLD_DATA_ALT"];
        let ST_NEW_DATA_ALT = bodyAdmnInside?.["ST_NEW_DATA_ALT"];

        return (
            <>
                {
                    BodyAdm && BodyAdm.length > 0 ?
                        <Card className="mb-3" >
                            <Card.Header>Changes made</Card.Header>
                            <Card.Body>
                                <Table responsive className="table-custom table-custom-bordered mb-0">
                                    <thead>
                                        <tr>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            ST_OLD_DATA_ALT?.["rent_value"] &&
                                            ST_NEW_DATA_ALT?.["rent_value"] &&
                                            (ST_OLD_DATA_ALT?.["rent_value"] !== ST_NEW_DATA_ALT?.["rent_value"]) &&
                                            <tr>
                                                <td>Rent value changed from the R$ {ST_OLD_DATA_ALT?.["rent_value"]} to R$ {ST_NEW_DATA_ALT?.["rent_value"]}.</td>
                                            </tr>
                                        }

                                        {
                                            ST_OLD_DATA_ALT?.["end2"] &&
                                            ST_NEW_DATA_ALT?.["end2"] &&
                                            (ST_OLD_DATA_ALT?.["end2"] !== ST_NEW_DATA_ALT?.["end2"]) &&
                                            <tr>
                                                <td>End date changed from {moment(ST_OLD_DATA_ALT?.["end2"])?.format('DD/MMM/YYYY')} to {moment(ST_NEW_DATA_ALT?.["end2"])?.format('DD/MMM/YYYY')}.</td>
                                            </tr>
                                        }
                                        {
                                            ST_OLD_DATA_ALT?.["due_date"] &&
                                            ST_NEW_DATA_ALT?.["due_date"] &&
                                            (String(ST_OLD_DATA_ALT?.["due_date"]) !== String(ST_NEW_DATA_ALT?.["due_date"])) &&
                                            <tr>
                                                <td>Due date changed from the {ST_OLD_DATA_ALT?.["due_date"]}th to the {ST_NEW_DATA_ALT?.["due_date"]}th.</td>
                                            </tr>

                                        }



                                        {
                                            ST_OLD_DATA_ALT?.["owner"] &&
                                            ST_NEW_DATA_ALT?.["owner"] &&
                                            (ST_OLD_DATA_ALT?.["owner"] !== ST_NEW_DATA_ALT?.["owner"]) &&
                                            <tr>
                                                <td>Owner Change  from {ST_OLD_DATA_ALT?.["owner"]} to {ST_NEW_DATA_ALT?.["owner"]} </td>
                                            </tr>
                                        }

                                        {
                                            ST_OLD_DATA_ALT?.["tenant"] &&
                                            ST_NEW_DATA_ALT?.["tenant"] &&
                                            (ST_OLD_DATA_ALT?.["tenant"] !== ST_NEW_DATA_ALT?.["tenant"]) &&
                                            <tr>
                                                <td>Tenant Change  from {ST_OLD_DATA_ALT?.["tenant"]} to {ST_NEW_DATA_ALT?.["tenant"]} </td>
                                            </tr>
                                        }

                                        {/* hERE THE SHOW BEGINS */}

                                        {
                                            ST_OLD_DATA_ALT?.["address"] &&
                                            ST_NEW_DATA_ALT?.["address"] &&
                                            (ST_OLD_DATA_ALT?.["address"] !== ST_NEW_DATA_ALT?.["address"]) &&
                                            <tr>
                                                <td>Address Change  from {ST_OLD_DATA_ALT?.["address"]} to {ST_NEW_DATA_ALT?.["address"]} </td>
                                            </tr>
                                        }

                                        {
                                            ST_OLD_DATA_ALT?.["assuredValue"] &&
                                            ST_NEW_DATA_ALT?.["assuredValue"] &&
                                            (ST_OLD_DATA_ALT?.["assuredValue"] !== ST_NEW_DATA_ALT?.["assuredValue"]) &&
                                            <tr>
                                                <td>Insurance Assured Value  change from R$ {ST_OLD_DATA_ALT?.["assuredValue"]} to R$ {ST_NEW_DATA_ALT?.["assuredValue"]} </td>
                                            </tr>
                                        }

                                        {
                                            (ST_OLD_DATA_ALT?.invoice || String(ST_OLD_DATA_ALT?.invoice) !== "") &&
                                            (ST_NEW_DATA_ALT?.invoice || String(ST_NEW_DATA_ALT?.invoice) !== "") &&
                                            (String(ST_OLD_DATA_ALT?.invoice) !== String(ST_NEW_DATA_ALT?.invoice)) &&
                                            <tr>
                                                <td>
                                                    {String(ST_OLD_DATA_ALT?.invoice) == "0" && (
                                                        <>
                                                            {String(ST_NEW_DATA_ALT?.invoice) == "1" && `Invoice Change  from (${t('doSystem')}) to (${t('issueBySystem')})`}
                                                        </>
                                                    )}
                                                    {String(ST_OLD_DATA_ALT?.invoice) == "1" && (
                                                        <>
                                                            {String(ST_NEW_DATA_ALT?.invoice) == "0" && `Invoice Change  from (${t('issueBySystem')}) to (${t('doSystem')})`}
                                                        </>
                                                    )}
                                                </td>
                                                {/* <td>Billing Address Change  from {ST_OLD_DATA_ALT?.billing_address} to {ST_NEW_DATA_ALT?.billing_address} </td> */}
                                            </tr>
                                        }

                                        {
                                            (ST_OLD_DATA_ALT?.income_tax || String(ST_OLD_DATA_ALT?.income_tax) !== "") &&
                                            (ST_NEW_DATA_ALT?.income_tax || String(ST_NEW_DATA_ALT?.income_tax) !== "") &&
                                            (String(ST_OLD_DATA_ALT?.income_tax) !== String(ST_NEW_DATA_ALT?.income_tax)) &&
                                            <tr>
                                                <td>
                                                    {String(ST_OLD_DATA_ALT?.income_tax) == "1" && (
                                                        <>
                                                            {String(ST_NEW_DATA_ALT?.income_tax) == "2" && `Income Tax Change  from (${t('withholdTaxes')}) to (${t('withholdCollects')})`}
                                                            {String(ST_NEW_DATA_ALT?.income_tax) == "0" && `Income Tax Change  from (${t('withholdTaxes')}) to (${t('withholdsSystem')})`}
                                                            {String(ST_NEW_DATA_ALT?.income_tax) == "-1" && `Income Tax Change  from (${t('withholdTaxes')}) to (${t('doesTax')})`}
                                                        </>
                                                    )}
                                                    {String(ST_OLD_DATA_ALT?.income_tax) == "2" && (
                                                        <>
                                                            {String(ST_NEW_DATA_ALT?.income_tax) == "1" && `Income Tax Change  from (${t('withholdCollects')}) to (${t('withholdTaxes')})`}
                                                            {String(ST_NEW_DATA_ALT?.income_tax) == "0" && `Income Tax Change  from (${t('withholdCollects')}) to (${t('withholdsSystem')})`}
                                                            {String(ST_NEW_DATA_ALT?.income_tax) == "-1" && `Income Tax Change  from (${t('withholdCollects')}) to (${t('doesTax')})`}
                                                        </>
                                                    )}

                                                    {String(ST_OLD_DATA_ALT?.income_tax) == "0" && (
                                                        <>
                                                            {String(ST_NEW_DATA_ALT?.income_tax) == "1" && `Income Tax Change  from (${t('withholdsSystem')}) to (${t('withholdTaxes')})`}
                                                            {String(ST_NEW_DATA_ALT?.income_tax) == "2" && `Income Tax Change  from (${t('withholdsSystem')}) to (${t('withholdCollects')})`}
                                                            {String(ST_NEW_DATA_ALT?.income_tax) == "-1" && `Income Tax Change  from (${t('withholdsSystem')}) to (${t('doesTax')})`}
                                                            {String(ST_NEW_DATA_ALT?.income_tax) == "0" && ``}

                                                        </>
                                                    )}

                                                    {String(ST_OLD_DATA_ALT?.income_tax) == "-1" && (
                                                        <>
                                                            {String(ST_NEW_DATA_ALT?.income_tax) == "1" && `Income Tax Change  from (${t('doesTax')}) to (${t('withholdTaxes')})`}
                                                            {String(ST_NEW_DATA_ALT?.income_tax) == "2" && `Income Tax Change  from (${t('doesTax')}) to (${t('withholdCollects')})`}
                                                            {String(ST_NEW_DATA_ALT?.income_tax) == "0" && `Income Tax Change  from (${t('doesTax')}) to (${t('withholdsSystem')})`}
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        }

                                        {
                                            ST_OLD_DATA_ALT?.billing_address &&
                                            ST_NEW_DATA_ALT?.billing_address &&
                                            (String(ST_OLD_DATA_ALT?.billing_address) !== String(ST_NEW_DATA_ALT?.billing_address)) &&
                                            <tr>
                                                <td>
                                                    {String(ST_OLD_DATA_ALT?.billing_address) == "1" &&
                                                        String(ST_NEW_DATA_ALT?.billing_address) == "2" &&
                                                        `Billing Address Change  from ${t('useRentalPtyAddress')} to ${t('useTenantAddress')}`}
                                                    {String(ST_OLD_DATA_ALT?.billing_address) == "2" &&
                                                        String(ST_NEW_DATA_ALT?.billing_address) == "1" &&
                                                        `Billing Address Change  from ${t('useTenantAddress')} to ${t('useRentalPtyAddress')} `}
                                                    {String(ST_OLD_DATA_ALT?.billing_address) == "1" &&
                                                        String(ST_NEW_DATA_ALT?.billing_address) == "3" &&
                                                        `Billing Address Change  from ${t('useRentalPtyAddress')} to ${t('setAnotherAddress')} `}
                                                    {String(ST_OLD_DATA_ALT?.billing_address) == "3" &&
                                                        String(ST_NEW_DATA_ALT?.billing_address) == "1" &&
                                                        `Billing Address Change  from ${t('setAnotherAddress')} to ${t('useRentalPtyAddress')} `}
                                                    {String(ST_OLD_DATA_ALT?.billing_address) == "2" &&
                                                        String(ST_NEW_DATA_ALT?.billing_address) == "3" &&
                                                        `Billing Address Change  from ${t('useTenantAddress')} to ${t('setAnotherAddress')} `}
                                                    {String(ST_OLD_DATA_ALT?.billing_address) == "3" &&
                                                        String(ST_NEW_DATA_ALT?.billing_address) == "2" &&
                                                        `Billing Address Change  from ${t('setAnotherAddress')} to ${t('useTenantAddress')} `}
                                                </td>
                                            </tr>
                                        }


                                        {
                                            (ST_OLD_DATA_ALT?.deduct_administration || String(ST_OLD_DATA_ALT?.deduct_administration) !== "") &&
                                            (ST_NEW_DATA_ALT?.deduct_administration || String(ST_NEW_DATA_ALT?.deduct_administration) !== "") &&
                                            (String(ST_NEW_DATA_ALT?.deduct_administration) !== String(ST_NEW_DATA_ALT?.deduct_administration)) &&
                                            <tr>
                                                <td>
                                                    {String(ST_OLD_DATA_ALT?.deduct_administration) == "1" &&
                                                        String(ST_NEW_DATA_ALT?.deduct_administration) == "2" &&
                                                        `Deduct Administration Change  from ${t('yesContract')} to ${t('noJustContract')}`}
                                                    {String(ST_OLD_DATA_ALT?.deduct_administration) == "2" &&
                                                        String(ST_NEW_DATA_ALT?.deduct_administration) == "1" &&
                                                        `Deduct Administration Change  from ${t('noJustContract')} to ${t('yesContract')} `}
                                                    {String(ST_OLD_DATA_ALT?.deduct_administration) == "1" &&
                                                        String(ST_NEW_DATA_ALT?.deduct_administration) == "0" &&
                                                        `Deduct Administration Change  from ${t('yesContract')} to ${t('followGeneralSetup')} `}
                                                    {String(ST_OLD_DATA_ALT?.deduct_administration) == "0" &&
                                                        String(ST_NEW_DATA_ALT?.deduct_administration) == "1" &&
                                                        `Deduct Administration Change  from ${t('followGeneralSetup')} to ${t('yesContract')} `}
                                                    {String(ST_OLD_DATA_ALT?.deduct_administration) == "2" &&
                                                        String(ST_NEW_DATA_ALT?.deduct_administration) == "0" &&
                                                        `Deduct Administration Change  from ${t('noJustContract')} to ${t('followGeneralSetup')} `}
                                                    {String(ST_OLD_DATA_ALT?.deduct_administration) == "0" &&
                                                        String(ST_NEW_DATA_ALT?.deduct_administration) == "2" &&
                                                        `Deduct Administration Change  from ${t('followGeneralSetup')} to ${t('noJustContract')} `}
                                                </td>
                                            </tr>
                                        }

                                        {
                                            (ST_OLD_DATA_ALT?.guarantee || String(ST_OLD_DATA_ALT?.guarantee) !== "") &&
                                            (ST_NEW_DATA_ALT?.guarantee || String(ST_NEW_DATA_ALT?.guarantee) !== "") &&
                                            (String(ST_OLD_DATA_ALT?.guarantee) !== String(ST_NEW_DATA_ALT?.guarantee)) &&
                                            <tr>
                                                <td>
                                                    {String(ST_OLD_DATA_ALT?.guarantee) == "1" && (
                                                        <>
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "2" && `Guarantee Change  from ${t('guarantor')} to ${t('securityDeposit')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "3" && `Guarantee Change  from ${t('guarantor')} to ${t('suretyBond')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "4" && `Guarantee Change  from ${t('guarantor')} to ${t('capitalizationBond')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "5" && `Guarantee Change  from ${t('guarantor')} to ${t('surety')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "6" && `Guarantee Change  from ${t('guarantor')} to ${t('fiduciaryAssignment')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "0" && `Guarantee Change  from ${t('guarantor')} to ${t('hasNoWarranty')}`}
                                                        </>
                                                    )}
                                                    {String(ST_OLD_DATA_ALT?.guarantee) == "2" && (
                                                        <>
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "1" && `Guarantee Change  from ${t('securityDeposit')} to ${t('guarantor')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "3" && `Guarantee Change  from ${t('securityDeposit')} to ${t('suretyBond')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "4" && `Guarantee Change  from ${t('securityDeposit')} to ${t('capitalizationBond')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "5" && `Guarantee Change  from ${t('securityDeposit')} to ${t('surety')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "6" && `Guarantee Change  from ${t('securityDeposit')} to ${t('fiduciaryAssignment')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "0" && `Guarantee Change  from ${t('securityDeposit')} to ${t('hasNoWarranty')}`}
                                                        </>
                                                    )}

                                                    {String(ST_OLD_DATA_ALT?.guarantee) == "3" && (
                                                        <>
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "1" && `Guarantee Change  from ${t('suretyBond')} to ${t('guarantor')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "2" && `Guarantee Change  from ${t('suretyBond')} to ${t('securityDeposit')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "4" && `Guarantee Change  from ${t('suretyBond')} to ${t('capitalizationBond')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "5" && `Guarantee Change  from ${t('suretyBond')} to ${t('surety')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "6" && `Guarantee Change  from ${t('suretyBond')} to ${t('fiduciaryAssignment')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "0" && `Guarantee Change  from ${t('suretyBond')} to ${t('hasNoWarranty')}`}
                                                        </>
                                                    )}

                                                    {String(ST_OLD_DATA_ALT?.guarantee) == "4" && (
                                                        <>
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "1" && `Guarantee Change  from ${t('capitalizationBond')} to ${t('guarantor')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "2" && `Guarantee Change  from ${t('capitalizationBond')} to ${t('securityDeposit')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "3" && `Guarantee Change  from ${t('capitalizationBond')} to ${t('suretyBond')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "5" && `Guarantee Change  from ${t('capitalizationBond')} to ${t('surety')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "6" && `Guarantee Change  from ${t('capitalizationBond')} to ${t('fiduciaryAssignment')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "0" && `Guarantee Change  from ${t('capitalizationBond')} to ${t('hasNoWarranty')}`}
                                                        </>
                                                    )}

                                                    {String(ST_OLD_DATA_ALT?.guarantee) == "5" && (
                                                        <>
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "1" && `Guarantee Change  from ${t('surety')} to ${t('guarantor')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "2" && `Guarantee Change  from ${t('surety')} to ${t('securityDeposit')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "3" && `Guarantee Change  from ${t('surety')} to ${t('suretyBond')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "4" && `Guarantee Change  from ${t('surety')} to ${t('capitalizationBond')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "6" && `Guarantee Change  from ${t('surety')} to ${t('fiduciaryAssignment')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "0" && `Guarantee Change  from ${t('surety')} to ${t('hasNoWarranty')}`}
                                                        </>
                                                    )}

                                                    {String(ST_OLD_DATA_ALT?.guarantee) == "6" && (
                                                        <>
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "1" && `Guarantee Change  from ${t('fiduciaryAssignment')} to ${t('guarantor')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "2" && `Guarantee Change  from ${t('fiduciaryAssignment')} to ${t('securityDeposit')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "3" && `Guarantee Change  from ${t('fiduciaryAssignment')} to ${t('suretyBond')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "4" && `Guarantee Change  from ${t('fiduciaryAssignment')} to ${t('capitalizationBond')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "5" && `Guarantee Change  from ${t('fiduciaryAssignment')} to ${t('surety')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "0" && `Guarantee Change  from ${t('fiduciaryAssignment')} to ${t('hasNoWarranty')}`}
                                                        </>
                                                    )}

                                                    {String(ST_OLD_DATA_ALT?.guarantee) == "0" && (
                                                        <>
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "1" && `Guarantee Change  from ${t('hasNoWarranty')} to ${t('guarantor')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "2" && `Guarantee Change  from ${t('hasNoWarranty')} to ${t('securityDeposit')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "3" && `Guarantee Change  from ${t('hasNoWarranty')} to ${t('suretyBond')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "4" && `Guarantee Change  from ${t('hasNoWarranty')} to ${t('capitalizationBond')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "5" && `Guarantee Change  from ${t('hasNoWarranty')} to ${t('surety')}`}
                                                            {String(ST_NEW_DATA_ALT?.guarantee) == "6" && `Guarantee Change  from ${t('hasNoWarranty')} to ${t('fiduciaryAssignment')}`}

                                                        </>
                                                    )}

                                                </td>
                                                {/* <td>Billing Address Change  from {ST_OLD_DATA_ALT?.billing_address} to {ST_NEW_DATA_ALT?.billing_address} </td> */}
                                            </tr>
                                        }


                                        {
                                            (ST_OLD_DATA_ALT?.guarantee2 !== "") &&
                                            (ST_NEW_DATA_ALT?.guarantee2 !== "") &&
                                            (String(ST_OLD_DATA_ALT?.guarantee2) !== String(ST_NEW_DATA_ALT?.guarantee2)) &&
                                            <tr>
                                                <td>
                                                    {String(ST_OLD_DATA_ALT?.guarantee2) == "1" &&
                                                        String(ST_NEW_DATA_ALT?.guarantee2) == "-1" &&
                                                        `Guaranteed Transfer Change  from ${t('guaranteeFewMonths')} to ${t('guaranteeContract')}`}
                                                    {String(ST_OLD_DATA_ALT?.guarantee2) == "-1" &&
                                                        String(ST_NEW_DATA_ALT?.guarantee2) == "1" &&
                                                        `Guaranteed Transfer Change  from ${t('guaranteeContract')} to ${t('guaranteeFewMonths')} `}
                                                    {String(ST_OLD_DATA_ALT?.guarantee2) == "1" &&
                                                        String(ST_NEW_DATA_ALT?.guarantee2) == "0" &&
                                                        `Guaranteed Transfer Change  from ${t('guaranteeFewMonths')} to ${t('doesNotHave')} `}
                                                    {String(ST_OLD_DATA_ALT?.guarantee2) == "0" &&
                                                        String(ST_NEW_DATA_ALT?.guarantee2) == "1" &&
                                                        `Guaranteed Transfer Change  from ${t('doesNotHave')} to ${t('guaranteeFewMonths')} `}
                                                    {String(ST_OLD_DATA_ALT?.guarantee2) == "-1" &&
                                                        String(ST_NEW_DATA_ALT?.guarantee2) == "0" &&
                                                        `Guaranteed Transfer Change  from ${t('guaranteeContract')} to ${t('doesNotHave')} `}
                                                    {String(ST_OLD_DATA_ALT?.guarantee2) == "0" &&
                                                        String(ST_NEW_DATA_ALT?.guarantee2) == "-1" &&
                                                        `Guaranteed Transfer Change  from ${t('doesNotHave')} to ${t('guaranteeContract')} `}
                                                </td>
                                            </tr>
                                        }

                                        {
                                            (ST_OLD_DATA_ALT?.installmentValue && ST_NEW_DATA_ALT?.installmentValue &&
                                                (ST_OLD_DATA_ALT?.installmentValue !== ST_NEW_DATA_ALT?.installmentValue)) &&
                                            <tr>
                                                <td> Fire Insurance Installment Value Change  from R$ {ST_OLD_DATA_ALT?.installmentValue} to R$ {ST_NEW_DATA_ALT?.installmentValue} </td>
                                            </tr>
                                        }

                                        {
                                            (ST_OLD_DATA_ALT?.numberInstallments2 && ST_NEW_DATA_ALT?.numberInstallments2
                                                && (ST_OLD_DATA_ALT?.numberInstallments2 !== ST_NEW_DATA_ALT?.numberInstallments2)) &&
                                            <tr>
                                                <td>No of Installments of Fire Insurance  changed from {ST_OLD_DATA_ALT?.numberInstallments2} to {ST_NEW_DATA_ALT?.numberInstallments2} </td>
                                            </tr>

                                        }

                                        {
                                            (ST_OLD_DATA_ALT?.prizeAmount && ST_NEW_DATA_ALT?.prizeAmount &&
                                                (ST_OLD_DATA_ALT?.prizeAmount !== ST_NEW_DATA_ALT?.prizeAmount)) &&
                                            <tr>
                                                <td>Insurance Premium Amount  changed from R$ {ST_OLD_DATA_ALT?.prizeAmount} to R$ {ST_NEW_DATA_ALT?.prizeAmount} </td>
                                            </tr>

                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                        :
                        ""
                }
            </>)
    }

    const jsonData = selectionData;
    // Extracting unique values for first, second, and third dropdowns
    const firstOptions: any = Array.from(new Set(jsonData.map(item => item.ST_NOME_TEH)));
    const secondOptionsData: any = {};
    const thirdOptionsData: any = {};

    jsonData.forEach(item => {
        secondOptionsData[item.ST_NOME_TEH] = Array.from(new Set(jsonData.filter(data => data.ST_NOME_TEH === item.ST_NOME_TEH).map(data => ({
            value: data.ID_MODELO_MOH,
            label: data.ST_NOME_MOH,
            templateData: data.ST_TEXTO_MOH,
            templateIdentifier: data.ST_IDENTIFICADOR_MOH
        }))));
        thirdOptionsData[item.ID_MODELO_MOH] = Array.from(new Set(jsonData.filter(data => data.ID_MODELO_MOH === item.ID_MODELO_MOH).map(data => ({
            value: data.ST_IDENTIFICADOR_MOH,
            label: data.ST_NOME_MOH
        }))));
    });

    // Function to handle change in the first dropdown
    const handleFirstChange = (event) => {
        const selectedValue = event.target.value;
        setFirstSelection(selectedValue);
        // Clear selections in second and third dropdowns
        setSecondSelection('');
        setThirdSelection('');
    };

    // Function to handle change in the second dropdown
    const handleSecondChange = (event, optionsData) => {
        const selectedValue = event.target.value;
        let templateData = optionsData;
        let obj = templateData.find(obj => obj.value == selectedValue);
        setIdentifier(obj.templateIdentifier);
        setTemplateName(obj.label)
        setDesc(obj.templateData);
        setSecondSelection(selectedValue);
        setThirdSelection('');
    };

    // Function to handle change in the third dropdown
    const handleThirdChange = (event) => {
        const selectedValue = event.target.value;
        setThirdSelection(selectedValue);
    };

    const handleEditorChange = (event: any, editor: ClassicEditor) => {
        const data = editor.getData();
        setDesc(data);
        setValue("description", desc);
    };

    const handleEditorReady = (editor: any) => {
        const data = editor.getData();
        setDesc(data);
        setValue("description", desc);
    }

    return (
        <section className="content-wrapper DD223DDDDDDDDDDDDDDD">
            {/* <!-- Breadcrumb section start from here --> */}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="gx-2 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 4, order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                                {
                                    (id && id !== "" && id !== null) ?
                                        <Link to={`/contract_details/${id}`}><img src="assets/images/icons/arrow-left.svg" className="h-14" alt="Arrow left" /></Link>
                                        :
                                        ""
                                }

                                {
                                    (location && location?.state &&
                                        location?.state?.folder_id &&
                                        location?.state?.folder_id !== null &&
                                        location?.state?.folder_id !== undefined &&
                                        location?.state?.folder_id !== "") &&
                                    <Link to={`/folder_listing/${location?.state?.folder_id}`}><img src="assets/images/icons/arrow-left.svg" className="h-14" alt="Arrow left" /></Link>
                                }

                                <div className="w-100 overflow-hidden ms-2 ms-xl-3">
                                    {
                                        (id != 'undefined' && id !== "") ?
                                            <h1 className="mb-0 h4 lh-base text-truncate">{t("DocumentTemplate.newAmendmant")}</h1>
                                            :
                                            <h1 className="mb-0 h4 lh-base text-truncate">{t("DocumentTemplate.newTemplate")}</h1>
                                    }
                                </div>
                            </div>
                        </Col>
                        {/* <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                            <InputGroup>
                                <Form.Control placeholder={t("formLabel.typeSearch")} aria-label="Search" aria-describedby="search_icon"
                                />
                                <InputGroup.Text id="search_icon">
                                    <img src="assets/images/icons/search.svg" alt="Search icon" />
                                </InputGroup.Text>
                            </InputGroup>
                        </Col> */}
                        <Col xs={{ order: 1 }} xl={{ span: 5, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary cursor-pe">
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("DocumentTemplate.changesSavedAutomatically")}</Tooltip>}>
                                        <img src="../assets/images/icons/cloud-arrow.svg" alt="cloud icon" className="h-20" />
                                    </OverlayTrigger>
                                </li> */}
                                {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary cursor-pe" onClick={editFolderModalShow}>
                                    <img src="../assets/images/icons/edit-primary.svg" alt="edit" className="h-16 me-2" />{t("button.edit")}
                                </li> */}

                                {
                                    (id != 'undefined' && id !== "") ?
                                        <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary cursor-pe" onClick={createElectronicEnvelope}>
                                            {t("create")} {" "} {t("electronicEnvelope")}
                                        </li>
                                        :
                                        ""
                                }

                                {
                                    (id != 'undefined' && id !== "") ?
                                        <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary cursor-pe" onClick={downloadPDF}>
                                            <img src="assets/images/icons/share.svg" alt="share" className="h-18 me-2" />{t("DocumentTemplate.share")}
                                        </li>
                                        :
                                        ""
                                }

                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary cursor-pe" onClick={downloadPDF}>
                                    <img src="assets/images/icons/print-light.svg" alt="print" className="h-18 me-2" />{t("DocumentTemplate.print")}
                                </li>

                                {
                                    (id != 'undefined' && id !== "") ?
                                        <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary cursor-pe" onClick={handleClick}>
                                            <img src="assets/images/icons/send-dark.svg" alt="send" className="h-18 me-2 cursor-pe" />
                                            {t("formLabel.sendEmail")}
                                        </li>
                                        :
                                        ""
                                }

                                {
                                    (id != 'undefined' && id !== "") ?
                                        <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary cursor-pe" onClick={handleDeleteAmendmant}>
                                            <img src="assets/images/icons/delete-light.svg" alt="delete" className="h-18 me-2 cursor-pe" />
                                            {t('button.delete')}
                                        </li>
                                        :
                                        ""
                                }

                                {/* {
                                    (id == '' && functionState === 0) ?
                                    <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary cursor-pe" onClick={handleSaveTemplate}>
                                        {t('button.save')}
                                    </li>
                                    :
                                    (id == '' && functionState !== 0) ?
                                    <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary cursor-pe" onClick={handleEditClick}>
                                        {t('button.edit')}
                                    </li>
                                    :
                                    ""
                                } */}

                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 fw-bold text-primary cursor-pe" onClick={handleSaveTemplate}>
                                    {t('button.save')}
                                </li>

                                {/* <li className="list-inline-item mb-md-0 mb-1">
                                    <img src="../assets/images/icons/question_icon.svg" alt="Help" className="h-20" />
                                </li> */}
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                <Form onSubmit={handleSubmit(formSubmit)}>
                    <Row>
                        <Col md={5}>
                            <Card className="mb-3">
                                <Card.Header>{t("formLabel.settings")}</Card.Header>
                                <Card.Body>
                                    {id && id != 'undefined' && id !== "" ?
                                        <>
                                            {/* Amendmant fields */}
                                            <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.amendmantName")}>
                                                <Form.Control type="text" placeholder={t("DocumentTemplate.amendmantName")} {...register('templateName')} />
                                            </FloatingLabel>
                                            <span className="fs-12 d-block text-secondary mt-1 ms-3 mb-3">{t("DocumentTemplate.amendmantIdentificationSystem")}</span>
                                            <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.amendmantTitle")} >
                                                <Form.Control type="text" placeholder={t("DocumentTemplate.amendmantTitle")} {...register('title')} />
                                            </FloatingLabel>
                                            <span className="fs-12 d-block text-secondary mt-1 ms-3 mb-3">{t("DocumentTemplate.titleAappearHeader")}</span>
                                            <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.amendmantSubTitle")} >
                                                <Form.Control type="text" placeholder={t("DocumentTemplate.amendmantSubTitle")} {...register('subtitle')} />
                                            </FloatingLabel>
                                            <span className="fs-12 d-block text-secondary mt-1 ms-3 mb-3">{t("DocumentTemplate.subtitleAppearHeader")}</span>

                                        </>
                                        :
                                        <>
                                            <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.templateName")}>
                                                <Form.Control type="text" placeholder={t("DocumentTemplate.templateName")} {...register('templateName')} />
                                            </FloatingLabel>
                                            <span className="fs-12 d-block text-secondary mt-1 ms-3 mb-3">{t("DocumentTemplate.templateIdentificationSystem")}</span>
                                            <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.documentTitle")} >
                                                <Form.Control type="text" placeholder={t("DocumentTemplate.documentTitle")} {...register('title')} />
                                            </FloatingLabel>
                                            <span className="fs-12 d-block text-secondary mt-1 ms-3 mb-3">{t("DocumentTemplate.titleAappearHeader")}</span>
                                            <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.documentSubtitle")} >
                                                <Form.Control type="text" placeholder={t("DocumentTemplate.documentSubtitle")} {...register('subtitle')} />
                                            </FloatingLabel>
                                            <span className="fs-12 d-block text-secondary mt-1 ms-3 mb-3">{t("DocumentTemplate.subtitleAppearHeader")}</span>
                                        </>}

                                    {
                                        (!id || id == null || id == "undefined" || id == "") ?
                                            (location.state && location.state.template_id &&
                                                location.state.template_id !== "" && location.state.template_id !== null) ?
                                                <>
                                                    <br />
                                                    <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.identifier")}>
                                                        <Form.Select {...register('documentIdentifier')} onChange={handleIdentifierChange} disabled>
                                                            <option value="">{t('dropdownItem.select')}</option>
                                                            {options.map((item) => (
                                                                <option key={item.value} value={item.value}>{item.label}</option>
                                                            ))}
                                                            {/* Add other options as needed */}
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                    <span className="fs-12 d-block text-secondary mt-1 ms-3 mb-3">{t("DocumentTemplate.identifier")}</span>
                                                </>
                                                :
                                                <>
                                                    <br />
                                                    <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.identifier")}>
                                                        <Form.Select {...register('documentIdentifier')} onChange={handleIdentifierChange}>
                                                            <option value="">{t('dropdownItem.select')}</option>
                                                            {options.map((item) => (
                                                                <option key={item.value} value={item.value}>{item.label}</option>
                                                            ))}
                                                            {/* Add other options as needed */}
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                    <span className="fs-12 d-block text-secondary mt-1 ms-3 mb-3">{t("DocumentTemplate.identifier")}</span>
                                                </>
                                            :
                                            ""
                                    }
                                    <Form.Group controlId="logoHeader" className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
                                        <Form.Check type="checkbox" label={t("DocumentTemplate.showLogoHeader")} />
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                            {/*
                            <Card className="mb-3">
                                <Card.Header>Preselected Templates</Card.Header>
                                <Card.Body>
                                    <FloatingLabel className="mb-3" label={t("formLabel.selectTemplate")}>
                                        <Form.Select className="mh-336 overflow-auto" aria-label="select_template" onChange={HandleModule}>
                                            <option value="">...Select</option>
                                            {emailTemplateLst && emailTemplateLst.length > 0 ?
                                                emailTemplateLst.map((curElem: any) => (
                                                    <option value={curElem.ID_MODELO_MOH}>{curElem.ST_NOME_MOH}</option>
                                                ))
                                                :
                                                ""
                                            }

                                        </Form.Select>
                                    </FloatingLabel>
                                </Card.Body>
                            </Card>
                         */}
                            <AdmantBody getAmendmant={getAmendmant} />
                            <Card className="mb-3">
                                <Card.Header className="d-flex align-items-center justify-content-between">
                                    {id && id != 'undefined' && id !== "" ?
                                        <>
                                            {t("formLabel.preselectedTemplates")}
                                        </>
                                        :
                                        <>
                                            {t("formLabel.variables")}
                                        </>
                                    }
                                    <OverlayTrigger placement="right" overlay={<Tooltip>{t("DocumentTemplate.variablesContent")}</Tooltip>}>
                                        <img src="assets/images/icons/info-dark.svg" alt="alert" />
                                    </OverlayTrigger>
                                </Card.Header>
                                <Card.Body>
                                    <InputGroup>
                                        <Form.Floating ref={inputRef} >
                                            <Form.Control
                                                type="text"
                                                placeholder="Type to search..."
                                                onChange={(e) => handlefilterSearchVariable(e)}
                                                onFocus={handleInputFocus}
                                                autoComplete="off"
                                            />
                                            {isInputFocused && (
                                                <ListGroup className="shadow position-absolute z-index-5 overflow-auto end-0 start-0 mt-1" style={{ maxHeight: "260px" }}   >
                                                    {searchResults?.slice(0, 10).reverse().map((item: any, index) => (
                                                        <ListGroup.Item className="px-3 cursor-pe" key={index} onClick={(e) => HandleVariableSearch(e, item[0])}>
                                                            {item[0]}
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>
                                            )}
                                            <Form.Label htmlFor="floatingInputValue">{t("DocumentTemplate.searchVariable")}</Form.Label >
                                        </Form.Floating>
                                        <InputGroup.Text id="search_icon">
                                            <img src="assets/images/icons/search.svg" alt="Search icon" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                    {jsonData && jsonData.length > 0 ?
                                        <>
                                            <Row className="gy-3 gy-lg-4">
                                                <Col md={6}>
                                                    {/* First dropdown */}
                                                    <FloatingLabel controlId="firstSelect" label={t('selectFolder')} className='mt-3'>
                                                        <Form.Select className={`form-control ${errors.firstSelectionOption ? 'is-invalid' : ''}`} {...register('firstSelectionOption')} aria-label="firstSelectionOption" value={firstSelection} onChange={handleFirstChange} >
                                                            <option value="">Select</option>
                                                            {firstOptions.map((option, index) => (
                                                                <option key={index} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">{(errors.firstSelectionOption?.message as string)}</Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Col>

                                                <Col md={6}>
                                                    {/* Second dropdown */}
                                                    {firstSelection && (
                                                        <FloatingLabel controlId="secondSelect" label={t('selectTemplate')} className='mt-3'>
                                                            <Form.Select className={`form-control ${errors.secondSelectionOption ? 'is-invalid' : ''}`} {...register('secondSelectionOption')} aria-label="secondSelectionOption" value={secondSelection} onChange={(e) => { handleSecondChange(e, secondOptionsData[firstSelection]) }}>
                                                                <option value="">Select</option>
                                                                {secondOptionsData[firstSelection].map((option, index) => (
                                                                    <option key={index} value={option.value}>
                                                                        {option.label}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">{(errors.secondSelectionOption?.message as string)}</Form.Control.Feedback>
                                                        </FloatingLabel>
                                                    )}
                                                </Col>

                                                <Col md={6}>
                                                    {/* Third dropdown */}
                                                    {identifier && (identifier == "Contract") ?
                                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                            <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                                onChange={(e) => {
                                                                    let selectedVariable = e.target.value;
                                                                    selectedVariable = selectedVariable.toLowerCase()
                                                                    if (editorRef.current) {
                                                                        const editor = editorRef.current.editor;
                                                                        editor.execute('insertText', { text: `%${selectedVariable}%` });
                                                                    }
                                                                }}>
                                                                <option value="">{t('dropdownItem.select')}</option>
                                                                {contractVariableData && Object.keys(contractVariableData).length > 0
                                                                    ? Object.keys(contractVariableData).map(key => (
                                                                        <option key={key} value={contractVariableData[key]}>
                                                                            {key}
                                                                        </option>
                                                                    ))
                                                                    : <option disabled>{t("dimob.NoDataFound")}</option>
                                                                }
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                        :
                                                        ""}

                                                    {identifier && (identifier == "Property") ?
                                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                            <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                                onChange={(e) => {
                                                                    let selectedVariable = e.target.value;
                                                                    selectedVariable = selectedVariable.toLowerCase()
                                                                    if (editorRef.current) {
                                                                        const editor = editorRef.current.editor;
                                                                        editor.execute('insertText', { text: `%${selectedVariable}%` });
                                                                    }
                                                                }}>
                                                                <option value="">{t('dropdownItem.select')}</option>
                                                                {propertyVariableData && Object.keys(propertyVariableData).length > 0
                                                                    ? Object.keys(propertyVariableData).map(key => (
                                                                        <option key={key} value={propertyVariableData[key]}>
                                                                            {key}
                                                                        </option>
                                                                    ))
                                                                    : <option disabled>{t("dimob.NoDataFound")}</option>
                                                                }
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                        :
                                                        ""
                                                    }
                                                </Col>
                                                {/* <Col md={6}>
                                                            {secondSelection && (
                                                                <FloatingLabel controlId="thirdSelect" label="Third Dropdown">
                                                                    <Form.Select className={`form-control ${errors.templateSelection ? 'is-invalid' : ''}`} {...register('templateSelection')} aria-label="templateSelection" value={thirdSelection} onChange={handleThirdChange}>
                                                                        <option value="">Select</option>
                                                                        {thirdOptionsData[secondSelection] && thirdOptionsData[secondSelection].map((option, index) => (
                                                                            <option key={index} value={option.value}>
                                                                                {option.value}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>
                                                                    <Form.Control.Feedback type="invalid">{(errors.templateSelection?.message as string)}</Form.Control.Feedback>
                                                                </FloatingLabel>
                                                            )}
                                                        </Col> */}
                                            </Row>
                                        </>
                                        :
                                        ""
                                    }
                                </Card.Body>
                            </Card>

                            {
                                ((id != 'undefined' && id !== "") || allVariablesOnContractSelect) ?
                                    <>
                                        {/* <ul className="list-inline mb-0">
                                                    <li className="list-inline-item mb-lg-3 mb-2">
                                                        <Badge className="bg-white border text-dark-70 rounded-4">Beneficiary's name</Badge>
                                                    </li>
                                                    <li className="list-inline-item mb-lg-3 mb-2">
                                                        <Badge className="bg-white border text-dark-70 rounded-4">Owner's name</Badge>
                                                    </li>
                                                    <li className="list-inline-item mb-lg-3 mb-2">
                                                        <Badge className="bg-white border text-dark-70 rounded-4">Owner's business name</Badge>
                                                    </li>
                                                    <li className="list-inline-item mb-lg-3 mb-2">
                                                        <Badge className="bg-white border text-dark-70 rounded-4">Owner's spouse name</Badge>
                                                    </li>
                                                    <li className="list-inline-item mb-lg-3 mb-2">
                                                        <Badge className="bg-white border text-dark-70 rounded-4">Tenant's name</Badge>
                                                    </li>
                                                    <li className="list-inline-item mb-lg-3 mb-2">
                                                        <Badge className="bg-white border text-dark-70 rounded-4">Tenant's business name</Badge>
                                                    </li>
                                                    <li className="list-inline-item mb-lg-3 mb-2">
                                                        <Badge className="bg-white border text-dark-70 rounded-4">Tenant's spouse name</Badge>
                                                    </li>
                                                    <li className="list-inline-item mb-lg-3 mb-2">
                                                        <Badge className="bg-white border text-dark-70 rounded-4">Guarantor's name</Badge>
                                                    </li>
                                                    <li className="list-inline-item mb-lg-3 mb-2">
                                                        <Badge className="bg-white border text-dark-70 rounded-4">Guarantor's spouse name</Badge>
                                                    </li>
                                                    <li className="list-inline-item mb-lg-3 mb-2">
                                                        <Badge className="bg-white border text-dark-70 rounded-4">Real estate agency name</Badge>
                                                    </li>
                                                    <li className="list-inline-item mb-lg-3 mb-2">
                                                        <Badge className="bg-white border text-dark-70 rounded-4">Real estate agency's business name</Badge>
                                                    </li>
                                                    <li className="list-inline-item mb-lg-3 mb-2">
                                                        <Badge className="bg-white border text-dark-70 rounded-4">Manager's name</Badge>
                                                    </li>
                                                </ul> */}

                                        <Accordion>
                                            <Accordion.Item eventKey="0" className="mb-3">
                                                <Accordion.Header>{t("DocumentTemplate.propertyOwnerVariables")}</Accordion.Header>
                                                <Accordion.Body>
                                                    <InputGroup className="mb-3">
                                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                            <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                                onChange={(e) => {
                                                                    let selectedVariable = e.target.value;
                                                                    selectedVariable = selectedVariable.toLowerCase()
                                                                    if (editorRef.current) {
                                                                        const editor = editorRef.current.editor;
                                                                        editor.execute('insertText', { text: `%${selectedVariable}%` });
                                                                    }
                                                                }}>
                                                                <option value="">{t('dropdownItem.select')}</option>
                                                                {ownerVariableData && Object.keys(ownerVariableData).length > 0
                                                                    ? Object.keys(ownerVariableData).map(key => (
                                                                        <option key={key} value={ownerVariableData[key]}>
                                                                            {key}
                                                                        </option>
                                                                    ))
                                                                    : <option disabled>{t("dimob.NoDataFound")}</option>
                                                                }
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                        <InputGroup.Text id="search_icon">
                                                            {/* <img src="assets/images/icons/search.svg" alt="Search icon" /> */}
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1" className="mb-3">
                                                <Accordion.Header>{t("DocumentTemplate.propertyVariables")}</Accordion.Header>
                                                <Accordion.Body>
                                                    <InputGroup className="mb-3">
                                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                            <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                                onChange={(e) => {
                                                                    let selectedVariable = e.target.value;
                                                                    selectedVariable = selectedVariable.toLowerCase()
                                                                    if (editorRef.current) {
                                                                        const editor = editorRef.current.editor;
                                                                        editor.execute('insertText', { text: `%${selectedVariable}%` });
                                                                    }
                                                                }}>
                                                                <option value="">{t('dropdownItem.select')}</option>
                                                                {propertyVariableData && Object.keys(propertyVariableData).length > 0
                                                                    ? Object.keys(propertyVariableData).map(key => (
                                                                        <option key={key} value={propertyVariableData[key]}>
                                                                            {key}
                                                                        </option>
                                                                    ))
                                                                    : <option disabled>{t("dimob.NoDataFound")}</option>
                                                                }
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                        <InputGroup.Text id="search_icon">
                                                            {/* <img src="assets/images/icons/search.svg" alt="Search icon" /> */}
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2" className="mb-3">
                                                <Accordion.Header>{t("DocumentTemplate.tenantVariables")}</Accordion.Header>
                                                <Accordion.Body>
                                                    <InputGroup className="mb-3">
                                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                            <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                                onChange={(e) => {
                                                                    let selectedVariable = e.target.value;
                                                                    selectedVariable = selectedVariable.toLowerCase()
                                                                    if (editorRef.current) {
                                                                        const editor = editorRef.current.editor;
                                                                        editor.execute('insertText', { text: `%${selectedVariable}%` });
                                                                    }
                                                                }}>
                                                                <option value="">{t('dropdownItem.select')}</option>
                                                                {tenantVariableData && Object.keys(tenantVariableData).length > 0
                                                                    ? Object.keys(tenantVariableData).map(key => (
                                                                        <option key={key} value={tenantVariableData[key]}>
                                                                            {key}
                                                                        </option>
                                                                    ))
                                                                    : <option disabled>{t("dimob.NoDataFound")}</option>
                                                                }
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                        <InputGroup.Text id="search_icon">
                                                            {/* <img src="assets/images/icons/search.svg" alt="Search icon" /> */}
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3" className="mb-3">
                                                <Accordion.Header>{t("DocumentTemplate.contractVariables")}</Accordion.Header>
                                                <Accordion.Body>
                                                    <InputGroup className="mb-3">
                                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                            <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                                onChange={(e) => {
                                                                    let selectedVariable = e.target.value;
                                                                    selectedVariable = selectedVariable.toLowerCase()
                                                                    if (editorRef.current) {
                                                                        const editor = editorRef.current.editor;
                                                                        editor.execute('insertText', { text: `%${selectedVariable}%` });
                                                                    }
                                                                }}>
                                                                <option value="">{t('dropdownItem.select')}</option>
                                                                {contractVariableData && Object.keys(contractVariableData).length > 0
                                                                    ? Object.keys(contractVariableData).map(key => (
                                                                        <option key={key} value={contractVariableData[key]}>
                                                                            {key}
                                                                        </option>
                                                                    ))
                                                                    : <option disabled>{t("dimob.NoDataFound")}</option>
                                                                }
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                        <InputGroup.Text id="search_icon">
                                                            {/* <img src="assets/images/icons/search.svg" alt="Search icon" /> */}
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4" className="mb-3">
                                                <Accordion.Header>{t("DocumentTemplate.dateVariables")}</Accordion.Header>
                                                <Accordion.Body>
                                                    <InputGroup className="mb-3">
                                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                            <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                                onChange={(e) => {
                                                                    let selectedVariable = e.target.value;
                                                                    selectedVariable = selectedVariable.toLowerCase()
                                                                    if (editorRef.current) {
                                                                        const editor = editorRef.current.editor;
                                                                        editor.execute('insertText', { text: `%${selectedVariable}%` });
                                                                    }
                                                                }}>
                                                                <option value="">{t('dropdownItem.select')}</option>
                                                                {dateVariableData && Object.keys(dateVariableData).length > 0
                                                                    ? Object.keys(dateVariableData).map(key => (
                                                                        <option key={key} value={dateVariableData[key]}>
                                                                            {key}
                                                                        </option>
                                                                    ))
                                                                    : <option disabled>{t("dimob.NoDataFound")}</option>
                                                                }
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                        <InputGroup.Text id="search_icon">
                                                            {/* <img src="assets/images/icons/search.svg" alt="Search icon" /> */}
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            {/* <Accordion.Item eventKey="5" className="mb-3">
                                                        <Accordion.Header>{t("DocumentTemplate.guaranteeVariables")}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <InputGroup className="mb-3">
                                                                <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                                    <Form.Control placeholder={t("DocumentTemplate.searchVariable")} value="Name" />
                                                                </FloatingLabel>
                                                                <InputGroup.Text id="search_icon">
                                                                    <img src="assets/images/icons/search.svg" alt="Search icon" />
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        </Accordion.Body>
                                                    </Accordion.Item> */}
                                            {/* <Accordion.Item eventKey="6" className="mb-3">
                                                        <Accordion.Header>{t("DocumentTemplate.guarantorVariables")}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <InputGroup className="mb-3">
                                                                <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                                    <Form.Control placeholder={t("DocumentTemplate.searchVariable")} value="Name" />
                                                                </FloatingLabel>
                                                                <InputGroup.Text id="search_icon">
                                                                    <img src="assets/images/icons/search.svg" alt="Search icon" />
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        </Accordion.Body>
                                                    </Accordion.Item> */}
                                            {/* <Accordion.Item eventKey="7" className="mb-3">
                                                        <Accordion.Header>{t("DocumentTemplate.transferVariables")}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <InputGroup className="mb-3">
                                                                <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                                    <Form.Control placeholder={t("DocumentTemplate.searchVariable")} value="Name" />
                                                                </FloatingLabel>
                                                                <InputGroup.Text id="search_icon">
                                                                    <img src="assets/images/icons/search.svg" alt="Search icon" />
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        </Accordion.Body>
                                                    </Accordion.Item> */}
                                            <Accordion.Item eventKey="8" className="mb-3">
                                                <Accordion.Header>{t("DocumentTemplate.realStateAgencyVariables")}</Accordion.Header>
                                                <Accordion.Body>
                                                    <InputGroup className="mb-3">
                                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                            <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                                onChange={(e) => {
                                                                    let selectedVariable = e.target.value;
                                                                    selectedVariable = selectedVariable.toLowerCase()
                                                                    if (editorRef.current) {
                                                                        const editor = editorRef.current.editor;
                                                                        editor.execute('insertText', { text: `%${selectedVariable}%` });
                                                                    }
                                                                }}>
                                                                <option value="">{t('dropdownItem.select')}</option>
                                                                {realEstateVariableData && Object.keys(realEstateVariableData).length > 0
                                                                    ? Object.keys(realEstateVariableData).map(key => (
                                                                        <option key={key} value={realEstateVariableData[key]}>
                                                                            {key}
                                                                        </option>
                                                                    ))
                                                                    : <option disabled>{t("dimob.NoDataFound")}</option>
                                                                }
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                        <InputGroup.Text id="search_icon">
                                                            {/* <img src="assets/images/icons/search.svg" alt="Search icon" /> */}
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            {/* <Accordion.Item eventKey="9" className="mb-3">
                                                        <Accordion.Header>{t("DocumentTemplate.managerVariables")}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <InputGroup className="mb-3">
                                                                <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                                    <Form.Control placeholder={t("DocumentTemplate.searchVariable")} value="Name" />
                                                                </FloatingLabel>
                                                                <InputGroup.Text id="search_icon">
                                                                    <img src="assets/images/icons/search.svg" alt="Search icon" />
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        </Accordion.Body>
                                                    </Accordion.Item> */}
                                            {/* <Accordion.Item eventKey="10" className="mb-3">
                                                        <Accordion.Header>{t("DocumentTemplate.signaturesVariables")}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <InputGroup className="mb-3">
                                                                <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                                    <Form.Control placeholder={t("DocumentTemplate.searchVariable")} value="Name" />
                                                                </FloatingLabel>
                                                                <InputGroup.Text id="search_icon">
                                                                    <img src="assets/images/icons/search.svg" alt="Search icon" />
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="11" className="mb-3">
                                                        <Accordion.Header>{t("DocumentTemplate.otherVariables")}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <InputGroup className="mb-3">
                                                                <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                                    <Form.Control placeholder={t("DocumentTemplate.searchVariable")} value="Name" />
                                                                </FloatingLabel>
                                                                <InputGroup.Text id="search_icon">
                                                                    <img src="assets/images/icons/search.svg" alt="Search icon" />
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        </Accordion.Body>
                                                    </Accordion.Item> */}
                                        </Accordion>
                                    </>
                                    :
                                    ""
                            }

                            {
                                allVariablesOnPropertySelect ?
                                    <>
                                        <Accordion>
                                            <Accordion.Item eventKey="0" className="mb-3">
                                                <Accordion.Header>{t("DocumentTemplate.propertyOwnerVariables")}</Accordion.Header>
                                                <Accordion.Body>
                                                    <InputGroup className="mb-3">
                                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                            <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                                onChange={(e) => {
                                                                    let selectedVariable = e.target.value;
                                                                    selectedVariable = selectedVariable.toLowerCase()
                                                                    if (editorRef.current) {
                                                                        const editor = editorRef.current.editor;
                                                                        editor.execute('insertText', { text: `%${selectedVariable}%` });
                                                                    }
                                                                }}>
                                                                <option value="">{t('dropdownItem.select')}</option>
                                                                {ownerVariableData && Object.keys(ownerVariableData).length > 0
                                                                    ? Object.keys(ownerVariableData).map(key => (
                                                                        <option key={key} value={ownerVariableData[key]}>
                                                                            {key}
                                                                        </option>
                                                                    ))
                                                                    : <option disabled>{t("dimob.NoDataFound")}</option>
                                                                }
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                        <InputGroup.Text id="search_icon">
                                                            {/* <img src="assets/images/icons/search.svg" alt="Search icon" /> */}
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1" className="mb-3">
                                                <Accordion.Header>{t("DocumentTemplate.propertyVariables")}</Accordion.Header>
                                                <Accordion.Body>
                                                    <InputGroup className="mb-3">
                                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                            <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                                onChange={(e) => {
                                                                    let selectedVariable = e.target.value;
                                                                    selectedVariable = selectedVariable.toLowerCase()
                                                                    if (editorRef.current) {
                                                                        const editor = editorRef.current.editor;
                                                                        editor.execute('insertText', { text: `%${selectedVariable}%` });
                                                                    }
                                                                }}>
                                                                <option value="">{t('dropdownItem.select')}</option>
                                                                {propertyVariableData && Object.keys(propertyVariableData).length > 0
                                                                    ? Object.keys(propertyVariableData).map(key => (
                                                                        <option key={key} value={propertyVariableData[key]}>
                                                                            {key}
                                                                        </option>
                                                                    ))
                                                                    : <option disabled>{t("dimob.NoDataFound")}</option>
                                                                }
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                        <InputGroup.Text id="search_icon">
                                                            {/* <img src="assets/images/icons/search.svg" alt="Search icon" /> */}
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4" className="mb-3">
                                                <Accordion.Header>{t("DocumentTemplate.dateVariables")}</Accordion.Header>
                                                <Accordion.Body>
                                                    <InputGroup className="mb-3">
                                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>

                                                            <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                                onChange={(e) => {
                                                                    let selectedVariable = e.target.value;
                                                                    selectedVariable = selectedVariable.toLowerCase()
                                                                    if (editorRef.current) {
                                                                        const editor = editorRef.current.editor;
                                                                        editor.execute('insertText', { text: `%${selectedVariable}%` });
                                                                    }
                                                                }}>
                                                                <option value="">{t('dropdownItem.select')}</option>
                                                                {dateVariableData && Object.keys(dateVariableData).length > 0
                                                                    ? Object.keys(dateVariableData).map(key => (
                                                                        <option key={key} value={dateVariableData[key]}>
                                                                            {key}
                                                                        </option>
                                                                    ))
                                                                    : <option disabled>{t("dimob.NoDataFound")}</option>
                                                                }
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                        <InputGroup.Text id="search_icon">
                                                            {/* <img src="assets/images/icons/search.svg" alt="Search icon" /> */}
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="8" className="mb-3">
                                                <Accordion.Header>{t("DocumentTemplate.realStateAgencyVariables")}</Accordion.Header>
                                                <Accordion.Body>
                                                    <InputGroup className="mb-3">
                                                        <FloatingLabel controlId="floatingSelect" label={t("DocumentTemplate.searchVariable")}>
                                                            <Form.Select className="mh-336 overflow-auto" aria-label="select_template"
                                                                onChange={(e) => {
                                                                    let selectedVariable = e.target.value;
                                                                    selectedVariable = selectedVariable.toLowerCase()
                                                                    if (editorRef.current) {
                                                                        const editor = editorRef.current.editor;
                                                                        editor.execute('insertText', { text: `%${selectedVariable}%` });
                                                                    }
                                                                }}>
                                                                <option value="">{t('dropdownItem.select')}</option>
                                                                {realEstateVariableData && Object.keys(realEstateVariableData).length > 0
                                                                    ? Object.keys(realEstateVariableData).map(key => (
                                                                        <option key={key} value={realEstateVariableData[key]}>
                                                                            {key}
                                                                        </option>
                                                                    ))
                                                                    : <option disabled>{t("dimob.NoDataFound")}</option>
                                                                }
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                        <InputGroup.Text id="search_icon">
                                                            {/* <img src="assets/images/icons/search.svg" alt="Search icon" /> */}
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </>
                                    :
                                    ""
                            }
                        </Col>
                        <Col md={7} className="editor-h">
                            <Form.Control type="hidden" placeholder="documentText"  {...register('description')} />
                            <CKEditor
                                editor={ClassicEditor}
                                data={desc}
                                onReady={handleEditorReady}
                                onChange={handleEditorChange}
                                ref={editorRef}
                                onBlur={handleEditorChange}
                                onFocus={handleEditorChange}
                            />
                        </Col>
                    </Row>
                    <Button style={{ display: "none" }} type="submit" ref={buttonRef}>Send Email</Button>
                </Form>
            </main>
            {/* <!-- /Main content start end --> */}
            {/* modal added */}

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Create a copy or edit the current template</Modal.Title>
                </Modal.Header>
                <Modal.Body>If you choose to edit the current template, the changes will be permanent, and you wont be able to restore the orignal version</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={handleClose}>
                        Edit Current Template
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Create A Copy
                    </Button>
                </Modal.Footer>
            </Modal>

            <ElectronicEnvelopeService />
            <Share showShare={showShareModal} shareClose={shareModalClose} />
            <EditFolder showEditFolder={showEditFolderModal} editFolderClose={editFolderModalClose} />
            <PrintFolderDetail showPrintDetail={showPrintDetailModal} printDetailClose={printDetailModalClose} />
        </section >
    )
}

export default FolderDetail

