import { Card, Dropdown, Table, Button, Form, Modal, Row, Col, FloatingLabel, Accordion, InputGroup, Collapse, Badge } from 'react-bootstrap';
import React, { useState, MouseEvent, useEffect, useMemo } from 'react';
import { CustomersAndEmployeesForm } from '../../../Interface';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import customerService from '../../../../services/customerService';
import Checkbox from '../Common/Checkbox';
import Loader from '../../../Profile/Company/Loader';
import '../../../../App.css';
import { idText } from 'typescript';
import Pagination from '../../../Pagination';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../../../redux/store';
import { getAllProperty, openEmailPop, openNewOwner } from '../../../../redux/slice/contractDataSlice';
import { getAllBillMethodListing, openTypeCustomer1 } from '../../../../redux/slice/expenditureDataSlice';
import SendEmails from '../Common/SendEmails';
import NewOwner from './NewOwner';
import { CNJP_CPFValidation, encrypt_Decrypt } from '../../../constants/utils';
import MaillingLabel from '../Common/MaillingLabel';
const Owners_and_beneficiaries = (CustomersAndEmployeesForm) => {
    const dispatch = useDispatch<AppDispatch>()
    const { billingData } = useSelector((state: RootState) => state.expenditureData)

    useEffect(() => {
        dispatch(getAllBillMethodListing({ "start": 0, "end": 1000 }))
    }, [dispatch]);

    const navigate = useNavigate();
    const location = useLocation();
    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(2050) === -1) {
            navigate("/customer_and_employees");
        }
        setUserJson(userResult);
    }, [])

    const { proprtyData } = useSelector((state: RootState) => state.contractData)
    const [state, setState] = useState({
        property: "",
        status: "",
        branch: "",
        city: "",
        tenants: "",
        neighborhood: "",
        administrator: "",
        manager: "",
        owner_type: "",
        condominium: "",
        business_type: "",
        property_type: "",
        real_estate: "",
        start: 0,
        end: 1000
    })

    const [t] = useTranslation();
    const [status, setStatus] = useState(true);

    /*--------Mailing and Label permission state--------*/

    const [hide5, setHide5] = useState(false);
    const [mailText, setMailText] = useState("");
    const [legalAndPhysical, setLegalAndPhysical] = useState<any>('')
    const [legalOwner, setLegalOwner] = useState<any>('')
    const [show3, setShow3] = useState(false);

    /*--------Data table listing state & multiple checked--------*/

    const [data, setData] = useState<any[]>([])
    const [LegalData, setLegalData] = useState<any[]>([])
    const [total, setTotal] = useState(0);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState<any[]>([]);
    const [AllStates, setAllStates] = useState([]);
    const [sendMailData, setSendMailData] = useState<any>([])
    const [mailData, setMailData] = useState<any>([]);
    const [personId, setPersonId] = useState<any>([]);


    // Retrieve banklist, show1, and status from the Redux state
    const { banklist } = useSelector((state: RootState) => state.bank)

    const [PageSize, setPageSize] = useState(20);
    const [ownstate, setownState] = useState({
        owner: "",
        person: "",
        status: "",
        start: 0,
        end: PageSize
    })

    // Assuming your data import takes 10 seconds
    useEffect(() => {
        getAllOwner(ownstate);
        dispatch(getAllProperty(state));
        (async () => {
            let resp = await customerService.getAddressBasedOnZipcode(`states`);
            if (resp.data.status == "success" || resp.data.type == "Success") {
                let loop = resp.data.data;
                let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
                // Sort the stateData alphabetically by label
                stateData.sort((a, b) => a.label.localeCompare(b.label));
                setAllStates(stateData);
            }
        })()
    }, [])

    // Assuming your data import takes 10 seconds
    const dataImportDelay = 50000;

    const [count_2, setCount_2] = useState(0);
    const [count_3, setCount_3] = useState(0);
    const [count_4, setCount_4] = useState(0);
    const [count_5, setCount_5] = useState(0);
    const [count_6, setCount_6] = useState(0);
    const [count_7, setCount_7] = useState(0);
    const [count_8, setCount_8] = useState(0);
    const [count_9, setCount_9] = useState(0);
    const [count_10, setCount_10] = useState(0);
    const [forValue1, setForValue1] = useState<any[]>([]);
    const [forValue2, setForValue2] = useState<any[]>([]);
    const [forValue3, setForValue3] = useState<any[]>([]);
    const [forValue4, setForValue4] = useState<any[]>([]);
    const [forValue5, setForValue5] = useState<any[]>([]);
    const [forValue6, setForValue6] = useState<any[]>([]);
    const [forValue7, setForValue7] = useState<any[]>([]);
    const [forValue8, setForValue8] = useState<any[]>([]);
    const [forValue9, setForValue9] = useState<any[]>([]);
    const [forValue10, setForValue10] = useState<any[]>([]);


    useEffect(() => {
        let timeoutId;
        const processData = () => {
            if (proprtyData && proprtyData.data) {
                // Count the occurrences of ownerName
                let ownerCount = {};
                if (data && data.length) {
                    for (let i = 0; i < proprtyData.data.length; i++) {
                        for (let j = 0; j < proprtyData.data[i].ownerResult.length; j++) {
                            let ownerName = proprtyData.data[i].ownerResult[j].ownerName;
                            if (ownerCount.hasOwnProperty(ownerName)) {
                                ownerCount[ownerName] += 1;
                            } else {
                                ownerCount[ownerName] = 1;
                            }
                        }
                    }
                }

                // Count the occurrences of ownerName for specific counts
                let countOccurrences = {};
                for (let count = 2; count <= 10; count++) {
                    countOccurrences[count] = Object.values(ownerCount).filter(value => value === count).length;
                }

                // Store the counts in separate variables
                setCount_2(countOccurrences[2] || 0);
                setCount_3(countOccurrences[3] || 0);
                setCount_4(countOccurrences[4] || 0);
                setCount_5(countOccurrences[5] || 0);
                setCount_6(countOccurrences[6] || 0);
                setCount_7(countOccurrences[7] || 0);
                setCount_8(countOccurrences[8] || 0);
                setCount_9(countOccurrences[9] || 0);
                setCount_10(countOccurrences[10] || 0);
            } else {
                // console.log('Error: Data is not available');
            }
        };

        let ownerNameCounts = {};
        const processData2 = () => {
            if (proprtyData && proprtyData.data) {
                for (const item of proprtyData.data) {
                    const ownerName = item.ownerName;
                    ownerNameCounts[ownerName] = (ownerNameCounts[ownerName] || 0) + 1;
                }

                // Convert the object into an array of key-value pairs
                const dataArray: [string, number][] = Object.entries(ownerNameCounts);

                // Sort the array based on the values
                dataArray.sort((a, b) => b[1] - a[1]);

                // Group the sorted array by their common values using Sets
                const groupedData = dataArray.reduce((acc, [key, value]) => {
                    if (!acc.has(value)) {
                        acc.set(value, new Set());
                    }
                    acc.get(value).add(key);
                    return acc;
                }, new Map());

                setForValue1(groupedData.get(1));
                setForValue2(groupedData.get(2));
                setForValue3(groupedData.get(3));
                setForValue4(groupedData.get(4));
                setForValue5(groupedData.get(5));
                setForValue6(groupedData.get(6));
                setForValue7(groupedData.get(7));
                setForValue8(groupedData.get(8));
                setForValue9(groupedData.get(9));
                setForValue10(groupedData.get(10));
            }
        };

        // Check if proprtyData is available
        if (proprtyData && proprtyData.data) {
            processData();
            processData2();
        } else {
            // Data is not available, start a timeout to wait for it
            timeoutId = setTimeout(() => {
                processData();
                processData2();
            }, dataImportDelay);
        }

        // Cleanup function to clear the timeout
        return () => clearTimeout(timeoutId);
    }, [proprtyData]);



    // // Convert the object into an array of key-value pairs
    // const dataArray : [string, number][] = Object.entries(ownerPropCount);

    // // Sort the array based on the values
    // dataArray.sort((a, b) => b[1] - a[1]);

    // // Group the sorted array by their common values using Sets
    // const groupedData = dataArray.reduce((acc, [key, value]) => {
    // if (!acc.has(value)) {
    //     acc.set(value, new Set());
    // }
    // acc.get(value).add(key);
    // return acc;
    // }, new Map());

    // const setForValue1 = groupedData.get(1);

    /*--------Common modal function--------*/

    const formShow = () => {
        dispatch(openNewOwner(true))
    }
    const mailingShow = () => {
        let count: any = false;
        let data = document.querySelectorAll('.check_Checkbox input');
        data.forEach((item, index) => {
            if (item["checked"]) {
                count = true;
            }
        })
        if (count) {
            setShow3(true)

        } else {
            Swal.fire({
                title: t("Sweetalert.Warning"),
                text: t("Sweetalert.PleaseSelectOwners"),
                // icon: 'success',"warning",
                confirmButtonText: "OK",
                timer: 2000
            });
        }
    }
    const mailingClose = () => { setShow3(false); reset(); }
    const handleMouseEvent = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        let count: any = false;
        let data = document.querySelectorAll('.check_Checkbox input');

        data?.forEach((item, index) => {
            let mailData: any = []; // Initialize mailData array for each iteration
            if (item["checked"]) {
                count = true
                sendMailData.forEach((element: any) => {
                    if (element.id == item.id) {
                        mailData.push(element.email);
                        personId.push(element.id);
                    }
                });

                // Push the mailData array into the emailArray
                setMailData(prevEmailArray => [...prevEmailArray, ...mailData]);
                setPersonId(prevEmailArray => [...prevEmailArray, ...personId]);
            }
            // setMailData(emailArray)
        });

        if (count) {
            setMailText(e.currentTarget.name)
            if (e.currentTarget.name === "Send letter" || e.currentTarget.name === "Enviar carta") {
                setHide5(true);
            } else {
                setHide5(false);
            }
            // setShow2(true)
            dispatch(openEmailPop(true))
        } else {
            Swal.fire({
                title: t("Sweetalert.Warning"),
                text: t("Sweetalert.PleaseSelectOwners"),
                // icon: 'success',"warning",
                confirmButtonText: "OK",
                timer: 2000
            });
        }
    }

    const formValidationSchemaStep1 = Yup.object().shape({
        cnpj: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
    });
    let frm = {
        resolver: yupResolver(formValidationSchemaStep1)
    }
    const { register, handleSubmit, reset, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<CustomersAndEmployeesForm>(frm);


    /*-------------Mailing label form submit-------------*/

    const permissionSubmit = (data: CustomersAndEmployeesForm) => {
        let permissionData = {
            "administrative_access": data.administrative_access,
            "change_and_query": data.change_and_query,
            "closing_opening_month": data.closing_opening_month,
            "chart_accounts": data.chart_accounts,
            "settings_consultation": data.settings_consultation,
            "customers_notify": data.customers_notify,
            "collections_consultation": data.collections_consultation,
            "collections_amendment": data.collections_amendment,
            "collections_invalidate": data.collections_invalidate,
            "collections_settlement": data.collections_settlement,
            "collections_reverse": data.collections_reverse,
            "metrics_visualization": data.metrics_visualization,
            "box_consultation": data.box_consultation,
            "agreements_consultation": data.agreements_consultation,
            "monetary_amendment": data.monetary_amendment,
            "legal_consultation": data.legal_consultation,
            "invoices_consultation": data.invoices_consultation,
            "invoices_amendment": data.invoices_amendment,
            "services_consultation": data.services_consultation,
            "expenses_consultation": data.expenses_consultation
        }
    }

    /*-------Get the listing of owners andbeneficiaries--------*/

    const getAllOwner = (owner) => {
        if (owner["start"] == 1) {
            owner["start"] = 0;
        }
        let ownerData = { owner: owner.owner, start: owner.start, end: owner.end, status: owner.status, person: owner.person, page: 'ownersandbenef', type: "ownerlist" }
        setStatus(true);
        if (location.state?.["type"] == "legal_entities") {
            ownstate['pageSearch'] = 'ownerListingPage'
            ownstate['person'] = 'Legal'
            delete location.state;
            customerService.filterOwner(ownstate).then((res) => {
                setStatus(false);
                if (res.data.resCode === 201) {
                    setData(res?.data?.data || []);
                    setLegalData(res?.data?.data || []);
                    setSendMailData(res?.data?.data || [])
                    setTotal(res?.data?.totalRow || 0)
                } else {
                    setData([]);
                    setLegalData([]);
                    setSendMailData([])
                    setTotal(0)
                }
            }).catch(err => {
                setStatus(false);
            });
        } else {
            customerService.getAllOwner(ownerData).then(res => {
                setStatus(false);
                if (res.data.resCode === 201) {
                    setData(res?.data?.data || []);
                    setLegalData(res?.data?.data || []);
                    setSendMailData(res?.data?.data || [])
                    setTotal(res?.data?.totalRow || 0)
                } else {
                    setData([]);
                    setTotal(0)
                    setLegalData([]);
                    setSendMailData([])
                }
            }).catch(err => {
                setStatus(false);
            });
        }

    }

    /*-------Checked/Unchecked the multiple checkbox functionality--------*/

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(data.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    const noneHandleSelectAll = e => {
        setIsCheckAll(false)
        setIsCheck([]);
    };
    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }

        if (document.querySelectorAll('.check_Checkbox input').length > 0) {
            let data = document.querySelectorAll('.check_Checkbox input');
            let count_allCheck = 0;
            setTimeout(() => {
                data.forEach((item, index) => {
                    if (item["checked"]) {
                        count_allCheck++
                    }

                    if (!item["checked"]) {
                        setIsCheckAll(false);
                    }
                })

                if (data && data.length) {
                    if (count_allCheck == data.length) {
                        setIsCheckAll(true);
                    }
                }
            }, 10)

        }
    };
    const [firstLoad, setFirstload] = useState(false);
    // Pagination start
    const [currentPage, setCurrentPage] = useState(1);
    // Pagination End

    const PropertyFilter = (Property_count: number) => {
        let obj = {
            "count": Property_count
        }
        customerService.moreThenProperty(obj).then((res) => {
            if (res.status === 200) {
                setData(res?.data?.data || [])
            }
        })
    }

    let typingTimer;
    const ActiveContract = (evt) => {
        setFirstload(true);
        let value = evt.target.value;
        let name = evt.target.name;
        if (name == "status") {
            if (value == "All") {
                // getAllOwner(null);
                setownState({
                    ...ownstate,
                    status: ""
                })

            } else {
                setownState({
                    ...ownstate,
                    status: value
                })
            }
        }
        if (name == "search") {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {
                setownState({
                    ...ownstate,
                    owner: value
                })
            }, 1000)
        }
    }

    useEffect(() => {
        if (!firstLoad) {
            setTimeout(() => {
                setFirstload(true)
            }, 1500)
        }
        setownState({
            ...ownstate,
            ["start"]: currentPage == 1 || currentPage == 0 ? 0 : (currentPage - 1) * PageSize,
            ["end"]: PageSize
        })
    }, [currentPage])


    // owner id on contract and conta banko mov 

    useEffect(() => {
        if (firstLoad) {
            ownstate['pageSearch'] = 'ownerListingPage'
            customerService.filterOwner(ownstate).then((res) => {
                if (res.data.resCode === 201) {
                    setData(res?.data?.data || []);
                    setLegalData(res?.data?.data || []);
                    setSendMailData(res?.data?.data || [])
                    setTotal(res?.data?.totalRow || 0)
                } else {
                    setData([]);
                    setLegalData([]);
                    setSendMailData([])
                    setTotal(0)
                }
            })
        }
    }, [ownstate])

    useEffect(() => {
        // if (location?.state?.type == "legal_entities") {
        //     setFirstload(true)
        //     setownState({
        //         ...ownstate,
        //         person: "Legal"
        //     })
        // }else{
        //     setFirstload(false)
        // }
    }, [location.state]);

    const legalAndPhycal = (evt) => {
        let value = evt.target.value;
        setownState({
            ...ownstate,
            person: value == "2" ? "Physical" : value == "3" ? "Legal" : "both",
            start: 0,
            end: 20
        })

        let checkCnpjCpf: any = []
        let legalData: any = []
        setLegalAndPhysical("")
        setLegalOwner("")
        LegalData.forEach((element: any) => {
            if (value == 1) {
                legalData.push(element)
            }
            let checkCNJP: any = CNJP_CPFValidation(`${element.cnpj}`, "both")
            checkCnpjCpf.push(checkCNJP)
            checkCnpjCpf.forEach(item => {
                if (item.cnpj === element.cnpj && value == 3) {
                    legalData.push(element)
                    setLegalOwner("Legal")
                    setLegalAndPhysical("")
                }
                if (item.cpf === element.cnpj && value == 2) {
                    legalData.push(element)
                    setLegalAndPhysical("Physical")
                    setLegalOwner("")
                }
            })
        });
        setData(legalData || [])
    }


    // Filters section
    const [openFilters, setFiltersOpen] = useState(false);
    const handleSelectName = (e, type) => {
        let typeClick: any = document.querySelector("body");
        typeClick.setAttribute("linkfor", type)
        typeClick.setAttribute("linkforid", "2050")
        dispatch(openTypeCustomer1(type))
    }

    const filterReset = () => {
        let setvalue: any = document.querySelector("#status");
        if (setvalue) {
            setvalue.value = ""

        }
        let setvalue1: any = document.querySelector("#contactstatus");
        if (setvalue1) {
            setvalue1.value = "All"

        }
        setLegalAndPhysical("")
        setLegalOwner("")
        setownState({
            owner: "",
            person: "",
            status: "",
            start: 0,
            end: 20
        })
    }

    return (
        <section className="content-wrapper content-wrapper-primary">
            {/* <!-- Breadcrumb section start from here -->*/}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100 mb-xl-4">
                    <Row className="gx-xl-5 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <Link to={"/customer_and_employees"}>
                                    <img className="h-16" src="./assets/images/icons/arrow-left.svg" />
                                </Link>
                                <div className="w-100 overflow-hidden ms-2 ms-xl-3 me-xl-1">
                                    <h1 className="mb-0 h4 lh-base text-truncate W-2001">
                                        {t('formLabel.ownersBeneficiaries')}
                                    </h1>
                                </div>
                                {/* Filter button visible in mobile devices */}
                                <div className="d-xl-none">
                                    <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                                        {t("dropdownItem.filters")}
                                        <img
                                            src="assets/images/icons/filter-icon.svg"
                                            alt="Filter"
                                            className="ms-1 h-12"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                            <div className="input-group">
                                <Form.Control type="text" id="search" placeholder={t('formLabel.typeSearch')}
                                    aria-describedby="basic-addon2" name="search" onChange={ActiveContract} />
                                <span className="input-group-text" id="basic-addon2">
                                    <img alt="img" src="assets/images/icons/search.svg" />
                                </span>
                            </div>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end col-xl mb-xl-0 mb-2">
                            {
                                userJson && userJson.length > 0 && (userJson.indexOf(2051) !== -1) &&
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                        <Link to={"#"} onClick={formShow} type="button" className="fw-bold">
                                            <img src="./assets/images/icons/plus-light.svg" className="me-1" />
                                            {t('customerAndEmployees.newOwner')}
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to={"/owners_area"} className="fw-bold">
                                            <img src="./assets/images/icons/plus-light.svg" className="me-1" />
                                            {t('formLabel.ownerArea')}
                                        </Link>
                                    </li>
                                    {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" size="sm" id="dropdownMenuButton1">
                                        <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#">{t('formLabel.printScreen')}</Dropdown.Item>
                                        <Dropdown.Item href="#">{t('customerAndEmployees.ownersReport')}</Dropdown.Item>
                                        <Dropdown.Item href="#">{t('customerAndEmployees.detailedOwnersReport')}</Dropdown.Item>
                                        <Dropdown.Item href="#">{t('customerAndEmployees.incomeGroupedOwner')}</Dropdown.Item>
                                        <Dropdown.Item href="#">{t('customerAndEmployees.extractIncomeTaxReturn')}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1" hidden>
                                <Link to={"#"} className="fw-bold">
                                    <img src="./assets/images/icons/settings-light.svg" />
                                </Link>
                            </li>
                            <li className="list-inline-item mb-md-0 mb-1" hidden>
                                <Link to={"#"} className="fw-bold">
                                    <img src="assets/images/icons/question_icon.svg" alt="Help" className="h-20" />
                                </Link>
                            </li> */}
                                </ul>
                            }
                        </Col>
                    </Row>
                </div>
                <Collapse className="d-xl-block collpase-filters" in={openFilters}>
                    <Form id="collpase-filter-text" action="" method="">
                        <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
                            <h5 className="mb-0 fw-bold">Filters</h5>
                            <span className="cursor-pe" onClick={() => setFiltersOpen(!openFilters)}>
                                <img src="assets/images/icons/cross.svg" alt="Plus icon" className="ms-xxl-1 ms-auto h-14" />
                            </span>
                        </div>
                        <Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
                            <Col xl="auto">
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" size="sm" className={`${ownstate?.["person"] ? "show" : ""}`}>
                                        {ownstate?.["person"] == "both" ? t('dropdownItem.physicalAndLegal') : ownstate?.["person"] == "Physical" ? t('dropdownItem.physical') : ownstate?.["person"] == "Legal" ? t('dropdownItem.legal') : t('dropdownItem.person')}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="px-4">
                                        <FloatingLabel controlId="floatingSelect" label={t('dropdownItem.status')}>
                                            <Form.Select id="status" aria-label={t('dropdownItem.status')} className={` ${errors.model ? 'is-invalid' : ''}`} onChange={legalAndPhycal}>
                                                <option value="">{t('formLabel.select')}</option>
                                                {/* <option value="1" >{t('dropdownItem.physicalAndLegal')}</option> */}
                                                <option value="2">{t('dropdownItem.physical')}</option>
                                                <option value="3">{t('dropdownItem.legal')}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" size="sm" id="dropdownMenuButton1" className={`${ownstate?.["status"] ? "show" : ""}`}>
                                        {ownstate?.["status"] == "activewithContract" ? t('dropdownItem.activeContracts') : ownstate?.["status"] == "All" ? t('All') : t('dropdownItem.contractStatus')}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="px-4" >
                                        <FloatingLabel controlId="floatingSelect" label={t('dropdownItem.contractStatus')}>
                                            <Form.Select id="contactstatus" aria-label={t('dropdownItem.gruops')} name="status" onChange={ActiveContract}>
                                                <option value="All">{t('all')}</option>
                                                <option value="activewithContract">{t('dropdownItem.activeContracts')}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            {ownstate?.["person"] || ownstate?.["status"] ? <Col xl="auto">
                                <Dropdown autoClose="outside" className="moreFilter">
                                    <Dropdown.Toggle variant="light" size="sm" className="d-flex align-items-center" onClick={filterReset}>
                                        {t("button.resetfilter")}
                                    </Dropdown.Toggle>
                                </Dropdown>

                            </Col> :
                                ""}
                        </Row>
                    </Form>
                </Collapse>
            </div>
            {/* <!-- Breadcrumb section end from here -->*/}
            {/* <!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                <div>
                    <Row className="h-100 gy-3 gy-lg-4">
                        <Col lg={8}>

                            {/* <!-- Table start from here -->*/}
                            {
                                status ?
                                    <Loader />
                                    :
                                    <>
                                        <Card>
                                            <Card.Body className="pb-1">
                                                <Table responsive className="table table-custom table-custom-bordered mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="w-24">
                                                                {data && data.length > 0 ?
                                                                    <Checkbox type="checkbox" name="selectAll" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} />
                                                                    : ''}
                                                            </th>
                                                            <th>{t('formLabel.name')}</th>
                                                            <th>{t('formLabel.contact')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data && data.length > 0 ?
                                                            data.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td className={"check_Checkbox"}>
                                                                        <Checkbox key={item.id} type="checkbox" name={item.fantasy ? item.fantasy : item.name} id={item.id} handleClick={handleClick} isChecked={isCheck.includes(item.id)} />
                                                                    </td>
                                                                    <td>
                                                                        <span className="d-block">
                                                                            <img alt="img" src="assets/images/icons/user_g.svg" />
                                                                            <Link to={"/owner_details/" + item.id} onClick={(e) => handleSelectName(e, "Owner")} className="ps-1 text-dark-70">{item.fantasy ? item.fantasy : item.name }</Link>
                                                                            {item.aarinId != '' && item.statusowner !== 1 &&
                                                                                <img style={{ width: '9px', marginLeft: '4px' }} alt="img" src="assets/images/icons/green-circle-icon.svg" />
                                                                            }

                                                                            <br />
                                                                            <span>{legalAndPhysical ? legalAndPhysical : legalOwner ? legalOwner : ""}</span>
                                                                        </span>
                                                                        {
                                                                            item.agencyStatus == 1 ?
                                                                                <span className="text-dark badge bg-info-20">{t("AgencyAccount")}</span>
                                                                                : ''
                                                                        }
                                                                        {(item.statusowner == 1 && item.agencyStatus != 1) &&
                                                                            <span><Badge className="text-white ms-1" bg="secondary">{item?.["statusowner"] == "1" ? t("inActive") : ''}</Badge></span>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <a href={`mailto:${item.email}`} className="d-block text-dark-70">{item.email}</a>
                                                                        <span>{item.mobile ? item.mobile : item.mobile}</span>
                                                                    </td>
                                                                </tr>
                                                            )) :
                                                            <tr>
                                                                <td colSpan={3}>
                                                                    <span>{t('formLabel.noRecordsFounds')}</span>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </Table>

                                            </Card.Body>
                                        </Card>
                                        <div className="d-flex justify-content-center mt-lg-4 mt-3">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={total}
                                                pageSize={PageSize}
                                                onPageChange={page => setCurrentPage(page)}
                                            />
                                        </div>
                                    </>
                            }
                            {/* <!-- /Table end --> */}
                            {/* {t('formLabel.moreItems')} */}
                            <p className="d-block py-2">{t('formLabel.listing')} {data && data?.length ? data.length : "0"} {t('customerAndEmployees.owners')}.</p>

                            {/*  <!-- Nav listing start from here --> */}
                            {
                                userJson && userJson.length > 0 && (userJson.indexOf(2051) !== -1) &&
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="light" size="sm" id="dropdownMenuButton1">
                                                {t('formLabel.mark')}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={handleSelectAll} href="#/action-1">{t('formLabel.all')}</Dropdown.Item>
                                                <Dropdown.Item onClick={noneHandleSelectAll} href="#/action-2">{t('formLabel.none')}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </li>
                                    <li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
                                        <span className="px-1">
                                            {t('formLabel.withTagged')}
                                        </span>
                                    </li>
                                    <li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
                                        <Button type="button" onClick={handleMouseEvent} name={t('formLabel.sendEmail')} variant="light" size="sm">
                                            {t('formLabel.sendEmail')}
                                        </Button>
                                    </li>
                                    <li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
                                        <Button type="button" onClick={handleMouseEvent} name={t('formLabel.sendLetter')} variant="light" size="sm">
                                            {t('formLabel.sendLetter')}
                                        </Button>
                                    </li>
                                    <li className="list-inline-item mb-lg-3 mb-md-2 mb-1 me-xl-3 me-2">
                                        <Button type="button" onClick={mailingShow} variant="light" size="sm">
                                            {t('formLabel.mailingLabel')}
                                        </Button>
                                    </li>
                                </ul>
                            }
                            {/* <!-- /Nav listing  end -->*/}

                        </Col>

                        <Col lg={4} >
                            <Card>
                                <Card.Body className="pb-1">
                                    <Card.Title>{t('formLabel.moreThanProperty')}</Card.Title>
                                    {/* <!-- Table start from here -->*/}
                                    <div className="table-responsive">
                                        <Table className="table table-custom table-custom-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th>{t('customerAndEmployees.owners')}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {count_2 !== 0 ?
                                                    <tr>
                                                        <td className="text-dark-70 cursor-pe">
                                                            <a onClick={(e) => { PropertyFilter(2) }} className="text-reset">
                                                                {t("ReceiveandReceived.with")} 2 {t("Links.properties")}
                                                            </a>
                                                        </td>
                                                        <td className="text-dark-70">
                                                            {count_2}
                                                        </td>
                                                    </tr>
                                                    : ""}
                                                {count_3 !== 0 ?
                                                    <tr>
                                                        <td className="text-dark-70 cursor-pe">
                                                            <a onClick={(e) => { PropertyFilter(3) }} className="text-reset">
                                                                {t("ReceiveandReceived.with")} 3 {t("Links.properties")}
                                                            </a>
                                                        </td>
                                                        <td className="ttext-dark-70">
                                                            {count_3}
                                                        </td>
                                                    </tr>
                                                    : ""}
                                                {count_4 !== 0 ?
                                                    <tr>
                                                        <td className="text-dark-70 cursor-pe">
                                                            <a onClick={(e) => { PropertyFilter(4) }} className="text-reset">
                                                                {t("ReceiveandReceived.with")} 4 {t("Links.properties")}
                                                            </a>
                                                        </td>
                                                        <td className="ttext-dark-70">
                                                            {count_4}
                                                        </td>
                                                    </tr>
                                                    : ""}
                                                {count_5 !== 0 ?
                                                    <tr>
                                                        <td className="text-dark-70 cursor-pe">
                                                            <a onClick={(e) => { PropertyFilter(5) }} className="text-reset">
                                                                {t("ReceiveandReceived.with")} 5 {t("Links.properties")}
                                                            </a>
                                                        </td>
                                                        <td className="ttext-dark-70">
                                                            {count_5}
                                                        </td>
                                                    </tr>
                                                    : ""}
                                                {count_6 !== 0 ?
                                                    <tr>
                                                        <td className="text-dark-70 cursor-pe">
                                                            <a onClick={(e) => { PropertyFilter(6) }} className="text-reset">
                                                                {t("ReceiveandReceived.with")} 6 {t("Links.properties")}
                                                            </a>
                                                        </td>
                                                        <td className="ttext-dark-70">
                                                            {count_6}
                                                        </td>
                                                    </tr> : ""
                                                }
                                                {count_7 !== 0 ?
                                                    <tr>
                                                        <td className="text-dark-70 cursor-pe">
                                                            <a onClick={(e) => { PropertyFilter(7) }} className="text-reset">
                                                                {t("ReceiveandReceived.with")} 7 {t("Links.properties")}
                                                            </a>
                                                        </td>
                                                        <td className="ttext-dark-70">
                                                            {count_7}
                                                        </td>
                                                    </tr> : ""}
                                                {count_8 !== 0 ?
                                                    <tr>
                                                        <td className="text-dark-70 cursor-pe">
                                                            <a onClick={(e) => { PropertyFilter(8) }} className="text-reset">
                                                                {t("ReceiveandReceived.with")} 8 {t("Links.properties")}
                                                            </a>
                                                        </td>
                                                        <td className="ttext-dark-70">
                                                            {count_8}
                                                        </td>
                                                    </tr>
                                                    : ""}
                                                {count_9 !== 0 ?
                                                    <tr>
                                                        <td className="text-dark-70 cursor-pe">
                                                            <a onClick={(e) => { PropertyFilter(9) }} className="text-reset">
                                                                {t("ReceiveandReceived.with")} 9 {t("Links.properties")}
                                                            </a>
                                                        </td>
                                                        <td className="ttext-dark-70">
                                                            {count_9}
                                                        </td>
                                                    </tr>
                                                    : ""}
                                                {count_10 !== 0 ?
                                                    <tr>
                                                        <td className="text-dark-70 cursor-pe">
                                                            <a onClick={(e) => { PropertyFilter(10) }} className="text-reset">
                                                                {t("ReceiveandReceived.with")} 10 {t("Links.properties")}
                                                            </a>
                                                        </td>
                                                        <td className="ttext-dark-70">
                                                            {count_10}
                                                        </td>
                                                    </tr> : ""}
                                            </tbody>
                                        </Table>
                                    </div>
                                    {/* <!-- /Table end -->*/}
                                    {/* <Link to={"/owners_and_beneficiaries"} className='text-reset'>
                                        <p className="d-block mt-2 mb-0">{t('formLabel.all')}</p>
                                    </Link> */}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </main>
            {/* <!-- /Main content start end --> */}

            {/*---------------Mail/letter from modal------------*/}
            <SendEmails mailText={mailText} mailData={mailData} personId={personId} customer_type="Owner" />
            {/*---------------Mailing permission form modal------------*/}

            <Modal show={show3} onHide={mailingClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('formLabel.changeAccessPermi')}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(permissionSubmit)}>
                    <Modal.Body>
                        <span className="d-block mb-3 fw-bold">
                            {t('formLabel.hits')}
                        </span>
                        <Button className="btn btn-light btn-sm me-3">
                            {t('button.selectAll')}
                        </Button>
                        <Button className="btn btn-light btn-sm">
                            {t('button.clearSelect')}
                        </Button>
                        <Row>
                            <Form.Group className="mb-3 gy-3">
                                <Form.Check type="checkbox" {...register('administrative_access')} label={t('checkboxLabel.administrativeAccess')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('change_and_query')} label={t('checkboxLabel.usersChangeQuery')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('closing_opening_month')} label={t('checkboxLabel.closingOpeningMonth')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('chart_accounts')} label={t('checkboxLabel.chartAccountsAmendment')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('settings_consultation')} label={t('checkboxLabel.settingsConsultation')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('customers_notify')} label={t('checkboxLabel.customersNotify')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('collections_consultation')} label={t('checkboxLabel.collectionsConsultation')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('collections_amendment')} label={t('checkboxLabel.collectionsAmendment')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('collections_invalidate')} label={t('checkboxLabel.collectionsInvalidate')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('collections_settlement')} label={t('checkboxLabel.collectionsSettlement')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('collections_reverse')} label={t('checkboxLabel.collectionsReverse')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('metrics_visualization')} label={t('checkboxLabel.metricsVisualization')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('box_consultation')} label={t('checkboxLabel.boxConsultation')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('agreements_consultation')} label={t('checkboxLabel.agreementsConsultation')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('monetary_amendment')} label={t('checkboxLabel.monetaryUpdateAmendment')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('legal_consultation')} label={t('checkboxLabel.legalConsultation')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('invoices_consultation')} label={t('checkboxLabel.invoicesConsultation')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('invoices_amendment')} label={t('checkboxLabel.invoicesAmendment')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('services_consultation')} label={t('checkboxLabel.servicesConsultation')} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="checkbox" {...register('expenses_consultation')} label={t('checkboxLabel.expensesConsultation')} />
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={mailingClose}>
                            {t('button.close')}
                        </Button>
                        <Button type="submit" variant="primary" name="To save">
                            {t('button.save')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/*---------------Owner form modal------------*/}
            <NewOwner getAllOwner={getAllOwner} setownState={setownState} fromData="indirect" />
            <MaillingLabel />

        </section>

    )
}
export default Owners_and_beneficiaries;


