import { Accordion, Table, FloatingLabel, InputGroup, Dropdown, Col, Row, Modal, Form, Button, Card } from 'react-bootstrap';
import React, { useState, MouseEvent, useEffect } from 'react';
import { CustomersAndEmployeesForm } from '../../../Interface';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import customerService from '../../../../services/customerService';
import Checkbox from '../Common/Checkbox';
import Loader from '../../../Profile/Company/Loader';
import '../../../../App.css';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { openErrorbox } from '../Common/actionPerform';
import { getAllProperty_administractor } from '../../../../redux/slice/contractDataSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
import { CNJP_CPFValidation, checkDuplicateCNJP_CPF } from '../../../constants/utils';

const Condos = (CustomersAndEmployeesForm) => {
    const [t] = useTranslation();
    let frm: any = "";
    const [status, setStatus] = useState(true);
    const [hide, setHide] = useState(true);
    const [hide2, setHide2] = useState(false);
    const [hide3, setHide3] = useState(false);
    const [show, setShow] = useState(false);
    const [show3, setShow3] = useState(false);
    const formClose = () => {
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        zipCodeCheck?.["removeAttribute"]('disabled');
        setShow(false);
        setHide2(false);
        setHide3(false)
         reset();
    }
    const formShow = () => { 
        setShow(true);
     }

    /*--------Data table listing state--------*/
    const dispatch = useDispatch<AppDispatch>()
    const [adminData, setAdminData] = useState<any[]>([])
    const [data, setData] = useState<any[]>([])
    const [total, setTotal] = useState(0);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState<any[]>([]);
    const [allStates, setAllStates] = useState([]);
    useEffect(() => { getAllAdministrators(null); getAllCondos(null); }, []);
    useEffect(() => {
        if (hide) {
            (async () => {
                let resp = await customerService.getAddressBasedOnZipcode(`states`);
                if (resp.data.status == "success" || resp.data.type == "Success") {
                    let loop = resp.data.data;
                    let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
                    // Sort the stateData alphabetically by label
                    stateData.sort((a, b) => a.label.localeCompare(b.label));
                    setAllStates(stateData);
                }
            })()
        }
    }, [hide]);
    /*--------Email modal state--------*/

    const [show2, setShow2] = useState(false);
    const [hide5, setHide5] = useState(false);
    const [mailText, setMailText] = useState("");
    const mailClose = () => { setShow2(false); reset(); }
    const administratorClose = () => { setShow3(false); }
    const administratorShow = () => { setShow3(true); }

    const [cnpjcpferrormessage, setcnpjcpferrormessage] = useState({});
    const step1 = () => { setHide(true); setHide2(false); setHide3(false); }
    const step2 = (data: CustomersAndEmployeesForm) => {
        if (errors?.cnpj?.message) {
            setError(cnpjcpferrormessage?.["name"], {
                type: "manual",
                message: cnpjcpferrormessage?.["message"]
            })
            setHide(true);
        } else {
            setHide(false); setHide2(true); setHide3(false);
        }
    }
    const step3 = (data: CustomersAndEmployeesForm) => { setHide(false); setHide2(false); setHide3(true); }
    const handleMouseEvent = (e: MouseEvent<HTMLButtonElement>) => {
        let count: any = false;
        let data = document.querySelectorAll('.check_Checkbox input');
        data.forEach((item, index) => {
            if (item["checked"]) {
                count = true;
            }
        })
        if (count) {
            e.preventDefault();
            setMailText(e.currentTarget.name)
            if (e.currentTarget.name === "Send letter") { setHide5(true); }
            else { setHide5(false); }
            setShow2(true)
        } else {
            Swal.fire({
                title: t("Sweetalert.Warning"),
                text: t("Sweetalert.PleaseSelectUser"),
                // icon: 'success',"warning",
                confirmButtonText: "OK",
                timer: 2000
            });
        }
    }

    /*-------------Form validation-------------*/

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const mailValidationSchema = Yup.object().shape({
        condo_identifier: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        condo_model: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        condo_title: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        condo_variable: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired'))
    });
    const formValidationSchemaStep1 = Yup.object().shape({
        // condo_cnpj: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
    });
    const formValidationSchemaStep2 = Yup.object().shape({
        condo_name: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        // condo_fantasy_name: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // condo_administrator: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // condo_email:Yup.string().trim().nullable()
        // .required(t('requiredMmessage.fieldRequired'))
        // .email(t('requiredMmessage.fieldNotValid')),
        // condo_zipcode: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // condo_address: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // condo_number: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // condo_complement: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // condo_neighborhood: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // condo_city: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // condo_state: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
    });
    const administratorValidationSchema = Yup.object().shape({
        admin_name: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        // admin_cnpj: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        // admin_responsible: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // admin_email: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired'))
        //     .email(t('requiredMmessage.fieldNotValid')),
        // admin_telephone: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // admin_zipcode: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // admin_address: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // admin_number: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // admin_complement: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // admin_neighborhood: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // admin_city: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        // admin_state: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired'))
    });
    frm = {
        resolver: yupResolver(mailValidationSchema)
    }
    if (show) {
        frm = {
            resolver: yupResolver(formValidationSchemaStep1)
        }
        if (hide2) {
            frm = {
                resolver: yupResolver(formValidationSchemaStep2)
            }
        }
        openErrorbox();
      
    }
    
    if (show3) {
        frm = {
            resolver: yupResolver(administratorValidationSchema)
        }
        openErrorbox();
    }
    const { register, handleSubmit, reset, setError, clearErrors, setValue, formState: { errors } } = useForm<CustomersAndEmployeesForm>(frm);

    /*-------------Email form submit-------------*/

    let [desc, setDesc] = useState('');
    const mailSubmit = (data: CustomersAndEmployeesForm) => {
        let mailData: any = ""

        if (desc != "") {
            mailData = {
                identifier: data.identifier,
                model: data.model,
                caption: data.caption,
                title: data.title,
                desc: desc,
                variable: data.variable
            }
        } else {
            mailData = {
                identifier: data.identifier,
                model: data.model,
                caption: data.caption,
                title: data.title,
                desc: '',
                variable: data.variable
            }
        }
        // console.log("mailData", mailData)
        // console.log("isCheckData", isCheck)
    }

    /*-------------Call the api & save Condos data-------------*/

    const condosSubmit = (data: CustomersAndEmployeesForm) => {
        let condosData = {
            cnpj: data.condo_cnpj,
            name: data.condo_name,
            fantasy_name: data.condo_fantasy_name,
            administrator: data.condo_administrator,
            zipcode: data.condo_zipcode,
            address: data.condo_address,
            number: data.condo_number,
            complement: data.condo_complement,
            neighborhood: data.condo_neighborhood,
            city: data.condo_city,
            state: data.condo_state,
            telephone: data.condo_telephone,
            email: data.condo_email,
            observation: data.condo_observation,
            billing_method: data.condo_billing_method,
            condo_due_date: data.condo_condo_due_date,
            trustee_name: data.condo_trustee_name,
            trustee_rg: data.condo_trustee_rg,
            trustee_cpf: data.condo_trustee_cpf,
            trustee_telephone: data.condo_trustee_telephone,
            trustee_mobile: data.condo_trustee_mobile,
            trustee_email: data.condo_trustee_email,
            liquidator_zipcode: data.condo_liquidator_zipcode,
            liquidator_address: data.condo_liquidator_address,
            liquidator_number: data.condo_liquidator_number,
            liquidator_complement: data.condo_liquidator_complement,
            liquidator_neighborhood: data.condo_liquidator_neighborhood,
            liquidator_city: data.condo_liquidator_city,
            liquidator_state: data.condo_liquidator_state,
            caretaker_name: data.condo_caretaker_name,
            caretaker_rg: data.condo_caretaker_rg,
            caretaker_cpf: data.condo_caretaker_cpf,
            caretaker_telephone: data.condo_caretaker_telephone,
            caretaker_mobile: data.condo_caretaker_mobile,
            caretaker_email: data.condo_caretaker_email,
            attorney_name: data.condo_attorney_name,
            attorney_rg: data.condo_attorney_rg,
            attorney_cpf: data.condo_attorney_cpf,
            lawyer_telephone: data.condo_lawyer_telephone,
            lawyer_mobile: data.condo_lawyer_mobile,
            lawyer_email: data.condo_lawyer_email,
            is_vendor: data.condo_is_vendor
        }
        customerService.createCondos(condosData).then(res => {
            /*-------Check the api response--------*/
            if (res.data.resCode === 202) {
                getAllCondos(null);
                formClose();
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.successget"),
                    // icon: 'success',"success",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            } else if(res?.data?.resCode == 409) {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.unqueEmails"),
                    // icon: 'success',"error",
                    confirmButtonText: "OK"
                    // timer : 2000
                })
            }
            else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.somethingWent"),
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
        }).catch(error => {
            /*-------Error exception handling--------*/
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            });
        });
    }

    /*-------------Call the api & save Administrators data-------------*/

    const administratorSubmit = (data: CustomersAndEmployeesForm) => {
        let administratorsData = {
            name: data.admin_name,
            cnpj: data.admin_cnpj,
            responsible: data.admin_responsible,
            email: data.admin_email,
            telephone: data.admin_telephone,
            zipcode: data.admin_zipcode,
            address: data.admin_address,
            number: data.admin_number,
            complement: data.admin_complement,
            neighborhood: data.admin_neighborhood,
            city: data.admin_city,
            state: data.admin_state,
            observation: data.observation,
            is_vendor: data.admin_is_vendor
        }
        customerService.createAdministrators(administratorsData).then(res => {
            /*-------Check the api response--------*/
            if (res.data.resCode === 202) {
                setShow3(false)
                getAllAdministrators(null);
                administratorClose();
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.successget"),
                    // icon: 'success',"success",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
            else if(res.data.resCode == 409){
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.unqueEmails"),
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.somethingWent"),
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
        }).catch(error => {
            /*-------Error exception handling--------*/
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                // icon: 'success',"error",
                confirmButtonText: "OK",
                timer: 2000
            });
        });
    };

    /*-------Get the Condos listing--------*/

    const getAllCondos = (condos) => {
        setStatus(true);
        let condosData = { condos: condos, start: 0, end: 100 }
        customerService.getAllCondos(condosData).then(res => {
            setStatus(false);
            if (res.data.resCode === 201) {
                setData(res.data.data);
                setTotal(res.data.totalRow)
            }
            else {
                setData([]);
                setTotal(0)
            }
        }).catch(err => {
            setStatus(false);
            //console.log("err", err)
        });
    }
    /*-------Get the Administrators listing--------*/

    const getAllAdministrators = (administrator) => {
        let administratorData = { administrator: administrator, start: 0, end: 100 }
        customerService.getAllAdministrators(administratorData).then(res => {
            if (res.data.resCode === 201) {
                setAdminData(res.data.data);
            }
            else {
                setAdminData([]);
            }
        }).catch(err => {
            setAdminData([]);
            //console.log("err", err)
        });
    }

    /*-------Checked/Unchecked multiple checkbox functionality--------*/

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(data.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    const noneHandleSelectAll = e => {
        setIsCheckAll(false)
        setIsCheck([]);
    }
    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }

        if (document.querySelectorAll('.check_Checkbox input').length > 0) {
            let data = document.querySelectorAll('.check_Checkbox input');
            let count_allCheck = 0;
            setTimeout(() => {
                data.forEach((item, index) => {
                    if (item["checked"]) {
                        count_allCheck++
                    }

                    if (!item["checked"]) {
                        setIsCheckAll(false);
                    }
                })

                if (count_allCheck == data.length) {
                    setIsCheckAll(true);
                }
            }, 10)

        }
    };
    const [uniqueSet, setUniqueSet] = useState([]);
    const [allPropertyCount, setallPropertyCount] = useState({});
    useEffect(() => {
        (async () => {
            let resp = await dispatch(getAllProperty_administractor({
                start: 0,
                end: 10000
            }));

            let uniqueValueCount: any = [];
            let allPropertyCount = {};
            let count = 1;
            data.forEach((dataItem) => {
                count = 1;
                uniqueValueCount.push(dataItem)
                resp.payload.data.forEach((userItem, index) => {
                    // console.log(userItem, "userItem -------", index)
                    if (dataItem.id == userItem.condos_id) {
                        allPropertyCount[`${dataItem.id}`] = {
                            "count": count++,
                            "id": dataItem.id,
                            "name": dataItem.name,
                            "trustee_name": dataItem?.trustee_name,
                            "trustee_email": dataItem?.trustee_email,
                            "trustee_mobile": dataItem?.trustee_mobile
                        }
                    }
                });
            });
            setallPropertyCount(allPropertyCount);
            setUniqueSet(uniqueValueCount);
        })();
    }, [data]);

    const HandleInput = (e: any) => {
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
        if (newValue == "" || newValue == null || newValue == undefined) {
            setError("telephone", {
                type: "manual",
                message: `${t("requiredMmessage.TelephoneError")}`
            });
        } else {
            clearErrors('telephone')
        }
    }

    const handleCPF_CNJPVaue = async (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        if (checkCNJP?.["status"] || e.target.value == "") {
            clearErrors(name)
            let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
            zipCodeCheck?.["setAttribute"]('disabled', "")
            if (e.target.value !== "") {
                let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, "");
                if (duplicateData["data"] > 0) {
                    zipCodeCheck?.["setAttribute"]('disabled', "")
                    setError(name, {
                        type: "manual",
                        message: t("formLabel.ErrorCNPJCPF")
                    });
                } else {
                    zipCodeCheck?.["removeAttribute"]('disabled');
                }
            }
        } else {
            // errorCNPJCPF(e.target.value)
            setcnpjcpferrormessage({
                name: name,
                message: checkCNJP?.["message"]
            })
            setError(name, {
                type: "manual",
                message: checkCNJP?.["message"]
            });
        };
    }
    let [zipvalue, setZipvalue] = useState({
        zip: "",
        empzip: "",
        goodofZip: "",
    });
    useEffect(() => {
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        if (zipvalue.zip == "" && zipvalue.empzip == "" || zipvalue.goodofZip == "") {
            zipCodeCheck?.["removeAttribute"]('disabled');
        } else {
            zipCodeCheck?.["setAttribute"]('disabled', "")
        }
    }, [zipvalue])
    const getLocation = async (e, inputType, forwhich) => {
        let str = e.target.value
        let newStr = str.replace('-', ''); // Removes the hyphen
        let querySelthird: any = ''
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
        let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];

        // Error validation
        // Retrieve address based on zipcode using customerService
        let resp = await customerService.getAddressBasedOnZipcode(
            `${inputType}/${newStr}`
        );
        let querySelfirst: any = document.querySelector('.accordion-custom [name="condo_zipcode"]');
        let querySelsecond: any = document.querySelector('.accordion-custom [name="condo_liquidator_zipcode"]');

        if (forwhich == "first") {
            setZipvalue({
                ...zipvalue,
                ["zip"]: str,
            })
        } else if (forwhich == "second") {
            setZipvalue({
                ...zipvalue,
                ["empzip"]: str
            })
        } else {
            setZipvalue({
                ...zipvalue,
                ["goodofZip"]: str
            })
        }

        if (value != "") {
            if (resp?.["data"]["status"] == "success" || resp?.["data"]["type"] == "Success") {
                let res = resp["data"]["data"];
                if (forwhich == "first") {
                    setZipvalue({
                        ...zipvalue,
                        ["zip"]: '',
                    })
                    querySelfirst.style.border = "0px solid red";
                    if (res[0]["city"]) {
                        setValue("condo_city", res[0]["city"])
                    }

                    if (res[0]["neigbohood"]) {
                        setValue("condo_neighborhood", res[0]["neigbohood"])
                    }

                    if (res[0]["state"]) {
                        setValue("condo_state", res[0]["state"])
                    }

                    if (res[0]["street"] || res[0]["streetType"]) {
                        let data = (res[0]["streetType"] ? res[0]["streetType"] + " " : "") + (res[0]["street"] ?? "");
                        setValue("condo_address", data)
                    }
                    clearErrors("condo_zipcode");
                    clearErrors("condo_city");
                    clearErrors("condo_neighborhood");
                    clearErrors("condo_address")
                    clearErrors("condo_state")
                } else if (forwhich == "second") {
                    setZipvalue({
                        ...zipvalue,
                        ["empzip"]: ''
                    })
                    querySelsecond.style.border = "0px solid red";
                    if (res[0]["city"]) {
                        setValue("admin_city", res[0]["city"])
                    }

                    if (res[0]["neigbohood"]) {
                        setValue("admin_neighborhood", res[0]["neigbohood"])
                    }

                    if (res[0]["state"]) {
                        setValue("admin_state", res[0]["state"])
                    }

                    if (res[0]["street"] || res[0]["streetType"]) {
                        let data = (res[0]["streetType"] ? res[0]["streetType"] + ", " : "") + (res[0]["street"] ?? "");
                        setValue("admin_address", data)
                    }
                } if (forwhich == "third") {
                    setZipvalue({
                        ...zipvalue,
                        ["goodofZip"]: ''
                    })
                    querySelsecond.style.border = "0px solid red";
                    if (res[0]["city"]) {
                        setValue("condo_liquidator_city", res[0]["city"])
                    }

                    if (res[0]["neigbohood"]) {
                        setValue("condo_liquidator_neighborhood", res[0]["neigbohood"])
                    }

                    if (res[0]["state"]) {
                        setValue('condo_liquidator_state',res[0]["state"])
                    }

                    if (res[0]["street"] || res[0]["streetType"]) {
                        let data = (res[0]["streetType"] ? res[0]["streetType"] + ", " : "") + (res[0]["street"] ?? "");
                        setValue("condo_liquidator_address", data)
                    }
                }

            } else {
                if (forwhich == "first") {
                    querySelfirst.style.border = "2px solid red"
                    setValue("condo_city", "")
                    setValue("condo_neighborhood", "")
                    setValue("condo_state", "")
                    setValue("condo_address", "")
                    setError("condo_zipcode", {
                        type: "manual",
                        message: `${t("requiredMessage.zipcodeError")}`
                      });

                } else if(forwhich == "second") {
                    querySelsecond.style.border = "2px solid red"
                    setValue("admin_city", "")
                    setValue("admin_neighborhood", "")
                    setValue("admin_state", "")
                    setValue("admin_address", "")
                    setError("admin_zipcode", {
                        type: "manual",
                        message: `${t("requiredMessage.zipcodeError")}`
                      });
                }else{
                    querySelsecond.style.border = "2px solid red"
                    setValue("condo_liquidator_city", "")
                    setValue("condo_liquidator_neighborhood", "")
                    setValue("condo_liquidator_state", "")
                    setValue("condo_liquidator_address", "")
                    setError("condo_liquidator_zipcode", {
                        type: "manual",
                        message: `${t("requiredMessage.zipcodeError")}`
                      });
                }
            }
        } else {
            if (forwhich == "first") {
                querySelfirst.style.border = "0px solid red";
            } else {
                querySelsecond.style.border = "0px solid red"
            }
        }
    }
    const HandleInputMob = (e: any) => {
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');
        if (newValue == "" || newValue == null || newValue == undefined) {
            setError("mobile", {
                type: "manual",
                message: `${t("requiredMmessage.mobileError")}`
            });
        } else {
            clearErrors('mobile');
        }
    }
    return (
        <section className="content-wrapper">
            {/*  <!-- Breadcrumb section start from here --> */}
            <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3 overflow-visible">
                <div className="w-100">
                    <Row className="gx-xl-5 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <Link to={"/properties"}>
                                    <img className="h-16" src="./assets/images/icons/arrow-left.svg" />
                                </Link>
                                <div className="w-100 overflow-hidden ms-2 ms-xl-3 me-1">
                                    <h1 className="mb-0 h4 lh-base text-truncate">{t('property.condos')}</h1>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                            <div className="input-group">
                                <input type="text" id="search" className="form-control" placeholder={t('formLabel.typeSearch')} aria-describedby="basic-addon2" onChange={(e) => getAllCondos(e.target.value)} />
                                <span className="input-group-text" id="basic-addon2">
                                    <img alt="img" src="./assets/images/icons/search.svg" />
                                </span>
                            </div>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end mb-xl-0 col-xl mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <Link to={"#"} onClick={formShow} type="button"
                                        className="fw-bold">
                                        <img src="./assets/images/icons/plus-light.svg" className="me-1" />
                                        {t('property.newCondos')}
                                    </Link>
                                </li>
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    {/* <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" /> */}
                                    <Dropdown>
                                        <Dropdown.Toggle variant="link" size="sm" className="p-0 fw-bold dropdown-arrow-none" id="dropdownMenuButton1">
                                            <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item><Link to={""}>Print the screen</Link></Dropdown.Item>
                                            <Dropdown.Item><Link to={""}>Condos</Link></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                {/* <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                <Link to={"#"} className="fw-bold">
                                    <img src="./assets/images/icons/settings-light.svg" />
                                </Link>
                            </li> */}
                                <li className="list-inline-item mb-md-0 mb-1">
                                    <Link to={"#"} className="fw-bold">
                                        <img src="assets/images/icons/question_icon.svg" alt="Help" className="h-20" />
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* <!-- /Breadcrumb section end -->*/}
            {/* <!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {/*  <!-- Table start -->*/}
                <div className="table-responsive">
                    {
                        status ?
                            <Loader />
                            :
                            <Card>
                                <Card.Body className="pb-1">
                                    <Table className="table-custom table-custom-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th className="w-24">
                                                    {data && data.length > 0 ?
                                                        <Checkbox type="checkbox" name="selectAll" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} />
                                                        : ''}
                                                </th>
                                                <th>{t('property.condominium')}</th>
                                                <th></th>
                                                <th>{t('property.trustee')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {uniqueSet && uniqueSet.length > 0 ? uniqueSet.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className={"check_Checkbox"}>
                                                            <Checkbox key={item["id"]} type="checkbox" name={item["name"]} id={item["id"]} handleClick={handleClick} isChecked={isCheck.includes(item["id"])} />
                                                        </td>
                                                        <td className="text-dark-70">
                                                            <Link className="text-reset" to={`/condos_details/${item["id"]}`}>{item["name"]}</Link>
                                                        </td>
                                                        <td>
                                                            {allPropertyCount[`${item["id"]}`] !== undefined ? allPropertyCount?.[`${item["id"]}`]?.["count"] : 0} property
                                                        </td>
                                                        <td>
                                                            {(item["trustee_name"] !== null || item["trustee_email"] !== null || item["trustee_mobile"] !== null) ?
                                                                <span className='d-flex align-item-center'>
                                                                    {item["trustee_name"] || ''}<br /> {item["trustee_email"] || ''}
                                                                    <br />
                                                                    {item["trustee_mobile"] || ''}
                                                                </span>
                                                                :
                                                                <svg width="9" height="17" viewBox="0 0 10 18" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M5 0C2.23772 0 0 2.23772 0 5C0 5.55228 0.447715 6 1 6C1.55228 6 2 5.55228 2 5C2 3.34228 3.34228 2 5 2C6.65772 2 8 3.34228 8 5C8 5.81648 7.80143 6.29447 7.56209 6.62855C7.3004 6.99383 6.93729 7.26709 6.43347 7.61346L6.31831 7.69229C5.87116 7.99769 5.29529 8.39102 4.84929 8.93923C4.3224 9.58685 4 10.4061 4 11.5V12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12V11.5C6 10.8439 6.1776 10.4756 6.40071 10.2014C6.65665 9.88681 7.00256 9.6491 7.51374 9.29782L7.56653 9.26154C8.06271 8.92041 8.6996 8.47492 9.18791 7.79332C9.69857 7.08053 10 6.18352 10 5C10 2.23772 7.76228 0 5 0ZM5 17.25C5.69036 17.25 6.25 16.6904 6.25 16C6.25 15.3096 5.69036 14.75 5 14.75C4.30964 14.75 3.75 15.3096 3.75 16C3.75 16.6904 4.30964 17.25 5 17.25Z"
                                                                        fill="#4c4c4c" />
                                                                </svg>}

                                                        </td>
                                                    </tr>)
                                            }) :
                                                <tr>
                                                    <td colSpan={4}>
                                                        <span>{t('formLabel.noRecordsFounds')}</span>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                    }
                </div>
                {/* <!-- /Table end -->*/}
                <div className="mt-lg-4 mt-3"> {t('formLabel.moreItems')}</div>
                <p className="d-block py-2">{t('formLabel.listing')} {total} {t('property.condos')}.</p>

                {/* <!-- Nav listing start from here -->*/}
                <ul className="list-inline mb-0">
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        <Dropdown>
                            <Dropdown.Toggle variant="light" size="sm" id="dropdownMenuButton1">
                                {t('formLabel.mark')}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={handleSelectAll} href="#/action-1">{t('formLabel.all')}</Dropdown.Item>
                                <Dropdown.Item onClick={noneHandleSelectAll} href="#/action-2">{t('formLabel.none')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        <span className="px-1">
                            {t('formLabel.withTagged')}
                        </span>
                    </li>
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        <Button type="button" variant="light" size="sm" onClick={handleMouseEvent} name={t('formLabel.sendEmail')}>{t('formLabel.sendEmail')}</Button>
                    </li>
                </ul>
                {/* <!-- /Nav listing  end --> */}
            </main>
            {/* <!-- /Main content start end --> */}

            {/*---------------Condos form modal------------*/}

            <Modal show={show} onHide={formClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('property.newCondos')} </Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(condosSubmit)}>
                    <Modal.Body className="accordion-custom">
                        <div data-step="1" style={{ display: hide ? "block" : "none" }}>
                            <FloatingLabel controlId="floatingSelect" label={t('formLabel.cnpj')}>
                                <Form.Control type="text" placeholder={t('formLabel.cnpj')} className={`${errors.condo_cnpj ? 'is-invalid' : ''}`}  {...register('condo_cnpj')} onChange={(e) => handleCPF_CNJPVaue(e, "cnpj")} />
                                <Form.Control.Feedback type="invalid">{errors.condo_cnpj?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                        </div>
                        <div data-step="2" style={{ display: hide2 ? "block" : "none" }}>
                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.name') + " *"}>
                                <Form.Control type="text" placeholder={t('formLabel.name')} className={`form-control ${errors.condo_name ? 'is-invalid' : ''}`}  {...register('condo_name')} />
                                <Form.Control.Feedback type="invalid">{errors.condo_name?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                            <Row>
                                <Col xs={6}>
                                    <InputGroup>
                                        <FloatingLabel label={t('formLabel.fantasyName')}>
                                            <Form.Control type="text" placeholder={t('formLabel.fantasyName')} className={`form-control ${errors.condo_fantasy_name ? 'is-invalid' : ''}`} {...register('condo_fantasy_name')} />
                                            <Form.Control.Feedback type="invalid">{errors.condo_fantasy_name?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                        <InputGroup.Text onClick={administratorShow}>
                                            <img src="assets/images/icons/documents-add-light.svg" alt="Documents add" className="h-20" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col xs={6}>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.fantasyName')}>
                                        <Form.Select aria-label={t('formLabel.administrator')} className={`form-control ${errors.condo_administrator ? 'is-invalid' : ''}`} {...register('condo_administrator')}>
                                            <option value="">{t('dropdownItem.select')}</option>
                                            {adminData && adminData.length > 0 ? adminData.map((item) => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            )) :
                                                <option value="">{t('formLabel.noRecordsFounds')}</option>
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.condo_administrator?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <span className="d-block mb-3 fw-bold">{t('formLabel.address')}</span>
                            <FloatingLabel className="mb-3 removeInsertDisable" controlId="floatingSelect" label={t('formLabel.zipCode')}>
                                <Form.Control type="text" placeholder={t('formLabel.zipCode')} className={`form-control ${errors.condo_zipcode ? 'is-invalid' : ''}`}  {...register('condo_zipcode')} onChange={(e) => getLocation(e, "zipcode", "first")} maxLength={9} minLength={9} />
                                <Form.Control.Feedback type="invalid">{errors.condo_zipcode?.message}</Form.Control.Feedback>
                            </FloatingLabel>
                            <Row>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.address')}>
                                        <Form.Control type="text" placeholder={t('formLabel.address')} className={`form-control ${errors.condo_address ? 'is-invalid' : ''}`} {...register('condo_address')} />
                                        <Form.Control.Feedback type="invalid">{errors.condo_address?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.number')}>
                                        <Form.Control type="text" placeholder={t('formLabel.number')} className={`form-control ${errors.condo_number ? 'is-invalid' : ''}`} {...register('condo_number')} />
                                        <Form.Control.Feedback type="invalid">{errors.condo_number?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.complement')}>
                                        <Form.Control type="text" placeholder={t('formLabel.complement')} className={`form-control ${errors.condo_complement ? 'is-invalid' : ''}`} {...register('condo_complement')} />
                                        <Form.Control.Feedback type="invalid">{errors.condo_complement?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                        <Form.Control type="text" placeholder={t('formLabel.neighborhood')} className={`form-control ${errors.condo_neighborhood ? 'is-invalid' : ''}`} {...register('condo_neighborhood')} />
                                        <Form.Control.Feedback type="invalid">{errors.condo_neighborhood?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.city')}>
                                        <Form.Control type="text" placeholder={t('formLabel.city')} className={`form-control ${errors.condo_city ? 'is-invalid' : ''}`} {...register('condo_city')} />
                                        <Form.Control.Feedback type="invalid">{errors.condo_city?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                                        <Form.Select aria-label={t('formLabel.state')} className={`form-control ${errors.condo_state ? 'is-invalid' : ''}`} {...register('condo_state')}>
                                            <option value="">{t('dropdownItem.select')}..</option>
                                            {allStates && allStates.length > 0 &&
                                                allStates.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                    )
                                                })}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.condo_state?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Accordion className="accordion-custom">
                                <Accordion.Item eventKey="1" >
                                    <Accordion.Header>{t('formLabel.contact')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.telephone')}  {...register('condo_telephone')} onChange={HandleInput} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                                                    <Form.Control type="text" className={`form-control ${errors.condo_email ? 'is-invalid' : ''}`} placeholder={t('formLabel.email')} {...register('condo_email')} />
                                                    <Form.Control.Feedback type="invalid">{errors.condo_email?.message}</Form.Control.Feedback>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>{t('formLabel.observation')}</Accordion.Header>
                                    <Accordion.Body>
                                        <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.observation')}>
                                            <Form.Control as="textarea" placeholder={t('formLabel.observation')} {...register('condo_observation')} style={{ height: '100px' }} />
                                        </FloatingLabel>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3" >
                                    <Accordion.Header>{t('formLabel.moreFields')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.billingMethod')}>
                                                    <Form.Select aria-label={t('formLabel.billingMethod')} {...register('condo_billing_method')}>
                                                        <option value="">{t('dropdownItem.select')}</option>
                                                        <option value="1">{t("expiredMonth")}</option>
                                                        <option value="2">{t("Monthtoexpire")}</option>
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.condoDueDate')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.condoDueDate')} {...register('condo_condo_due_date')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div data-step="3" style={{ display: hide3 ? "block" : "none" }}>
                            <span className="d-block mb-3 fw-bold">{t('property.trustee')}</span>
                            <Row>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.name')}>
                                        <Form.Control type="text" placeholder={t('formLabel.name')} {...register('condo_trustee_name')} />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('RG')}>
                                        <Form.Control type="text" placeholder={t('RG')} {...register('condo_trustee_rg')} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cpf')}>
                                        <Form.Control type="text" placeholder={t('formLabel.cpf')} {...register('condo_trustee_cpf')} onChange={(e) => handleCPF_CNJPVaue(e, "cpf")} />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                        <Form.Control type="text" placeholder={t('formLabel.telephone')}  {...register('condo_trustee_telephone')} onChange={HandleInput} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.mobileNumber')}>
                                        <Form.Control type="text" placeholder={t('formLabel.mobileNumber')} {...register('condo_trustee_mobile')} onChange={HandleInputMob} />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                                        <Form.Control type="text" placeholder={t('formLabel.email')} {...register('condo_trustee_email')} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Accordion className="accordion-custom">
                                <Accordion.Item eventKey="4" >
                                    <Accordion.Header>{t('formLabel.liquidatorAddress')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.zipCode')}>
                                                <Form.Control type="text" placeholder={t('formLabel.zipCode')} {...register('condo_liquidator_zipcode')} onChange={(e) => getLocation(e, "zipcode", "third")} maxLength={9} minLength={9} />
                                            </FloatingLabel>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.address')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.address')} {...register('condo_liquidator_address')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.number')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.number')} {...register('condo_liquidator_number')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.complement')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.complement')} {...register('condo_liquidator_complement')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.neighborhood')} {...register('condo_liquidator_neighborhood')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.city')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.city')} {...register('condo_liquidator_city')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                                                    <Form.Select aria-label={t('formLabel.state')} {...register('condo_liquidator_state')}>
                                                        <option value="">{t('dropdownItem.select')}..</option>
                                                        {allStates && allStates.length > 0 &&
                                                            allStates.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                                )
                                                            })}
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>{t('formLabel.caretaker')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.name')}>
                                                <Form.Control type="text" placeholder={t('formLabel.name')}  {...register('condo_caretaker_name')} />
                                            </FloatingLabel>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('RG')}>
                                                    <Form.Control type="text" placeholder={t('RG')} {...register('condo_caretaker_rg')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cpf')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.cpf')} {...register('condo_caretaker_cpf')} onChange={(e) => handleCPF_CNJPVaue(e, "cpf")} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6" >
                                    <Accordion.Header>Caretaker's contact</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                                <Form.Control type="text" placeholder={t('formLabel.telephone')} {...register('condo_caretaker_telephone')} onChange={HandleInput} />
                                            </FloatingLabel>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.mobileNumber')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.mobileNumber')} {...register('condo_caretaker_mobile')} onChange={HandleInputMob} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.email')} {...register('condo_caretaker_email')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7" >
                                    <Accordion.Header>Attorney</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.name')}>
                                                <Form.Control type="text" placeholder={t('formLabel.name')} {...register('condo_attorney_name')} />
                                            </FloatingLabel>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('RG')}>
                                                    <Form.Control type="text" placeholder={t('RG')} {...register('condo_attorney_rg')} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cpf')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.cpf')} {...register('condo_attorney_cpf')} onChange={(e) => handleCPF_CNJPVaue(e, "cpf")} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8" >
                                    <Accordion.Header>{t('formLabel.lawyerContact')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                                <Form.Control type="text" placeholder={t('formLabel.telephone')} {...register('condo_lawyer_telephone')} onChange={HandleInput} />
                                            </FloatingLabel>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.mobileNumber')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.mobileNumber')} {...register('condo_lawyer_mobile')} onChange={HandleInputMob} />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                                                    <Form.Control type="text" placeholder={t('formLabel.email')} {...register('condo_lawyer_email')} />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-sm-flex justify-content-between w-100 align-items-center">
                            {/* <div className="me-sm-2 mb-sm-0 mb-2 d-flex">
                                <Form.Group>
                                    <Form.Check type="checkbox" {...register('condo_is_vendor')} label={t("button.afterSaving")} />
                                </Form.Group>
                            </div> */}
                            <div className="flex-shrink-0">
                                <Button variant="outline-primary" className="rounded-4 me-2 me-lg-3" onClick={formClose}>
                                    {t('button.close')}
                                </Button>
                                <Button variant="outline-primary" className="js-btn-step me-2 me-lg-3" onClick={handleSubmit(hide2 ? step1 : hide3 ? step2 : step1)}  >
                                    {t('button.previous')}
                                </Button>
                                {!hide3 ?
                                    <Button variant="primary" className="min-w-auto js-btn-step zipCodeCheck" onClick={handleSubmit(hide ? step2 : step3)}  >
                                        {t('button.next')}
                                    </Button>
                                    :
                                    <Button variant="primary" type="submit" onClick={openErrorbox} >
                                        {t('button.save')}
                                    </Button>
                                }
                            </div>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/*---------------Mail/Letter form modal------------*/}

            <Modal show={show2} onHide={mailClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{mailText}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(mailSubmit)}>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.caption')}>
                                    <Form.Control type="text" placeholder={t('formLabel.caption')} className={`form-control ${errors.caption ? 'is-invalid' : ''}`}  {...register('caption')} />
                                    <Form.Control.Feedback type="invalid">{errors.identifier?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.identifier')}>
                                    <Form.Select aria-label={t('formLabel.identifier')} className={`form-control ${errors.model ? 'is-invalid' : ''}`}  {...register('identifier')}>
                                        <option value="">{t('dropdownItem.select')}</option>
                                        <option value="1">A</option>
                                        <option value="2">B</option>
                                        <option value="3">C</option>
                                        <option value="4">D</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.model?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.title')}>
                                    <Form.Control type="text" placeholder={t('formLabel.title')} className={`form-control ${errors.title ? 'is-invalid' : ''}`}  {...register('title')} />
                                    <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.variables')}>
                                    <Form.Select aria-label={t('formLabel.variables')} className={`form-control ${errors.variable ? 'is-invalid' : ''}`}  {...register('variable')}>
                                        <option value="">{t('dropdownItem.select')}</option>
                                        <option value="1">A</option>
                                        <option value="2">B</option>
                                        <option value="3">C</option>
                                        <option value="4">D</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.variable?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Control type="hidden" placeholder="description" className={`${errors.description ? 'is-invalid' : ''}`}  {...register('description')} />
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={desc}
                                    onReady={(editor) => {
                                        const data = (editor as any).getData();
                                        setDesc(data)
                                    }}                                   
                                    onChange={(event, editor) => {
                                        const data = (editor as any).getData();
                                        setDesc(data)
                                    }}
                                    onBlur={(event, editor) => {
                                        const data = (editor as any).getData();
                                        setDesc(data)

                                    }}
                                    onFocus={(event, editor) => {
                                        const data = (editor as any).getData();
                                        setDesc(data)
                                    }}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={mailClose}>
                            {t('button.close')}
                        </Button>
                        <Button type="submit" variant="primary" name="send" >
                            {t('button.toSend')}
                        </Button>
                        <Button type="submit" variant="primary" name="save&send" >
                            {t('button.saveSend')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/*---------------Administrator form modal------------*/}

            <Modal show={show3} onHide={administratorClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('property.newAdministrator')}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(administratorSubmit)}>
                    <Modal.Body>
                        <FloatingLabel controlId="floatingInput" label={t('formLabel.name') + " *"} className="mb-3" >
                            <Form.Control type="text" placeholder={t('formLabel.name')} className={`form-control ${errors.admin_name ? 'is-invalid' : ''}`}  {...register('admin_name')} />
                            <Form.Control.Feedback type="invalid">{errors.admin_name?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.cnpj') + " *"}>
                                    <Form.Control type="text" placeholder={t('formLabel.cnpj') + " *"} className={`form-control ${errors.admin_cnpj ? 'is-invalid' : ''}`}  {...register('admin_cnpj')} />
                                    <Form.Control.Feedback type="invalid">{errors.admin_cnpj?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.responsible')}>
                                    <Form.Control type="text" placeholder={t('formLabel.responsible')} className={`form-control ${errors.admin_responsible ? 'is-invalid' : ''}`}  {...register('admin_responsible')} />
                                    <Form.Control.Feedback type="invalid">{errors.admin_responsible?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.email')}>
                                    <Form.Control type="text" placeholder={t('formLabel.email')} className={`form-control ${errors.admin_email ? 'is-invalid' : ''}`}  {...register('admin_email')} />
                                    <Form.Control.Feedback type="invalid">{errors.admin_email?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.telephone')}>
                                    <Form.Control type="text" placeholder={t('formLabel.telephone')} className={`form-control ${errors.admin_telephone ? 'is-invalid' : ''}`}  {...register('admin_telephone')} onChange={HandleInput} />
                                    <Form.Control.Feedback type="invalid">{errors.admin_telephone?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Accordion className="accordion-custom">
                            <Accordion.Item eventKey="1" >
                                <Accordion.Header>{t('formLabel.address')}</Accordion.Header>
                                <Accordion.Body>
                                    <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.zipCode')}>
                                        <Form.Control type="text" placeholder={t('formLabel.zipCode')} className={`form-control ${errors.admin_zipcode ? 'is-invalid' : ''}`} {...register('admin_zipcode')} onChange={(e) => getLocation(e, "zipcode", "second")} maxLength={9} minLength={9} />
                                        <Form.Control.Feedback type="invalid">{errors.admin_zipcode?.message}</Form.Control.Feedback>
                                    </FloatingLabel>
                                    <Row>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.address')}>
                                                <Form.Control type="text" placeholder={t('formLabel.address')} className={`form-control ${errors.admin_address ? 'is-invalid' : ''}`} {...register('admin_address')} />
                                                <Form.Control.Feedback type="invalid">{errors.admin_address?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.number')}>
                                                <Form.Control type="text" placeholder={t('formLabel.number')} className={`form-control ${errors.admin_number ? 'is-invalid' : ''}`} {...register('admin_number')} />
                                                <Form.Control.Feedback type="invalid">{errors.admin_number?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.complement')}>
                                                <Form.Control type="text" placeholder={t('formLabel.complement')} className={`form-control ${errors.admin_complement ? 'is-invalid' : ''}`} {...register('admin_complement')} />
                                                <Form.Control.Feedback type="invalid">{errors.admin_complement?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.neighborhood')}>
                                                <Form.Control type="text" placeholder={t('formLabel.neighborhood')} className={`form-control ${errors.admin_neighborhood ? 'is-invalid' : ''}`} {...register('admin_neighborhood')} />
                                                <Form.Control.Feedback type="invalid">{errors.admin_neighborhood?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.city')}>
                                                <Form.Control type="text" placeholder={t('formLabel.city')} className={`form-control ${errors.admin_city ? 'is-invalid' : ''}`} {...register('admin_city')} />
                                                <Form.Control.Feedback type="invalid">{errors.admin_city?.message}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.state')}>
                                                <Form.Select className={`form-control ${errors.admin_state ? 'is-invalid' : ''}`}  {...register('admin_state')} aria-label={t('formLabel.state')}>
                                                    <option value="">{t('dropdownItem.select')}..</option>
                                                    {allStates && allStates.length > 0 &&
                                                        allStates.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                            )
                                                        })}
                                                </Form.Select>
                                            </FloatingLabel>
                                            <Form.Control.Feedback type="invalid">{errors.admin_state?.message}</Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>{t('formLabel.observation')}</Accordion.Header>
                                <Accordion.Body>
                                    <FloatingLabel controlId="floatingTextarea2" label={t('formLabel.observation')}>
                                        <Form.Control as="textarea" placeholder={t('formLabel.observation')} {...register('observation')} style={{ height: '100px' }} />
                                    </FloatingLabel>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* <Form.Group className="mb-3">
                            <Form.Check type="checkbox" {...register('admin_is_vendor')} label="This admin is also a vendor." />
                        </Form.Group> */}
                        <Button variant="outline-primary" onClick={administratorClose}>
                            {t('button.close')}
                        </Button>
                        <Button type="submit" variant="primary" onClick={openErrorbox}>
                            {t('button.toSave')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </section>
    )
}
export default Condos;