import React, { useEffect } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import moment from "moment";
import "moment/min/locales";
import { FormProvider, useForm } from "react-hook-form";

import { BankAccountActionButtons, BankAccountForm } from "../BankAccountForm";
import { useBankAccount } from "../BankAccountForm/BankAccountProvider";
import { getBankAccountFormSchema } from "../BankAccountForm/schema";
import { AccountType } from "../BankAccountForm/types";
import { Divider } from "../Divider";
import { Drawer } from "../Drawer";
import { useBankAccountDrawer } from "./BankAccountDrawerStore";

export function BankAccountDrawerContainer() {
  const { bankAccountDrawerAction, setShowBankAccountDrawer, bankAccount } =
    useBankAccountDrawer();
  const {
    accountVariation,
    selectedBank,
    shouldHomologate,
    resetContextValues,
    setAccountVariation,
    setSelectedBank,
    setShouldHomologate,
    setAccountType
  } = useBankAccount();
  const form = useForm({
    resolver: zodResolver(
      getBankAccountFormSchema({
        accountVariation,
        selectedBank,
        shouldHomologate,
      })
    ),
    values: {
      ...bankAccount,
      moneyaccount: bankAccount?.moneyaccount.toString() ?? "0",
      date: bankAccount?.date ?? moment(new Date()).format("YYYY-MM-DD"),
      accountVariation:
        bankAccount?.accountType === AccountType.MANUAL
          ? AccountType.MANUAL
          : AccountType.CORRENTE,
      accountType: bankAccount?.accountType === AccountType.INVESTIMENTO ? AccountType.INVESTIMENTO : AccountType.CORRENTE,
      shouldHomologate: bankAccount?.isHomologated ? "1" : "0",
      ...(bankAccount?.isHomologated && {
        ContaCodigoBeneficiario:
          bankAccount.homologatedAccount?.cod_beneficiario ?? "",
        ConvenioNumero:
          bankAccount.homologatedAccount?.convenios.at(0)?.numero_convenio ??
          "",
        ConvenioDescricao:
          bankAccount.homologatedAccount?.convenios.at(0)?.descricao_convenio ??
          "",
        ConvenioCarteira:
          bankAccount.homologatedAccount?.convenios.at(0)?.carteira ?? "",
        ConvenioPadraoCNAB:
          bankAccount.homologatedAccount?.convenios.at(0)?.padraoCNAB ?? "",
        ConvenioNumeroContrato:
          bankAccount.homologatedAccount?.convenios.at(0)?.numero_contrato ??
          "",
        carteira_codigo:
          bankAccount.homologatedAccount?.convenios.at(0)?.carteira_codigo ??
          "",
        ConvenioVersaoLayoutArquivo:
          bankAccount.homologatedAccount?.convenios.at(0)?.versao_layout ?? "",
        ContaCodigoEmpresa: bankAccount.homologatedAccount?.cod_empresa ?? "",
        TituloVariacaoCarteira: bankAccount.titulo_variacao_carteira ?? "",
        TituloDocEspecie: bankAccount.titulo_doc_especie ?? "",
        TituloAceite: bankAccount.titulo_aceite ?? "",
        TituloCodBaixaDevolucao: bankAccount.titulo_codigo_baixa_devolucao ?? "",
        TituloPrazoBaixa: bankAccount.titulo_prazo_baixa ?? "",
        ConvenioDensidaDeRemessa: bankAccount.homologatedAccount?.convenios.at(0)?.densidade_remessa ?? "",
        ConvenioNumeroRemessa: bankAccount.homologatedAccount?.convenios.at(0)?.numero_remessa ?? "",
      }),
    },
  });

  useEffect(() => {
    if (bankAccount) {
      setSelectedBank(bankAccount.bankname);

      setAccountVariation(
        bankAccount.accountType === AccountType.MANUAL
          ? AccountType.MANUAL
          : AccountType.CORRENTE
      );
      // form.setValue(
      //   "accountVariation",
      //   bankAccount.accountType === AccountType.MANUAL
      //     ? AccountType.MANUAL
      //     : AccountType.CORRENTE
      // );

      setShouldHomologate(bankAccount.isHomologated);
      // form.setValue("shouldHomologate", bankAccount.isHomologated ? "1" : "0");

      setAccountType(
        bankAccount.accountType === AccountType.INVESTIMENTO
          ? AccountType.INVESTIMENTO
          : AccountType.CORRENTE
      );
      // form.setValue(
      //   "accountType",
      //   bankAccount.accountType === AccountType.INVESTIMENTO
      //     ? AccountType.INVESTIMENTO
      //     : AccountType.CORRENTE
      // );
    }
  }, [
    bankAccount,
    form,
    setAccountType,
    setAccountVariation,
    setSelectedBank,
    setShouldHomologate,
  ]);

  const showBankAccountDrawer = !!bankAccountDrawerAction;

  return (
    <FormProvider {...form} key={bankAccount?.id}>
      <Drawer.Root
        show={showBankAccountDrawer}
        onHide={() => {
          form.reset();
          resetContextValues();
          setShowBankAccountDrawer({
            action: undefined,
            bankAccount: undefined,
          });
        }}
      >
        <Drawer.Header>Conta bancária</Drawer.Header>

        <Drawer.Body>
          <BankAccountForm />
        </Drawer.Body>

        <Drawer.Footer>
          <Divider />

          <BankAccountActionButtons />
        </Drawer.Footer>
      </Drawer.Root>
    </FormProvider>
  );
}
