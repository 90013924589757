import React from 'react';
import { Modal, Form, Button, Badge, Table } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { converter, encrypt_Decrypt } from '../../../../constants/utils';

const ViewCharge = (props) => {
    //View charge modal close open function
    const { showViewCharge,viewChargeClose,viewChargeShow,singleViewCharge,totalSummaryAmt,summaryData } = props;
    const [t] = useTranslation();
    const toFixed = (amount) => {
        if (amount) {
            return parseFloat(amount).toFixed(2);
        } else { return amount; }
    }
    return (
        <>
            <Modal show={showViewCharge} onHide={viewChargeClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("newTransfer.charge")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pt-0">
                    <div className="border border-light rounded-4 p-lg-4 p-3">
                    <div className="d-sm-flex align-items-center justify-content-between mb-3">
                                <h6 className="mb-0 fw-bold d-flex align-items-center">{t("transfer")} { singleViewCharge.index } <Badge bg="success-40" className="text-dark ms-xl-4 ms-lg-3 ms-2">{t("newTransfer.guaranteed")}</Badge></h6>
                                <span className="ms-sm-2"> <Button variant="link" className="p-0 fw-bold">{t("newTransfer.openDetails")}</Button></span>
                            </div>
                        <Table borderless className="mb-0">
                            <thead>
                                <tr>
                                    <th className="text-secondary ps-0 pb-0 py-0">{t("description")}</th>
                                    <th className="text-secondary text-end pe-0 py-0">{t("value")} (R$)</th>
                                </tr>
                            </thead>
                            <tbody>
                                { 
                                    summaryData && summaryData.length ?
                                    summaryData.map((item, index) => (
                                        <>
                                            <tr key={index} >
                                                <td className="ps-0">
                                                    <span className="d-block">
                                                        {item.id == '3.1' ? t("newTransfer.standardRent") : item.title == "Administration fee" ? t("Dashboard.Administrationfee") : item.title == "Interest" ? t('formReceipt.allFees') : item.title == "Fine" ? t('formReceipt.fine') : item.title =="Tarifa Doc-Ted" ? t("transferDocSetting") : item.title }
                                                    </span>
                                                </td>
                                                <td className="text-end pe-0">
                                                    { item.title!="Standard rent" ? item.sign : '' } { converter(item.amount) }
                                                </td>
                                            </tr>
                                        </>
                                    )) :
                                    <></>
                                }
                                
                                <tr>
                                    <td className="ps-0 pb-0 border-top">
                                        <span className="d-block">{t("total")} </span>
                                    </td>
                                    <td className="text-end pe-0 pb-0 fw-bold border-top">{ converter(totalSummaryAmt) }</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    </Modal.Body>
                </Form>
            </Modal>
        </>
    )
}
export default ViewCharge
