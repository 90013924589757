import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { useForm, } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Modal, Form, Button, Row, Col, FloatingLabel, Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { openEmailPop } from '../../../../redux/slice/contractDataSlice';
import { emailPost, mailData, emailPostSave } from '../../../../redux/slice/SendEmailSlice';
import { openErrorbox } from './actionPerform'
import customerService from '../../../../services/customerService';
import { getComapnyLogo, uploadImage } from '../../../../redux/slice/companyDataSlice';
import i18n from '../../../../i18n';
import '@ckeditor/ckeditor5-build-classic/build/translations/pt';
const SendEmails = (props) => {
    let imgtype = '';
    let allEmails = props.mailData;
    let customer_type = props.customer_type;
    let personId = props.personId;
    let page_type = props.page_type;
    const dispatch = useDispatch<AppDispatch>()
    const [t] = useTranslation();
    const { emailShow } = useSelector((state: RootState) => state.contractData)
    const { getlogo, status } = useSelector((state: RootState) => state.companyData);
    const mailClose = () => { dispatch(openEmailPop(false)); reset(); }
    const [templateData, setTemplateData] = useState<any>([]);
    const [variableData, setVariableData] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [double, setDouble] = useState(false);
    const [logoPath, setLogoPath] = useState<any>("/logos/logo.png");
    const mailValidationSchema = Yup.object().shape({
        // identifier: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired')),
        model: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        title: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
    });
    let frm = {
        resolver: yupResolver(mailValidationSchema)
    }
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<mailData>(frm);

    let [desc, setDesc] = useState('');

    function uniqueArray(arr) {
        var uniqueValues = {};
        for (var i = 0; i < arr.length; i++) {
            uniqueValues[arr[i]] = true;
        }
        var uniqueArray = Object.keys(uniqueValues).map(function (key) {
            return parseInt(key);
        });
        return uniqueArray;
    } 
    const mailSubmit = async (data: mailData) => {
        setLoading(true);
        let successCount: any = 0;
        let totalEmails = 0; // Variable to count total emails to send

        if (desc !== "") {
            if (personId && personId.length > 0) {
                personId = uniqueArray(personId);
                totalEmails = personId.length; // Set totalEmails to personId length

                // Initialize SweetAlert with a progress bar
                Swal.fire({
                    title: t("Sweetalert.emailProgress"),
                    html: '<div id="progress-bar" style="width: 100%; background-color: #f3f3f3; border-radius: 5px; overflow: hidden;">' +
                        '<div id="progress-bar-inner" style="width: 0; height: 30px; background-color: #4caf50;"></div>' +
                        '</div>',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });

                for (let i = 0; i < personId.length; i++) {
                    if (allEmails[i] && allEmails[i] !== null && allEmails[i] !== undefined) {
                        try {
                            const response = await customerService.getCustomerRenderModels(personId[i], data.model, desc, customer_type,data.title);
                            let mailContent = response.data.data[0].ST_TEXTO_MOH;

                            let emaildata: any = {
                                status: "new",
                                subject: "Email vindo do POSTMAN",
                                content: mailContent,
                                layout: "default",
                                sender: "noreply@dev.kenlo.io",
                                to: [allEmails[i]],
                                cc: ["vikas6h@wegile.com"],
                                bcc: ["karan@wegile.com"],
                                identifier: "testing",
                                model: data.model,
                                caption: "test",
                                title: data.title,
                            };

                            let res = await dispatch(emailPostSave(emaildata));
                            if (res.payload.resCode == 201) {
                                successCount++;
                            } else {
                                throw new Error('Failed to send email');
                            }

                            // Update the progress bar
                            let progress = ((i + 1) / totalEmails) * 100;
                            const progressBarInner = document.getElementById('progress-bar-inner');
                            if (progressBarInner) {
                                progressBarInner.style.width = progress + '%';
                            }
                        } catch (error) {
                            Swal.fire({
                                title: t("Sweetalert.Error"),
                                text: t("Sweetalert.somethingWent"),
                                icon: "error",
                                confirmButtonText: "OK",
                                timer: 2000
                            });
                            setLoading(false);
                            setDesc('');
                            return; // Exit function on error
                        }
                    }
                }
            } else {
                totalEmails = allEmails.length; // Set totalEmails to allEmails length
            }

            // Display Sweetalert message outside the loop
            if (successCount > 0) {
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: `${successCount} out of ${totalEmails} emails sent successfully.`,
                    icon: "success",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            } else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.noEmailSent"),
                    icon: "error",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }

            setLoading(false);
            setDesc('');
            dispatch(openEmailPop(false));
            reset();
        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.emailsMessage"),
                confirmButtonText: "OK",
                timer: 2000
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await customerService.getTemplates();
                const owner_response = await customerService.getAllOwnerTemplate();
                const tenant_response = await customerService.getAllTenantTemplate();
                await dispatch(getComapnyLogo());
                const result = response.data.data[0];
                const owner_result = owner_response.data.data;
                const tenant_result = tenant_response.data.data;
                const variableResult = response.data.data[1];
                const propertyVariableResult = response.data.data[2];
                const ownerVariableResult = response.data.data[3];
                const tenantVariableResult = response.data.data[4];

                if (customer_type === "Owner" && owner_result.length > 0) {
                    setVariableData(ownerVariableResult);
                    setTemplateData(owner_result);
                } else if (customer_type === "Tenant" && tenant_result.length > 0) {
                    setVariableData(tenantVariableResult);
                    setTemplateData(tenant_result);
                } else if (result.length > 0 && customer_type !== "Owner" && customer_type !== "Tenant") {
                    setTemplateData(result);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        setValue("title", ``);
        setDesc(``);
        fetchData();
    }, [dispatch, imgtype, customer_type]);

    let type = getlogo?.logo_name
    let gettype = type?.split(/[#?]/)[0].split('.').pop().trim()
    // Split the first part by '.' and retrieve the last part (logo file extension)
    imgtype = gettype;

    const HandleModule = (e) => {
        const selectedModelId = e.target.value;
        let selectedModel: any = null;

        for (let i = 0; i < templateData.length; i++) {
            if (templateData[i].ID_MODELO_MOH == selectedModelId) {
                selectedModel = templateData[i];
                break;
            }
        }
        if (selectedModel) {
            const newText: any = `${selectedModel.ST_TEXTO_MOH}` || ''; // Use the actual property name if needed
            const newTitle = selectedModel.ST_TITULO_MOH || ''
            setDesc(newText);
            setValue("title", `${newTitle}`)
        } else {
            setDesc(''); // or set to a default value
            setValue("title", ``)
        }
    };

    const customUploadAdapter = (loader) => {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const data = new FormData();
                    loader.file
                        .then(async(file) => {
                            data.append('image', file);
                            const res = await dispatch(uploadImage(data))
                            if(res.payload.resCode === 202){
                                resolve({
                                    default: res.payload.data.url
                                });
                            }
                        });
                });
            },
        };
    };

    const uploadPlugin = (editor) => {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return customUploadAdapter(loader);
        };
    };

    return (
        <>
            <Modal show={emailShow} className='Owners_and_beneficiariestsx' onHide={mailClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.mailText}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(mailSubmit)}>
                    <Modal.Body className="py-2">
                        <Row id="CurrentPage">
                            {/* <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.identifier')}>
                                    <Form.Control type="text" placeholder={t('formLabel.identifier')} className={` ${errors.identifier ? 'is-invalid' : ''}`}  {...register('identifier')} />
                                    <Form.Control.Feedback type="invalid">{errors.identifier?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col> */}
                            <Col>
                                <FloatingLabel className="mb-3 selectTemp" controlId="floatingSelect" label={t('formLabel.selectTemplate')}>
                                    <Form.Select aria-label={t('formLabel.selectTemplate')} className={` ${errors.model ? 'is-invalid' : ''}`}  {...register('model')} onChange={HandleModule}>
                                        {/* <option value="1">{t("formLabel.ExtractforIR")}</option>
                                        <option value="2">{t("formLabel.Enteryourmodel")}</option> */}
                                        <option value="">{t('dropdownItem.select')}</option>
                                        {/* <option value="new">{t('dropdownItem.newTemplate')}</option> */}
                                        {templateData && templateData.length > 0 ? (
                                            templateData.map(item => (
                                                <option key={item.ID_MODELO_MOH} value={item.ID_MODELO_MOH}>
                                                    {item.ST_NOME_MOH}
                                                </option>
                                            ))
                                        ) : (
                                            <option disabled>{t("dimob.NoDataFound")}</option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.model?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.title')}>
                                    <Form.Control type="text" placeholder={t('formLabel.title')} className={` ${errors.title ? 'is-invalid' : ''}`}  {...register('title')} />
                                    <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col>
                                <FloatingLabel className="mb-3" controlId="floatingSelect" label={t('formLabel.variables')}>
                                    <Form.Select aria-label={t('formLabel.variables')} className={` ${errors.variable ? 'is-invalid' : ''}`}  {...register('variable')}
                                        onChange={(e) => {
                                            const selectedVariable = e.target.value;
                                            const appendedContent = `${desc} %${selectedVariable}%`; setDesc(appendedContent);
                                        }}>
                                        <option value="">{t('dropdownItem.select')}</option>
                                        {variableData && Object.keys(variableData).length > 0
                                            ? Object.keys(variableData).map(key => (
                                                <option key={key} value={variableData[key]}>
                                                    {key}
                                                </option>
                                            ))
                                            : <option disabled>{t("dimob.NoDataFound")}</option>
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.variable?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Control type="hidden" placeholder="description" className={`${errors.description ? 'is-invalid' : ''}`}  {...register('description')} />
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={desc}
                                    config={{
                                        language: {
                                            ui: i18n.language, // UI language
                                            content: i18n.language, // Content language
                                        },
                                    }}
                                    onReady={editor => {
                                        uploadPlugin(editor);
                                        // console.log(editor.config.get('language'));
                                        // const data = editor.getData();
                                        // setDesc(data)
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setDesc(data)
                                    }}
                                    onBlur={(event, editor) => {
                                        const data = editor.getData();
                                        setDesc(data)

                                    }}
                                    onFocus={(event, editor) => {
                                        const data = editor.getData();
                                        setDesc(data)
                                    }}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={mailClose}>
                            {t("button.close")}
                        </Button>
                        {loading ? (
                            <Button disabled={true}>
                                {t("button.submitting")}
                            </Button>

                        ) : (
                            // Your existing Modal content
                            <Button disabled={double} type="submit" variant="primary" onClick={openErrorbox}>
                                {t("button.toSave")}
                            </Button>
                        )}
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default SendEmails