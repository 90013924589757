import { Table, Row, Col, Button, Alert } from 'react-bootstrap';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetch_allFinancialIndexing, getReadSimulateContract, insertReadjustContract } from "../../../redux/slice/expenditureDataSlice";
import { AppDispatch, RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Profile/Company/Loader';
import Swal from 'sweetalert2';
import moment from "moment-timezone";
import { ClllThis } from './Readjustment/ReadjustmentIndexes';
let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
const ReadjustSimulate = () => {
    const { simulateData, simulateStatus } = useSelector((state: RootState) => state.expenditureData)
    const [double, setDouble] = useState(false);
    const [t] = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { id } = useParams();
    let currentDt = new Date(dateBrazil);
    let currentMonth = moment(currentDt).format("MMMM");
    let currentDate = moment(currentDt).format("DD/MM/YYYY");
    const [readName, setReadName] = useState<any>([]);

    useEffect(() => {
        (async () => {
            let dataOutPut = await dispatch(fetch_allFinancialIndexing({}));
            if (dataOutPut["payload"]) {
                let result = await ClllThis(dataOutPut["payload"]["data"], "");
                setReadName(result);
            }
        })()
    }, [])

    useEffect(() => {
        if (typeof location.state !== "undefined" && location.state && location.state.contractId.length > 0 && location.state.index) {
            dispatch(getReadSimulateContract(location.state))
        }
    }, [dispatch, location]);
    const readjustment = async (e: any) => {
        e.preventDefault();
        if (typeof location.state !== "undefined" && location.state && location.state.contractId.length > 0 && location.state.index) {
            Swal.fire({
                title: t("newTextSweetAlert.readjust_it_title"),
                text: t("Sweetalert.Youwontabletomodifyitlater"),
                // icon: 'success','info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t("newTextSweetAlert.Readjust_it"),
                cancelButtonText:  t('button.cancel'),
            }).then(async (result) => {
                if (result.value) {
                    setDouble(true)
                    let response = await dispatch(insertReadjustContract(location.state));
                    if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
                        setDouble(false);
                        Swal.fire({
                            icon: 'success',
                            title: t("Sweetalert.Success"),
                            text: response.payload.message,
                            confirmButtonText: "OK",
                            timer: 2000
                        })
                        setTimeout(() => {
                            navigate('/readjustContracts');
                        }, 2000)
                    }
                    else {
                        setDouble(false)
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: response.payload.message,
                            icon: "error",
                            confirmButtonText: "OK",
                            timer: 2000
                        })
                    }
                }
            })
        }
        else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                icon: "error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    }
    return (
        <section className="content-wrapper">
            {/* <!-- Breadcrumb section start from here --> */}
            <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-end border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="gx-2 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 2, order: 0 }} className="mb-xl-0 mb-3">
                            <Link to={"/readjustContracts"}>
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-left.svg"} className="h-14" alt="Arrow left" />
                            </Link>
                        </Col>
                        <Col xxl={7} xl="auto" className="order-1 order-xl-3 text-xl-end mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item mb-md-0 mb-1">
                                    {
                                        location.state ?
                                            <Button variant='outline-primary' disabled={double} onClick={readjustment} >
                                                {t("translate_readjustment.Readjust")}
                                            </Button>
                                            : ''
                                    }
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                <h4>{currentMonth} {t("translate_readjustment.Readjustment_Simulation")}</h4>
                <small>{currentDate} </small>
                {
                    (simulateStatus == "loading") ?
                        <Loader />
                        :
                        <Table responsive="md" className="table-custom mb-lg-4 mb-3">
                            <thead>
                                <tr>
                                    <th>{t("translate_readjustment.Last_Readjustment")}</th>
                                    <th>{t("contract")}</th>
                                    <th>{t("fixedvalue")}</th>
                                    <th>{t("previous")}</th>
                                    <th>{t("NonPayment.addition")}</th>
                                    <th>{t("translate_readjustment.Updated")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    simulateData.data && simulateData.data.length > 0 ? simulateData.data.map((item: any, index) => (
                                        <tr key={index}>
                                            <td className="text-dark-70">
                                                {item.expireDate}
                                            </td>
                                            <td>
                                                {item.type} {item.propertyId + '/' + item.id}
                                            </td>
                                            <td>
                                                {item.fixedValue}
                                            </td>
                                            <td>
                                                {item.rent_amount}
                                            </td>
                                            <td>
                                                {item.additionValue}
                                            </td>
                                            <td>
                                                {item.updateValue}
                                            </td>
                                        </tr>
                                    ))
                                        :
                                        <tr>
                                            <td colSpan={6}>
                                                <span>{t("formLabel.noRecordsFounds")}</span>
                                            </td>
                                        </tr>
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan={3}>
                                        {t("translate_readjustment.List_of")} {simulateData.totalRow && simulateData.totalRow.totalRow > 0 ? simulateData.totalRow.totalRow : 0} {t("contract")}.
                                    </th>
                                    <th>
                                        {simulateData.totalRow && simulateData.totalRow.totalRent ? simulateData.totalRow.totalRent : 0}
                                    </th>
                                    <th>
                                        {simulateData.totalRow && simulateData.totalRow.totalAddition ? simulateData.totalRow.totalAddition : 0}
                                    </th>
                                    <th>
                                        {simulateData.totalRow && simulateData.totalRow.totalUpdated ? simulateData.totalRow.totalUpdated : 0}
                                    </th>
                                </tr>
                            </tfoot>
                        </Table>
                }

            </main>
        </section>
    )
}
export default ReadjustSimulate;