import React, { useEffect, useState } from 'react';
import { Form, Modal, Button, Row, Col, FloatingLabel, Card, Offcanvas } from 'react-bootstrap';
import CommonToast from './CommonToast';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { contractDatails, custom_post_termination_checklist, get_custom_post_termination_checklist } from '../../../../../../redux/slice/contractDataSlice';
import { AppDispatch, RootState } from '../../../../../../redux/store';
import { LinkHttpChecklist, minDate } from '../../../../../constants/utils';
import { useParams } from 'react-router-dom';
import customerService from '../../../../../../services/customerService';
import moment from 'moment';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

const TerminateAddItem = (props) => {
    const [t] = useTranslation();
    const { id }: any = useParams();
    const dispatch = useDispatch<AppDispatch>();
    //Add item modal close open function
    const { showAddItem, addItemClose, addItemShow, terminationResp, allresponsibleList, hideChecklistTermination1, ShowThisData, currentClickCustomId, setcurrentClickCustomId } = props;
    const { register, handleSubmit, reset, getValues, setValue, watch, formState: { errors } } = useForm<any>();

    const [fileUploadName, setFileUploadName] = useState<any>({
        first_file: "",
        second_file: "",
        third_file: "",
        fourth_file: "",
    })

    const [oldData, setOldData] = useState<any>({
        old: "",
        new: "",
    });
    useEffect(() => {
        if (showAddItem) {
            setValue("responsible", 1);
        }
    }, [showAddItem])

    const [runOncee, setrunOnce] = useState(true);
    useEffect(() => {
        if (ShowThisData && currentClickCustomId != "" && runOncee) {
            (async () => {
                let response = await dispatch(get_custom_post_termination_checklist({ "termination_id": currentClickCustomId }));
                if (response["payload"]["type"] == "success" || response["payload"]["type"] == "Success") {
                    let post = response["payload"]["data"][0];
                    if (post !== undefined) {
                        setValue("custom_id", post["ID_TERMINATION_TRMCKL"])
                        setValue('title', post["TX_TITLE_TRMCKL"]);
                        setValue('responsible', post["TA_RESPONSIBLE_TRMCKL"]);
                        setValue('limit_date', moment(post["DT_LIMITDATE_TRMCKL"].split("T")[0]).format("YYYY-MM-DD"));
                        setValue("first_file_or_report", post["FL_FILE_TRMCKL"] ?? "");
                        setValue('observation', post["TA_OBSERVATION_TRMCKL"]);
                        setStatusOfChecklist(Number(post["ST_STATUS_TRMCKL"]) ?? 1);

                        // handleResponsible(post["SL_RESPONSIBLE_TERPOP"], post["SL_RESPONSIBLE_PARTY_NAME_TERPOP"] ?? "", "load")

                        setFileUploadName({
                            ...fileUploadName,
                            ["first_file"]: post["FL_FILE_TRMCKL"] ?? ""
                        })
                        setOldData({
                            old: post["FL_FILE_TRMCKL"] ?? "",
                            new: post["FL_FILE_TRMCKL"] ?? ""
                        });

                        if (post["ST_STATUS_TRMCKL"] !== 1) {
                            document.querySelectorAll('.DisableThis').forEach((item, index) => {
                                item.setAttribute("disabled", "");
                                item.setAttribute("read-only", "");
                            });

                            document.querySelectorAll('.DisableThis input').forEach((item, index) => {
                                item.setAttribute("disabled", "");
                                item.setAttribute("read-only", "");
                            })
                        }

                        setrunOnce(false);
                    }
                }
            })()
        }
    }, [ShowThisData && showAddItem])

    useEffect(() => {
        if (getValues("first_file_or_report") && showAddItem) {
            setTimeout(() => {
                setOldData({
                    ...oldData,
                    ["new"]: getValues("first_file_or_report")?.[0]?.["name"] !== undefined ? "checklist/" + getValues("first_file_or_report")?.[0]?.["name"] : ""
                })
            }, 200)
        }
    }, [watch('first_file_or_report'), showAddItem, oldData]);

    const [statusOfChecklist, setStatusOfChecklist] = useState(1);
    let typeSubmit: any = "draft";
    const handleSaveDraftClick = () => {
        // Set the value to identify the button clicked
        typeSubmit = 'draft';
        // Submit the form
        handleSubmit(onAddItemFormSubmit)();
    };

    let [showResponsible, setShowResponsible] = useState([]);
    const handleResponsible = (e, rep_id, type) => {
        setValue("responsible_party_name", "");
        let id: any = 0;
        let arrayChange: any = [];
        if (type == "form") {
            id = Number(e.target.value);
        } else {
            id = Number(e);
        }


        if (id !== "") {
            if (id == 1) {
                arrayChange = allresponsibleList[0]["agency"];
            }

            if (id == 2) {
                arrayChange = allresponsibleList[0]["owner"]
            }

            if (id == 3) {
                arrayChange = allresponsibleList[0]["tenent"]
            }

            if (id == 4) {
                arrayChange = allresponsibleList[0]["manager"]
            }

            if (id == 5) {
                arrayChange = allresponsibleList?.[0]?.["gurantor"] ?? allresponsibleList?.[0]?.["guarantor"]
            }
        }

        if (type == "load") {
            setTimeout(() => {
                setValue("responsible_party_name", rep_id);
            }, 100)
        }

        setShowResponsible(arrayChange);
    }

    const handleSaveCompleteClick = () => {
        // Set the value to identify the button clicked
        typeSubmit = 'done';
        // Submit the form
        Swal.fire({
            // icon: 'success','warning',
            title: t("Sweetalert.Areyousure"),
            text: t("newTextSweetAlert.Youablerevert"),
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText:  t('button.cancel'),
            confirmButtonText: t('button.confirm')
        }).then(res => {
            if (res.isConfirmed) {
                handleSubmit(onAddItemFormSubmit)();
            }
        })
    };

    //Form submit
    const onAddItemFormSubmit = async (data) => {
        data["contract_id"] = id;
        data["hide_data"] = hideChecklistTermination1;

        if (typeSubmit == "done") {
            data["status"] = 0;
        }

        if (typeSubmit == "draft") {
            data["status"] = 1;
        }

        data["first_file_or_report"] = data["first_file_or_report"] ? data["first_file_or_report"] : "";
        const formData = new FormData();
        if (typeof data["first_file_or_report"] === "object") {
            formData.append('first_file_or_report', data["first_file_or_report"]?.[0]);
        } else {
            formData.append('first_file_or_report', data["first_file_or_report"]);
        }

        Object.keys(data) && Object.keys(data).length > 0 &&
            Object.keys(data).forEach((item, index) => {
                if (data !== "first_file_or_report") {
                    formData.append(`${item}`, data[`${item}`]);
                }
            })

        let response = await dispatch(custom_post_termination_checklist(formData));
        if (response["payload"]["type"] == "success" || response["payload"]["type"] == "Success") {

            await dispatch(contractDatails(id));
            Swal.fire({
                // icon: 'success','success',
                title: t("Sweetalert.Success"),
                text: t("Sweetalert.successget"),
                confirmButtonText: "OK",
                timer: 1500
            })
            handleClose();
            setStatusOfChecklist(1);
        } else {
            Swal.fire({
                // icon: 'success','error',
                title: t("Sweetalert.Error"),
                text: "Operation failed to performed!!!!",
                confirmButtonText: "OK",
            })
        }
    };

    const handleClose = () => {
        reset();
        setcurrentClickCustomId("");
        addItemClose();
        setStatusOfChecklist(1);
    }

    //Common toast
    const [showToast, setToastShow] = useState(false);
    const commonToastClose = () => setToastShow(false);
    const commonToastShow = () => {
        setToastShow(true);
        setTimeout(() => {
            setToastShow(false);
        }, 5000);
    }

    const handleDelete = (e) => {
        setValue("first_file_or_report", "");
        setOldData({
            ...oldData,
            ["new"]: ""
        });
        setFileUploadName({
            ...fileUploadName,
            ["first_file"]: ""
        })
    }



    return (
        <>
            <Offcanvas show={showAddItem} placement="end" onHide={handleClose}>
                <Form className="d-flex flex-column h-100" onSubmit={handleSubmit(onAddItemFormSubmit)}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title className="h4">{t("button.additem")}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Form.Control type="text" {...register("custom_id")} hidden />
                        <Row className="gy-lg-4 gy-3 align-items-center mb-lg-4 mb-3">
                            <Col md={12}>
                                <FloatingLabel controlId="title" label={t("formLabel.title")} >
                                    <Form.Control type="text" placeholder={t("formLabel.title")} {...register("title")} className='DisableThis' />
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="responsible" label={t("formLabel.responsible")}>
                                    <Form.Select aria-label="Responsible" {...register("responsible")} className='DisableThis' >
                                        <option value="">{t("formLabel.select")}</option>
                                        {terminationResp && terminationResp.length > 0 &&
                                            terminationResp.map((item, index) => {
                                                return (<option key={index} value={item["value"]}>{item["label"]}</option>)
                                            })
                                        }
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="limit_date" label={t("terminatelist.limit") + " " + t("terminatelist.date")}>
                                    <Form.Control type="date" placeholder={t("terminatelist.limit") + " " + t("terminatelist.date")} min={minDate()} {...register("limit_date")} className='DisableThis' />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        {
                            fileUploadName && fileUploadName["first_file"] !== "" && fileUploadName["first_file"] !== "undefined" ?
                                <Card className="border mblg-4 mb-3">
                                    <Card.Body className="d-sm-flex align-items-center">
                                        <div className="me-lg-4 me-sm-3 w-100 overflow-hidden">
                                            <h4 className="text-truncate">{fileUploadName["first_file"].replace("checklist/", "")}</h4>
                                            <p className="mb-sm-0 fs-12 text-secondary">Sent in 03/05/2023</p>
                                        </div>
                                        <div className="flex-shrink-0 ms-sm-auto">
                                            <Link to={LinkHttpChecklist.trim() + oldData?.["old"].trim()} className="btn text-link" download="file">
                                                <img src="../assets/images/icons/upload-primary.svg" alt="Edit" className="cursor-pe me-2 me-md-3 h-18" />
                                            </Link>
                                            {
                                                statusOfChecklist == 1 &&
                                                <img src="../assets/images/icons/delete-danger.svg" alt="Delete" className="cursor-pe h-18" onClick={(e) => handleDelete(e)} />
                                            }
                                        </div>
                                    </Card.Body>
                                </Card>
                                :
                                oldData && oldData?.["new"] !== "" ?
                                    <Card className="border mblg-4 mb-3">
                                        <Card.Body className="d-sm-flex align-items-center">
                                            <div className="me-lg-4 me-sm-3 w-100 overflow-hidden">
                                                <h4 className="text-truncate">{getValues("first_file_or_report")?.[0]?.["name"]}</h4>
                                                <p className="mb-sm-0 fs-12 text-secondary">Sent in 03/05/2023</p>
                                            </div>
                                            <div className="flex-shrink-0 ms-sm-auto">
                                                {
                                                    statusOfChecklist == 1 &&
                                                    <img src="../assets/images/icons/delete-danger.svg" alt="Delete" className="cursor-pe h-18" onClick={(e) => handleDelete(e)} />
                                                }
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    :
                                    <div className="border-2 border border-dashed py-4 px-2 position-relative text-center rounded-4 mb-lg-4 mb-3">
                                        <Form.Control type="file" className="position-absolute cursor-pe end-0 start-0 top-0 bottom-0 h-100 w-100 opacity-0 z-index-1 DisableThis" {...register("first_file_or_report")} />
                                        <h4 className="text-primary fw-bold mb-2"><img src="../assets/images/icons/upload-file-primary.svg" alt="Upload file" className="cursor-pe me-2" />{t("terminatelist.Attachfile")}</h4>
                                        <label className="mb-0 form-label fw-normal text-dark"><img src="../assets/images/icons/info-dark.svg" alt="Info" className="h-16 cursor-pe me-1" />{t("terminatelist.SupportedformatsPDFandPNG")}.</label>
                                    </div>
                        }
                        <FloatingLabel controlId="observation" label={t("formLabel.observation")}>
                            <Form.Control as="textarea" placeholder={t("formLabel.observation")} {...register("observation")} className='DisableThis' />
                        </FloatingLabel>
                    </Offcanvas.Body>
                    {
                        statusOfChecklist === 1 &&
                        <>
                            <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                                <Button variant="outline-primary" onClick={handleSaveDraftClick}>
                                    {t('terminatelist.Savedraft')}
                                </Button>
                                <Button variant="primary" onClick={handleSaveCompleteClick}>
                                    {t('terminatelist.Saveandcomplete')}
                                </Button>
                            </div>
                        </>
                    }
                </Form>
            </Offcanvas>
            {/* Toast start from here */}
            <CommonToast showCommonToast={showToast} commonToastClose={commonToastClose} />
            {/* Toast end here */}
        </>
    )
}

export default TerminateAddItem
