import { Dropdown, Table, Button, Form, Modal, Row, Col, FloatingLabel, Accordion, Card } from 'react-bootstrap';
import React, { useState, MouseEvent, useEffect } from 'react';
import { ManagerForm } from '../../../Interface';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import customerService from '../../../../services/customerService';
import Checkbox from '../Common/Checkbox';
import Loader from '../../../Profile/Company/Loader';
import '../../../../App.css';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { getAllProperty_administractor } from '../../../../redux/slice/contractDataSlice';
import { useDispatch } from 'react-redux';
import axios from 'axios';

const Managers = (ManagerForm) => {
    const dispatch = useDispatch<AppDispatch>()
    const { proprtyData } = useSelector((state: RootState) => state.contractData)
    const [t] = useTranslation();
    const [status, setStatus] = useState(true);
    const [show, setShow] = useState(false);
    const formClose = () => { setShow(false); reset(); }
    //const formShow = () => setShow(true);
    const newManagerShow = async (id) => {
        if (id == 0) {
            setShow(true)
        }
        else {
            let managersData = { manager: '', id: id, start: 0, end: 100 }
            let response = await customerService.getAllManagers(managersData)
            const fields: string[] = ['id', 'name', 'email', 'telephone'];
            if (response.data.data.length > 0) {
                fields.forEach((field: any) => setValue(field, response.data.data[0][field]));
                setShow(true)
            }
        }
    }
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    /*--------Data table listing state--------*/
    const [data, setData] = useState<any[]>([])
    const [total, setTotal] = useState(0);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState<any[]>([]);
    const [state, setState] = useState({
        property: "",
        status: "",
        branch: "",
        city: "",
        tenants: "",
        neighborhood: "",
        administrator: "",
        manager: "",
        owner_type: "",
        condominium: "",
        business_type: "",
        property_type: "",
        real_estate: "",
        start: 0,
        end: 1000
    })
    useEffect(() => { getAllManagers(null); }, []);
    /*-------------------Form validation-------------------*/
    const managerValidationSchema = Yup.object().shape({
        name: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
        // email: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired'))
        //     .email(t('requiredMmessage.fieldNotValid')),
        // telephone: Yup.string().trim().nullable()
        //     .required(t('requiredMmessage.fieldRequired'))
        //     .matches(/\(\d{2}\) \d{4}-\d{4}/, `${t("requiredMmessage.TelephoneError")}`),
    });
    let frm = {
        resolver: yupResolver(managerValidationSchema)
    }
    const { register, handleSubmit, reset, setValue, getValues, setError, clearErrors, formState: { errors } } = useForm<ManagerForm>(frm);
    const managerSubmit = (data: ManagerForm) => {
        let managersData = {
            id: data.id,
            name: data.name,
            telephone: data.telephone,
            email: data.email,
            status: data.status
        }
        customerService.createManagers(managersData).then(res => {
            /*-------Check the api response--------*/
            if (res.data.resCode === 202 || res.data.resCode === 200) {
                getAllManagers(null);
                formClose();
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: t("Sweetalert.successget"),
                    // icon:"success",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
            else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.somethingWent"),
                    // icon:"error",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
        }).catch(error => {
            /*-------Error exception handling--------*/
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                // icon:"error",
                confirmButtonText: "OK",
                timer: 2000
            });
        });
    }
    const getAllManagers = (manager) => {
        setStatus(true);
        let managersData = { manager: manager, id: '', start: 0, end: 100 }
        customerService.getAllManagers(managersData).then(res => {
            setStatus(false);
            if (res.data.resCode == 201) {
                setData(res.data.data);
                setTotal(res.data.totalRow)
            }
            else {
                setData([]);
                setTotal(0)
            }
        }).catch(err => {
            setStatus(false);
            setData([]);
            setTotal(0)
            //console.log("err", err)
        });
    }
    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(data.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    const noneHandleSelectAll = e => {
        setIsCheckAll(false)
        setIsCheck([]);
    }
    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }

        if (document.querySelectorAll('.check_Checkbox input').length > 0) {
            let data = document.querySelectorAll('.check_Checkbox input');
            let count_allCheck = 0;
            setTimeout(() => {
                data.forEach((item, index) => {
                    if (item["checked"]) {
                        count_allCheck++
                    }

                    if (!item["checked"]) {
                        setIsCheckAll(false);
                    }
                })

                if (count_allCheck == data.length) {
                    setIsCheckAll(true);
                }
            }, 10)

        }
    };

    const deleteManger = () => {
        if (isCheck.length > 0) {
            Swal.fire({
                title: t("Sweetalert.Areyousure"),
                text: t("Sweetalert.Youwontbeabletorevert"),
                // icon: 'success','warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: t('button.cancel'),
                confirmButtonText: t("newTextSweetAlert.Yes_it")
            }).then((result) => {
                if (result.value) {
                    let managersData = isCheck;
                    customerService.deleteManagers(isCheck).then(res => {
                        /*-------Check the api response--------*/
                        if (res.data.resCode === 408 || res.data.resCode === 414) {
                            getAllManagers(null);
                            Swal.fire({
                                title: t("Sweetalert.Success"),
                                text: res.data.message,
                                // icon:"success",
                                confirmButtonText: "OK",
                                timer: 2000
                            });
                        }
                        else {
                            Swal.fire({
                                title: t("Sweetalert.Error"),
                                text: t("Sweetalert.somethingWent"),
                                // icon:"error",
                                confirmButtonText: "OK",
                                timer: 2000
                            });
                        }
                    }).catch(error => {
                        /*-------Error exception handling--------*/
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: t("Sweetalert.somethingWent"),
                            // icon:"error",
                            confirmButtonText: "OK",
                            timer: 2000
                        });
                    });
                }
            })
        }
        else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.Pleasecheckedthe"),
                // icon:"error",
                confirmButtonText: "OK",
                timer: 2000
            });
        }
    }

    const [uniqueSet, setUniqueSet] = useState([]);
    const [allPropertyCount, setallPropertyCount] = useState({});
    useEffect(() => {
        (async () => {
            let resp = await dispatch(getAllProperty_administractor({
                start: 0,
                end: 1000
            }));

            let uniqueValueCount: any = [];
            let allPropertyCount = {};
            let count = 1;
            data.forEach((dataItem) => {
                count = 1;
                uniqueValueCount.push(dataItem)
                resp.payload.data.forEach((userItem, index) => {
                    // console.log(userItem, "userItem -------", index)
                    if (dataItem.id == userItem.manager_id) {
                        allPropertyCount[`${dataItem.id}`] = {
                            "count": count++,
                            "id": dataItem.id,
                            "name": dataItem.name
                        }
                    }
                });
            });
            setallPropertyCount(allPropertyCount);
            setUniqueSet(uniqueValueCount);
        })();
    }, [data]);

    const HandleInput = (e: any) => {
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        let newValue = e.target.value = !value[2] ? value[1] : '(' + value[1] + ') ' + value[2] + (value[3] ? '-' + value[3] : '');

        // Error validation
        if (newValue == "" || newValue == null || newValue == undefined) {
            // Set an error message using setError or update your error state
            setError("telephone", {
                type: "manual",
                message: `${t("requiredMmessage.TelephoneError")}`
            });
        } else {
            // Clear the telephone error if the value is not empty
            clearErrors('telephone');
        }
    }
    return (
        <section className="content-wrapper">
            {/* <!-- Breadcrumb section start from here --> */}
            <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-end border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="gx-xl-5 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <Link to={"/properties"}>
                                    <img className="h-16" src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-left.svg"} />
                                </Link>
                                <div className="w-100 overflow-hidden ms-2 ms-xl-3 me-1">
                                    <h1 className="mb-0 h4 lh-base text-truncate">
                                        {t('property.managers')}
                                    </h1>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end mb-xl-0 col-xl mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item mb-md-0 mb-1">
                                    <Link to={"#"} onClick={() => newManagerShow('0')} type="button"
                                        className="fw-bold">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/plus-light.svg"} className="me-1" />
                                        {t('property.newManager')}
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {/* <!-- Table start --> */}
                <div className="table-responsive">
                    {
                        status ?
                            <Loader />
                            :
                            <Card>
                                <Card.Body className="pb-1">
                                    <Table className="table table-custom table-custom-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th className="w-24">
                                                    {data && data.length > 0 ?
                                                        <Checkbox type="checkbox" name="selectAll" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} />
                                                        : ''}
                                                </th>
                                                <th>{t('formLabel.name')}</th>
                                                <th>{t('formLabel.properties')}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.length > 0 ? data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className='check_Checkbox'>
                                                        <Checkbox key={item["id"]} type="checkbox" name={item["name"]} id={item["id"]} handleClick={handleClick} isChecked={isCheck.includes(item["id"])} />
                                                    </td>
                                                    <td>
                                                        <span className="text-dark-70 d-block">
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/user_g.svg"} className="me-1" />
                                                            <Link className="text-reset" to={`/managers_details/${item["id"]}`}>{item["name"]}</Link>
                                                        </span>
                                                        <span className="d-block">{item["telephone"]}</span>
                                                        <span className="d-block">{item["email"]}</span>
                                                    </td>
                                                    <td>
                                                        {allPropertyCount[`${item["id"]}`] !== undefined ? allPropertyCount?.[`${item["id"]}`]?.["count"] : 0} Properties
                                                    </td>
                                                    <td className="text-end">
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/edit-primary.svg"} onClick={() => newManagerShow(item["id"])} className="cursor-pe h-20" />
                                                    </td>
                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan={4}>
                                                        <span>{t('formLabel.noRecordsFounds')}</span>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>

                    }
                </div>
                {/* <!-- /Table end --> */}
                <div className="mt-4 mt-3">{t('formLabel.moreItems')}</div>
                <p className="py-2">{t('formLabel.listing')} {total} {t('property.managers')}</p>
                {/* <!-- Nav listing start from here -->*/}
                <ul className="list-inline mb-0">
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        <Dropdown>
                            <Dropdown.Toggle variant="light" size="sm" id="dropdownMenuButton1">
                                {t('formLabel.mark')}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={handleSelectAll} href="#/action-1">{t('formLabel.all')}</Dropdown.Item>
                                <Dropdown.Item onClick={noneHandleSelectAll} href="#/action-2">{t('formLabel.none')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        <span className="px-1"> {t('formLabel.withTagged')}</span>
                    </li>
                    <li className="list-inline-item mb-lg-0 mb-1">
                        <Button variant="light" size="sm" onClick={deleteManger} type="button" >{t('button.delete')}</Button>
                    </li>
                </ul>
                {/* <!-- /Nav listing  end --> */}
            </main>
            {/* <!-- /Main content start end --> */}
            <Modal show={show} onHide={formClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('property.newManager')}</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit(managerSubmit)}>
                    <input type="hidden" {...register('id')} />
                    <Modal.Body>
                        <FloatingLabel className="mb-3 mb-lg-4" controlId="floatingSelect" label={t('formLabel.name') + " *"}>
                            <Form.Control type="text" placeholder={t('formLabel.name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`}   {...register('name')} />
                            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                        <Row className="g-lg-4 g-3">
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.cellPhone')}>
                                    <Form.Control type="text" placeholder={t('formLabel.cellPhone')} className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}   {...register('telephone')} onChange={HandleInput} />
                                    <Form.Control.Feedback type="invalid">{errors.telephone?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="floatingSelect" label={t('formLabel.email')}>
                                    <Form.Control type="text" placeholder={t('formLabel.email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`}   {...register('email')} />
                                    <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={formClose}>
                            {t('button.close')}
                        </Button>
                        <Button type="submit" variant="primary" >
                            {t('button.toSave')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </section>
    )
}
export default Managers;


