import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Offcanvas, Form, Button, Row, Col, Badge, Table, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DeleteChargeItem from "../Contracts/ContractDetail/DeleteChargeItem";
import { getReadContractData, getTenants, setCheckedId, getContractSetting } from "../../../../redux/slice/contractDataSlice";
import { useDispatch, useSelector } from 'react-redux';
import customerService from "../../../../services/customerService";
import Loader from "../../../Profile/Company/Loader";
import Swal from 'sweetalert2';
import { gettoken } from '../../../../Api/Contact';
import { AppDispatch, RootState } from "../../../../redux/store";
import { getReadSimulateContract, insertReadjustContract, readjustClose } from "../../../../redux/slice/expenditureDataSlice";
import { converter, formatNumberEnglish, formatNumberPortuges } from "../../../constants/utils";
import moment from "moment-timezone";
let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");

const RedjustmentSimulate = (props: any) => {
    let { redjustOpen, setrejustOpen, redjustData } = props;
    const dispatch = useDispatch<AppDispatch>();
    const { simulateData, simulateStatus } = useSelector((state: RootState) => state.expenditureData)
    const { singleContCount, contractDatail, expenseData, tenantsData, expenseStatus, guarantorData, status1, transferStatus, contTransferData, Property_Type, guarantors, showContractSearch, ContractSettingData } = useSelector((state: RootState) => state.contractData);
    const [t] = useTranslation();
    let currentDt = new Date(dateBrazil);
    let currentMonth = moment(currentDt).format("MMMM");
    let currentDate = moment(currentDt).format("DD/MM/YYYY");
    const [double, setDouble] = useState(false);
    useEffect(() => {
        dispatch(getContractSetting())
        if (redjustOpen) {
            if (typeof redjustData !== "undefined" && redjustData && redjustData?.["contractId"].length > 0 && redjustData?.index) {
                dispatch(getReadSimulateContract(redjustData))
            }
        }
    }, [dispatch, redjustOpen]);

    const handleClose = () => {
        setrejustOpen(false);
        dispatch(readjustClose(true));
        setDouble(false)
    }
    const [addTotal, setaddTotal] = useState<any>({
        additional: 0,
        totaladditiona: 0
    })
    useEffect(() => {
        if (redjustOpen) {
            let additional = 0;
            let totaladditional = 0;
            simulateData && simulateData.data && simulateData.data.length > 0 &&
                simulateData.data.map((item: any, index: number) => {
                    let addPoint = 0;
                    let nameValue = "";
                    redjustData && redjustData["checkedItemAlldata"].length > 0 &&
                        redjustData["checkedItemAlldata"].forEach((itemsub: any, indexsub: number) => {
                            if (itemsub[`${item.id}`]) {
                                let data = redjustData && redjustData?.["redjustIndices"] && redjustData?.["redjustIndices"].length > 0 && redjustData["redjustIndices"].filter((itemchild: any, indexchild: number) => itemchild.name == itemsub?.[`${item.id}`]?.["redjustment_index_name"]);
                                addPoint = ContractSettingData && ContractSettingData.length > 0 && ContractSettingData[0].do_not_readjust_negative_indexes == "1" ? (data?.[0]?.["value"] < 0 ? 0 : data?.[0]?.["value"]) : data?.[0]?.["value"];

                                nameValue = data?.[0]?.["name"];
                            }
                        })

                    let rentAmount = item.rent_amount;
                    if(rentAmount){
                        if (rentAmount.includes(',')) {
                            rentAmount = rentAmount.replaceAll(',', '');
                        }
                    }

                    let fixedComa: any = "";
                    if (redjustData && redjustData?.["isFixed"]) {
                        if (redjustData?.["fixed_value"] && redjustData?.["fixed_value"] != "") {
                            if (redjustData?.["fixed_value"].includes(',')) {
                                if (redjustData?.["fixed_value"].split(",")[0].length > 3) {
                                    fixedComa = formatNumberEnglish(redjustData?.["fixed_value"]);
                                } else {
                                    fixedComa = redjustData?.["fixed_value"].replace(",", ".");
                                }
                            }
                        }
                        if (fixedComa && fixedComa != "") {
                            if (fixedComa.includes(',')) {
                                fixedComa = fixedComa.replaceAll(',', '');
                            }
                        }
                    }

                    // if (redjustData && redjustData?.["isFixed"]) {
                    //     fixedComa = formatNumberEnglish(redjustData?.["fixed_value"]);
                    //     if (fixedComa && fixedComa != "") {
                    //         if (fixedComa.includes(',')) {
                    //             fixedComa = fixedComa.replaceAll(',', '');
                    //         }
                    //     }
                    // }
                    let calculateAddValue: any = redjustData && redjustData?.["isFixed"] ? Number(fixedComa) : Number(rentAmount) * Number(addPoint) / 100;
                    additional += redjustData && redjustData?.["isFixed"] ? Number(fixedComa) : (Number(rentAmount) * Number(addPoint) / 100);
                    totaladditional += Number(rentAmount) + Number(calculateAddValue);
                })

            setaddTotal({
                additional: additional,
                totaladditiona: totaladditional
            })
        }
    }, [simulateData.data, redjustOpen])

    const [simulateTotalPRFormate, setsimulateTotalPRFormate] = useState<number>(0);
    useEffect(() => {
        if (simulateData && simulateData.totalRow && simulateData.totalRow.totalRow > 0) {
            let fixedComa: any = simulateData.totalRow.totalRent;
            if (fixedComa && fixedComa != "") {
                if (fixedComa.includes(',')) {
                    fixedComa = fixedComa.replaceAll(',', '');
                }
            }
            setsimulateTotalPRFormate(fixedComa);
        }
    }, [simulateData.totalRow])

    const handleReadjustment = async (e: any) => {
        e.preventDefault();
        if (typeof redjustData !== "undefined" &&
            redjustData && redjustData.contractId.length > 0 &&
            redjustData.index) {
            Swal.fire({
                title: t("newTextSweetAlert.readjust_it_title"),
                text: t("Sweetalert.Youwontabletomodifyitlater"),
                // icon: 'success','info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t("newTextSweetAlert.Readjust_it"),
                cancelButtonText:  t('button.cancel'),
            }).then(async (result) => {
                if (result.value) {
                    setDouble(true)
                    if (redjustData["isFixed"]) {
                        redjustData["fixed_value"] = redjustData["fixed_value"]?.replace(/\./g, '')?.replace(',', '.')
                    }

                    let response = await dispatch(insertReadjustContract(redjustData));
                    if (typeof response.payload !== "undefined" && (response.payload.resCode === 202 || response.payload.resCode === 200)) {
                        setDouble(false);
                        Swal.fire({
                            // icon: 'success','success',
                            title: t("Sweetalert.Success"),
                            text: response.payload.message,
                            confirmButtonText: "OK",
                            timer: 2000
                        })
                        setrejustOpen(false);
                        dispatch(readjustClose(false));
                        dispatch(setCheckedId([]));
                        dispatch(getReadContractData({ contract: "", "start": 0, "end": 10 }));
                    } else {
                        setDouble(false)
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: response.payload.message,
                            // icon:"error",
                            confirmButtonText: "OK",
                            timer: 2000
                        })
                    }
                }
            })
        } else {
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                // icon:"error",
                confirmButtonText: "OK",
                timer: 2000
            })
        }
    }


    return (
        <>
            <Offcanvas
                className="border-sm-none"
                show={redjustOpen}
                onHide={handleClose}
                placement="end"
            >
                <Form className="d-flex flex-column h-100" id="ContractSimulate">
                    <Offcanvas.Header className="border-bottom">
                        <Offcanvas.Title className="fw-bold d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center w-100">
                            {t("translate_readjustment.Readjust_Simulation")}
                            <div className="text-primary ms-sm-auto d-flex justify-sm-end justify-content-between">
                                <span onClick={handleClose} className="cursor-pe">
                                    <img
                                        src={process.env.PUBLIC_URL + "/assets/images/icons/cross.svg"}
                                        alt="Close"
                                        className="h-16"
                                    />
                                </span>
                            </div>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <h5 className="mb-3 mb-lg-4 fw-bold">{t("translate_readjustment.Readjustment_Simulation")} - {currentMonth}  <Badge bg="primary-20" className="text-dark fw-semibold ms-2">{currentDate}</Badge></h5>
                        {
                            (simulateStatus == "loading") ?
                                <Loader />
                                :
                                <>
                                    {
                                        simulateData.data && simulateData.data.length > 0 ?
                                            simulateData.data.map((item: any, index: number) => {
                                                let addPoint = 0;
                                                let nameValue = "";
                                                let fixedComa: any = "";
                                                if (redjustData && redjustData?.["isFixed"]) {
                                                    if (redjustData?.["fixed_value"] && redjustData?.["fixed_value"] != "") {
                                                        if (redjustData?.["fixed_value"].includes(',')) {
                                                            if (redjustData?.["fixed_value"].split(",")[0].length > 3) {
                                                                fixedComa = formatNumberEnglish(redjustData?.["fixed_value"]);
                                                            } else {
                                                                fixedComa = redjustData?.["fixed_value"].replace(",", ".");
                                                            }
                                                        }
                                                    }
                                                    if (fixedComa && fixedComa != "") {
                                                        if (fixedComa.includes(',')) {
                                                            fixedComa = fixedComa.replaceAll(',', '');
                                                        }
                                                    }
                                                }

                                                redjustData?.checkedItemAlldata && redjustData.checkedItemAlldata.length > 0 &&
                                                    redjustData.checkedItemAlldata.forEach((itemsub: any, indexsub: number) => {
                                                        if (itemsub[`${item.id}`]) {
                                                            let data = redjustData && redjustData["redjustIndices"] && redjustData["redjustIndices"].length > 0 &&
                                                                redjustData["redjustIndices"].filter((itemchild: any, indexchild: number) => itemchild.name == itemsub?.[`${item.id}`]?.["redjustment_index_name"]);
                                                            let filterVal = redjustData["readjustarr"].filter((item: any) => item["name"] == data?.[0]?.["slug"]);
                                                            if (filterVal) {
                                                                addPoint = redjustData && redjustData?.["isFixed"] ?
                                                                    Number(fixedComa) : (
                                                                        ContractSettingData && ContractSettingData.length > 0 &&
                                                                            ContractSettingData[0].do_not_readjust_negative_indexes == "1" ?
                                                                            (data?.[0]?.["value"] < 0 ? 0 : (filterVal?.[0]?.["value"] !== "") ?
                                                                                filterVal?.[0]?.["value"] : data?.[0]?.["value"])
                                                                            : (filterVal?.[0]?.["value"] !== "") ? filterVal?.[0]?.["value"] : data?.[0]?.["value"]); // check added
                                                            }
                                                            nameValue = redjustData && redjustData?.["isFixed"] ? "Fixed" : data?.[0]?.["name"];
                                                        }
                                                    })

                                                let rentAmount = item.rent_amount;
                                                if(rentAmount){
                                                    if (rentAmount.includes(',')) {
                                                        rentAmount = rentAmount.replaceAll(',', '');
                                                    }
                                                }

                                                let calculateAddValue: any = redjustData && redjustData?.["isFixed"] ? Number(fixedComa) : Number(rentAmount) * Number(addPoint) / 100;
                                                let totalcalculateAddValue: number = Number(rentAmount) + Number(calculateAddValue);
                                                return (
                                                    <div className="border border-light rounded-4 p-lg-4 p-3 mb-3" key={index}>
                                                        <h6 className="mb-3 fw-bold">{t("translate_readjustment.Last_Readjustment")} {item.expireDate}</h6>
                                                        <Row className="g-xl-4 g-3 mb-lg-4 mb-3">
                                                            <Col sm={7} className="border-end border-xs-none">
                                                                <p className="fs-12 text-secondary mb-1">{t("contract")}</p>
                                                                {/* <h6 className="mb-md-0">{item.type} {item.propertyId + '/' + item.id} - Teste salvar alterações novo cliente</h6> */}
                                                                <h6 className="mb-md-0">{t(item.type)} {item.id}/{item.lease_count ? item.lease_count : "0"}</h6>
                                                            </Col>
                                                            <Col sm="5">
                                                                <p className="fs-12 text-secondary mb-1">{t("fixedvalue")} {t("NonPayment.or")} %</p>
                                                                {/* <h6 className="mb-md-0">Charge apply as per <Link className="fw-bold" to={"/readjustment_indexes"}>redjustment indexes</Link></h6> */}
                                                                {nameValue} ({addPoint ? addPoint : '0.00'} )
                                                            </Col>
                                                        </Row>
                                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                                            <span className="text-secondary">{t("previous")}</span>
                                                            {rentAmount ? formatNumberPortuges(parseFloat(rentAmount)) : '0.00'}(R$)
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                                            <span className="text-secondary">{t("NonPayment.addition")}</span>
                                                            {calculateAddValue ? formatNumberPortuges(calculateAddValue.toFixed(2)) : '0.00'}(R$)
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <span className="text-secondary">{t("translate_readjustment.Updated")}</span>
                                                            {totalcalculateAddValue ? formatNumberPortuges(totalcalculateAddValue) : '0.00'}(R$)
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className="border border-light rounded-4 p-lg-4 p-3 mb-lg-4 mb-3">
                                                {t("formLabel.noRecordsFounds")}
                                            </div>
                                    }
                                    <div>
                                        <h6 className="fw-bold mb-3 mb-lg-4 pt-lg-3 pt-2">{t("Invoices.summary")}</h6>
                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                        {t("translate_readjustment.List_of")} {t("notifications.contract")}.
                                            <span>{simulateData.totalRow && simulateData.totalRow.totalRow > 0 ? simulateData.totalRow.totalRow : 0}</span>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <span>{t("translate_readjustment.Total_Previous")}</span>
                                            {simulateData.totalRow && simulateData.totalRow.totalRent ? formatNumberPortuges(simulateTotalPRFormate) : 0}
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <span>{t("translate_readjustment.Total_Addition")}</span>
                                            {addTotal.additional ? formatNumberPortuges(addTotal.additional) : '0.00'}
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span className="fw-bold">{t("translate_readjustment.Total_Updated")}</span>
                                            {addTotal.totaladditiona ? formatNumberPortuges(addTotal.totaladditiona) : '0.00'}
                                        </div>
                                    </div>
                                </>
                        }
                    </Offcanvas.Body>
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        <Button
                            disabled={double}
                            type="button"
                            onClick={handleReadjustment}
                        >
                            {t("Readjust")}
                        </Button>
                    </div>
                </Form>
            </Offcanvas>
        </>
    )
}

export default RedjustmentSimulate;