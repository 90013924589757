import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, Badge, Alert, Table, Card, Dropdown, } from 'react-bootstrap';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getGenerateCharge, getGenerateMeat, setExpType } from "../../../../../redux/slice/expenditureDataSlice";
import { AppDispatch, RootState } from '../../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../Profile/Company/Loader';
import Swal from 'sweetalert2';
import customerService from '../../../../../services/customerService';
import { contractDatails, getTenants, newExpenseClose, newExpenseOpen, setExpenseCheckbox, setExpenseTitle, setExpenseType } from '../../../../../redux/slice/contractDataSlice';
import RentalFeeService from '../../Common/RentalFeeService';
import ExpenseService from '../../Common/ExpenseService';
import { gettoken } from '../../../../../Api/Contact';
import { converter, encrypt_Decrypt } from '../../../../constants/utils';
const NewGenerateChargeSimulate = () => {
    const { generateChargeData, generateChargeStatus } = useSelector((state: RootState) => state.expenditureData)
    const { tenantsData, contractDatail } = useSelector((state: RootState) => state.contractData)
    const [t] = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { id } = useParams();
    const [load, setLoad] = useState(true);
    const [double, setDouble] = useState(false);
    const [loader_on, setLoader_on] = useState(false);
    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(3020) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])


    useEffect(() => {
        if (document.querySelector('#root')?.getAttribute("expense_data") === null &&
            document.querySelector('#root')?.getAttribute("expense_data_run") == "true") {
            setLoad(!load);
        } else {
            document.querySelector('#root')?.removeAttribute("expense_data_run");
        }
    }, [document.querySelector('#root')?.getAttribute("expense_data")])

    useEffect(() => {
        const data = location.state;
        setLoader_on(true);
        if (data) {
            (async () => {
                let dispatchDta = await dispatch(getGenerateCharge(data));
                if (dispatchDta) {
                    setLoader_on(false);
                }
            })()
        }
    }, [dispatch, id, newExpenseClose, load, location]);

    const [hide, setHide] = useState(true);
    const [showPrint, setShowPrint] = useState(false);

    useEffect(() => {
        setDouble(true);
        dispatch(getTenants());
        if (generateChargeData.data && generateChargeData.totalRow !== "0.00" &&
            generateChargeData.data.length > 0) {
            setHide(false);
            setDouble(false);
        }
        setTimeout(() => {
            setShowPrint(true);
        }, 5000);
    }, [dispatch, id, load, generateChargeData])

    const chargeGenerateSimulate = () => {
        let data = location.state;
        Swal.fire({
            title: "Gostaria de confirmar a geração das cobranças?",
            text: "Não será possível reverter essa ação depois.",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            customClass: {
                htmlContainer: "1Areyousure1want1to1generate1it1"
            },
        }).then((result) => {
            if (result.value) {
                setDouble(true);
                data.userId = gettoken.userId;
                customerService.chargeGeneratesSimulate(data).then(res => {
                    /*-------Check the api response--------*/
                    if (res.data.resCode === 202) {
                        setDouble(false);
                        Swal.fire({
                            title: t("Sweetalert.Success"),
                            text: t("Sweetalert.successget"),
                            // icon:"success",
                            confirmButtonText: "OK",
                            timer: 2000
                        });
                        navigate('/generate_charges');
                    }
                    else {
                        setDouble(false);
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: t("Sweetalert.somethingWent"),
                            // icon:"error",
                            confirmButtonText: "OK",
                            timer: 2000
                        });
                    }
                }).catch(error => {
                    /*-------Error exception handling--------*/
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: t("Sweetalert.somethingWent"),
                        // icon:"error",
                        confirmButtonText: "OK",
                        timer: 2000
                    });
                });
            }
        })
    }

    const expenseOpen = (evt) => {
        if (contractDatail.length > 0) {
            dispatch(setExpenseTitle(evt))
            dispatch(setExpType(true))
            dispatch(setExpenseCheckbox(false));
            dispatch(setExpenseType(false));
            dispatch(newExpenseOpen(true));
        }
    }

    const handleEditExpenses = (e, id, type) => {
        expenseOpen(type);
        document.querySelector('div[id=root]')?.setAttribute('expense_data', JSON.stringify([id, type, "simulateData"]));
    }

    const loadSimulate = (url) => {
        if (url) {
            const newWindow: any = window.open(url, '_blank');
            newWindow.addEventListener('load', () => {
                newWindow.print();
            });
        }
    }

    return (
        <section className="content-wrapper newgeneratetsxsimulate">
            {/*  <!-- Breadcrumb section start from here --> */}
            <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-end border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 5, order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-start">
                                <Link to={"/generate_charges"}><img src="./assets/images/icons/arrow-left.svg" className="h-14" alt="Arrow left" /></Link>
                                <div className="w-100 overflow-hidden ms-2 ms-xl-3">
                                    <h1 className="mb-0 h4 lh-base text-truncate">{t("chargeSimulation")}</h1>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 1 }} xl={{ span: 7, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                {
                                    userJson && userJson.length > 0 &&
                                    (userJson.indexOf(3021) !== -1) &&
                                    <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                        {
                                            !loader_on && generateChargeData.totalRow !== "0.00" &&
                                                <Button className="py-2 lh-base" style={{ display: hide ? "none" : "block" }} disabled={double} onClick={chargeGenerateSimulate}>
                                                   Gerar cobrança
                                                </Button>
                                        }
                                    </li>
                                }
                                <li className="list-inline-item text-primary" style={{ cursor: "pointer" }}>
                                    {!loader_on && generateChargeData.totalRow !== "0.00" && generateChargeData.simulatePathUrl && showPrint ?
                                        <span className="me-3 me-lg-4 fs-6 fw-bold" onClick={() => loadSimulate(generateChargeData.simulatePathUrl)}>{t("newTransfer.printSimulation")}</span>
                                        :
                                        ''
                                    }
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                <Card>
                    <Card.Header>
                        <div className="d-sm-flex align-items-center justify-content-between mb-3 mb-lg-4">
                            <h6 className="mb-0 fw-bold">
                                {t("dueDate")}  {t("from")} {!loader_on && generateChargeData.start ? generateChargeData.start : ''} {t("to")} {!loader_on && generateChargeData.end ? generateChargeData.end : ''}
                            </h6>
                        </div>
                    </Card.Header>
                </Card>
                {
                    loader_on ?
                        <Loader />
                        :
                        <>
                            {
                                generateChargeData.data && generateChargeData.data.length > 0 ? generateChargeData.data.map((item: any, index) => (item.isRentShow == 1 || item.isExpneseShow == 1 ?
                                    <Card key={item.id} className='mt-1'>
                                        <Card.Body className='mt-1'>
                                            <div key={index} className="border border-light rounded-4 p-lg-4 p-3 mb-lg-4">
                                                <div className="d-sm-flex align-items-center justify-content-between">
                                                    <h6 className="mb-0 fw-bold">{t("contract")} {item.id}</h6>
                                                </div>
                                                <ul className="list-inline d-flex flex-wrap">
                                                    <li className="list-inline-item border-end border-sm-none me-3 me-xl-4 pe-xl-4 pe-md-3">
                                                        <p className="fs-12 text-secondary mb-1">{t("dueDate")}</p>
                                                        <h6 className="mb-md-0">{item.rentDate}</h6>
                                                    </li>
                                                    <li className="list-inline-item border-end border-sm-none me-3 me-xl-4 pe-xl-4 pe-md-3">
                                                        <p className="fs-12 text-secondary mb-1">{t("newTransfer.indentifier")}</p>
                                                        <h6 className="mb-md-0">{item.ST_IDENTIFICADOR_IMO}</h6>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <p className="fs-12 text-secondary mb-1">{t("tenant")}</p>
                                                        <h6 className="mb-md-0">
                                                            {
                                                                // tenantsData && tenantsData.length > 0 &&
                                                                item.datatenants?.["tenants"] &&
                                                                item.datatenants?.["tenants"].map((itemsingle, index) => {
                                                                    // let pickData = tenantsData && tenantsData.length > 0 && tenantsData.filter(item => Number(item?.["id"]) === Number(itemsingle?.["ID_PESSOA_PES"]));
                                                                    // return (
                                                                    //     <>
                                                                    //         <Row key={index}>
                                                                    //             {pickData?.["0"]?.["name"]}
                                                                    //         </Row>
                                                                    //     </>
                                                                    // )
                                                                    return (
                                                                        <>
                                                                            <Row key={index}>
                                                                                { itemsingle.ST_NOME_PES }
                                                                            </Row>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </h6>
                                                    </li>
                                                </ul>
                                                <p className="fs-12 text-secondary mb-1">{t("Property")}</p>
                                                <h6 className="mb-0">{item.propertyType} in {item.address}, {item.city} {item.state} {item.number}</h6>
                                                <div className="d-block">
                                                    <hr className="w-20" />
                                                </div>
                                                <Table borderless>
                                                    <thead>
                                                        <tr>
                                                            <th className="text-secondary ps-0 py-0">
                                                                {t("description")}
                                                            </th>
                                                            <th className="text-secondary py-0">
                                                                {t("adminFee")} ({item.simble || 'R$'})
                                                            </th>
                                                            <th className="text-secondary text-end pe-0 py-0">
                                                                {t("formLabel.value")} (R$)
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr >
                                                            <td className="ps-0 pb-0">
                                                                <span className="d-block mb-1">
                                                                    {t("newTransfer.standardRent")}
                                                                </span>
                                                            </td>
                                                            <td className="pb-0">
                                                                <span className="d-block text-nowrap">
                                                                    {item.simble === '%' ? `${converter(String(item.admPercentage))}%` : `R$ ${converter(String(item.admPercentage))}`}
                                                                </span>
                                                            </td>
                                                            <td className="text-end pe-0 pb-0"> <span className="d-block text-nowrap">R$ {converter(String(item.rent))}</span></td>
                                                        </tr>
                                                        {item.preMonthRent != 0 &&
                                                            <tr>
                                                                <td className="ps-0 pb-0">
                                                                    <span className="d-block mb-1">
                                                                        {t("proportionRent")}
                                                                    </span>
                                                                </td>
                                                                <td className="pb-0">
                                                                    R$ 0,00
                                                                </td>
                                                                <td className="text-end pe-0 pb-0"> <span className="d-block text-nowrap">R$ {converter(String(item.preMonthRent))}</span></td>
                                                            </tr>
                                                        }
                                                        {item.bankFee > 0 && item.isRentShow &&
                                                            <tr >
                                                                <td className="ps-0 pb-0">
                                                                    <span className="d-block mb-1">
                                                                        {t('chargeDocBankFee')}
                                                                    </span>
                                                                </td>
                                                                <td className="pb-0">
                                                                    R$ 0,00
                                                                </td>
                                                                <td className="text-end pe-0 pb-0"> <span className="d-block text-nowrap">R$ {converter(String(item.bankFee))}</span></td>
                                                            </tr>
                                                        }
                                                        {
                                                            item.boletoFee > 0 && item.isRentShow &&
                                                            <tr >
                                                                <td className="ps-0 pb-0">
                                                                    <span className="d-block mb-1">
                                                                        {/*{t('chargeBankFee')}*/}
                                                                     { item.tenantBankFeeService }
                                                                    </span>
                                                                </td>
                                                                <td className="pb-0">
                                                                    R$ 0,00
                                                                </td>
                                                                <td className="text-end pe-0 pb-0"> <span className="d-block text-nowrap">R$ {converter(String(item.boletoFee))}</span></td>
                                                            </tr>
                                                        }
                                                        {item.taxAmt ?
                                                            <tr >
                                                                <td className="ps-0 pb-0">
                                                                    <span className="d-block mb-1">
                                                                        IR
                                                                    </span>
                                                                </td>
                                                                <td className="pb-0">
                                                                    R$ 0,00
                                                                </td>
                                                                <td className="text-end pe-0 pb-0"> <span className="d-block text-nowrap">- R$ {converter(String(item.taxAmt))}</span></td>
                                                            </tr>
                                                            : ''
                                                        }
                                                        {
                                                            item.expense && item.expense.length > 0 ?
                                                                item.expense.map((items: any, indexs) => {
                                                                    return (
                                                                        // <p key={indexs} className="d-block cursor-pe" >{items.productName + '  --  R$ '} {items.expenseValue}</p>
                                                                        <>
                                                                            <tr key={indexs} >
                                                                                <td className="ps-0 pb-0">
                                                                                    <span className="d-block mb-1">
                                                                                        {items.productName ? items.productName : 'Service'}
                                                                                    </span>
                                                                                </td>
                                                                                <td className="pb-0">
                                                                                    {items.simble === '%' ? `${converter(String(items.admPercentage))}%` : `R$ ${converter(String(items.admPercentage))}`}
                                                                                </td>
                                                                                <td className="text-end pe-0 pb-0"> <span className="d-block text-nowrap">R$ {converter(String(items.expenseSimAmt))}</span></td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })
                                                                :
                                                                ''
                                                        }
                                                        <tr>
                                                            <td className="fw-bold ps-0 pb-0">{t("total")}</td>
                                                            <td colSpan={2} className="fw-bold text-end pe-0 pb-0"> <span className="d-block text-nowrap">R$ {converter(String(item.totalRentExpnse))}</span></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                                <div className="d-block">
                                                    <hr className="w-20" />
                                                </div>
                                                <div className="d-sm-flex align-items-center justify-content-between mb-3">
                                                    <span className="mb-1 mb-sm-0">
                                                        {t("Links.bankAccounts")}
                                                    </span>
                                                    <span>{item.nameReference + '-' + item.account}</span>
                                                </div>
                                                <Form.Group className="bg-light rounded-4 p-2 mt-3 mt-lg-4 min-h-56 d-flex flex-wrap align-items-center">
                                                    <Form.Check id="verified_mark_2" label={t("newTransfer.markVerified")} />
                                                </Form.Group>


                                            </div>
                                        </Card.Body>
                                    </Card>
                                    :
                                    ''
                                )) :
                                    <div className="border border-light rounded-4 p-lg-4 p-3 mb-lg-4 mb-3">
                                        <span>{t('formLabel.noRecordsFounds')}</span>
                                    </div>
                            }
                            <Card className='mt-1'>
                                <Card.Body>
                                    <h6 className="fw-bold mb-3 mb-lg-4 pt-lg-3 pt-2">{t("ReceiveandReceived.Summary")}</h6>
                                    <Table className="mb-0" borderless>
                                        <tbody>
                                            <tr>
                                                <td className="ps-0 py-0">{t("numberChargesGenerated")}</td>
                                                <td className="text-end pe-0 py-0">{generateChargeData.data && generateChargeData.data.length ? generateChargeData.data.length : 0}</td>
                                            </tr>
                                            <tr>
                                                <td className="ps-0 pb-0">{t("chargeGenerated")}</td>
                                                <td className="text-end pe-0 pb-0">{generateChargeData.data && generateChargeData.data.length ? generateChargeData.data.length : 0}</td>
                                            </tr>
                                            <tr>
                                                <td className="ps-0 pb-0">{t("clientBilled")}</td>
                                                <td className="text-end pe-0 pb-0">{generateChargeData.data && generateChargeData.data.length ? generateChargeData.data.length : 0}</td>
                                            </tr>
                                            <tr>
                                                <td className="ps-0 pb-0">{t("amountChargesGenerated")}</td>
                                                <td className="text-end pe-0 pb-0"> <span className="d-block text-nowrap">R$ {generateChargeData.totalRow ? converter(String(generateChargeData.totalRow)) : '0,00'}</span></td>
                                            </tr>
                                            <tr>
                                                <td className="ps-0 pb-0">{t("amountWithholdings")}</td>
                                                <td className="text-end pe-0 pb-0">R$ 0,00</td>
                                            </tr>
                                            <tr>
                                                <td className="ps-0 pb-0">{t("numberCharges")}</td>
                                                <td className="text-end pe-0 pb-0">0</td>
                                            </tr>
                                            <tr>
                                                <td className="ps-0 pb-0">{t("amountCharges")}</td>
                                                <td className="text-end pe-0 pb-0">R$ 0,00</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </>
                }
            </main>
        </section >
    )
}
export default NewGenerateChargeSimulate;
