import React, { useState, useEffect } from 'react';
import { Accordion, Table, FloatingLabel, InputGroup, Dropdown, Col, Row, Modal, Form, Button } from 'react-bootstrap';
import {
    getProperty_serviceProvision, updateProperty_serviceProvision
} from '../../../../redux/slice/propertyDataSlice';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { ServiceAndProvision } from '../../../Interface';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { numericOnly, openErrorbox } from '../Common/actionPerform';
import { useTranslation } from 'react-i18next';
import { convertToNumber, converter, formatNumber } from '../../../constants/utils';

const PropertiesServicesProvision = (props) => {
    const [t] = useTranslation();
    const { showServicesProvision, servicesProvisionClose, servicesProvisionShow, transfer_day, guaranteed_transfer, condominium, adminfee, chekuncheck, setchekuncheck } = props;
    const dispatch = useDispatch<AppDispatch>();
    const [rentVal, setRentVal]  = useState('')
    const [saleValue, setSalevalue]  = useState('')
    const { serviceProvision } = useSelector((state: RootState) => state.propertyData)
    //New service note modal close open function
    const { id } = useParams();

    const PropertiesServicesProvisionValidation = Yup.object().shape({
        service_provision_condominium: Yup.string().trim().nullable()
            .required(t('requiredMmessage.fieldRequired')),
    });
    let frm = {
        resolver: yupResolver(PropertiesServicesProvisionValidation)
    }

    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm<ServiceAndProvision>(frm);
    useEffect(() => {
        dispatch(getProperty_serviceProvision(id));
    }, [])

    useEffect(() => {
        if (serviceProvision.length > 0) {
            let newobj: any = {
                ...serviceProvision[0]
            }
            let fields = Object.keys(newobj);
            fields.forEach((field: any) => setValue(field, newobj?.[field]));
        
            setchekuncheck({
                admtxt: newobj.service_provision_admtxt_percent,
                leasetax: newobj.service_provision_leasetax_percent
            })
            if(newobj.service_provision_guaranteed_transfer == "1"){
                setGurnty(false);
            }else{
                setGurnty(true);
            }
        }
    }, [dispatch, serviceProvision, showServicesProvision])

    const handlePerChange = (e, val) => {
        setchekuncheck({
            ...chekuncheck,
            [val]: e.target.checked
        })
    }

    const onServiceSubmit = async (data: any) => {
        let newobj: any = {
            id: id,
            service_provision_adminfee: data.service_provision_adminfee,
            service_provision_admtxt: data.service_provision_admtxt,
            service_provision_admtxt_percent: data.service_provision_admtxt_percent,
            service_provision_condominium: data.service_provision_condominium,
            service_provision_leasetax: data.service_provision_leasetax,
            service_provision_leasetax_percent: data.service_provision_leasetax_percent,
            service_provision_maxlease: data.service_provision_maxlease,
            service_provision_rentvalue: data.service_provision_rentvalue,
            service_provision_funding_date: data.service_provision_funding_date,
            service_provision_salevalue: data.service_provision_salevalue,
            service_provision_termination: data.service_provision_termination,
            service_provision_transfer_day: data.service_provision_transfer_day,
            service_provision_day_to_transfer: data.service_provision_day_to_transfer,
            service_provision_guaranteed_transfer: data.service_provision_guaranteed_transfer,
            months:data.months
            
        }
        let changeVal = rentVal ? rentVal : newobj.service_provision_rentvalue
        let rentvalue: any = convertToNumber(changeVal);
        newobj["service_provision_rentvalue"] = rentvalue 
        let changeVal1 = saleValue ? saleValue : newobj.service_provision_salevalue
        let salevalue: any = convertToNumber(changeVal1);
        newobj["service_provision_salevalue"] = salevalue
        let resp = await dispatch(updateProperty_serviceProvision(newobj));
        let dataresp: any = resp?.["payload"]?.["data"];
        if (dataresp?.type == "success" || dataresp?.type == "Success") {
            servicesProvisionClose(false);
            dispatch(getProperty_serviceProvision(id));
            Swal.fire({
                position: 'center',
                // icon: 'success','success',
                title: t("Sweetalert.Update"),
                text: t("Sweetalert.updatesuccess"),
                showConfirmButton: true,
                timer: 2000
            })
        } else {
            Swal.fire({
                position: 'center',
                // icon: 'success','error',
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.somethingWent"),
                showConfirmButton: true,
            })
        }
    }

    const Handlerentvalue = (e, type) =>{
        let { value } = e.target;
        value = value.replace(/[^\d,]/g, '');
        if (value) {
            if (type == "rentvalue") {
                setValue("service_provision_rentvalue", formatNumber(value))
                setRentVal(value)
            } else {
                setValue("service_provision_salevalue", formatNumber(value))
                setSalevalue(value)
            }
        }
    }
    const [tnsf, setTnsfr] = useState(false);
    const [fixedday, setfixeday] = useState(false)
    const [gurnty, setGurnty] = useState(true);
    const transfer = (e) => {
        if (e.target.value !== "2") {
            setTnsfr(true)
            setfixeday(true)
            setValue("service_provision_transfer_day", "2");
        } else {
            setTnsfr(false)
            setfixeday(false)
            setValue("service_provision_transfer_day", "0");
        }
        if(e.target.value == "1"){
            setValue("months", "")
        }
        if (e.target.value == 1) {
            setGurnty(false);
          }else{
            setGurnty(true);
          }
    }
    return (
        <>
            <Modal show={showServicesProvision} onHide={servicesProvisionClose}>
                <Form onSubmit={handleSubmit(onServiceSubmit)} id="servicesProvision">
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Adminfeeisleviedon")} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3 align-items-center">
                            <Col md={6}>
                                <FloatingLabel controlId="rent_amount" label={t("property.rentAmount")}>
                                    <Form.Control type="text" placeholder={t("property.rentAmount")} {...register('service_provision_rentvalue')}  onChange={(e) =>Handlerentvalue(e, "rentvalue")}/>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="sale_value" label={t("property.saleValue")}>
                                    <Form.Control type="text" placeholder={t("property.saleValue")}  {...register('service_provision_salevalue')} onChange={(e) =>Handlerentvalue(e, "salevalue")}/>
                                </FloatingLabel>
                            </Col>
                            <Col md={12}>
                                <FloatingLabel controlId="funding_date" label={t("formLabel.fundingDate")} >
                                    <Form.Control type="date" placeholder={t("formLabel.fundingDate")}  {...register('service_provision_funding_date')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={4}>
                                <FloatingLabel controlId="admin_rate" label={`${t("adminrate")} (${chekuncheck.admtxt ? "Rs." : "%"})`}>
                                    <Form.Control type="text" placeholder={`${t("adminrate")} (${chekuncheck.admtxt ? "Rs." : "%"})`} {...register('service_provision_admtxt')} />
                                </FloatingLabel>
                            </Col>
                            <Col md={8}>
                                <Form.Check type="checkbox" id="use_fixed_amount" label={t("checkboxLabel.DoFixedAmount")} {...register('service_provision_admtxt_percent')} onChange={(e) => handlePerChange(e, "admtxt")} />
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="administration_fee" label={t("formLabel.administrationFee")}>
                                    <Form.Select {...register('service_provision_adminfee')}>
                                        {
                                            adminfee.map((item, index) => (
                                                <option key={index} value={item.value}>{item.label}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="condominium" label={t("formLabel.Condominiumadministration")+ " *" }>
                                    <Form.Select className={`${errors.service_provision_condominium ? 'is-invalid' : ''}`}  {...register('service_provision_condominium')}>
                                        {
                                            condominium?.map((item, index) => (
                                                <option key={index} value={item.value }>{ item.label }</option>
                                            ))
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.service_provision_condominium?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="max_lease_term" label={t("formLabel.maximumLease")}>
                                    <Form.Control type="text" placeholder={t("formLabel.maximumLease")} {...register('service_provision_maxlease')} />
                                    {/* change on jun16  2023 type date to text */}
                                </FloatingLabel>
                            </Col>
                            <Col md={6}>
                                <FloatingLabel controlId="fime_exemption" label={t("formLabel.fineExemption")}>
                                    <Form.Control type="text" placeholder={t("formLabel.fineExemption")} {...register('service_provision_termination')} />
                                    {/* change on jun16  2023 type date to text */}
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Accordion className="accordion-custom">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>{t("rentalFee")}</Accordion.Header>
                                <Accordion.Body>
                                    <Row className="gy-lg-4 gy-3 align-items-center">
                                        <Col md={4}>
                                            <FloatingLabel controlId="lease_rate" label={`${t("Leaserate")}  (${chekuncheck.leasetax ? "Rs." : "%"})`}>
                                                <Form.Control type="text" placeholder={`${t("Leaserate")}  (${chekuncheck.leasetax ? "Rs." : "%"})`} {...register('service_provision_leasetax')} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Check type="checkbox" id="lease_rate_use_fixed_amount" label={t("checkboxLabel.DoFixedAmount")} {...register('service_provision_leasetax_percent')} onChange={(e) => handlePerChange(e, "leasetax")} />
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>{t("transfer")}</Accordion.Header>
                                <Accordion.Body>
                                    <Row className="gy-lg-4 gy-3">
                                        <Col md={6}>
                                            <FloatingLabel controlId="guaranteed_transfer" label={t("guaranteedTransfer")}>
                                                <Form.Select {...register('service_provision_guaranteed_transfer')} onChange={(e) => transfer(e)}>
                                                    {
                                                        guaranteed_transfer.map((item, index) => (
                                                            <option key={index} value={item.value}>{item.label}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6} style={{ display: gurnty ? "none" : "block" }}>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label={t("newContractDev.GuaranteedMonths")}
                                            >
                                                <Form.Control
                                                    type="text"
                                                    placeholder={t("newContractDev.GuaranteedMonths")}
                                                    {...register("months")}
                                                    onChange={(e) => {
                                                        numericOnly(e);
                                                    }}
                                                />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="transfer_day" label={t("transferDay")}>
                                                <Form.Select disabled={tnsf ? tnsf : tnsf} {...register('service_provision_transfer_day')}>
                                                    {
                                                        transfer_day.map((item, index) => (
                                                            <option key={index} value={item.value}>{item.label}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={6}>
                                            <FloatingLabel controlId="days_transfer" label={fixedday ? t("formLabel.transferDay") : t('formLabel.daysOfTransfer')}>
                                                <Form.Control type="text" placeholder={t("formLabel.daysOfTransfer")} {...register('service_provision_day_to_transfer')} />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={servicesProvisionClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" type='submit' onClick={openErrorbox}>
                            {t("button.toSave")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default PropertiesServicesProvision
