import React, { useEffect, useState } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { FloatingLabel, InputGroup, Dropdown, Col, Row, Modal, Form, Button, Collapse, ButtonGroup, Alert } from 'react-bootstrap';
import InvoicesOverview from './InvoicesOverview';
import InvoicesListing from './InvoicesListing';
import NewServiceNote from './NewServiceNote';
import InvoicesFilters from './InvoicesFilters';
import eventBus from "../ReceivableCharges/EventBus";
import { useTranslation } from "react-i18next";
import { incomeService } from '../../../../services/incomeServices';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch } from 'react-redux';
import { getBraches } from '../../../../redux/slice/branchSlice';
import moment from "moment-timezone";
import Pagination from '../../../Pagination';
import { converter, encrypt_Decrypt } from '../../../constants/utils';
import { useVerifyApprovedCity } from '../../../../services/hooks';
import { registerData } from '../../../../redux/slice/companyDataSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { Invoice } from '../../../Interface';
import { cancelInvoice, GetCityCode, getInvoiceSetting, getPendingInvoice } from '../../../../redux/slice/taxationSlice';
import SettingInvoice from './SettingInvoice';
import Swal from 'sweetalert2';
import i18n from '../../../../i18n';
// const [t] = useTranslation();

const ChargeInvoices = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();
    const schema1 = yup.object().shape({
    });
    const [status, setStatus] = useState<any>("");

    const [isCheck, setIsCheck] = useState<any[]>([]);
    const [cityCodes, setCityCode] = useState<any>('')
    const [accountSetting, setAccountSetting] = useState('')
    const [loading, setLoading] = useState(false)
    //New charges modal initialization
    const [showNewServiceNoteModal, setNewServiceNoteModal] = useState(false);
    const newServiceNoteModalClose = () => setNewServiceNoteModal(false);
    const newServiceNoteModalShow = () => setNewServiceNoteModal(true);

    //console.log("This is errrorrrrr");

    //Change issue date modal initialization
    const [showChangeIssueDateModal, setChangeIssueDateModal] = useState(false);
    const changeIssueDateModalClose = () => setChangeIssueDateModal(false);
    const changeIssueDateModalShow = () => setChangeIssueDateModal(true);

    //Change issue date modal initialization
    const [showResponsibleModal, setChangeResponsibleModal] = useState(false);
    const changeResponsibleModalClose = () => setChangeResponsibleModal(false);
    const changeResponsibleModalShow = () => setChangeResponsibleModal(true);

    //Invoice mobile filter modal initialization
    const [showMobileInvocieFilterModal, setMobileInvocieFilterModal] = useState(false);
    const mobileInvocieFilterModalClose = () => setMobileInvocieFilterModal(false);
    const mobileInvocieFilterModalShow = () => setMobileInvocieFilterModal(true);

    // const { isCityApproved, verifyCity } = useVerifyApprovedCity()

    const { companydata } = useSelector((state: RootState) => state.companyData);
    const schema2 = yup.object().shape({
        changeissueDate: yup.string().nullable().required(t("requiredMmessage.fieldRequired")),
    });

    let frm = {
        resolver: yupResolver(schema1)
    };
    if (showChangeIssueDateModal) {
        frm = {
            resolver: yupResolver(schema2),
        };
    }


    const { register, handleSubmit, reset, setValue, formState: { errors }, setError, clearErrors, } = useForm<Invoice>(frm);
    const [data, setData] = useState('');
    const [filter, setFilter] = useState("");
    const [getInvioceList, setGetInvioceList] = useState<any>([])
    const [searchData, setSearchData] = useState<any>([])
    const [allCutomer, setallCustomer] = useState<any>([])
    const [totalValue, setTotalValue] = useState<number>()
    const [userstatus, setUserStatus] = useState("Status");
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [showperiodfilter, setShowPeriodFilter] = useState(false)
    const [selectedBranches, setSelectedBranches] = useState<string[]>([]);
    const [totalRow, setTotalRow] = useState<any>(0)
    const [sippingtotalRow, setSippingtotalRow] = useState<any>(0)
    const [cusomreFilter, setCusomreFilter] = useState('')
    const [serviceFilter, setserviceFilter] = useState('')
    const [minValue, setMinValue] = useState('')
    const [cancelStatus, setCancelStatus] = useState(true)
    const [showSetting, setShowSetting] = useState(false);
    const [statusCancel, setStatusCancel] = useState<any>(false)
    const [invoicePermission, setInvoicePermission] = useState(false)
    const [settingData, setSettingData] = useState<any>({})
    const { serviceData } = useSelector((state: RootState) => state.contractData)
    const { branchList } = useSelector((state: RootState) => state.branch)
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    let newDate = new Date(dateBrazil);
    let startDt = moment(newDate).subtract(1, 'M');
    let searchDt = moment(newDate)
    let endDt = moment(newDate).add(1, 'M');
    const [state, setState] = useState<any>({
        status: "all",
        startDate: startDt,
        endDate: endDt,
        searchDate: searchDt,
        search_type: "",
        start: 0,
        end: 20
    })
    const [branchState, setBranchState] = useState({
        start: "0",
        end: "100",
    });
    const parentToChild = () => {
        setData("");
    }
    const selectAllUsers = () => {
        let owner = { action: 'selectall' }
        setData(JSON.stringify(owner));
    }
    const unSelectAllUsers = () => {
        let owner = {
            action: 'unselectall'
        }
        setData(JSON.stringify(owner));
    }
    eventBus.on("couponApply", (data) => {
        let owner = {
            bankAccount: data.bankAccount,
            action: 'change_bank'
        }
        setData(JSON.stringify(owner));
        removeEvent();
    });
    const removeEvent = () => {
        eventBus.remove("couponApply", {});
    }
    // Filters section
    const [openFilters, setFiltersOpen] = useState(false);
    let PageSize = state.end;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalpage, setTotalPage] = useState(0);
    useEffect(() => {
        if (totalRow) {
            let count: any = totalRow;
            setTotalPage(count)
        }
    }, [totalRow, currentPage,])

    const [userJson, setUserJson] = useState<any>([]);
    useEffect(() => {
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(3130) == -1) {
            // navigate("/charge_invoices");
        }
        setUserJson(userResult);
    }, [])

    //console.log(totalpage, "totalpage")
    useEffect(() => {

        const updatedState = { ...state, start: (currentPage - 1) * state["end"] };
        incomeService.getInvoce(updatedState).then(async (res: any) => {
            if (res.resCode === 201) {
                const invoiceResults = res?.data?.InvoceResult || [];
                const totalRows = res?.data?.totalRow || 0;
                const SippingtotalRow = res?.data?.SippingtotalRow || 0
                if (invoiceResults.length > 0) {
                    try {
                        // Fetch status for each invoice using Promise.all
                        const resultsWithStatus = await Promise.all(
                            invoiceResults.map(async (invoice: any, index: number) => {
                                let statusResponse = "";
                                if (!!invoice?.ID_NFELOTE_NOT) {
                                    try {
                                        const response = await dispatch(getPendingInvoice(invoice?.ID_NFELOTE_NOT));
                                        statusResponse = response?.payload?.status || "";
                                    } catch (statusError) {
                                        statusResponse = ""
                                    }
                                }

                                return { ...invoice, statusRespo: statusResponse };
                            })
                        );
                        // Set the updated state after processing
                        setGetInvioceList(resultsWithStatus);
                        setSearchData(resultsWithStatus);

                        // Calculate and format the total value
                        const totalValue = resultsWithStatus.reduce(
                            (sum, item) => sum + (item.VL_VALOR_NOT || 0),
                            0
                        );
                        setTotalValue(totalValue.toFixed(2));
                        console.log()
                    } catch (error) {
                        console.error("Error fetching invoice status:", error);
                    }
                } else {
                    // Handle empty result case
                    setGetInvioceList([]);
                    setSearchData([]);
                    setTotalValue(0);
                }

                // Update total rows
                setTotalRow(totalRows);
                setSippingtotalRow(SippingtotalRow)
            }
        });
    }, [state, currentPage]);


    useEffect(() => {
        (async () => {
            let GetClientData = await incomeService?.getClients();
            if (GetClientData?.data?.clients.length > 0) {
                setallCustomer(GetClientData.data.clients);
            }
        })();
    }, [])

    // useEffect(() => {
    //     if(companydata && companydata.length>0){
    //         const { city, state } = companydata[0] ?? {}

    //         if (city && state) verifyCity(city, state)
    //     }
    // }, [companydata])

    //Getting the data shown when the page is rendered for the first time --registerData and getbranches
    useEffect(() => {
        dispatch(getBraches(branchState))
        dispatch(registerData())
    }, [dispatch])

    const InvoiceStatusis = (e: any) => {
        e.preventDefault();
        var action: any = "Status";
        action = e.currentTarget.getAttribute("data-action");

        var tname: any = e.currentTarget.getAttribute("data-name");
        // setCompositeFilter(action);
        // console.log('++++++++', action);
        if (action == "all") {
            tname = "Status";
        }
        setUserStatus(tname);
        if (action == 0) {
            let invoiceData: any = []
            incomeService.getInvoce(state).then((res) => {
                res?.data?.InvoceResult?.forEach((invoice) => {
                    if (invoice.FL_STATUS_NOT == action) {
                        invoiceData.push(invoice)
                    }
                });
                setGetInvioceList(invoiceData)
            })

        }
        if (action == -1) {
            let invoiceData: any = []
            incomeService.getInvoce(state).then((res) => {
                res?.data?.InvoceResult.forEach((invoice) => {
                    if (invoice.FL_STATUS_NOT == action) {
                        invoiceData.push(invoice)
                    }
                });
                setGetInvioceList(invoiceData)
            })

        }
    }
    const FilterCusto = (e: any, data) => {
        incomeService.getInvoce(state).then((res) => {
            let invoiceData: any = [];

            if (data === "customer") {
                if (e.target.value === "") {
                    // If the selected value is empty, show all items
                    setGetInvioceList(res.data);
                } else {
                    // Filter invoices based on the selected customer ID
                    res?.data?.InvoceResult?.forEach((invoice) => {
                        if (invoice.ID_SACADO_SAC == e.target.value) {
                            invoiceData.push(invoice);
                        }
                    });
                    setGetInvioceList(invoiceData);
                    var selectElement: any = document.querySelector('select[name="costomer"]');
                    var selectedIndex = selectElement?.selectedIndex;
                    var selectedOption = selectElement?.options[selectedIndex];
                    // Get the value of the data-action attribute
                    var dataAction: any = selectedOption?.getAttribute('label');
                    //console.log(dataAction)
                    setCusomreFilter(dataAction)
                }
            }

            if (data == "service") {
                if (e.target.value === "") {
                    // If the selected value is empty, show all items
                    setGetInvioceList(res.data.InvoceResult);
                } else {
                    var selectElement: any = document.querySelector('select[name="service"]');
                    var selectedIndex = selectElement?.selectedIndex;
                    var selectedOption = selectElement?.options[selectedIndex];
                    // Get the value of the data-action attribute
                    var dataAction: any = selectedOption?.getAttribute('label');
                    setserviceFilter(dataAction)
                    // Filter invoices based on the selected service ID
                    res?.data?.InvoceResult?.forEach((invoice) => {
                        if (invoice.ID_PRODUTO_PRD == e.target.value) {
                            invoiceData.push(invoice);
                        }
                    });
                    setGetInvioceList(invoiceData);
                }
            }
        });
    };
    const handleCheckboxChange = (branchId: any) => {
        if (branchId === -1) {
            setSelectAll(!selectAll);
            setSelectedBranches(selectAll ? [] : branchList && branchList.length > 0 && branchList?.map(branch => branch?.id));
        } else {
            const updatedSelectedBranches = selectedBranches.includes(branchId)
                ? selectedBranches.filter(branch => branch !== branchId)
                : [...selectedBranches, branchId];
            incomeService.getInvoce(state).then((res) => {
                if (res.resCode == 201) {
                    const filteredData = res?.data?.InvoceResult?.filter(entry => updatedSelectedBranches.includes(entry.ID_FILIAL_FIL));
                    setGetInvioceList(filteredData)
                }
            })

            setSelectedBranches(updatedSelectedBranches);
            if (updatedSelectedBranches?.length > 0) {
                setSelectAll(updatedSelectedBranches?.length === branchList?.length);
            }
        }
    };

    const HandleSearch = (e) => {
        if (e.target.value === "") {
            setGetInvioceList(searchData);
        } else {
            const filteredResult = searchData?.filter(item => {
                const lowerCaseFantasia = item.ST_FANTASIA_PES ? item.ST_FANTASIA_PES.toLowerCase() : '';
                return lowerCaseFantasia.includes(e.target.value.toLowerCase());
            });
            if (filteredResult.length > 0) {
                setGetInvioceList(filteredResult)
            } else {
                setGetInvioceList([])
            }
        }
        setFilter(e.target.value);
    }
    const filterReset = () => {
        setCusomreFilter('')
        setserviceFilter('')
        setUserStatus('Status')
        setSelectAll(false)
    }
    const filterCharge = (dt, tp) => {
        if (typeof tp !== "undefined") {
            let currentDate = new Date(dt);
            if (tp == "Subtract") {
                startDt = moment(currentDate).subtract(1, 'M');
                searchDt = moment(startDt).add(1, 'M');
                endDt = moment(currentDate).add(1, 'M');
            }
            else {
                startDt = moment(currentDate).subtract(1, 'M');
                searchDt = moment(startDt).add(1, 'M');
                endDt = moment(currentDate).add(1, 'M');
            }
            setState({
                ...state,
                ['startDate']: startDt,
                ['endDate']: endDt,
                ['searchDate']: searchDt,
                ['start']: 0
            });

        }
    }
    const handleShow = () => {
        setShowPeriodFilter(true)
    }
    const peridfilterClose = () => {
        setShowPeriodFilter(false)
    }
    const dateSubmit = (data) => {
        let start2 = moment(data.startDate); // Assign within function
        let endDt2 = moment(data.endDate);

        setState({
            ...state,
            ['startDate']: start2,
            ['endDate']: endDt2,
            ["type"]: "periodic",
            ['start']: 600
        })
        setShowPeriodFilter(false)
        reset();
    }

    const startDate = (e: any) => {
        let { value } = e.target
        setMinValue(value)
    }

    const handleShowSetting = () => {
        if (userJson && userJson.length > 0 && userJson.indexOf(3131) !== -1) {
            setShowSetting(true);
        } else {
            Swal.fire({
                icon: 'warning',
                text: t("Sweetalert.Youdonothavepermission"),
                confirmButtonText: "OK",
                timer: 2000,
            });

        }
    };

    useEffect(() => {
        if (companydata.length) {
            cityCode()
        }
    }, [isCheck, companydata])

    const cityCode = async () => {
        let res = await dispatch(GetCityCode({ zipCode: companydata[0]?.zipcode }))
        if (res.payload) {
            // const filteredData = res.payload.filter(item => item?.nome == companydata[0]?.city && item?.uf == companydata[0]?.state);
            // setCityCode(filteredData[0]?.id)
            setCityCode(res?.payload?.ibge)
        }
    }
    const handleInvoice = (e: any) => {
        if (isCheck.length > 0) {
            let data1 = {
                isCheck: isCheck,
                city_code: cityCodes,
                SentEmail: settingData?.copy_of_the_generated_invoice,
                account_category: accountSetting
            };
            Swal.fire({
                text: t("Sweetalert.invoiceTransmit"),
                showCancelButton: true,
                confirmButtonText: t("yes"),
                cancelButtonText: t("no"),
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoading(true)
                    // plugnotasgenerate
                    incomeService.GeneratePlungnota(data1).then((res) => {
                        // Segregate the success and error details
                        const successDetails = res.data.details.filter((detail: any) => detail.status == 'success');
                        const errorDetails = res.data.details.filter((detail: any) => detail.status == 'error');
                        // Separate messages by status
                        let successMessages: string[] = [];
                        let errorMessages: string[] = [];
                        // Organize messages based on their status
                        res.data.details.forEach((detail: any) => {
                            if (detail.status == "success") {
                                setLoading(false)
                                successMessages.push(detail.message);
                                setState({
                                    ...state,
                                    ['start']: 0
                                })
                            } else if (detail.status == "error") {
                                setLoading(false)
                                errorMessages.push(`${JSON.stringify(detail.data?.data?.fields || detail.data?.data?.fields || detail?.data?.message)}`);
                            }
                        });

                        const alertMessage = `${successDetails.length > 0  ? '<b>Success:</b><br>' + successMessages.join('<br />') + '<br /><br />' : ''} 
                        ${res?.data.alreadyRegisteredCount > 0 
                          ? res?.data.alreadyRegisteredCount + " " + t("Alreadyconnected") + '<br /><br />' 
                          : ''
                        } 
                        ${errorDetails.length > 0 
                          ? '<b>Errors:</b><br>' + errorMessages.join('<br />') 
                          : ''
                        }`;
                        setLoading(false)

                        // Show single SweetAlert with both success and error messages
                        Swal.fire({
                            position: "center",
                            icon: errorDetails.length > 0 ? "error" : 'success', // Show 'error' if there are any error details
                            title: errorDetails.length > 0 ? `${t("Sweetalert.Error")}` : `${t("Sweetalert.Success")}`,
                            html: alertMessage, // using 'html' to support line breaks and formatting
                            showCloseButton: true
                        });
                    });


                }
            });
        } else {
            Swal.fire({
                title: `${t("Sweetalert.Error")}`,
                text: `${t("Sweetalert.pleaseSelectInvoice")}`,
                // icon: 'success',"error",
                confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
            });
        }

    }
    useEffect(() => {
        if (isCheck.length > 0) {
            const filteredData = getInvioceList?.filter(item =>
                item.statusRespo == "CONCLUIDO" && isCheck.includes(item.ID_NOTA_NOT)
            );
            if (filteredData.length == isCheck?.length) {
                setCancelStatus(false)
            } else {
                setCancelStatus(true)
            }
        }
    }, [isCheck])

    useEffect(() => {
        const fetchInvoiceSetting = async () => {
            let getInvo = await dispatch(getInvoiceSetting());
            if (getInvo.payload.type == "Success" || getInvo.payload.type == "success") {
                setSettingData(getInvo?.payload?.data[0])
            }
        };

        fetchInvoiceSetting();
    }, [dispatch, showSetting]);


    const handleCancel = async (e) => {
        if (isCheck.length > 0) {
            Swal.fire({
                text: i18n.language == "en"
                    ? `Do you really want to cancel the selected invoice${isCheck.length > 1 ? "(s) " : ""}?`
                    : "Deseja mesmo cancelar as faturas selecionadas?",
                showCancelButton: true,
                confirmButtonText: t("yes"),
                cancelButtonText: t("no"),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setStatusCancel(true);
                    const filteredData = getInvioceList?.filter(item =>
                        isCheck.includes(item.ID_NOTA_NOT)
                    );
                    const uniqueLotIds: string[] = Array.from(new Set(filteredData.map(item => item.ID_NFELOTE_NOT)));

                    try {
                        const emailResponse = await incomeService.SendEmailInvoice({
                            isCheck: isCheck,
                            SentEmail: settingData?.copy_of_the_cancelled_invoice,
                        });

                        console.log(emailResponse);
                        const { sentCount, nullEmailCount } = emailResponse.data;

                        // Dispatch cancellation requests for each unique lot ID
                        const responses: any = await Promise.all(uniqueLotIds.map(id => dispatch(cancelInvoice(id))));
                        const successMessages: any = Array.from(
                            new Set(
                                responses.map((res: any) =>
                                    res.payload?.message ? res.payload.message : res.payload.error.message
                                )
                            )
                        );

                        let message = successMessages.join("\n"); // Show API success messages
                        let iconType: any = successMessages.includes("Cancelamento em processamento") ? "success" : "error";
                        let title = iconType === "success" ? t("Sweetalert.Success") : t("Sweetalert.Error");

                        // If nullEmailCount is 1, append an HTML message
                        if (nullEmailCount === 1) {
                            message += t("Sweetalert.emailRequired")

                        }

                        Swal.fire({
                            title: title,
                            html: message, // Use 'html' instead of 'text' to format the message
                            icon: iconType,
                            confirmButtonText: t("Sweetalert.ConfirmButtonText"),
                        });

                        setIsCheck([]);
                        setTimeout(() => {
                            setStatusCancel(false);
                        }, 500);

                        setCancelStatus(true);
                        setState({
                            ...state,
                            ["start"]: 0,
                        });
                    } catch (error: any) {
                        let errorMessage = error?.response?.data?.message || error.message;
                        setStatusCancel(false);
                        Swal.fire({
                            title: t("Sweetalert.Error"),
                            text: errorMessage, // Show API error message
                            icon: "error",
                            confirmButtonText: t("Sweetalert.ConfirmButtonText"),
                        });
                    }
                } else {
                    setStatusCancel(false);
                }
            });
        } else {
            Swal.fire({
                title: `${t("Sweetalert.Error")}`,
                text: `${t("Sweetalert.pleaseSelectInvoice")}`,
                icon: "error",
                confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
            });
        }
    };


    const ChnageDate = (data) => {
        console.log(data, "1212")
    }
    const handlSendEamil = (e: any) => {
        if (isCheck.length > 0) {
            setStatus("loading");
            incomeService.SendEmailInvoice({
                isCheck: isCheck,
                SentEmail: settingData?.copy_of_the_generated_invoice,
            }).then((res) => {
                const { success, sentCount, notSentCount, nullEmailCount } = res.data;

                if (nullEmailCount > 0 && sentCount === 0) {
                    Swal.fire({
                        title: `${t("Sweetalert.Error")}`,
                        text: `${t("Sweetalert.emailRequired")}`,
                        icon: "error",
                        confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
                    });
                    setStatus("");
                } else if (sentCount > 0 && nullEmailCount === 0) {
                    Swal.fire({
                        title: `${t("Sweetalert.Success")}`,
                        text: `${t("Sweetalert.mailsent")}`,
                        icon: "success",
                        confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
                    });
                    setState({
                        ...state,
                        ['start']: 0
                    })
                    setStatus("");
                    setIsCheck([]);
                } else if (sentCount > 0 && nullEmailCount > 0) {
                    Swal.fire({
                        title: `${t("Sweetalert.Warning")}`,
                        text: `${t("Sweetalert.emailRequired")}`,
                        icon: "warning",
                        confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
                    });
                    setStatus("");
                }
            });
        } else {
            Swal.fire({
                title: `${t("Sweetalert.Error")}`,
                text: `${t("Sweetalert.pleaseSelectInvoice")}`,
                icon: "error",
                confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
            });
        }
    };

    return (
        <section className="content-wrapper content-wrapper-primary">
            {/*<!-- Breadcrumb section start from here -->*/}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center filters border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100 mb-xl-4">
                    <Row className="gx-xl-5 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: "auto", order: 0 }} className="mb-xl-0 mb-3">
                            <div className="d-flex align-items-center justify-content-between me-xl-1">
                                <h1 className="mb-0 lh-base text-truncate">{t("Invoices.invoices")}</h1>
                                {/* Filter button visible in mobile devices */}
                                <div className="d-xl-none">
                                    <Button variant="light" size="sm" className="text-nowrap" onClick={() => setFiltersOpen(!openFilters)} aria-controls="collpase-filter-text" aria-expanded={openFilters}>
                                        {t("dropdownItem.filters")}
                                        <img
                                            src="assets/images/icons/filter-icon.svg"
                                            alt="Filter"
                                            className="ms-1 h-12"
                                        />
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ order: 2 }} xl={{ span: 3, order: 2 }}>
                            {/* Search bar */}
                            <InputGroup>
                                <Form.Control placeholder={t("formLabel.typeSearch")} aria-label="Search" aria-describedby="search_icon"
                                    onChange={HandleSearch} />
                                <InputGroup.Text id="search_icon">
                                    <img src="./assets/images/icons/search.svg" alt="Search icon" />
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} xl={{ order: 3 }} className="text-xl-end col-xl mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <a className="fw-bold cursor-pe" onClick={newServiceNoteModalShow}>
                                        <img src="assets/images/icons/plus-light.svg" alt="Plus icon" className="me-1 h-20" />
                                        {t("Invoices.newServiceNote")}
                                    </a>
                                </li>
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1 text-reset">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="link" size="sm" className="p-0 fw-bold dropdown-arrow-none" id="moreDropdown">
                                            {t("more")}
                                            <img src="assets/images/icons/arrow-bottom-primary.svg" alt="Plus icon" className="ms-1 h-8" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="/">{t("Invoices.batches")}</Dropdown.Item>
                                            {/* <Dropdown.Item ><Link className="text-body" to={"/series"}>{t("Invoices.series")}</Link></Dropdown.Item> */}
                                            <Dropdown.Item ><Link className="text-body" to={"/taxation"}> {t("Invoices.Taxation")} </Link></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="link" size="sm" className="p-0 fw-bold dropdown-arrow-none" id="morePrintAll">
                                            <img src="assets/images/icons/print-light.svg" alt="Print" className="h-20" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item>{t("Invoices.printThisScreen")}</Dropdown.Item>
                                            <Dropdown.Item>{t("Invoices.Taxation")}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </li>
                                <li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1">

                                    <a onClick={handleShowSetting}>
                                        <img src="assets/images/icons/settings-light.svg" alt="Settings" className="h-20" />
                                    </a>

                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
                {/* Filters start here */}
                <Collapse className="d-xl-block collpase-filters" in={openFilters}>
                    <Form id="collpase-filter-text" action="" method="">
                        <div className="d-xl-none d-flex align-items-center justify-content-between mb-4">
                            <h5 className="mb-0 fw-bold">Filters</h5>
                            <span className="cursor-pe" onClick={() => setFiltersOpen(!openFilters)}>
                                <img src="assets/images/icons/cross.svg" alt="Plus icon" className="ms-xxl-1 ms-auto h-14" />
                            </span>
                        </div>
                        <Row className="gx-2 gx-xxl-3 gy-xxl-0 gy-3">
                            <Col xl="auto">
                                <ButtonGroup className="btn-date-filter" size="sm" aria-label="Date">
                                    <Button variant="primary" className="btn-arrow" onClick={() => filterCharge(state.startDate, 'Subtract')}>
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white.svg"} className="h-24" />
                                        {/* {state.startDate} */}
                                    </Button>
                                    <Button variant="primary" className="btn-date capitalize" onClick={() => filterCharge(state.startDate, 'Subtract')} disabled>
                                        {state.searchDate.format('MMM/YYYY')}
                                    </Button>
                                    <Button variant="primary" className="btn-arrow" onClick={() => filterCharge(state.endDate, 'Add')}>
                                        {/* {state.endDate} */}
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-white-right.svg"} className="h-24" />
                                    </Button>
                                </ButtonGroup>
                            </Col>
                            <Col xl="auto">
                                <Dropdown>
                                    <Dropdown.Toggle variant="light" size="sm" className={`${userstatus !== "Status" ? "show" : ""}`}>
                                        {t(`${userstatus}`)}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="px-4">
                                        <Dropdown.Item
                                            href="#"
                                            data-action="0"
                                            data-name={t("Invoices.issued")}
                                            onClick={InvoiceStatusis}
                                        >
                                            {t("Invoices.issued")}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            href="#"
                                            data-action="-1"
                                            data-name={t("Invoices.canceled")}
                                            onClick={InvoiceStatusis}
                                        >
                                            {t("Invoices.canceled")}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            {/* <Col xl="auto">
                            <Dropdown autoClose="outside">
                                <Dropdown.Toggle variant="light" size="sm">
                                    Shipping
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="px-4">
                                    <div className="py-2">
                                        All
                                    </div>
                                    <div className="py-2">
                                        Unmarked
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col> */}
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={`${cusomreFilter ? "show" : ""}`}>
                                        {cusomreFilter ? cusomreFilter : t("Invoices.customers")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t("Invoices.customers")}>
                                            <Form.Select aria-label="Customers" name="costomer" onChange={(e) => FilterCusto(e, "customer")}>
                                                <option value="">{t('dropdownItem.select')}..</option>
                                                {allCutomer && allCutomer.length > 0 &&
                                                    allCutomer?.map((item, index) => (
                                                        <option key={index} value={item.ID_SACADO_SAC} label={item.ST_NOME_SAC}>{item.ST_NOME_SAC}</option>
                                                    ))}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            {/* <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm">
                                        Groups
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label="Groups">
                                            <Form.Select aria-label="Groups">
                                                <option value="">{t("formLabel.select")}</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col> */}
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={`${serviceFilter ? "show" : ""}`}>
                                        {serviceFilter ? serviceFilter : t("taxation.services")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4">
                                        <FloatingLabel label={t("taxation.services")}>
                                            <Form.Select
                                                className="form-control"
                                                name="service"
                                                onChange={(e) => FilterCusto(e, "service")}>
                                                <option value="">{t('dropdownItem.select')}..</option>
                                                {serviceData && serviceData.length > 0 &&
                                                    serviceData?.map((item, i) => (
                                                        <option key={i} value={item.id} label={item.name}>{item.name}</option>
                                                    ))}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={selectAll ? "show" : ""}>
                                        {t("branches.branches")}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdwon-lg px-4 pt-4" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                        <Form.Check
                                            type="checkbox"
                                            id="filter_branches_1"

                                            className="mb-3"
                                            label={t("formLabel.selectAll")}
                                            checked={selectAll}
                                            onChange={() => handleCheckboxChange(-1)}
                                        />
                                        {branchList && branchList.length > 0 &&
                                            branchList.map((branch, index) => (
                                                <Form.Check
                                                    key={index}
                                                    type="checkbox"
                                                    id={`filter_branches_${index + 2}`}
                                                    className="mb-3"
                                                    label={branch.name}
                                                    checked={selectedBranches.includes(branch.id)}
                                                    onChange={() => handleCheckboxChange(branch.id)}
                                                />
                                            ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xl="auto">
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="light" size="sm" className={state?.["type"] == "periodic" ? "show" : ""} onClick={handleShow}>
                                        {t("periodfilter")}
                                    </Dropdown.Toggle>
                                </Dropdown>
                            </Col>
                            {cusomreFilter || serviceFilter || userstatus !== "Status" || selectAll ?
                                <Col xl="auto">
                                    <Dropdown autoClose="outside" className="moreFilter">
                                        <Dropdown.Toggle variant="light" size="sm" className="d-flex align-items-center" onClick={filterReset}>
                                            {t("button.resetfilter")}
                                        </Dropdown.Toggle>
                                    </Dropdown>

                                </Col> :
                                ""}
                        </Row>
                    </Form>
                </Collapse>
                {/* /Filters end */}
            </div>
            {/*<!-- /Breadcrumb section end -->*/}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                {/* {isCityApproved === false && (
                    <Alert variant="danger" className="mb-lg-4 mb-3">
                        {t("taxation.danger")}
                    </Alert>
                )} */}
                {/* <!-- overview -->*/}

                {/* <!-- /overview -->*/}
                {/*<!-- Table start from here -->*/}
                <InvoicesListing params={data} getInvioceList={getInvioceList} totalRow={totalRow} isCheck={isCheck} setIsCheck={setIsCheck} sippingtotalRow={sippingtotalRow} setStatus={setStatus} status={status} />
                <SettingInvoice showSetting={showSetting} setShowSetting={setShowSetting} setAccountSetting={setAccountSetting} />
                {totalRow ?
                    <div className="d-flex justify-content-center mt-lg-4 mt-3">
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={totalpage}
                            pageSize={PageSize}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                    : ""}

                {/*<!-- /Table end -->*/}
                <p className="my-lg-4 my-3">{t("formLabel.listing")} {totalRow} {t("Invoices.notesFrom")} {getInvioceList.length} {t("Invoices.customers")} {t("comparetolastmonth.total")} R$ {getInvioceList.length > 0 ? converter(String(totalValue)) : 0}</p>
                {/* <!-- Nav listing start from here -->*/}
                <ul className="list-inline mb-0">
                    {/* <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        <Dropdown>
                            <Dropdown.Toggle variant="light" size="sm">
                                Mark
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={selectAllUsers}>{t("all")}</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={unSelectAllUsers}>None</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li> */}
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2"> <span className="px-1">{t("Invoices.withtagged")}:</span></li>
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2" >
                        {loading ?
                            <>
                                <span className="spinner-border spinner-border-sm"></span>
                                <span className="px-1">{t("Processing")}</span>
                            </>
                            :
                            <Button variant="light" size="sm" onClick={(e) => handleInvoice(e)}>{t("Invoices.NFEDialtoTransmit")}</Button>
                        }
                    </li>
                    {/* <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        <Button variant="light" size="sm">{t("Invoices.unmarkfromtransmission")}</Button>
                    </li> */}
                    <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                        {statusCancel ?
                            <>
                                <span className="spinner-border spinner-border-sm"></span>
                                <span className="px-1">{t("Processing")}</span>
                            </>
                            :
                            <Button variant="light" size="sm" onClick={(e) => handleCancel(e)} style={{ backgroundColor: cancelStatus ? "rgb(242, 242, 242)" : "#fff", borderColor: "#d8d8d8", color: "#4c4c4c", borderRadius: "50rem" }}>{t("button.cancel")}</Button>
                        }
                    </li>
                    <li className="list-inline-item mb-lg-0 mb-1">
                        <Dropdown drop="up">
                            <Dropdown.Toggle variant="light" size="sm">
                                {t("more")}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="mh-456 overflow-auto">
                                <Dropdown.Item onClick={(e) => handlSendEamil(e)}>{t("Invoices.sendbyemail")}</Dropdown.Item>
                                {/* <Dropdown.Item>{t("Invoices.printinvoicemirror")}</Dropdown.Item> */}
                                {/* <Dropdown.Item onClick={changeIssueDateModalShow}>{t("Invoices.changeissuedate")}</Dropdown.Item>
                                <Dropdown.Item onClick={changeResponsibleModalShow}>{t("Invoices.changeresponsiblecompany")}</Dropdown.Item> */}
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                </ul>
                {/*<!-- /Nav listing  end -->*/}
            </main>
            {/*<!-- /Main content end -->*/}
            {/* Change issue date modal start from here */}
            <Modal show={showChangeIssueDateModal} onHide={changeIssueDateModalClose}>
                <Form onSubmit={handleSubmit(ChnageDate)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Invoices.changeissuedate")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel controlId="issuance" label="Issuance">
                            <Form.Control type="date" placeholder="Issuance"  {...register("changeissueDate")}  {...register("changeissueDate")} />
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={changeIssueDateModalClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" type='submit'>
                            {t("button.toSave")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* /Change issue date modal end */}
            {/* Change the company responsible modal start from here */}
            <Modal show={showResponsibleModal} onHide={changeResponsibleModalClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Invoices.changethecompanyresponsible")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FloatingLabel controlId="company" label="Company">
                            <Form.Select>
                                <option value="1">{t("Invoices.headquarters")}</option>
                                <option value="2">{t("Invoices.laercioJoseIlkiu")}</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={changeResponsibleModalClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" onClick={changeResponsibleModalClose}>
                            {t("button.toSave")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* /Change the company responsible modal end */}
            <NewServiceNote showServiceNote={showNewServiceNoteModal} serviceNoteShow={newServiceNoteModalShow} serviceNoteClose={newServiceNoteModalClose} setGetInvioceList={setGetInvioceList} state={state} setState={setState} />
            <Modal show={showperiodfilter} onHide={peridfilterClose}>
                <Form onSubmit={handleSubmit(dateSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("TransferPage.month")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="g-3 g-xl-4">
                            <Col md>
                                <FloatingLabel label={t("TransferPage.startDate")}>
                                    <Form.Control type="date" aria-label="From"  {...register("startDate")} onChange={startDate}></Form.Control>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel label={t("TransferPage.endDate")}>
                                    <Form.Control type="date" aria-label="to" min={minValue}   {...register("endDate")} ></Form.Control>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={peridfilterClose}>
                            {t("button.close")}
                        </Button>
                        <Button variant="primary" type='submit'>
                            {t("button.save")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </section>
    )
}

export default ChargeInvoices
