import moment from "moment";
import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";

import { useChargesToReceive } from "../../ChargesToReceiveProvider";
import { converter } from "../../../../../component/constants/utils";
import { ID_FORMAPAGAMENTO_RECB } from "../../constants";

type HomologatedChargesListContextState = {
  homologatedCharges: any[];
  selectedCharges: number[];
  deselectAllCharges: () => void;
  handleSelectAllCharges: () => void;
  handleSelectOneCharge: (chargeId: number) => void;
  isAllChargesSelected: boolean;
  countSelectedCharges: number;
  isFetchingCharges: boolean;
  totalPaidAmountHomologateCharges: number;
  totalPaidQuantityHomologateCharges: number;
  totalPendingAmountHomologateCharges: number;
  totalPendingQuantityHomologateCharges: number;
};

function parseFromPtBrDate(dateString: string) {
  const [day, month, year] = dateString.split("/").map(Number);
  return new Date(year, month - 1, day);
}

const HomologatedChargesListContext =
  createContext<HomologatedChargesListContextState>(
    {} as HomologatedChargesListContextState
  );

export function HomologatedChargesListProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [selectedCharges, setSelectedCharges] = useState<number[]>([]);
  const { bankSlips, charges, isFetchingCharges, chargeFilters } = useChargesToReceive();
  const searchedMonth = (moment(chargeFilters.searchDate).get("month") + 1).toString().padStart(2, "0");

  const homologatedCharges = useMemo(() => {
    if (!charges || charges.length <= 0) {
      return [];
    }

    const homologatedCharges = charges.filter(
      (charge) =>
        charge.ID_FORMAPAGAMENTO_RECB ===
        ID_FORMAPAGAMENTO_RECB.homologatedBankSlip
    );
    const currentDate = moment(new Date()).startOf("day");

    return homologatedCharges.map((charge) => {
      const bankSlip = bankSlips.find(
        (bankSlip) => bankSlip.IdRecebimento === charge.ID_RECEBIMENTO_RECB
      );
      const dueDate = bankSlip
        ? moment(
            new Date(
              parseFromPtBrDate(bankSlip.TituloDataVencimento.split(" ")[0])
            )
          ).startOf("day")
        : moment(charge.DT_VENCIMENTO_RECB).startOf("day");
      const dueDateDisplay = dueDate.format("DD/MM/YYYY");
      const isOverdue =
        charge.FL_STATUS_RECB !== 1 && dueDate.isBefore(currentDate);
      const daysLate = isOverdue ? currentDate.diff(dueDate, "days") : 0;
      const isCanceled = charge.FL_STATUS_RECB === 6; // FL_STATUS_RECB = 6 cancelado
      const ourNumber = bankSlip ? bankSlip.TituloNossoNumero : "";
      const statusBankSlip = bankSlip ? bankSlip.situacao : undefined;
      const contractNumber = bankSlip
      ? bankSlip.Contrato
      : charge.CUSTOMST_MARCADOR_RECB;
      const isSingle = !contractNumber; // é avulso quando não tem contrato associado
      const payerName = bankSlip ? bankSlip.SacadoNome : charge.ST_NOMEREF_SAC;
      const totalValue = bankSlip
        ? parseFloat(bankSlip.TituloValor.replace(",", "."))
        : String(charge.VL_TOTAL_RECB);
      const IdIntegracao = bankSlip?.IdIntegracao || ''

      return {
        ...charge,
        id: charge.ID_RECEBIMENTO_RECB,
        bankSlip,
        bankSlipUrl: bankSlip ? bankSlip.UrlBoleto : undefined,
        contractNumber,
        daysLate,
        daysLateDisplay:
          daysLate > 0
            ? `${daysLate} dia${daysLate > 1 ? "s" : ""} em atraso`
            : "",
        dueDate,
        dueDateDisplay,
        integrationId: bankSlip ? bankSlip.IdIntegracao : undefined,
        isCanceled,
        isOverdue,
        isSingle,
        ourNumber,
        payerName,
        statusBankSlip,
        totalValue,
        totalValueDisplay: `R$ ${converter(totalValue)}`,
        IdIntegracao
      };
    });
  }, [bankSlips, charges]);

  const countSelectedCharges = useMemo(
    () => selectedCharges.length,
    [selectedCharges]
  );

  const countHomologatedCharges = useMemo(
    () => homologatedCharges.length,
    [homologatedCharges]
  );

  function selectAllCharges() {
    setSelectedCharges(
      homologatedCharges.map((charge) => charge.ID_RECEBIMENTO_RECB)
    );
  }

  function deselectAllCharges() {
    setSelectedCharges([]);
  }

  function selectOneCharge(chargeId: number) {
    setSelectedCharges((currentSelectedCharges) => [
      ...currentSelectedCharges,
      chargeId,
    ]);
  }

  function removeOneCharge(chargeId: number) {
    setSelectedCharges((currentSelectedCharges) =>
      currentSelectedCharges.filter((id) => id !== chargeId)
    );
  }

  function handleSelectAllCharges() {
    if (isAllChargesSelected) {
      deselectAllCharges();
      return;
    }

    selectAllCharges();
  }

  function handleSelectOneCharge(chargeId: number) {
    if (selectedCharges.includes(chargeId)) {
      removeOneCharge(chargeId);
      return;
    }

    selectOneCharge(chargeId);
  }

  const isAllChargesSelected = useMemo(
    () =>
      countHomologatedCharges > 0 &&
      countHomologatedCharges === countSelectedCharges,
    [countHomologatedCharges, countSelectedCharges]
  );

  const totalPaidAmountHomologateCharges = useMemo(
    () =>
      bankSlips.reduce((acc, bankSlip) => {
        if (
          bankSlip.situacao === "LIQUIDADO" &&
          bankSlip.TituloDataVencimento.split("/")[1] === searchedMonth
        )
          return (
            acc + parseFloat(bankSlip.PagamentoValorPago.replace(",", "."))
          );

        return acc;
      }, 0),
    [bankSlips, searchedMonth]
  );

  const totalPaidQuantityHomologateCharges = useMemo(
    () =>
      bankSlips.reduce((acc, bankSlip) => {
        if (
          bankSlip.situacao === "LIQUIDADO" &&
          bankSlip.TituloDataVencimento.split("/")[1] === searchedMonth
        )
          return acc + 1;

        return acc;
      }, 0),
    [bankSlips, searchedMonth]
  );

  const totalPendingAmountHomologateCharges = useMemo(
    () =>
      bankSlips.reduce((acc, bankSlip) => {
        if (
          bankSlip.situacao === "EMITIDO" &&
          bankSlip.TituloDataVencimento.split("/")[1] === searchedMonth
        )
          return acc + parseFloat(bankSlip.TituloValor.replace(",", "."));

        return acc;
      }, 0),
    [bankSlips, searchedMonth]
  );

  const totalPendingQuantityHomologateCharges = useMemo(
    () =>
      bankSlips.reduce((acc, bankSlip) => {
        if (
          bankSlip.situacao === "EMITIDO" &&
          bankSlip.TituloDataVencimento.split("/")[1] === searchedMonth
        )
          return acc + 1;

        return acc;
      }, 0),
    [bankSlips, searchedMonth]
  );

  return (
    <HomologatedChargesListContext.Provider
      value={{
        countSelectedCharges,
        deselectAllCharges,
        handleSelectAllCharges,
        handleSelectOneCharge,
        homologatedCharges,
        isAllChargesSelected,
        selectedCharges,
        isFetchingCharges,
        totalPaidAmountHomologateCharges,
        totalPaidQuantityHomologateCharges,
        totalPendingAmountHomologateCharges,
        totalPendingQuantityHomologateCharges
      }}
    >
      {children}
    </HomologatedChargesListContext.Provider>
  );
}

export function useHomologatedChargesList() {
  const context = useContext(HomologatedChargesListContext);

  if (context === undefined) {
    throw new Error(
      "useHomologatedChargesList must be used within a HomologatedChargesListProvider"
    );
  }

  return context;
}
