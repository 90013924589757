import { gettoken, config } from "./apiConfig";
import axios from "axios";
const CustomerApiBaseUrl = config.CUSTOMER__API;
const companyV2_URL = config.companyV2_URL
// const Token = gettoken.Token;
const Token = localStorage.getItem("tokenV2");
if (localStorage.getItem("userData")) {
  axios.defaults.headers.common['connection-name'] = localStorage.getItem("userData");
}
const customerV2 = config.customerV2;
const companyV2_URL_LOCAL = config.companyV2_URL_LOCAL;
const companyV2_URL_STAGING = config.CUSTOMER_API;
const notificationsV2URL = config.notificationsV2URL;
const companyV2 = config.companyV2;
const TRANSFER_API = config.TRANSFER_API;
let user_id = gettoken.userId
class customerService {

/*-------------Batch Settle-------------*/

  chargeBatchSettle(settleData) {
    settleData.userId = 1;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(TRANSFER_API + "/M1/contract/chargeBatchSettle", settleData);
  }

/*-------------User email listing-------------*/

  getEmailListing(emailData) {
    emailData.userId = 1;    
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(TRANSFER_API + "/M1/getAllEmailListing", emailData);
  }

  documentStatusUpdate(optionId, documentId) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/documentStatusUpdate", { optionId: optionId, documentId: documentId });
  }

  getRenderedContractAmmendmantTemplate(contract_id, templateSelection) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/renderContractAmendmantVariablesAndExtractEmails", { contract_id: contract_id, templateSelection: templateSelection });
  }
  insertLiquidate(liquidateData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
  }
  /*-------------Customer & employee insert api-------------*/
  createOwner(owner) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertCustomerAndEmployees", owner);
  }
  deleteOwner(owner) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/deleteOwner", owner);
  }
  deleteTenant(owner) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/deleteTenant", owner);
  }
  addOwnerbank(owner) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/addOwnerbank", owner);
  }
  updateOwnerMainAccount(owner) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/updateOwner/MainAccount", owner);
  }
  disableMainAccount(owner) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/updateOwner/DisableAccount", owner);
  }
  /*-------------Check Property Identifier api-------------*/

  checkPropertyIdentifier(property) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/checkPropertyIdentifier", property);
  }

  /*-------------Owner & beneficiaries get listing api-------------*/

  UpdateSingleOwner(owner) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/updateSingleOwner", owner);
  }

  /*-------------Single contract details page count api-------------*/

  getContractDetailsCount(id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(TRANSFER_API + "/M1/singleContractDashboard", { id: id });
  }

  /*-------------Contract dashboard count api-------------*/

  getContractCount() {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/contractDashboard");
  }

  contractsMirrorData(contractData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/getContractMirror", contractData);
  }

  getContractsChecklistData(contractData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/getContractsChecklistData", contractData);
  }

  /*-------------Customer & employee count api-------------*/

  getOwner() {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/customerAndEmployees");
  }

  /*-------------Owner & beneficiaries get listing api-------------*/

  getAllOwner(owner) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allOwners", owner);
  }
  filterOwner(owner) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/filterOwners", owner);
  }

  /*------------Propert owner listing api-------------*/

  getPropertyOwner(ownerData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allPropertyOwner", ownerData);
  }

  /*-------------Witness get listing api-------------*/

  getAllWitness(witnessData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allWitness", witnessData);
  }

  /*-------------Service code get listing api-------------*/

  getAllServiceCode(serviceCodeData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allServiceCode", serviceCodeData);
  }

  /*-------------Billing method get listing api-------------*/

  getAllBillMethodListing(billingData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    let rm = axios.post(customerV2 + "/v2/contract/allBillingMethod", billingData);
    if (rm.message && rm.message == 'Connection name is invalid.') {
      window.location.href = '/';
    } else {
      return rm;
    }
  }
  /*------------- supplier category get listing api-------------*/
  getAllSuppliersCate(postData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/getCategory", postData);
  }
  /*-------------Guarantor get listing api-------------*/

  getAllGuarantor(guarantor) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allGuarantors", guarantor);
  }

  /*-------------Broker get listing api-------------*/

  getAllBroker(broker) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allBrokers", broker);
  }
  /*-------------Broker update listing api-------------*/

  updateBroker(broker) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/updateBroker", broker);
  }
  /*-------------Checlists get listing api-------------*/

  getAllChecklistItems(checklistItem) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allChecklistItems", checklistItem);
  }

  /*-------------Single contract charge get listing api-------------*/

  getSingleContractCharge(id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/singleContractCharge", { id: id });
  }

  /*-------------Contract transfer get listing api-------------*/

  getContractTransfer(contractData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    // return axios.post(customerV2 + "/v2/contract/allTransfer", contractData);
    return axios.post(TRANSFER_API + "/M1/contract/allTransfer", contractData);
  }

  /*-------------Generate meat simulate get listing api-------------*/

  getGenerateMeat(id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allSimulate", { userId: 1, id: id });
  }

  getGenerateCharge(contractData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/income/allGenerateChargeSimulate", contractData);
  }
  /*-------------Checlists get listing api-------------*/

  getAllContractChecklist(contractChecklist) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(
      customerV2 + "/v2/contract/allContractChecklists",
      contractChecklist
    );
  }

  /*-------------Account get listing api-------------*/

  getAllAccounts(accountData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allAccounts", accountData);
  }

  /*-------------Checlists get listing api-------------*/

  getAllChecklists(checklist) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allChecklists", checklist);
  }

  deleteChkItem(checklist) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/deleteCheckList", checklist);
  }

  /*-------------Service get listing api-------------*/

  getAllService(service) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allService", service);
  }

  /*-------------Suppliers get listing api-------------*/

  getAllSuppliers(supplier) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allSuppliers", supplier);
  }

  /*-------------Expense get listing api-------------*/

  getAllExpense(expense) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allContractExpense", expense);
  }

  getsingleExpense(expense) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/singleExpense", expense);
  }
  /*-------------Contract charge get listing api-------------*/
  getAllContractCharge(chargeData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    // return axios.post(customerV2 + "/v2/contract/allContractCharge", chargeData);
    return axios.post(customerV2 + "/v2/contract/allContractCharge?apiversion=v3", chargeData);
  }
  /*-------------Expense get listing api-------------*/
  getAllContractsExpenseData(contExpenseData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(
      //customerV2 + "/v2/contract/allContractsExpense",
      TRANSFER_API + "/M1/contract/allContractsExpense",
      contExpenseData
    );
  }

  /*-------------Inspector get listing api-------------*/

  getAllInspector(inspector) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allInspectors", inspector);
  }

  /*-------------Tenants get listing api-------------*/

  getAllTenant(tenant) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allTenants", tenant);
  }
  getSingleCustomer(id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/SingleCustomer", { id: id });
  }

  filterTenant(tenant) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/filterTenants", tenant);
  }
  /*-------------Contract Tenants get listing api-------------*/

  getContractTenants(tenantData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allContractTenants", tenantData);
  }
  allTenantContracts(contractData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allTenantContracts", contractData);
  }
  TransferTenant(contractData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/TanantTransfer", contractData);
  }

  allOwnerCharge(chargedata) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allchargesowner", chargedata);
  }
  /*-------------Owner background image Insert/Update api-------------*/

  updateImageOwner(owner) {
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/imageUpload", owner);
  }

  /*-------------Background image get api-------------*/

  getImage(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/getImage", data);
  }

  /*-------------Administrators data insert api-------------*/

  createAdministrators(administratorsData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(
      customerV2 + "/v2/contract/insertAdministrators",
      administratorsData
    );
  }

  /*-------------Administrators data insert api-------------*/

  updateAdministrators(administratorsData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(
      customerV2 + "/v2/contract/updateAdministrators",
      administratorsData
    );
  }

  /*-------------Administrators data insert api-------------*/

  deleteAdministrators(id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.delete(`${customerV2}/v2/contract/deleteAdministrators`, {
      data: { id: id },
    });
  }

  /*-------------Administrators get listing api-------------*/

  getAllAdministrators(administratorsData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(
      customerV2 + "/v2/contract/allAdministrators",
      administratorsData
    );
  }

  /*-------------Condos data insert api-------------*/

  createCondos(condosData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertCondos", condosData);
  }

  /*-------------Condos get listing api-------------*/

  getAllCondos(condosData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allCondos", condosData);
  }

  /*-------------Condos update  api-------------*/

  updateCondos(condosData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/updateCondos", condosData);
  }

  /*-------------Managers data insert api-------------*/

  createManagers(managersData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/insertManagers", managersData);
  }

  /*------------- Managers get listing api-------------*/

  getAllManagers(managersData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allManagers", managersData);
  }

  /*------------- Genearte charge contract listing api-------------*/

  getAllGenerateContract(contractData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allGenerateContract", contractData);
  }

  /*------------- Managers delete records api-------------*/

  deleteManagers(managersData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.delete(customerV2 + "/v2/contract/deleteManagers", { data: managersData });
  }

  /*-------------Delete property expense records api-------------*/

  deletePropertyExpenses(expensesData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v1/contract/deletePropertyExpenses", expensesData);
  }

  /*------------- Expense delete records api-------------*/

  deleteExpenses(expensesData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/deleteContractExpenses", expensesData);
  }

  /*------------- Contract delete records api-------------*/

  deleteContract(contractData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/deleteContract", contractData);
  }

  /*------------- Generate charge records api-------------*/

  chargeGenerates(generateData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/chargeGenerates", generateData);
  }

  chargeGeneratesSimulate(generateData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/income/chargeGeneratesSimulate", generateData);
  }

  downloadGenerate(generateData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/income/downloadGenerate", generateData);
  }
  /*------------- Index delete records api-------------*/

  getIndexDatails(id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/indexDatails", { id: id });
  }

  /*------------- Index delete records api-------------*/

  deleteIndexs(indexData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/deleteIndex", indexData);
  }

  /*------------- Get readjustment indexed records api-------------*/

  getAllLunchIndexs(indexData) {
    // Working herereee
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allLunchIndexs", indexData);
  }

  /*------------- Get readjustment indexed records api-------------*/

  fetch_allFinancialIndexing(indexData) {
    // Working herereee
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/fetch_allFinancialIndexing", indexData);
  }

  getAllLunchIndexs_based_on_Name(indexData) {
    // Working herereee
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/fetchFinancialIndex", indexData);
  }

  /*------------- Get simulate contract readjustment indexed records api-------------*/

  getReadSimulateContract(indexData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allReadSimulateContract", indexData);
  }

  /*------------- Get contract readjustment indexed records api-------------*/

  getAllReadjustIndexs(indexData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allReadjustLunchIndexs", indexData);
  }

  /*------------- Get three month contract readjustment indexed records api-------------*/

  getThreeReadjustIndexs(indexData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/summery_three_month_readjustContracts", indexData);
  }

  /*------------- Get contract readjustment indexed records api-------------*/

  getAllReadjustIndexs_based_on_name(indexData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allReadjustLunchIndexs_based_on_name", indexData);
  }

  /*------------- Get readjustment index records api-------------*/

  getAllIndexs(indexData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allIndexs", indexData);
  }

  /*------------- Insert readjustment index records api-------------*/

  insertLunchIndex(indexData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertLunchIndex", indexData);
  }

  insertLunchIndex1(indexData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertLunchIndex1", indexData);
  }

  getSingleLunchIndex(indexData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/getSingleLunchIndex", indexData);
  }

  /*------------- Insert contract readjustment api-------------*/

  insertReadjustContract(readjustData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertReadjustContract", readjustData);
  }


  /*------------- Insert index records api-------------*/

  insertIndexs(indexData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertIndex", indexData);
  }

  insertIndexs1(indexData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertIndex1", indexData);
  }

  /*-------------Property data insert api-------------*/

  createProperty(propertyData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertProperty", propertyData);
  }

  /*-------------Property data update api-------------*/

  updateProperty(propertyData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/updateProperty", propertyData);
  }

  /*-------------Property data delete api-------------*/

  deleteProperty(propertyData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v1/contract/deleteProperty", propertyData);
  }

  /*-------------Get property expense listing api-------------*/

  getPropertyExpense(expenseData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v1/getPropertyExpense", expenseData);
  }

  settleReverseReceipt(expenseData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v1/settleReverseReceipt", expenseData);
  }
  /*-------------Get property charge listing api-------------*/

  getPropertyCharge(chargeData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v1/getPropertyCharge", chargeData);
  }

  /*-------------Property expense data insert api-------------*/

  insertPropertyExpense(expenseData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(
      customerV2 + "/v1/insertPropertyExpense",
      expenseData
    );
  }

  /*-------------Property charge data insert api-------------*/

  insertCharge(chargeData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v1/insertCharge", chargeData);
  }

  suspendedCharge(chargeData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/suspendedContractCharge", chargeData);
  }

  reverseExpense(chargeData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    // return axios.post(customerV2 + "/v2/contract/reverseExpense", chargeData);
    return axios.post(TRANSFER_API + "/M1/expense/paid/reverse", chargeData);
  }

  reverseCharge(chargeData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/reverseCharge", chargeData);
  }

  settledCharge(contractData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/settledCharge", contractData);
  }

  /*-------------Extra charge data insert api-------------*/

  insertExtraCharge(extraChargeData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(
      customerV2 + "/v2/contract/insertExtraCharge",
      extraChargeData
    );
  }

  deleteServi(service) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/deleteService", service);
  }
  /*-------------Extra charge data insert api-------------*/

  insertPropertyBillingCharge(propertyBillingChargeData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v1/property/insertPropertyBillingCharge", propertyBillingChargeData);
  }

  /*-------------Checklists data insert api-------------*/

  insertChecklists(checklistsData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertChecklists", checklistsData);
  }

  /*-------------Checklists data insert api-------------*/

  contractPause(contractData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/paused", contractData);
  }

  /*-------------Checklist data insert api-------------*/

  insertChecklist(checklistData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertChecklist", checklistData);
  }

  addNewChecklist(checklistData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/addNewChecklist", checklistData);
  }

  addChecklistItem(checklistData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/addChecklistItem", checklistData);
  }

  updateChecklistItem(checklistData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/updateChecklistItem", checklistData);
  }

  updateChecklist(checklistData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/checklist/update", checklistData);
  }

  /*-------------Liquidate data insert api-------------*/

  insertSimulate(simulateData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertSimulate", simulateData);
  }

  getGenerateDashboard(dashIncomeData) {
    axios.defaults.headers.common['Content-Type'] = "application/json";
    axios.defaults.headers.common['x-auth-token'] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + '/v2/income/generateChargeDashboard', dashIncomeData);
  }

  /*-------------Service data insert api-------------*/

  insertService(serviceData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertService", serviceData);
  }
  /*-------------Expense data insert api-------------*/
  insertExpense(expenseData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertExpense", expenseData);
  }

  /*-------------Electronic envelope data insert api-------------*/

  insertRentalFee(rentalFeeData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertRentalFee", rentalFeeData);
  }

  /*-------------Electronic envelope data insert api-------------*/

  insertElectronicEnvelope(electronicEnvelopeData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
  }

  /*-------------Inspection data insert api-------------*/

  insertInspection(inspectionData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertInspection", inspectionData);
  }

  /*-------------Maintenance data insert api-------------*/

  insertMaintenance(maintenanceData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    //console.log("maintenanceData", maintenanceData)
  }

  /*-------------Envelope data insert api-------------*/

  insertEnvelope(envelopeData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
  }

  /*-------------Witness data insert api-------------*/

  insertWitness(witnessData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertWitness", witnessData);
  }

  /*-------------Surveyor data insert api-------------*/

  insertSurveyor(surveyorData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
  }

  /*-------------History data insert api-------------*/

  insertHistory(historyData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/property/insertPropertyHistory", historyData);
  }

  /*-------------Postpone readjustment data insert api-------------*/

  insertContractPostponeReadjust(postponeData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertContractReadjustPostpone", postponeData);
  }

  /*------------- Property listing in property module api-------------*/

  getAllProperty(propertyData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allProperty", propertyData);
  }

  getAllProperty_administractor(propertyData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v1/contract/allProperty", propertyData);
  }
  /*------------- Property listing in contract api-------------*/

  getProperty(propertyData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allContractProperty", propertyData);
  }

  /*------------- Neighborhood get listing api-------------*/

  getAllNeighborhood(neighData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allNeighborhood", neighData);
  }

  /*------------- Cities get listing api-------------*/

  getAllCities(citiesData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allCities", citiesData);
  }

  /*-------------Property data update api-------------*/

  changePermissionProperty(propertyData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v1/contract/changeProperty", propertyData);
  }

  /*-------------Property get single records api-------------*/

  getSingleProperty(id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/singleProperty", { id: id });
  }

  postSingleProperty(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v1/contract/updatePropertyProvision", data);
  }

  /*-------------History get listing api-------------*/

  getAllHistory(historyData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/property/allPropertyHistory", historyData);
  }

  /*-------------History delete api-------------*/

  deleteHistory(historyData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/property/deletePropertyHistory", historyData);
  }

  /*-------------Property charge listing api-------------*/

  getAllPropertyCharge(propertyData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v1/allPropertyCharge", { checkedId: propertyData });
  }

  /*-------------Contract status data insert api-------------*/

  insertContractLabel(contractLabelData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    //console.log("contractLabelData", contractLabelData)
  }

  /*-------------Contract status data insert api-------------*/

  insertContractStatus(contractStatusData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertContractStatus", contractStatusData);
  }

  /*-------------Contract data insert api-------------*/

  insertContract(contractData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertContract", contractData);
  }
  addContractCronRecords(contractData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(notificationsV2URL + "/addContractCronRecords", { 'contract_id': contractData });
  }
  updateContractInsurancePolicy(contractId, policyNumber, policyNumberId) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(notificationsV2URL + "/updateContractInsurancePolicy", { 'contractId': contractId, 'policyNumber': policyNumber, "policyNumberId" :policyNumberId });
  }
  addBillingTicketCron(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(notificationsV2URL + "/notifications/addBillingTicketCron", { 'contract_id': data });
  }
  cancelInsuranceRequest(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(notificationsV2URL + "/contracts/cancelInsuranceRequest", { 'contract_id': data });
  }
  deleteBillingTicketCron(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(notificationsV2URL + "/notifications/deleteBillingTicketCron", { 'receb_id': data });
  }
  /*-------------Insurance data insert api-------------*/

  insertInsurance(insuranceData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    // return axios.put(customerV2 + "/v2/contract/insertInsurance", insuranceData);
    return axios.post(config.TRANSFER__API + "/v1/addInsurance", insuranceData);
  }

  /*-------------Tenants data insert api-------------*/

  insertTenants(tenantsData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertTenants", tenantsData);
  }

  UpdateSingleTenant(tenantsData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/updateSingleTenant", tenantsData);
  }

  /*-------------Manager data insert api-------------*/

  insertManager(managerData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/insertManagers", managerData);
  }

  /*-------------Warranty data insert api-------------*/

  insertWarranty(warrantyData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
  }

  /*-------------Quote data insert api-------------*/

  insertQuote(quoteData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/insertQuote", quoteData);
  }

  /*-------------Renew insurance data insert api-------------*/

  insertRenewInsurance(renewInsuranceData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(
      customerV2 + "/v2/contract/insertRenewInsurance",
      renewInsuranceData
    );
  }

  /*-------------Settle data api-------------*/

  getSettleData(settleData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    // return axios.post(customerV2 + "/v2/contract/allSettle", { id: settleData });
    return axios.post(TRANSFER_API + "/M1/singlePaidSettleExpense", { id: settleData });
  }

  /*-------------transfer setting data api-------------*/

   getTransferSetting(transferData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(TRANSFER_API+"/transferSetting",transferData);
  }

  insertTransferSetting(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(TRANSFER_API+"/insertTransferSetting", data);
  }

  /*-------------Contract get listing api-------------*/

  getAllContPendingChck(contPendingData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(
      customerV2 + "/v2/contract/allChecklistContracts",
      contPendingData
    );
  }

  /*-------------Readjust Contract listing api-------------*/

  getAllReadjustContracts(contractData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allReadjustContracts", contractData);
  }

  /*-------------Contract listing api-------------*/

  getAllContracts(contractData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["timeout"] = 1000;
    axios.defaults.headers.common["X-Custom-Header"] = "foobar";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allContracts", contractData);
  }


  // Latest Contracts
  getAllLatestContracts(contractData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/getAllLatestContracts", contractData);
  }

  getAllBankHolidays(contractData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/GetAllBankHolidays", contractData);
  }

  getAllContractSearch(contractData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allContractSearch", contractData);
  }

  changeContractStatus(contractData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/changeContractStatus", contractData);
  }

  getAllOwnerContracts(contractData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allOwnerContracts", contractData);
  }

  /*-------------Contract get single records api-------------*/

  getSingleContract(id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/singleContract", { id: id, user_id:user_id });
  }

  moreThenProperty(filterData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(
      customerV2 + "/v1/contract/moreThanOnePropertyFilter",
      filterData
    );
  }

  changePropertyStatusEnable(id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v1/contract/changePropertyStatusEnable", {
      id,
    });
  }

  async getAddressBasedOnZipcode(endpoint_with_params) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    let data = await axios.post(companyV2 + "/v1/swagger/location/api", {
      url: config.locationApi + endpoint_with_params,
    });
    return data;
  }

  getContractDetailDocuments(id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v1/contract/documents", { id: id });
  }

  getPersonDocuments(id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v1/contract/peopleDocuments", { id: id });
  }

  uploadContractDetailDocuments(data) {
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/insertDocuments", data);
  }

  deleteContractDetailDocuments(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    const requestBody = { id: data };
    return axios.delete(customerV2 + "/v1/contract/deleteDocuments", {
      data: requestBody,
    });
  }

  uploadTobepaidDocuments(data) {
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/expense/tobePaid/fileUpload", data);
  }

  deleteTobepaidDocuments(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v1/contract/expense/tobePaid/deleteTobepaidFile", data);
  }

  gettoBePaidDocuments(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v1/contract/expense/tobePaid/getDocuments", data);
  }

  insertSettle(settleData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    // return axios.put(customerV2 + "/v2/contract/settle", settleData);
    return axios.put(TRANSFER_API + "/M1/expense/tobePaid/settle", settleData);
  }

  getPropertyDetailDocuments(id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v1/contract/getPropertyDocuments", { id: id });
  }

  uploadPropertyDetailDocuments(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v1/contract/insertPropertyDocuments", data);
  }

  deletePropertyDetailDocuments(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    const requestBody = { id: data };
    return axios.delete(customerV2 + "/v1/contract/deleteDocuments", {
      data: requestBody,
    });
  }

  /*-------------Warranty data update api----------- */

  updateWarranty(warrantyData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/renewWarranty", warrantyData);
  }

  getAllWarranty(getwarrantyData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allWarranty", getwarrantyData);
  }
  //Karan start
  // ---------------------- Termination service ---------------

  pendinginitialTermination(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/tenent_pendingCharge", {
      termination: data,
    });
  }

  initialTermination(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/initial_termination", {
      termination: data,
    });
  }

  CancelinitialTermination(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/cancel_initial_termination", {
      termination: data,
    });
  }

  getinitialTermination(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/get_initial_termination", {
      termination: data,
    });
  }

  get_terminationDefaultTerminationList(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/get_terminationDefaultTerminationList", data);
  }

  get_terminationDefaultTerminationList_basedOn_Contractid(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/get_default_contractid_default", data);
  }

  post_terminationDefaultTerminationList(data) {
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/post_terminationDefaultTerminationList", data);
  }

  post_termination_checklist(postData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/termination_checklist", postData);
  }

  get_custom_post_termination_checklist(postData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/get_custom_termination_formdata", postData);
  }

  custom_post_termination_checklist(postData) {
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/custom_termination_checklist", postData);
  }

  completeTermination(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v2/contract/complete_termination", data);
  }

  async checkDuplicateCPF_CNJP_SERVICE(Data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    let data = await axios.post(customerV2 + "/v2/contract/duplicateCheckCPF_CNJP", {
      requiredObj: Data
    });
    return data["data"]
  }
  //Karan end

  //Vikash code
  /*-------------create Suppliers create api----------- */
  RepresentativeServiceInsert(postData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/insertRepresentative", postData);
  }

  GetAllRepresentative() {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.get(customerV2 + "/v2/contract/getRepresentative");
  }
  createSuppliers(supplier) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
  }

  getadministratorsCondos(admdata) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allAdministratorCondos", admdata);
  }

  addContact(contactData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v1/contract/addContact", contactData);
  }
  ListContact(tenantId) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v1/contract/ListContact", { tenantId: tenantId });
  }

  DeleteContact(email) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v1/contract/deleteContact", { email: email });
  }

  EditContact(email) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v1/contract/editContact", { email: email });
  }
  UpdateContact(contactData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.put(customerV2 + "/v1/contract/updateContact", contactData);
  }
  // Aaditya aaditya code
  getSurveyors() {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allSurveyors", { start: 0, end: 1000 });
  }
  SendNotification(notifyData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v1/sendContractNotification", notifyData);
  }

  getSurveyList(id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allSurvey", {
      property_id: id,
    });
  }

  checkStatusAndUpdateApi(DOCUSIGN_ACCOUNT_ID, ST_IDENTIFICADOR_ENV, CustomerApiBaseUrl, DOCUSIGN_DEV_BASE_URL) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/checkStatusAndUpdateApi", { DOCUSIGN_ACCOUNT_ID: DOCUSIGN_ACCOUNT_ID, ST_IDENTIFICADOR_ENV: ST_IDENTIFICADOR_ENV, CustomerApiBaseUrl: CustomerApiBaseUrl, DOCUSIGN_DEV_BASE_URL: DOCUSIGN_DEV_BASE_URL });
  }

  getSubscribers(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/allSubscriber", { contract_id: data });
  }

  /*------------- Branched get listing api-------------*/

  getAllBranch(branchData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(companyV2 + "/branchList", branchData);
  }

  /*-------------Bank get listing api-------------*/

  getAllBanks(bankData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(companyV2_URL + "/company/bankList", bankData);
  }

  // postEmailDocusign(data) {
  //   axios.defaults.headers.common["Content-Type"] = "application/json";
  //   axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
  //   return axios.post(config.TRANSFER__API + "/v1/form", { data: data });
  // }
// Docosign api
  // postEmailDocusign(data) {
  //   axios.defaults.headers.common["Content-Type"] = "application/json";
  //   axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
  //   return axios.post(customerV2 + "/v1/form", { data: data });
  // }
  // Clicksign api
    postEmailDocusign(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(companyV2 + "/v1/generateClickSignDocument", data);
  }

  listElectronicEnvelopeDocument(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.MOBILE_API_URL + "/api/v1/document/allDocuments", { contractId: data });

  }


  postEmailDocusignForProperty(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/formProperty", { data: data });
  }
  selectElectronicEnvelopeTemplate() {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/selectElectronicEnvelopeTemplate");
  }

  selectElectronicEnvelopeTemplateForProperty() {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/selectElectronicEnvelopeTemplateForProperty");
  }

  getAllElectronicEnvelopeTemplateWithIdentifierAndFolder() {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/getAllElectronicEnvelopeTemplateWithIdentifierAndFolder");
  }

  renderElectronicEnvelope(contract_id, templateSelection, desc) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/renderModels", { contract_id: contract_id, templateSelection: templateSelection, desc: desc });
  }

  renderModelsForProperty(property_id, templateSelection, desc) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/renderModelsForProperty", { property_id: property_id, templateSelection: templateSelection, desc: desc });
  }

  getCustomerRenderModels(customer_id, template_id, desc, customer_type, title) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/getCustomerRenderModels", { customer_id: customer_id, templateSelection: template_id, desc: desc, customer_type: customer_type, title : title });
  }



  singleElectronicEnvelope(id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.get(config.TRANSFER__API + "/v1/singleElectronicEnvelope/" + id);
  }

  allElectronicEnvelopeForSpecificContract(id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.get(config.TRANSFER__API + "/v1/allElectronicEnvelopeForSpecificContract/" + id);
  }

  allElectronicEnvelopeForSpecificProperty(id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.get(config.TRANSFER__API + "/v1/allElectronicEnvelopeForSpecificProperty/" + id);
  }

  getAccessToken() {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.get(config.MOBILE_API_URL + "/api/v1/docusign");
  }

  getPropertySubscribers(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/getPropertySubscribers", { property_id: data });
  }


  renderCKEditorTemplateVariables(data, id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/renderCKEditorTemplateVariables", { data: data, id: id });
  }

  createAmendmant(data, oldData, selectedDate) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/createAmendmant", { data: data, oldData: oldData, selectedDate: selectedDate });
  }

  listAmendmant(contract_id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/listAmendmant", { contract_id: contract_id });
  }
  getAmendmant(id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/getAmendmant", { id: id });
  }

  deleteAmendmant(contract_id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/deleteAmendmant", { id: contract_id });
  }

  createPDFTemplate(template) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/createPDFTemplate", { template: template });
  }

  // createPDFTemplate(template) {
  //   axios.defaults.headers.common["Content-Type"] = "application/json";
  //   axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
  //   return axios.post(customerV2 + "/v2/createPDFTemplate", { template: template });
  // }

  getDocumentFolderListing() {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/listTemplateFolder");
  }

  listTemplatesInsideFolder(postData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/listTemplatesInsideFolder", { id: postData });
  };

  createNewTemplateFolder(postData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/createNewTemplateFolder", { data: postData });
  }

  createNewTemplateFolder_new(postData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/createNewTemplateFolder_new", { data: postData });
  }

  // getTemplates() {
  //   axios.defaults.headers.common["Content-Type"] = "application/json";
  //   axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
  //   return axios.post(customerV2 + "/v2/contract/allEmailTemplate");
  // }

  getTemplates() {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/selectEmailTemplate");
  }

  listTemplateContent_setting(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/listTemplateContent_setting", { data });
  }

  getAllOwnerTemplate() {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/getAllOwnerTemplate");
  }

  getAllTenantTemplate() {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/getAllTenantTemplate");
  }



  // updateTemplate(data) {
  //   axios.defaults.headers.common["Content-Type"] = "application/json";
  //   axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
  //   return axios.post(customerV2 + "/v2/contract/updateTemplate", { data });
  // }

  updateTemplate(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/updateTemplate", { data });
  }

  duplicateEmailTemplate(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/duplicateEmailTemplate", { data });
  }


  listTemplateContent(template_id) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/listTemplateContent", { template_id: template_id });
  }

  changeDocumentFolder(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");;
    return axios.post(config.TRANSFER__API + "/v1/changeDocumentFolder", { data: data });
  }

  renameDocumentFolder(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");;
    return axios.post(config.TRANSFER__API + "/v1/renameDocumentFolder", { data: data });
  }

  renameDocumentTemplate(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");;
    return axios.post(config.TRANSFER__API + "/v1/renameDocumentTemplate", { data: data });
  }

  deleteDocumentTemplate(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");;
    return axios.post(config.TRANSFER__API + "/v1/deleteDocumentTemplate", { data: data });
  }

  deleteDocumentFolder(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");;
    return axios.post(config.TRANSFER__API + "/v1/deleteDocumentFolder", { data: data });
  }


  createLinkViaEnvelope(token, envelopeId, signerName, signerEmail) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v1/createLinkViaEnvelope", { token: token, envelopeId: envelopeId, signerName: signerName, signerEmail: signerEmail });
  }


  // Aaditya aaditya code end
  /*-------------Insurance get listing api-------------*/
  getAllInsurance(insuranceData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/transfer/allInsurance", insuranceData);
  }
  InsuranceDelete(insuranceData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/deleteInsurance", insuranceData);
  }

  PdfGenerator(PdfGeneratordata) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/pdfgenerator", PdfGeneratordata);
  }

  getContractSettings() {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/get_contract_settings");
  }

  /*-------------Contract & setting insert api-------------*/
  InsertContractSetting(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(customerV2 + "/v2/contract/update_contract_settings", data);
  }

  UpdateTenantContract(TenatsData) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(companyV2 + "/v1/inspection/updateContractTenant", TenatsData);
  }
  listTemplateInvoice_setting(data) {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["x-auth-token"] = localStorage.getItem("tokenV2");
    return axios.post(config.TRANSFER__API + "/v1/listTemplateInvoice_setting", { data });
  }

}
export default new customerService();