import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Row, Col, FloatingLabel, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { transferSettingForm } from '../../../Interface';
import { AppDispatch, RootState } from '../../../../redux/store';
import { getTransferSetting } from "../../../../redux/slice/contractDataSlice";
import Swal from 'sweetalert2';
import customerService from '../../../../services/customerService';
import { numericOnly, openErrorbox } from '../../Contracts/Common/actionPerform';
import { addBusinessDaysToDate, convertToNumber, converter, formatNumber, formatNumberEnglish } from '../../../constants/utils';
import { get_trasfer_settings, update_trasfer_settings } from "../../../../redux/slice/companyDataSlice";


const TransferSetting = (props) => {
    const { register, handleSubmit,setValue,getValues,watch, reset, formState: { errors },setError,clearErrors } = useForm<transferSettingForm>();
    const dispatch = useDispatch<AppDispatch>()
    const [ loader_on,setloader_on ] = useState(false);
    const [ double,setDouble ] = useState(false);
    const [t] = useTranslation();
    const { transSettingData,transStatus } = useSelector((state: RootState) => state.contractData)
    const [ transferGrid,setTransferGrid ]   = useState(false);
    const [ transferDiscount,setTransferDiscount ] = useState(false);
    const [transferSetting, setTransferSetting] = useState(false);

    const { showMainTransferSetting,closeMainTransferSetting,settingType } = props;
    let tenaferSetType = "repasses.grid.exibirsempreagrupado','valor.minimo.repasse','valor.tarifabancaria.repasse','repasse.descontopontualidade','repasse.total.pagamento','imobiliaria.percentual.juros.repasse','imobiliaria.percentual.multa.repasse','imobiliaria.percentual.atualizacao.repasse";
    useEffect(() => {
        dispatch(getTransferSetting({ type:tenaferSetType }));
    }, [dispatch]);
    
    useEffect(()=>{
        let trnsSet:any = false;
        let trnsDisSet:any = false;
        let trnsFeeSet: any = false;
        if(transSettingData.length){
            transSettingData.forEach((item,index) => {
                if(item.ST_NOME_CONF=="repasses.grid.exibirsempreagrupado"){
                    trnsSet = item.ST_VALOR_CONF=='1' ? true : false;
                }
                if(item.ST_NOME_CONF=="repasse.descontopontualidade"){
                    trnsDisSet = item.ST_VALOR_CONF=='1' ? true : false;
                }
                if(item.ST_NOME_CONF=="valor.minimo.repasse"){
                    setValue("transferTaxSetting",item.ST_VALOR_CONF ? converter(String(item.ST_VALOR_CONF)) : "")
                }
                if(item.ST_NOME_CONF=="valor.tarifabancaria.repasse"){
                    setValue("transferDocSetting",item.ST_VALOR_CONF ? converter(String(item.ST_VALOR_CONF)) : "")
                }
                if (item.ST_NOME_CONF == "repasse.total.pagamento") {
                    trnsFeeSet = item.ST_VALOR_CONF == '1' ? true : false;
                }
                if (item.ST_NOME_CONF == "imobiliaria.percentual.juros.repasse") {
                    setValue("transferSetJuros", item.ST_VALOR_CONF ? converter(String(item.ST_VALOR_CONF)) : "")
                }
                if (item.ST_NOME_CONF == "imobiliaria.percentual.multa.repasse") {
                    setValue("transferSetMulta", item.ST_VALOR_CONF ? converter(String(item.ST_VALOR_CONF)) : "")
                }
                if (item.ST_NOME_CONF == "imobiliaria.percentual.atualizacao.repasse") {
                    setValue("transferSetAtualizacao", item.ST_VALOR_CONF ? converter(String(item.ST_VALOR_CONF)) : "")
                }
            })
        }
        setTransferGrid(trnsSet);
        setTransferDiscount(trnsDisSet);
        setTransferSetting(trnsFeeSet);
    },[transSettingData])

    const closedMainTransferSetting = ()=>{
        closeMainTransferSetting();
        setloader_on(false);
        setDouble(false);
    }
    const transferSettingSubmit = async(data:any,e: any) => {
        let transferData = [{
            type          : "repasses.grid.exibirsempreagrupado",
            ST_VALOR_CONF : data.exibirsempreagrupado ? 1 : 0
        },{
            type          : "valor.minimo.repasse",
            ST_VALOR_CONF : data.transferTaxSetting.replace(',', '.')
        },{
            type          : "valor.tarifabancaria.repasse",
            ST_VALOR_CONF : data.transferDocSetting.replace(',', '.')
        },{
            type          : "repasse.descontopontualidade",
            ST_VALOR_CONF : data.transferDisSetting ? 1 : 0
        },{
            type          : "repasse.total.pagamento",
            ST_VALOR_CONF : data.transferCalculationAdminFee ? 1 : 0
        },{
            type          : "imobiliaria.percentual.juros.repasse",
            ST_VALOR_CONF : data.transferSetJuros.replace(',', '.')
        }, {
            type          : "imobiliaria.percentual.multa.repasse",
            ST_VALOR_CONF : data.transferSetMulta.replace(',', '.')
        }, {
            type          : "imobiliaria.percentual.atualizacao.repasse",
            ST_VALOR_CONF : data.transferSetAtualizacao.replace(',', '.')
        }]

        const obj: any = {
            user_id: 1,
            calc_adm_charge: Boolean(data?.calc_adm_charge)
        };
    
        Object.entries(percentValues).forEach(([key, value]) => {
            obj[key] = value === '' ? null : convertToNumber(value)
        })

        setloader_on(true);        
        setDouble(true);
        const transferResponse = await customerService.insertTransferSetting({type:transferData});
         if (typeof transferResponse.data !== "undefined" && transferResponse.data.resCode === 200) {                
            const response = await dispatch(update_trasfer_settings(obj));

            dispatch(getTransferSetting({ type:tenaferSetType }));
            closedMainTransferSetting();
            Swal.fire({
                title: t("Sweetalert.Success"),
                text: t("Sweetalert.Recordupdated"),
                confirmButtonText: "OK",
                timer: 2000
            })
        }
        else {
            setDouble(false);
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: t("Sweetalert.cannotupdated"),
                confirmButtonText: "OK",
                timer: 2000
            })
        }        
    }
    const transferShowGrid = (evt)=>{
        let check = evt.target.checked;
        setTransferGrid(check);
    }
    const transferCalculationAdminFee = (evt) => {
        let check = evt.target.checked;
        setTransferSetting(check);
    }
    const transferSetDiscount = (evt)=>{
        let check = evt.target.checked;
        setTransferDiscount(check);
    }

    const limitNumber = async(e, txt) =>{
        let { value } = e.target;
        value = value.replace(/[^\d,]/g, '');
        if(e.target.value){
            let val:any = formatNumber(value);
            val = val.toString().replace(',', '.');
            if(val<100){
                setDouble(false);
                clearErrors(txt);
                if(val==0){
                    setValue(txt,1)   
                }
                else {
                    setValue(txt,formatNumber(value))    
                }                
            }
            else{
                setValue(txt,formatNumber(value)) 
                setDouble(true);
                setError(txt,{
                    type: "manual",
                    message: t("setLimit"),
                })
            }
        }
    }

    const isAdmCharge = watch('calc_adm_charge');
    const [percentValues, setPercentValues] = useState({});

    useEffect(() => {
        (async () => {
            const { payload: { data = {} } } = await dispatch(get_trasfer_settings({})) ?? {};
            const percentFields: string[] = ['percent_fee', 'percent_fine'];
            const obj = {
                calc_adm_charge: Boolean(data?.IND_TX_ADM_JUROS_MULTA),
                percent_fee: data?.VL_JUROS !== undefined && data?.VL_JUROS !== null ? converter(data?.VL_JUROS) : undefined,
                percent_fine: data?.VL_MULTA !== undefined && data?.VL_MULTA !== null ? converter(data?.VL_MULTA) : undefined,
            }

            const fields: string[] = Object.keys(obj);
            fields.forEach((field: any) => setValue(field, obj[field]));
            percentFields.forEach((field: any) => {
                setPercentValues(values => ({ ...values, [field]: obj[field] || '' }));
            })
        })()
    }, [showMainTransferSetting])

    const handlePercentage = (e: any, key: any) => {
        let { value } = e.target;
        value = value.replace(/[^\d,]/g, '');

        if (value !== "") {
            const [intg, dec] = value.split(',')
            value = convertToNumber(intg)?.toString() + (dec !== undefined ? `,${dec}` : '')
            if (convertToNumber(value) > 100) value = '100,00'
        
            setValue(key, formatNumber(value))
            setPercentValues({ ...percentValues, [key]: value })
        } else {
            setValue(key, "")
            setPercentValues({ ...percentValues, [key]: '' })
        }
    };
    
    return (
        <Modal show={showMainTransferSetting} onHide={closedMainTransferSetting}>
                <Form  onSubmit={handleSubmit(transferSettingSubmit)}  id="transferSettingForm">
                    <Modal.Header closeButton>
                        <Modal.Title>{t("formLabel.settings")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <hr/>
                        <span className="d-block mb-3 fw-bold text-dark">{t('Dashboard.Transfers')}</span>
                        <hr/>
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" checked={ transferDiscount } id="cb-1" {...register("transferDisSetting")} label={t("transferDisSetting")} onChange={transferSetDiscount}/>   
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" checked={ transferGrid } id="cb-1" {...register("exibirsempreagrupado")} label={t("transferGridSetting")} onChange={transferShowGrid}/>                            
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" checked={transferSetting} id="cb-1" {...register("transferCalculationAdminFee")} label={t("contractsettings.transferCalculationAdminFee")} onChange={transferCalculationAdminFee} />
                        
                        <Row className="mb-lg-4 g-3 g-xl-4">
                            <Col md>
                                <FloatingLabel label={t("transferTaxSetting")}>
                                    <Form.Control type="text" aria-label="transferTaxSetting" className={`form-control ${errors.transferTaxSetting ? 'is-invalid' : ''}`} {...register("transferTaxSetting")} onChange={e=>limitNumber(e,'transferTaxSetting')} ></Form.Control>
                                     <Form.Control.Feedback type="invalid">{errors.transferTaxSetting?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel label={t("transferDocSetting")}>
                                    <Form.Control type="text" aria-label="transferDocSetting" className={`form-control ${errors.transferDocSetting ? 'is-invalid' : ''}`} {...register("transferDocSetting")} onChange={e=>limitNumber(e,'transferDocSetting')} ></Form.Control>
                                     <Form.Control.Feedback type="invalid">{errors.transferDocSetting?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>  
                        <span className="mb-lg-4 d-block mb-3 fw-bold text-dark">{t('realSettingEstate')}</span>
                        
                        <Row className="g-3 g-xl-4 mb-lg-4">
                            <Col md>
                                <FloatingLabel label={t("setInterest")}>
                                    <Form.Control type="text" aria-label="Interest" className={`form-control ${errors.transferSetJuros ? 'is-invalid' : ''}`} {...register("transferSetJuros")} onChange={e => limitNumber(e, 'transferSetJuros')} ></Form.Control>
                                    <Form.Control.Feedback type="invalid">{errors.transferSetJuros?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel label={t("setFine")}>
                                    <Form.Control type="text" aria-label="Fine" className={`form-control ${errors.transferSetMulta ? 'is-invalid' : ''}`} {...register("transferSetMulta")} onChange={e => limitNumber(e, 'transferSetMulta')} ></Form.Control>
                                    <Form.Control.Feedback type="invalid">{errors.transferSetMulta?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel label={t("setMonetary")}>
                                    <Form.Control type="text" aria-label="Monetary" className={`form-control ${errors.transferSetAtualizacao ? 'is-invalid' : ''}`} {...register("transferSetAtualizacao")} onChange={e => limitNumber(e, 'transferSetAtualizacao')} ></Form.Control>
                                    <Form.Control.Feedback type="invalid">{errors.transferSetAtualizacao?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>               
                        <h6 className="d-sm-flex align-items-center justify-content-between mb-3 mb-lg-4 fw-bold">
                            {t('TransferSettingsDrawer.transfer')}
                        </h6>

                        <Form.Check type="checkbox" {...register('calc_adm_charge')} label={t('TransferSettingsDrawer.calcAdmFee')} />

                        <hr />

                        <h6 className="d-sm-flex align-items-center justify-content-between mb-3 mb-lg-4 fw-bold">
                            {t('TransferSettingsDrawer.transferCharges')}
                        </h6>

                        <Row className="gy-3 gy-lg-4">
                            <Col md={6} className="supplier_head">
                                <FloatingLabel className="mb-lg-4 mb-3" controlId="name" label={t('fees')}>
                                    <Form.Control disabled={!!isAdmCharge} type="text" placeholder={t('fees')} className={`${errors.percent_fee ? 'is-invalid' : ''}`}  {...register('percent_fee')} onChange={e => handlePercentage(e, 'percent_fee')} />
                                    <Form.Control.Feedback type="invalid">{errors.percent_fee?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>

                            <Col md={6} className="supplier_head">
                                <FloatingLabel className="mb-lg-4 mb-3" controlId="name" label={t('trafficTicket')}>
                                    <Form.Control disabled={!!isAdmCharge} type="text" placeholder={t('trafficTicket')} className={`${errors.percent_fine ? 'is-invalid' : ''}`}  {...register('percent_fine')} onChange={e => handlePercentage(e, 'percent_fine')} />
                                    <Form.Control.Feedback type="invalid">{errors.percent_fine?.message}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>       
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={closedMainTransferSetting}>
                            {t("button.close")}
                        </Button>
                        {
                            loader_on ?
                                <Button className="btn-width" disabled={double}>
                                    <span className="spinner-border spinner-border-sm"></span>
                                    <span className="px-1">{t("button.save")}</span>
                                </Button>
                            :
                                <Button variant="primary" type='submit' disabled={double} >
                                    {t("button.save")}
                                </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
    )
}
export default TransferSetting