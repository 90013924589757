import { config } from "../../../../services/apiConfig";

type CreateHomologateBankSlipPayload = {
  CedenteContaNumero: string;
  CedenteContaNumeroDV: string;
  CedenteConvenioNumero: string;
  CedenteContaCodigoBanco: string;
  SacadoCPFCNPJ: string;
  SacadoEmail: string;
  SacadoEnderecoNumero: string;
  SacadoEnderecoBairro: string;
  SacadoEnderecoCEP: string;
  SacadoEnderecoCidade: string;
  SacadoEnderecoComplemento: string;
  SacadoEnderecoLogradouro: string;
  SacadoEnderecoPais: string;
  SacadoEnderecoUF: string;
  SacadoNome: string;
  SacadoTelefone: string;
  SacadoCelular: string;
  TituloDataEmissao: string;
  TituloDataVencimento: string;
  TituloMensagem01: string;
  TituloMensagem02?: string;
  TituloMensagem03?: string;
  TituloNossoNumero: string;
  TituloNumeroDocumento: string;
  TituloValor: string;
  TituloLocalPagamento: string;
  IdRecebimento: number;
  Contrato?: string;
  TituloCodDesconto?: number;
  TituloDataDesconto?: string;
  TituloValorDescontoTaxa?: string;
  TituloValorDesconto?: string;
  TituloCodigoMulta?: number;
  TituloDataMulta?: string;
  TituloValorMultaTaxa?: string;
  TituloCodigoJuros?: number;
  TituloDataJuros?: string;
  TituloValorJuros?: string;
  TituloVariacaoCarteira?: string;
  TituloDocEspecie?: string;
  TituloAceite?: string;
  TituloCodBaixaDevolucao?: string;
  TituloPrazoBaixa?: string;
  CNAB?: string;
};

function nossoNumeroGenerator(
  createHomologateBankSlipPayload: CreateHomologateBankSlipPayload
) {
  const { CedenteContaCodigoBanco, TituloNossoNumero, CedenteConvenioNumero } =
    createHomologateBankSlipPayload;

  // Banco do Brasil
  if (CedenteContaCodigoBanco === "001") {
    if (CedenteConvenioNumero.length === 4)
      return { TituloNossoNumero: TituloNossoNumero.slice(-10) };

    if (CedenteConvenioNumero.length === 6)
      return { TituloNossoNumero: TituloNossoNumero.slice(-5) };

    if (CedenteConvenioNumero.length === 7)
      return { TituloNossoNumero: TituloNossoNumero.slice(-2) };
  }

  return { TituloNossoNumero };
}

function multaFormatter(
  createHomologateBankSlipPayload: CreateHomologateBankSlipPayload
) {
  const {
    TituloValor,
    TituloCodigoMulta,
    TituloDataMulta,
    TituloValorMultaTaxa,
  } = createHomologateBankSlipPayload;

  if (TituloValorMultaTaxa) {
    const valorMultaTaxa = parseFloat(TituloValorMultaTaxa.replace(",", "."));
    const valorCobranca = parseFloat(TituloValor.replace(",", "."));
    const valorMultaDiaria = ((valorMultaTaxa / 100) * valorCobranca) / 30;

    return {
      TituloCodigoMulta: 1,
      TituloDataMulta,
      TituloValorMultaTaxa: valorMultaDiaria.toFixed(2).replace(".", ","),
    };
  }

  return {
    TituloCodigoMulta,
    TituloDataMulta,
    TituloValorMultaTaxa,
  };
}

function jurosFormatter(
  createHomologateBankSlipPayload: CreateHomologateBankSlipPayload
) {
  const { TituloValor, TituloCodigoJuros, TituloDataJuros, TituloValorJuros } =
    createHomologateBankSlipPayload;

  if (TituloValorJuros) {
    const valorJuros = parseFloat(TituloValorJuros.replace(",", "."));
    const valorCobranca = parseFloat(TituloValor.replace(",", "."));
    const valorJurosDiario = ((valorJuros / 100) * valorCobranca) / 30;

    return {
      TituloCodigoJuros: 1,
      TituloDataJuros,
      TituloValorJuros: valorJurosDiario.toFixed(2).replace(".", ","),
    };
  }

  return {
    TituloCodigoJuros,
    TituloDataJuros,
    TituloValorJuros,
  };
}

function sanitizeMessage(message: string) {
  if (!message || message === "-") return "-";

  const sanitizedMessage = message
    .replaceAll("\n", ". ")
    .replaceAll("%", " porcento")
    .replaceAll(" (", ", ")
    .replaceAll(")", ".");

  return sanitizedMessage;
}

export async function createHomologateBankSlip({
  ...createHomologateBankSlipPayload
}: CreateHomologateBankSlipPayload) {
  const connectionName = localStorage.getItem("connectionName") ?? "";
  const token = localStorage.getItem("tokenV2") ?? "";

  const response = await fetch(
    `${config.TRANSFER_API}/homologate/bankslip/bankslip`,
    {
      method: "POST",
      headers: {
        "x-auth-token": token,
        "Content-type": "application/json",
        "connection-name": connectionName,
      },
      body: JSON.stringify({
        ...createHomologateBankSlipPayload,
        ...nossoNumeroGenerator(createHomologateBankSlipPayload),
        ...multaFormatter(createHomologateBankSlipPayload),
        ...jurosFormatter(createHomologateBankSlipPayload),
        TituloMensagem01: sanitizeMessage(
          createHomologateBankSlipPayload.TituloMensagem01
        ),
        TituloLocalPagamento: sanitizeMessage(
          createHomologateBankSlipPayload.TituloLocalPagamento
        ),
      }),
    }
  );

  const data = await response.json();

  if (data.resCode === 201 || data.resCode === 200) return data.data;

  const invalidPayloadError =
    data.data?.errorData?.length > 0
      ? `${data.data.errorData[0]._campo || ""} - ${
          data.data.errorData[0]._erro || ""
        }`
      : "";
  const homologateError = data.data?.message;
  const genericError = data.data;

  throw new Error(invalidPayloadError || homologateError || genericError);
}
