import React, { useEffect, useState, useRef } from 'react';
import { Col, Row, Offcanvas, Form, Button } from 'react-bootstrap';
import BillingMessages from './BillingMessages';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { AppDispatch } from '../../../../redux/store';
import * as yup from "yup";
import Swal from 'sweetalert2';
import BillingTicket from './BillingTicket';
import { encrypt_Decrypt } from '../../../constants/utils';
import { appUpdateGet_notifications } from "../../../../redux/slice/formsOfReceiptSlice";

const BillingRule = () => {
    const [t] = useTranslation()
    const dispatch = useDispatch<AppDispatch>();
    const schema = yup.object().shape({ });
    const navigate = useNavigate();
    const [userJson, setUserJson] = useState<any>([]);
    
    // Contract setting modal initialization
    const [BillingSetting, setBillingSetting] = useState<any>([]);
    const [showBillingSetting, setBillingSettingModal] = useState(false);
    const billingSettingClose = () => setBillingSettingModal(false);
    const billingSettingModalShow = () => setBillingSettingModal(true);

    const inputRef = useRef<any>(null); 
    const { register, reset, handleSubmit, setValue, getValues, formState: { errors }, setError, clearErrors, watch } = useForm<any>({
        resolver: yupResolver(schema),
    });

    useEffect( () => {

        (async () => {
			let response : any = await dispatch(appUpdateGet_notifications({}))
            // setBillingSetting(response?.data);
            if( response && response.payload && response.payload.data && response.payload.data.length ){
                let setData = response.payload.data[0];
                if( setData.ST_NOME_CONF == "trava.notificacao.boleto.nao.registrado" ){
                    setValue('nonotify_charges_for_unregistered_or_failed_bills', String(setData['ST_VALOR_CONF']) == "1" ? true : false)
                }
            } 
		})()
        
        let userResult = encrypt_Decrypt();
        if (!userResult || userResult.indexOf(1040) === -1) {
            navigate("/dashboard");
        }
        setUserJson(userResult);
    }, [])


    const billingSetSubmit = async (data: any) => {
        let serviceId = '';
        data['action'] = 'addUpdate'
        let response = await dispatch(appUpdateGet_notifications(data));
        if ( response.payload.resCode == 201 ) { 
            Swal.fire({
                position: "center", 
                title: `${t("Sweetalert.Success")}`,
                text: `${t("Sweetalert.RecordUpdated")}`,
                showConfirmButton: false,
                timer: 3000,
            });
            setBillingSettingModal(false);
            billingSettingClose();
            dispatch(appUpdateGet_notifications({}))
        } else {
            Swal.fire({
                title: `${t("Sweetalert.Error")}`,
                text: `${t("Sweetalert.somethingWent")}`, 
                confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
                timer: 3000,
            });
        }
    }

    return (
        <section className="content-wrapper">
            {/*<!-- Breadcrumb section start from here -->*/}
            <div
                className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3 overflow-visible">
                <div className="w-100">
                    <Row className="gx-2 justify-content-between align-items-center LinksbilingRule">
                        <Col xs={{ order: 12 }} xl={{ span: 2, order: 0 }} className="mb-xl-0 mb-3">
                            <h1 className="mb-0 lh-base text-truncate">{t("Links.billingRule")}</h1>
                        </Col>
                        <Col xs={{ order: 1 }} xl={{ span: 7, order: 3 }} className="text-xl-end mb-0">
                            <ul className="list-inline mb-0">
                                {/* {<Link to={"#"} className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1" onClick={() => window.print()}>
                                    <img src="assets/images/icons/print-light.svg" alt="Print"  className="h-20" />
                                </Link>} */}
                                {<li className="list-inline-item me-2 me-xl-3 me-xxl-4 mb-md-0 mb-1" onClick={billingSettingModalShow}>
                                    <img src="assets/images/icons/settings-light.svg" alt="Settings" className="h-20" />
                                </li>}
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/*<!-- /Breadcrumb section end -->*/}
            {/*<!-- Main content start from here -->*/}
            <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                <Row className="gy-lg-4 gy-3">
                    <Col xl={7}>
                        <BillingTicket />
                    </Col>
                    <Col xl={5}>
                        <BillingMessages />
                    </Col>
                </Row>
            </main>
            {/*<!-- /Main content end -->*/}
            


            {/* Billing modal start from here */}
            <Offcanvas show={showBillingSetting} onHide={billingSettingClose} placement='end' className="border-start-0">
                <Form className="d-flex flex-column h-100" onSubmit={handleSubmit(billingSetSubmit)}>
                    <Offcanvas.Header closeButton className="border-bottom">
                        <h4 className='fw-bold m-0'>{t("contractsettings.Configuration")}</h4>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <span className="d-block mb-3 fw-bold text-dark">{t("contracts")}</span>
                        <Form.Check type="checkbox" className="mb-lg-4 mb-3" id="cb-1" {...register("nonotify_charges_for_unregistered_or_failed_bills")} label={t("contractsettings.donotnotifyUNRegisterBils")} />
                    </Offcanvas.Body>
                    <div className="text-end border-top d-flex justify-content-lg-end offcanvas-footer">
                        <Button variant="outline-primary" onClick={billingSettingClose}>
                            {t('button.close')}
                        </Button>
                        <Button variant="primary" type="submit">
                            {t('button.toSave')}
                        </Button>
                    </div>
                </Form>
            </Offcanvas>
            {/* /Contract settings modal end */}
        </section>
    )
}

export default BillingRule
