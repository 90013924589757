import React, { useState, useEffect } from 'react'
import { Dropdown, InputGroup, Col, Row, Form, Button, Modal, Card, Table, FloatingLabel, Alert, Accordion, Badge, DropdownButton } from 'react-bootstrap';
import { Outlet, Link, useParams, useSearchParams, useNavigate } from 'react-router-dom';
import customerService from '../../../../../services/customerService';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getAllProperty, showEmail, getAllSuppliers } from '../../../../../redux/slice/contractDataSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../redux/store';
import { handleAction } from '../../../../Profile/Company/actions';
import { CustomersAndEmployeesForm } from '../../../../Interface';
import { text } from 'stream/consumers';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import SendEamilAdmini from './SendEamilAdmini';
import Loader from '../../../../Profile/Company/Loader';
import { CNJP_CPFValidation, checkDuplicateCNJP_CPF } from '../../../../constants/utils';
const Administrators_details = ({ submenu }) => {
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const [t] = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const [data, setData] = useState([])
    const [supplierData, setSupplierData] = useState([])
    const [AllStates, setAllStates] = useState([]);
    const [status, setStatus] = useState(true);
    const [admName, setAdmName] = useState<any>();
    const [allData, setAllData] = useState<any>();
    const [allCondos, setAllCondos] = useState<any>([])
    const navigate = useNavigate()
    const { emailShow } = useSelector((state: RootState) => state.contractData)
    const propertyValidation = Yup.object().shape({
        name: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')),
        cnpj: Yup.string().trim().nullable().required(t('requiredMmessage.fieldRequired')), 
    });
    let frm = {
        resolver: yupResolver(propertyValidation)
    }
    const { register, handleSubmit, setValue, formState: { errors }, setError, clearErrors } = useForm<CustomersAndEmployeesForm>(frm);
    useEffect(() => {
        // setStatus(true);
        let administratorData = { administrator: "", start: 0, end: 10 };
        customerService.getAllAdministrators(administratorData)
            .then(res => {
                if (res.data.resCode === 201) {
                    res.data.data.forEach((item: any) => {
                        if (item.id == id) {
                            setAdmName(item?.name)
                            setAllData(item)
                            // setStatus(false);
                            const fields: string[] = ['id', 'cnpj', 'name', 'responsible', 'zipcode', 'address', 'city', 'telephone', 'email', 'complement', 'number', 'neighborhood', 'observation'];
                            fields.forEach((field: any) => setValue(field, item[field]));
                            setTimeout(() => {
                                setValue('state', item["state"]);
                            }, 2000);
                        }
                    });
                } else {
                    // setData([]);
                    // setTotal(0)
                }
            })
            .catch(err => {
                console.log("err", err);
            });

    }, []); 
    useEffect(() => {
        (async () => {
            let resp = await dispatch(getAllProperty({
                start: 0,
                end: 1000
            }));
            let allData: any = [];
            resp.payload.data.forEach((item) => {
                if (Number(item.addministrators_id) === Number(id)) {
                    allData.push(item)
                }
            });
            setData(allData)

        })();
    }, []);
    const [data1, setData1] = useState<any>()
    useEffect(() => {
        let supplierData = { supplier: "", start: 0, end: 10 };
        customerService
            .getAllSuppliers(supplierData)
            .then((res) => {
                if (res.data.resCode === 201) {
                    let SupplierData: any = [];
                    const isMatch = res.data.data.some((data: any) => data.favorecidoId === allData?.supplier_id);
                    setData1(isMatch)
                    setStatus(false);
                    setSupplierData(SupplierData);
                } else {
                    // setData([]);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
        // Add any dependencies to the array below if needed
    }, [allData]);

    useEffect(() => {
        (async () => {
            let resp = await customerService.getAddressBasedOnZipcode(`states`);
            if (resp.data.status == "success" || resp.data.type == "Success") {
                let loop = resp.data.data;
                let stateData = loop.map((item) => ({ value: item.state, label: item.state }));
                // Sort the stateData alphabetically by label
                stateData.sort((a, b) => a.label.localeCompare(b.label));
                setAllStates(stateData);
            }
        })()
    }, [])
    const getLocation = async (e, inputType) => {
        let str = e.target.value
        let newStr = str.replace('-', ''); // Removes the hyphen
        // Input processing and formatting
        let value = e.target.value.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
        let newValue = e.target.value = !value[2] ? value[1] : value[1] + '-' + value[2];
        // Error validation
        // Retrieve address based on zipcode using customerService
        let resp = await customerService.getAddressBasedOnZipcode(
          `${inputType}/${newStr}`
        );
        // Retrieve address based on zipcode using customerService
        // Get the DOM element for the zipcode input
        let querySel: any = document.querySelector('.getzipcode [name="zipcode"]');
        let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
        if (newValue !== "" || newValue !== null || newValue !== undefined) {
            // Check if the response status is success
            if (resp?.["data"]["type"] == "Success") {
                let res = resp["data"]["data"];
                querySel.style.border = "0px solid red";
                zipCodeCheck.removeAttribute('disabled');
                // Set the city value if available in the response
                if (res[0]["city"]) {
                    setValue("city", res[0]["city"])
                }
                if (res[0]["neigbohood"]) {
                    setValue("neighborhood", res[0]["neigbohood"])
                }
                if (res[0]["state"]) {
                    setValue("state", res[0]["state"])
                }
                if (res[0]["street"] || res[0]["streetType"]) {
                    let data = (res[0]["streetType"] ? res[0]["streetType"] + " " : "") + (res[0]["street"] ?? "");
                    setValue("address", data)
                }
                clearErrors("zipcode");
                clearErrors("city");
                clearErrors("neighborhood");
                clearErrors("address");
                clearErrors("state");
            } else {
                setError("zipcode", {
                    type: "manual",
                    message: t("invalidzipcode"),
                });
                // Reset city, neighborhood, and address values
                querySel.style.border = "2px solid red"
                setValue("city", "")
                setValue("neighborhood", "")
                setValue("state", "")
                setValue("address", "")
                zipCodeCheck.setAttribute('disabled', "")
            }
        } else {
            querySel.style.border = "0px solid red";
            zipCodeCheck.removeAttribute('disabled');
        }
    }

    const onSubmit = async (data: any) => {
        setStatus(true)
        customerService.updateAdministrators(data).then(res => {
            /*-------Check the api response--------*/
            if (res.data.resCode === 202) {
                Swal.fire({
                    title:t("Sweetalert.Update"),
                    text:t("Sweetalert.updatesuccess"),
                    // icon:"success",
                    confirmButtonText:"OK",
                    timer:2000});
                setStatus(false)
            }
            else {
                Swal.fire({
                    title:t("Sweetalert.Error"),
                    text:t("Sweetalert.somethingWent"),
                    // icon:"error",
                    confirmButtonText:"OK",
                    timer:2000});
            }
        }).catch(error => { 
            Swal.fire({
                title:t("Sweetalert.Error"),
                text:t("Sweetalert.somethingWent"),
                // icon:"error",
                confirmButtonText:"OK",
                timer:2000});
        });
    }
    const HandleEmail = () => {
        dispatch(showEmail(true))
    }
    const HandleDelete = () => {
        if (data.length > 0) {
            Swal.fire({
                title:t("Sweetalert.Warning"),
                text:t("Sweetalert.Itwasnotpossibletoperformth"),
                // icon:"warning",
                confirmButtonText:"OK",
                timer:5000});
        } else {
            Swal.fire({
                title: t("Sweetalert.Areyousure"),
                text: t("Sweetalert.Youwontbeabletorevert"),
                // icon: 'success','warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t("newTextSweetAlert.delete_it"),
                cancelButtonText: t('button.cancel'),
            }).then((result) => {
                if (result.isConfirmed) {
                    customerService.deleteAdministrators(id).then((res) => {
                        if (res.data.resCode == 201) {
                            Swal.fire({
                                title:t("Sweetalert.Delete"),
                                text:t("Sweetalert.deletesuccess"),
                                // icon:"success",
                                confirmButtonText:"OK",
                                timer:2000});
                            navigate("/administrators")
                        } else {
                            Swal.fire({
                                title:t("Sweetalert.Error"),
                                text:t("Sweetalert.somethingWent"),
                                // icon:"error",
                                confirmButtonText:"OK",
                                timer:2000});
                        }
                    })
                }
            })

        }
    }

    const HandleSupplier = () => {
        setStatus(true)
        let supplierData: any = {
            id: id,
            type: "Consult Suppliers",
            cnpj: allData?.cnpj,
            name: allData?.name,
            address: allData?.address,
            complement: allData?.complement,
            number: allData?.number,
            city: allData?.city,
            state: allData?.state,
            zipcode: allData?.zipcode,
            neighborhood: allData.neighborhood,
            telephone: allData.telephone,
            email: allData?.email,
        }
        customerService.createOwner(supplierData).then(res => {
            if (res.data.resCode == 202) {
                Swal.fire({
                    title:t("Sweetalert.Success"),
                    text:t("Sweetalert.successget"),
                    // icon:"success",
                    confirmButtonText:"OK",
                    timer:2000});
                setData1(true)
                setStatus(false)
            }
            else {
                Swal.fire({
                    title:t("Sweetalert.Error"),
                    text:res.data.data?res.data.data:t("Sweetalert.somethingWent"),
                    // icon:"error",
                    confirmButtonText:"OK",
                    timer:2000});
                setStatus(false)
            }
        }).catch(error => {
            Swal.fire({
                title:t("Sweetalert.Error"),
                text:t("Sweetalert.somethingWent"),
                // icon:"error",
                confirmButtonText:"OK",
                timer:2000});
        });
    }
    useEffect(() => {
        let getCondos = { id: id, start: 0, end: 10 };
        customerService.getadministratorsCondos(getCondos).then((res) => {
            if (res.data.resCode == 201) {
                setAllCondos(res.data.data)
                // setAllCondos()
            } else {
                setAllCondos([])
            }
        })
    }, [])

     const handleCPF_CNJPVaue = async (e, type) => {
        let name = e.target.name;
        let checkCNJP = CNJP_CPFValidation(e.target.value, type);
        if (checkCNJP?.["status"] || e.target.value == "") {
          clearErrors(name);
          let zipCodeCheck: any = document.querySelector('.zipCodeCheck');
            zipCodeCheck?.["setAttribute"]('disabled', "")
            if (e.target.value !== "") {
                let duplicateData = await checkDuplicateCNJP_CPF(e.target.value, id);
                if (duplicateData["data"] > 0) {
                    zipCodeCheck?.["setAttribute"]('disabled', "")
                    setError(name, {
                        type: "manual",
                        message: t("formLabel.ErrorCNPJCPF")
                    });
                } else {
                    zipCodeCheck?.["removeAttribute"]('disabled');
                }
            }
        } else {
          // errorCNPJCPF(e.target.value)
          setError(name, {
            type: "manual",
            message: checkCNJP?.["message"]
          });
        };
      }
    const numericOnly = (e) => {
        const reg = /^[0-9\b]+$/
        let preval = e.target.value
        if (e.target.value === '' || reg.test(e.target.value)) return true
        else e.target.value = preval.substring(0, (preval.length - 1))
    }
    const handleEdit = (e: any, cmd: string) => {
    }
    return (
        <section className="content-wrapper">
            {submenu}
            {/*  <!-- Breadcrumb section start from here --> */}
            <div className="bg-white breadcrumb-section d-xl-flex align-items-xl-center flex-xl-column justify-content-xl-center border-bottom py-3 px-xl-5 px-md-4 px-3">
                <div className="w-100">
                    <Row className="gx-2 justify-content-between align-items-center">
                        <Col xs={{ order: 12 }} xl={{ span: 2, order: 0 }} className="mb-xl-0 mb-3">
                            <Link to={"/administrators"}>
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/arrow-left.svg"} className="h-14" alt="Arrow left" />
                            </Link>
                        </Col>
                        <Col xs={{ order: 1 }} xl={{ span: 7, order: 3 }} className="text-xl-end mb-xl-0 mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item mb-md-0 mb-1">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/question_icon.svg"} alt="Help" className="h-20" />
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* <!-- /Breadcrumb section end --> */}
            {/* <!-- Main content start from here --> */}
            {status ?
                <Loader /> : <main className="container-fluid px-xl-5 px-md-4 px-3 pt-3 pt-md-4 pb-xl-5 pb-md-4 pb-3">
                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3 ">
                        <Col xl={12}>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col lg={8}>
                                            <div className="d-flex align-items-center">
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/home-reqular.svg"} alt="Folder" className="h-40 flex-shrink-0" />
                                                <div className="w-100 ms-2 ms-lg-3">
                                                    <h1 className="h3 lh-base mb-0">{admName}</h1>
                                                    <p className="mb-0">Administrator</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <ul
                                                className="list-inline d-sm-flex align-items-center justify-content-start justify-content-lg-end mb-0">
                                                <li className="list-inline-item me-lg-3 mb-sm-0 mb-1">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="light" size="sm" id="dropdown-basic">
                                                            Actions
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="mh-456 overflow-auto">
                                                            <Dropdown.Item>Change log</Dropdown.Item>
                                                            <Dropdown.Item onClick={HandleEmail}>Send email</Dropdown.Item>
                                                            <Dropdown.Item onClick={HandleDelete}>Delete</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </li>
                                                <li className="list-inline-item mb-sm-0 mb-1">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="light" size="sm" id="dropdown-basic2">
                                                            Printout
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#">Contract</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={6}>
                            <Card>
                                <Card.Header className="d-flex align-items-center justify-content-between">
                                    Registration data
                                    <span className="d-block TOEDIT">
                                        <span>
                                            <span>
                                                <Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "toedit", "administ")}>
                                                    {t("button.edit")}
                                                </Button>
                                            </span>
                                        </span>
                                    </span>
                                    <div className="d-none ACTION">
                                        <Row>
                                            <Col xl={4} sm={4}>
                                                <Button variant="link " className="p-0 fw-bold text-nowrap zipCodeCheck" onClick={(e) => handleAction(e, "update", "administ")} type="submit">
                                                    {t("button.toSave")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Form
                                        onSubmit={handleSubmit(onSubmit)} id="editform_companydata">
                                        <Form.Control hidden type="text" placeholder="id"  {...register('id')} />

                                        <FloatingLabel controlId="Name" label="Name" className="mb-lg-4 mb-3 removeInsertDisable">
                                            <Form.Control disabled type="text" placeholder="Name" className={`${errors.name ? 'is-invalid' : ''}`} {...register('name')} />
                                            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                        <FloatingLabel controlId="cnpj" label="CNPJ" className="mb-lg-4 mb-3 removeInsertDisable">
                                            <Form.Control disabled type="text" placeholder="CNPJ" className={`${errors.cnpj ? 'is-invalid' : ''}`} {...register('cnpj')} onChange={(e) => handleCPF_CNJPVaue(e, "cnpj")}/>
                                            <Form.Control.Feedback type="invalid">{errors.cnpj?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                        <FloatingLabel controlId="responsible" label="Responsible" className="mb-lg-4 mb-3 removeInsertDisable">
                                            <Form.Control disabled type="text" placeholder="Responsible" className={`${errors.responsible ? 'is-invalid' : ''}`} {...register('responsible')} />
                                            <Form.Control.Feedback type="invalid">{errors.responsible?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                        <FloatingLabel controlId="Email" label="Email" className="mb-lg-4 mb-3 removeInsertDisable">
                                            <Form.Control disabled type="email" placeholder="Email" className={`${errors.email ? 'is-invalid' : ''}`} {...register('email')} readOnly />
                                            <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                        <FloatingLabel controlId="telephone" label="Telephone" className="mb-lg-4 mb-3 removeInsertDisable">
                                            <Form.Control disabled type="text" placeholder="Telephone" className={`${errors.telephone ? 'is-invalid' : ''}`} {...register('telephone')} />
                                            <Form.Control.Feedback type="invalid">{errors.telephone?.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                        <Accordion className="accordion-custom">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Address</Accordion.Header>
                                                <Accordion.Body>
                                                    <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3 removeInsertDisable">
                                                        <Col md={6}>
                                                            <FloatingLabel controlId="zipCode" label="Zip code" className="mb-lg-4 mb-3  removeInsertDisable getzipcode">
                                                                <Form.Control type="text" placeholder="Zip code" className={`${errors.zipcode ? 'is-invalid' : ''}`} {...register("zipcode")} onChange={(e) => getLocation(e, "zipcode")} maxLength={9} minLength={9}  disabled />
                                                                <Form.Control.Feedback type="invalid">{errors.zipcode?.message}</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FloatingLabel controlId="address" label="Address" className="mb-lg-4 mb-3 removeInsertDisable" >
                                                                <Form.Control disabled type="text" placeholder="Address" className={`${errors.address ? 'is-invalid' : ''}`} {...register("address")} />
                                                                <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FloatingLabel controlId="number" label="Number" className="mb-lg-4 mb-3 removeInsertDisable" >
                                                                <Form.Control disabled type="text" placeholder="Number" className={`${errors.number ? 'is-invalid' : ''}`} {...register("number")} />
                                                                <Form.Control.Feedback type="invalid">{errors.number?.message}</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FloatingLabel controlId="complement" label="Complement" className="mb-lg-4 mb-3 removeInsertDisable">
                                                                <Form.Control disabled type="text" placeholder="Complement" className={`${errors.complement ? 'is-invalid' : ''}`} {...register("complement")} />
                                                                <Form.Control.Feedback type="invalid">{errors.complement?.message}</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col md={5}>
                                                            <FloatingLabel controlId="neighborhood" label="Neighborhood" className="mb-lg-4 mb-3 removeInsertDisable">
                                                                <Form.Control disabled type="text" placeholder="Neighborhood" className={`${errors.neighborhood ? 'is-invalid' : ''}`} {...register("neighborhood")} />
                                                                <Form.Control.Feedback type="invalid">{errors.neighborhood?.message}</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col md={4}>
                                                            <FloatingLabel controlId="city" label="City" className="mb-lg-4 mb-3 removeInsertDisable">
                                                                <Form.Control disabled type="text" placeholder="City" className={`${errors.city ? 'is-invalid' : ''}`} {...register("city")} />
                                                                <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Col>
                                                        <Col md={3}>
                                                            <FloatingLabel controlId="state" label={t("formLabel.state")} >
                                                                <Form.Select className={`${errors.state ? 'is-invalid' : ''}`}  {...register('state')} id="removeInsertDisable" disabled>
                                                                    <option value=''>--type--</option>
                                                                    {AllStates.map((item, index) => {
                                                                        return (
                                                                            <option key={index} value={item?.["label"]}>{item?.["label"]}</option>
                                                                        )
                                                                    })}
                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">{errors.state?.message}</Form.Control.Feedback>
                                                            </FloatingLabel>

                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Observation</Accordion.Header>
                                                <Accordion.Body>
                                                    <FloatingLabel controlId="observation" label={t('formLabel.observation')} className="mb-lg-4 mb-3 removeInsertDisable">
                                                        <Form.Control disabled as="textarea" placeholder="Observation" className={`${errors.observation ? 'is-invalid' : ''}`} {...register("observation")} />
                                                        <Form.Control.Feedback type="invalid">{errors.observation?.message}</Form.Control.Feedback>
                                                    </FloatingLabel>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                        <Button className="submitForm" type="submit" variant="primary" hidden>
                                            {t("button.update")}
                                        </Button>
                                        <Button className="fillModalwithThisData" onClick={(e) => handleEdit(e, "administ")} hidden>
                                            {t("button.edit")}
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={6}>
                            <Card>
                                {data1}
                                <Card.Header className="d-flex align-items-center justify-content-between">
                                    Recent payments
                                    {data1 && (
                                        <span className="ms-2">
                                            <Button type="button" variant="link" size="sm" className="fw-bold p-0 edit">
                                                <Link to={"/toBePaid"}>All payment</Link>
                                            </Button>
                                        </span>
                                    )}
                                </Card.Header>
                                <Card.Body>
                                    {!data1 &&
                                        (<div className='text-center'>
                                            <span className="ms-2">
                                                <p>This Admin is not yet a vendor</p>
                                                <Button type="button" variant="link" size="sm" className="fw-bold p-0 edit" onClick={HandleSupplier}>Register suppliers</Button>
                                            </span>
                                        </div>)
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Email list</Accordion.Header>
                            <Accordion.Body>
                                <Alert variant="warning" className="text-center my-lg-3 my-2">
                                    No email in the mailing list.
                                </Alert>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Condos</Accordion.Header>
                            <Accordion.Body>
                                <Table className="table table-custom mb-0">
                                    <thead>
                                        <tr>

                                            <th>{t('property.condominium')}</th>
                                            <th></th>
                                            <th>{t('property.trustee')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allCondos && allCondos.length > 0 ? allCondos.map((item, index) => {
                                            return (
                                                <tr key={index}>

                                                    <td className="text-dark-70">
                                                        <Link className="text-reset" to={`/condos_details/${item["id"]}`}>{item["name"]}</Link>
                                                    </td>
                                                    <td>
                                                        {/* {allPropertyCount[`${item["id"]}`] !== undefined ? allPropertyCount?.[`${item["id"]}`]?.["count"] : 0} property */}
                                                    </td>
                                                    <td>
                                                        {(item["trustee_name"] !== null || item["trustee_email"] !== null || item["trustee_mobile"] !== null) ?
                                                            <span className='d-flex align-item-center'>
                                                                {item["trustee_name"] || ''}<br /> {item["trustee_email"] || ''}
                                                                <br />
                                                                {item["trustee_mobile"] || ''}
                                                            </span>
                                                            :
                                                            <svg width="9" height="17" viewBox="0 0 10 18" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M5 0C2.23772 0 0 2.23772 0 5C0 5.55228 0.447715 6 1 6C1.55228 6 2 5.55228 2 5C2 3.34228 3.34228 2 5 2C6.65772 2 8 3.34228 8 5C8 5.81648 7.80143 6.29447 7.56209 6.62855C7.3004 6.99383 6.93729 7.26709 6.43347 7.61346L6.31831 7.69229C5.87116 7.99769 5.29529 8.39102 4.84929 8.93923C4.3224 9.58685 4 10.4061 4 11.5V12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12V11.5C6 10.8439 6.1776 10.4756 6.40071 10.2014C6.65665 9.88681 7.00256 9.6491 7.51374 9.29782L7.56653 9.26154C8.06271 8.92041 8.6996 8.47492 9.18791 7.79332C9.69857 7.08053 10 6.18352 10 5C10 2.23772 7.76228 0 5 0ZM5 17.25C5.69036 17.25 6.25 16.6904 6.25 16C6.25 15.3096 5.69036 14.75 5 14.75C4.30964 14.75 3.75 15.3096 3.75 16C3.75 16.6904 4.30964 17.25 5 17.25Z"
                                                                    fill="#4c4c4c" />
                                                            </svg>}

                                                    </td>
                                                </tr>)
                                        }) :
                                            <tr>
                                                <td colSpan={4}>
                                                    <span>{t('formLabel.noRecordsFounds')}</span>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>


                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Properties</Accordion.Header>
                            <Accordion.Body>
                                <Table responsive className="table-custom mb-0">
                                    <thead>
                                        <tr>
                                            <th></th>

                                            <th>{t('property.immobile')}</th>
                                            <th></th>
                                            <th>{t('property.saleValue')}</th>
                                            <th>{t('property.rentAmount')}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.length > 0 ? (
                                            data.map((item: any, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="check_Checkbox">
                                                            {/* <Checkbox key={item.id} type="checkbox" name={item.name} id={item.id} handleClick={handleClick} isChecked={isCheck.includes(item.id)} */}
                                                            {/* /> */}
                                                        </td>
                                                        <td>
                                                            <Link className="text-reset" to={"/property_detail/" + item.id}>
                                                                <img src="../assets/images/icons/home_g.svg" />
                                                                <span className="ps-1 text-dark-70">
                                                                    {item.number} - {item.address}, {item.zipcode}
                                                                </span>
                                                                <span className="d-block">
                                                                    {item.city} - {item.state}
                                                                </span>
                                                            </Link>
                                                        </td>
                                                        <td className="text-dark-70">
                                                            <span className="d-block">
                                                                <img src="../assets/images/icons/user_g.svg" className="me-1" />
                                                                <Link to={"/profile/" + item.ownerId} className="text-secondary text-nowrap me-2">
                                                                    {item.ownerName}
                                                                </Link>

                                                            </span>
                                                            <span className="d-block">
                                                                <img src="../assets/images/icons/key_g.svg" className="me-1" />
                                                                <Link to={"/profile_details/" + item.tenetID} className="text-secondary text-nowrap me-2">
                                                                    {item.tenetName}
                                                                </Link>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {item.sale_value !== null ? (
                                                                <span className="d-block text-nowrap">R$ {item.sale_value}</span>
                                                            ) : (
                                                                <span>R$ {0}</span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {item.rent_amount !== null ? (
                                                                <span className="d-block text-nowrap">R$ {item.rent_amount}</span>
                                                            ) : (
                                                                <span>R$ {0}</span>
                                                            )}
                                                        </td>
                                                        <td className="text-end">

                                                            {
                                                                item.contractId ?
                                                                    <Link to={"/contract_details/" + item.contractId} className="fw-bold me-2 text-nowrap">
                                                                        {t('button.seeContract')}
                                                                    </Link>
                                                                    :
                                                                    ''
                                                            }
                                                            <Link to={"/property_detail/" + item.id} className="">
                                                                {t('button.seeProperty')}
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={7}>
                                                    <span>{t('formLabel.noRecordsFounds')}</span>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </main>}

            {/* <!-- /Main content start end --> */}
            <SendEamilAdmini />
        </section>
    )
}
export default Administrators_details