import { FloatingLabel, Toast, ToastContainer, Col, Row, Modal, Form, Button } from 'react-bootstrap';
import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from '../../../../../redux/store';
import { settleForm } from '../../../../Interface';
import customerService from '../../../../../services/customerService';
import Loader from '../../../../Profile/Company/Loader';
import { openErrorbox, numericOnly } from '../../../Contracts/Common/actionPerform';
import { transferToSwitchOff, getTransferSimulate, transferToPostinFinancial } from "../../../../../redux/slice/transferDataSlice";
import * as yup from 'yup';
//let moment: any = require("moment");
import moment from "moment-timezone";
import { fetchService } from '../../../../../redux/slice/ServiceSlice';
import { GetCityCode, ServiceRecord, getInvoiceSetting, getTaxation } from '../../../../../redux/slice/taxationSlice';
import { registerData } from '../../../../../redux/slice/companyDataSlice';
import i18n from '../../../../../i18n';

const SettleTransfers = (props, settleForm) => {
    let dateBrazil = moment.tz('America/Sao_Paulo').format("YYYY-MM-DD HH:mm");
    const [double, setDouble] = useState(false);
    const [loader_on, setloader_on] = useState(false);
    const formRef = useRef(null);
    const navigate = useNavigate();
    //Settle transfers modal close open function
    const { showSettleTransfers, settleTransfersClose, simulateData, transferSimulation } = props;
    const { taxadata, taxalist } = useSelector((state: RootState) => state.taxa)
    const { companydata } = useSelector((state: RootState) => state.companyData);
    const [t] = useTranslation();
    //Common toast
    const [showToast, setToastShow] = useState(false);
    const commonToastClose = () => setToastShow(false);
    let [gettingFiles, setgettingFiles] = useState('');
    let [allFiles, setAllFiles] = useState<any>('');
    const [servicesId, setServicesId] = useState<any>()
    const [AdminFee, setAdminFee] = useState()
    const [selectedFile, setSelectedFile] = useState<File | null>();
    const [TaxationServices, setTaxationServices] = useState<any>([])
    const [serviceRender, setServiceRender] = useState<any>({})
    const [settingData, setSettingData] = useState<any>({})
    const [getServiceId, setGetServiceId] = useState<any>([])
    const [cityCodes, setCityCode] = useState<any>('')
    const [accountSetting, setAccountSetting] = useState('')
    const dispatch = useDispatch<AppDispatch>();
    const Schema = yup.object().shape({
        saleOf: yup.string().required(t("requiredMmessage.fieldRequired")),
        value: yup.string().required(t("requiredMmessage.fieldRequired"))
    })
    const { register, handleSubmit, getValues, setValue, reset, formState: { errors } } = useForm<settleForm>({
        resolver: yupResolver(Schema)
    });

    useEffect(() => {
        let todayDate: any = moment(new Date(dateBrazil)).format("YYYY-MM-DD")
        setValue("saleOf", todayDate)
        dispatch(getTaxation())
        dispatch(ServiceRecord())
    }, []);

    useEffect(() => {
        //console.log('In  SIMULATE Popup >>>', simulateData);
        if (simulateData.length) {
            let contractIds: string[] = [];
            if (simulateData && simulateData.length) {
                simulateData.forEach((element: { contractId?: string, serviceId: any, adminAmount: any }) => {
                    if (element && element.contractId) {
                        contractIds.push(element.contractId);
                        setServicesId(element.serviceId)
                        setAdminFee(element.adminAmount)
                    }
                });
            }
            let searchData = {
                "CONTRATO_CON_IDS": JSON.stringify(contractIds),
                "id": getValues('id'), "id_etiqueta_eti": 1
            }
            getSettleDocument(searchData);
        }

    }, [showSettleTransfers])

    useEffect(() => {
        const fetchInvoiceSetting = async () => {
            if (showSettleTransfers) {
                let getInvo = await dispatch(getInvoiceSetting());
                setAccountSetting(getInvo.payload.data[0]?.account_category)
                setSettingData(getInvo?.payload?.data[0])
            }
        };

        fetchInvoiceSetting();
    }, [dispatch, showSettleTransfers]);

    const commonToastShow = () => {
        setToastShow(true);
        settleTransfersClose(false);
        setTimeout(() => {
            setToastShow(false);
            window.location.reload();
        }, 2000);
    }
    const getContractIds = () => {
        let contractIds: string[] = [];
        if (simulateData && simulateData.length) {
            simulateData.forEach((element: { contractId?: string }) => {
                if (element && element.contractId) {
                    contractIds.push(element.contractId);
                }
            });
        }
        return contractIds;
    }
    const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
        let files = e.target.files;
        if (files && files.length) {
            var formdata = new FormData();
            const myFile: any = files;
            if (myFile) {
                let contractIds: string[] = [];
                if (simulateData && simulateData.length) {
                    simulateData.forEach((element: { contractId?: string }) => {
                        if (element && element.contractId) {
                            contractIds.push(element.contractId);
                        }
                    });
                }
                formdata.append('settleContractIds', JSON.stringify(contractIds));
                formdata.append('ID_ETIQUETA_ETI', '1');
                formdata.append('AAAAAAAAAAAAAAAAAAABB', '1');
                formdata.append("file", myFile[0], myFile[0]?.name);
                formdata.append("image", myFile[0], myFile[0]?.name);
                let response = await customerService.uploadTobepaidDocuments(formdata);
                /*-------Check the api response--------*/
                if (typeof response !== "undefined" && (response.data.resCode === 202 || response.data.resCode === 200)) {
                    let searchData = {
                        "CONTRATO_CON_IDS": JSON.stringify(contractIds),
                        "id": getValues('id'), "id_etiqueta_eti": 1
                    }
                    getSettleDocument(searchData);
                    Swal.fire({
                        title: t("Sweetalert.Success"),
                        text: i18n.language == "en" ? "File uploaded successfully!" : "Arquivo enviado com sucesso!",
                        icon: "success",
                        confirmButtonText: "OK",
                        timer: 2000
                    });
                }
                else {
                    Swal.fire({
                        title: t("Sweetalert.Error"),
                        text: t("Sweetalert.somethingWent"),
                        icon: "error",
                        confirmButtonText: "OK",
                        timer: 2000
                    });
                }
            }
        }
    }
    const handleFileUpload = async (event) => {
        event.preventDefault();
        const data = new FormData();
        if (selectedFile && selectedFile.name) {
            let contractIds: string[] = [];
            if (simulateData && simulateData.length) {
                simulateData.forEach((element: { contractId?: string }) => {
                    if (element && element.contractId) {
                        contractIds.push(element.contractId);
                    }
                });
            }
            data.append('file', selectedFile, selectedFile?.name);
            data.append('settleContractIds', JSON.stringify(contractIds));
            data.append('ID_ETIQUETA_ETI', '1');
            let response = await customerService.uploadTobepaidDocuments(data);
            /*-------Check the api response--------*/
            if (typeof response !== "undefined" && (response.data.resCode === 202 || response.data.resCode === 200)) {
                let searchData = {
                    "CONTRATO_CON_IDS": JSON.stringify(contractIds),
                    "id": getValues('id'), "id_etiqueta_eti": 1
                }
                getSettleDocument(searchData);
                Swal.fire({
                    title: t("Sweetalert.Success"),
                    text: i18n.language == "en" ? "File uploaded successfully!" : "Arquivo enviado com sucesso!",
                    icon: "success",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
            else {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.somethingWent"),
                    icon: "error",
                    confirmButtonText: "OK",
                    timer: 2000
                });
            }
        }
    };
    const handleImageDelete = async (e, item) => {
        let conIds = getContractIds();
        let data = {
            // ID_CONTRATO_CON : getValues('contractId'),
            "CONTRATO_CON_IDS": JSON.stringify(conIds),
            ID_ARQUIVO_ARQ: item.ID_ARQUIVO_ARQ
        }
        await customerService.deleteTobepaidDocuments(data);
        let searchData = {
            // "ID_CONTRATO_CON":getValues('contractId'),
            "CONTRATO_CON_IDS": JSON.stringify(conIds),
            "id": getValues('id'), "id_etiqueta_eti": 1
        }
        getSettleDocument(searchData);
    }
    const getSettleDocument = async (searchData) => {
        setgettingFiles('loading');
        setAllFiles('');
        const response = await customerService.gettoBePaidDocuments(searchData);
        if (response.data.resCode === 202) {
            setAllFiles(response.data.data);
            setgettingFiles('');
        } else {
            setgettingFiles('');
        }
    }
    const settleClosed = () => {
        reset();
        dispatch(settleTransfersClose(false));
        setAllFiles('');
        setgettingFiles('');
    }

    useEffect(() => {
        if (taxadata && taxadata?.length > 0) {
            setServiceRender(taxadata[0])
        }
    }, [taxadata])
    useEffect(() => {
        // Asynchronous function within the useEffect hook
        (async () => {
            // Dispatch the fetchService action
            let newdispatch = await dispatch(fetchService());
            // Set the service state with the fetched data
            setGetServiceId(newdispatch.payload);
        })()

    }, [])
    useEffect(() => {
        if (getServiceId.length > 0) {
            getServiceId.forEach((item) => {
                if (item.ID_PRODUTO_PRD == "17") {
                    taxalist.forEach((taxserviceCode) => {
                        if (taxserviceCode.id == item.ID_CODIGOSERVICO_CSE) {
                            setTaxationServices(taxserviceCode)
                        }
                    })
                }
            })
        }
    }, [showSettleTransfers])

    useEffect(() => {
        dispatch(registerData());
    }, [dispatch, showSettleTransfers])
    useEffect(() => {
        if (companydata.length) {
            cityCode()
        }
    }, [companydata])

    const settleTransfersClosed = () => {
        settleTransfersClose(true);
        setDouble(false);
        setloader_on(false);
    }
    const cityCode = async () => {
        let res = await dispatch(GetCityCode({ zipCode: companydata[0]?.zipcode }))
        if (res.payload) {
            // const filteredData = res.payload.filter(item => item?.nome == companydata[0]?.city && item?.uf == companydata[0]?.state);
            // setCityCode(filteredData[0]?.id)
            setCityCode(res?.payload?.ibge)
        }
    }

    const submitSettleForm = async (data: any) => {
        let invoiceData: any = []
        let recIds: string[] = [];
        let totals: any = [];
        if (simulateData && simulateData.length) {
            simulateData.forEach((element: any) => {
                if (element && element.id) {
                    recIds.push(element.id);
                    totals.push(element.total);
                    invoiceData.push({
                        ID_RECEBIMENTO_RECB: element.id,
                        AdminFee: element.adminAmount,
                        invoceid: element.invoice,
                        servicecode: TaxationServices.servicecode ? TaxationServices.servicecode : "",
                        description: TaxationServices.description,
                        CNAE: TaxationServices.CNAE,
                        taxcode: TaxationServices.taxcode,
                        cpfCnpj: serviceRender.CNPJ,
                        municipal_registration: serviceRender.municipal_registration,
                        social_contribution_tax: serviceRender.social_contribution_tax,
                        ISSQNrate: TaxationServices.ISSQNrate ? TaxationServices.ISSQNrate : "",
                        city_code: cityCodes,
                        saleOf: data.saleOf,
                        account_category: accountSetting,
                        SentEmail: settingData?.copy_of_the_generated_invoice,
                    })
                }
            });
        }

        let postData = {
            ID_RECEBIMENTO: recIds,
            sendBeneficiariesEmail: data.value,
            saleOf: data.saleOf,
            totals: totals,
            invoiceData: invoiceData
        }
        console.log(postData)
        setDouble(true);
        setloader_on(true);
        let Trresponse = await dispatch(transferToSwitchOff(postData));
        if (typeof Trresponse.payload !== "undefined" && (Trresponse.payload.resCode === 202 || Trresponse.payload.resCode === 200)) {
            commonToastShow();
            transferSimulation(true);
            setDouble(false);
            setloader_on(false);
        }
        else {
            setDouble(false);
            setloader_on(false);
            Swal.fire({
                title: t("Sweetalert.Error"),
                text: Trresponse.payload.message,
                icon: "error",
                confirmButtonText: "OK",
                timer: 2000
            });
        }
    }

    const handleUpload = async () => {
        // setviewFiles(true);
        let AddDoc: any = document.querySelector('.attachedDocument');
        AddDoc.click();
        // setAllFiles(''); setgettingFiles('loading'); setDocumentType('');
        // let data = {"ID_CONTRATO_CON":item.contractId,"id_etiqueta_eti":id_etiqueta_eti,type:type, item : item}
        // setDocumentType(data);
        // const response = await customerService.gettoBePaidDocuments(data); 
        // if( response.data.resCode === 202 ){ 
        //     setAllFiles(response.data.data); setgettingFiles('');  
        // }
    };
    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedFile(undefined);
        if (event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            const maxAllowedSize = 5 * 1024 * 1024;
            if (file.size > maxAllowedSize) {
                Swal.fire({
                    title: t("Sweetalert.Error"),
                    text: t("Sweetalert.5mb") + ' 5MB',
                    // icon: 'success',"error",
                    confirmButtonText: "OK",
                    timer: 3000
                })
                event.target.value = '';
            } else {
                setSelectedFile(file);
                let AddDoc: any = document.querySelector('.uploadFilenow');
                setTimeout(() => {
                    AddDoc.click();
                }, 2000)
            }
        } else {
            // setOpenOnce(true);
        }
    };
    return (
        <>
            <Modal show={showSettleTransfers} onHide={settleTransfersClosed}>
                {/* <form ref={formRef} onSubmit={handleFileUpload} style={{ display: "none" }} id="fileuploadform">
                    <input type="file" accept=".xls,.xlsx,.pdf,.PDF,.jpeg,.png"  onChange={handleFileChange} className="position-absolute left-0 end-0 attachedDocument" hidden />
                    <button type='submit' className='uploadFilenow'></button>
                </form> */}
                <Form onSubmit={handleSubmit(submitSettleForm)} id="settleeTransfertsx">
                    <Modal.Header closeButton>
                        <Modal.Title>{t("newTransfer.settleTransfers")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-2 pt-0">
                        <p className="mb-lg-4 mb-3">{t("newTransfer.settleTransfersContent")}</p>
                        <FloatingLabel controlId="settlement" label={t("newTransfer.dateOfSettlement")} className="mb-lg-4 mb-3">
                            <Form.Control type="date" className={`${errors.saleOf?.message ? 'is-invalid' : ''}`} placeholder={t("newTransfer.dateOfSettlement")} {...register("saleOf")} />
                        </FloatingLabel>

                        {(simulateData && simulateData.length == 1) &&
                            <>
                                <div className="border-2 border border-dashed py-4 px-2 position-relative text-center rounded-4 mb-lg-4 mb-3">
                                    <input type="file" accept=".xls,.xlsx,.pdf,.PDF,.jpeg,.png" name="image" className="position-absolute cursor-pe end-0 start-0 top-0 bottom-0 h-100 w-100 opacity-0 z-index-1" onChange={handleChange} />
                                    <h4 className="text-primary fw-bold mb-2"><img src="assets/images/icons/upload-file-primary.svg" alt="Upload file" className="cursor-pe me-2" />{t("attachFile")}</h4>
                                    <label className="mb-0 form-label fw-normal text-dark"><img src="assets/images/icons/info-dark.svg" alt="Info" className="h-16 cursor-pe me-1" />{t("newTransfer.supportedFormats")}</label>
                                </div>
                                <Row className="gy-lg-4 gy-3 mb-lg-4 mb-3 mt-3">
                                    {
                                        allFiles && allFiles.length > 0 ? allFiles.map((item, index) => (
                                            <Col md={6} lg={4} key={index}>
                                                <div className='w-100 text-center border rounded p-3 position-relative'>
                                                    <Button variant="link" className='bg-primary position-absolute rounded-circle wh-xs-1 d-flex align-items-center justify-content-center end-0 mt-n3 me-n3 top-0 z-index-3 p-0' data-rowid={index} onClick={(e) => handleImageDelete(e, item)} >
                                                        <img className="h-10" src="./assets/images/icons/times-white.svg" alt="Delete" />
                                                    </Button>
                                                    <img src={item.ST_HASH_ARQ} alt="Filter" style={{ height: "100px" }} className="w-100 object-fit-contain" />
                                                    <h6 className="fw-semibold fs-14 text-truncate mt-2 mb-0">{item.ST_NOME_ARQ}</h6>
                                                </div>
                                            </Col>
                                        ))
                                            : ""
                                    }
                                    {
                                        (gettingFiles == "loading") &&
                                        <Col md={12}><Loader /></Col>
                                    }
                                </Row>
                            </>
                        }
                        <Form.Group className="bg-light rounded-4 p-2 min-h-56 d-flex flex-wrap align-items-center">
                            <Form.Check type="checkbox" id="subsequent_transfers" label={t("newTransfer.sendStatementBeneficiariesEmail")} {...register("value")} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={settleTransfersClosed}>
                            {t("button.cancel")}
                        </Button>
                        {
                            loader_on ?
                                <Button variant="primary" disabled={double} >
                                    <span className="spinner-border spinner-border-sm"></span>
                                    <span className="px-1">{t("button.confirm")}</span>
                                </Button>
                                :
                                <Button variant="primary" type='submit' onClick={openErrorbox}>{t("button.confirm")}</Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            <ToastContainer className="p-3 position-fixed" position="bottom-end" style={{ zIndex: 1 }}>
                <Toast bg="dark" className="rounded-4 text-white border-0" show={showToast} onClick={commonToastClose}>
                    <Toast.Body className="d-flex align-items-center p-3">
                        <img className="me-2 me-xxl-4 me-xl-3" src="assets/images/icons/check-circle-success.svg" alt="arrow right" />
                        <span className="me-2">{t("newTransfer.transfersRegisteredSettled")}</span>
                        <span className="ms-auto cursor-pe" onClick={commonToastClose}><img src="assets/images/icons/cross-white.svg" alt="arrow right" /></span>
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    )
}

export default SettleTransfers
