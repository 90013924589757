import React, { useState, MouseEvent, useEffect } from 'react';
import { Dropdown, Badge, Row, Col, OverlayTrigger, Table, Tooltip, Modal, Form, Button, Card } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { AppDispatch, RootState } from '../../../../redux/store';
import Checkbox from "../../../Pages/Contracts/Common/Checkbox";
import Loader from "../../Company/Loader";
import Pagination from "../../../Pagination";
import { singleemailPost, singleemailfetchData } from '../../../../redux/slice/SendEmailSlice';
import { userService } from "../../../../services/userService";
import { deleteTenant, newContact, tenantsOpen, getEmailListing } from '../../../../redux/slice/contractDataSlice';
import moment from "moment-timezone";
const SendEmailListing = (props) => {
    const { searchUserFilter } = props;
    const [t] = useTranslation();
    //Email viewed modal initialization
    const [showEmailViewedModal, setEmailViewedModa] = useState(false);
    const emailViewedModalModalClose = () => setEmailViewedModa(false);
    const emailViewedModalModalShow = (e, rowId : any ) => {
        setEmailViewedModa(true);
    }

    //Display modal initialization
    const [showDisplayModal, setDisplayModal] = useState(false);
    const displayModalClose = () => setDisplayModal(false);
    const displayModalShow = (e, rowId : any ) => {
        setDisplayModal(true);
    }

    const [fetchEmailData, setfetchEmaildata] = useState([]);
    const [DelGroupModel, setdeleteGroupModel] = useState(false);
    const [emailDataData, setemailDataData] = useState<any>();

    const dispatch = useDispatch<AppDispatch>()
    const [status, setStatus] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [totalpage, setTotalPage] = useState(0);
	const [PageSize, setPageSize] = useState(25);
	const [sentEmailsList, setsentEmails] = useState<Array<any>>([]);
	const [limitSelect, setLimitSelect] = useState<any>({
		start: 0,
		end: PageSize,
	});
	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheck, setIsCheck] = useState<Array<any>>([]);
	const [users, setUsers] = useState<Array<any>>([]);

    useEffect(() => {
        console.log('3eeeeee');
        getAllUsers({ searchwith : searchUserFilter });
    },[searchUserFilter])

    useEffect(() => { 
		getAllUsers({ userId: "" })
	}, [currentPage && limitSelect])

    // function to Get All Users
	const getAllUsers = async ( QueryParam : any) => {
        let response = await dispatch(getEmailListing({ id: 1, start: limitSelect.start, end: limitSelect.end , isEmailHistoryPage : true, searchWhere : searchUserFilter }))
        if( response.payload && response.payload.data.length ){
            setsentEmails(response.payload.data);
            setTotalPage( response.payload.totalRow );
        } 
	};

    useEffect(() => {
		setLimitSelect({
			...limitSelect,
			["start"]: currentPage == 1 || currentPage == 0 ? 0 : (currentPage - 1) * PageSize,
			["end"]: PageSize
		}) 
	}, [currentPage])

    // Delete Group 
    const closeGroupDeleteModel = () => {
        setdeleteGroupModel(false);
    }
    const handleGroupDeleteEvent = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const groupId = e.currentTarget.name; 
        // const groupName = e.currentTarget.getAttribute('data-gropname');
        let deleteGPobj : any = []
        deleteGPobj.push( groupId );
        // setHeadingText((groupName != null) ? groupName : '');
        setemailDataData(deleteGPobj);
        setdeleteGroupModel(true);
    }
    const deleteGroupNow = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        userService.deleteEmalHistory({ emails : JSON.stringify(emailDataData) }).then(res => {
            if (res.data && res.type == "Success") {
                setdeleteGroupModel(false);
                Swal.fire({
                    title: `${t("Sweetalert.Success")}`,
                    text: `${t("Sweetalert.deleteRecord")}`,
                    confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                    timer: 2000
                });
                getAllUsers({}); 
            }
            else {
                Swal.fire({
                    title: `${t('Sweetalert.Error')}`,
                    text: t('Sweetalert.deleteFailed'),
                    confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                    timer: 2000
                });
            } 
        }).catch(error => {
            Swal.fire({
                title: `${t('Sweetalert.Error')}`,
                text: `${t('Sweetalert.somethingWent')}`,
                confirmButtonText: `${t('Sweetalert.ConfirmButtonText')}`,
                timer: 2000
            }); 
        });
    }

    const handleSelectAll = (e) => {
		setIsCheckAll(!isCheckAll);
		if (!isCheckAll) {
			selectAllEmls();
		} else {
			unSelectAllEmls();
		}
	}; 
    const selectAllEmls = () => {
		setIsCheckAll(true);
		setsentEmails(sentEmailsList.map((topping) => ({ ...topping, isChecked: true })));
	};
	const unSelectAllEmls = () => {
		setIsCheckAll(false);
		setIsCheck([]);
		setsentEmails(sentEmailsList.map((topping) => ({ ...topping, isChecked: false })));
	};
    const selectUser = async (e) => {
		let checkArr: any = [];
		setIsCheck([]);
        let dms : any = []; 
        await sentEmailsList.forEach((data : any ) => {
            let mmt = false;
			if (data && e.target.value == data.ID_EMAIL_FE) {
				mmt = !data.isChecked ? true : false;
			} else {
                mmt = data.isChecked;
            }
            dms.push({...data, isChecked : mmt }); 
		});
		await setsentEmails(dms);
		if (document.querySelectorAll(".check_Checkbox input")?.length > 0) {
			let data = document.querySelectorAll(".check_Checkbox input");
			let count_allCheck = 0;
			setTimeout(() => {
				let chekAllbtn: any = document.querySelector("#selectAll");
				data.forEach((item, index) => {
					if (item["checked"]) {
						count_allCheck++;
						checkArr.push(item.getAttribute("data-id"));
					}
					if (!item["checked"]) {
						chekAllbtn["checked"] = false;
					}
				});
				if (count_allCheck == data?.length) {
					chekAllbtn["checked"] = true;
				}
				setIsCheck(checkArr);
			}, 10);
		}
	};

    const getSelectedUsers = () => {
		let selectedRws: string[] = [];
		sentEmailsList.map((rows) => {
			if (rows.isChecked) {
				selectedRws.push(rows.ID_EMAIL_FE);
			}
		}); 
		if (!selectedRws?.length) {
			Swal.fire({
				title: `${t("Sweetalert.Error")}`,
				text: `${t("Sweetalert.pleaseSelect")}`,
				confirmButtonText: `${t("Sweetalert.ConfirmButtonText")}`,
			});
		} else {
			return selectedRws;
		}
	};
    const handleAllDeleteEvent = () => {
        let rowIds: any = getSelectedUsers();
		if (rowIds?.length > 0) {
            setemailDataData(rowIds);
            setdeleteGroupModel(true);
        }
    }

    const [firstLoad, setFirstLoad] = useState(false);
    const [hisemailModel, sethisemailModel] = useState(false);
    const SeenEmailHandle = async (e, emailid, email) => {
        setFirstLoad(true)
        let reqobj: any = {
            id: emailid,
            email: email,
            usersEmails: true
        };
        let res1 = await dispatch(singleemailfetchData(reqobj));
        if (res1["payload"]["data"].length > 0) {
            sethisemailModel(true);
            // setfetchEmaildata(res1["payload"]["data"]);
            setTimeout(() => {
                gethtml(res1["payload"]["data"][0].emailContent);
            }, 1000);
        }
        // setDisplayModal(true);
    }
    const closeEmailHisModel = () => {
        sethisemailModel(false);
        // setfetchEmaildata([]);
    }
    const gethtml = (cHtml: any) => {
        let newdata: any = document.querySelector("#emailhtmlPart");
        const dv = document.createElement('Div');
        dv.innerHTML = cHtml;
        newdata?.appendChild(dv);
    }
    return (
        <>
        <Card className="mb-lg-4 mb-3">
            { ( sentEmailsList && sentEmailsList.length > 0 ) ? 
                <Card.Header>{t("sendEmail.SummaryFrom")} { moment(sentEmailsList[0]['frstResult'][0]['DT_ENTRADA_FE']).format('DD/MM/YYYY') } {t("to")} { moment(sentEmailsList[0]['lastResult'][0]['DT_ENTRADA_FE']).format('DD/MM/YYYY') } </Card.Header>
                : ''
            }
            <Card.Body>
                <Row className="gy-3 gx-xxl-5 gx-md-4">
                    <Col sm={4} className="border-end border-light border-xs-none">
                        <span className="d-block text-dark h3 lh-1 mb-2"><img src="assets/images/icons/circle-check-light.svg" alt="Sent emails" className="me-1 h-20" />{totalpage}</span>
                        <span className="text-secondary d-block">{t("sendEmail.SentEmails")}</span>
                    </Col> 
                </Row>
            </Card.Body>
        </Card>

        <Card>
            <Card.Body className="pb-1">
            {status == "loading" ? (
				<Loader />
			) : (
                <Table id="sndEmailListings" responsive className="table-custom table-custom-bordered mb-0">
                    <thead>
                        <tr>
                            <th className="w-24">
                                {sentEmailsList && sentEmailsList?.length > 0 ? (
                                    <Checkbox
                                        type="checkbox"
                                        name="selectAll"
                                        id="selectAll"
                                        handleClick={handleSelectAll}
                                        isChecked={isCheckAll}
                                    />
                                ) : (
                                    ""
                                )}    
                            </th>
                            <th>{t("sendEmail.Recipient")}</th>
                            <th>{t("sendEmail.Message")}</th>
                            <th>{t("dropdownItem.status")}</th>
                            <th className="text-end"></th>
                        </tr>
                    </thead>  
                    <tbody className='emilHistorytsx'>
                        {   sentEmailsList &&
                            sentEmailsList?.length > 0 &&
                            sentEmailsList.map((row: any, index) => (
                                <tr key={index}>
                                    <td>
                                        <Form.Check
                                            type="checkbox"
                                            data-id={row.ID_EMAIL_FE}
                                            onChange={selectUser}
                                            checked={row.isChecked}
                                            value={row.ID_EMAIL_FE}
                                        />
                                    </td>
                                    <td>
                                        <span className="d-block">{row.ST_PARA_FE ? row.ST_PARA_FE : ''}</span>
                                        <Badge bg="success">    
                                            {   row.DT_ENVIADO_FE != ''  ? 
                                                <>
                                                { (row.DT_ENVIADO_FE) }  
                                                </>
                                                : <></>
                                            }  
                                        </Badge>
                                    </td>
                                    <td>
                                        {row.ST_ASSUNTO_FE}
                                    </td>
                                    <td>
                                        {/* <span onClick={(e) => emailViewedModalModalShow(e, row.ID_EMAIL_FE )}
                                        className="cursor-pe">
                                            <img src="assets/images/icons/eye.svg" alt="Eye" className="cursor-pe h-12 me-1" />{t("sendEmail.hasAlreadySeen")}
                                        </span>  */}
                                        <img src="./assets/images/icons/envelop.svg" alt="eye" className="h-12 me-1 cursor-pe" onClick={(e) => SeenEmailHandle(e, row["ID_EMAIL_FE"], row["ST_PARA_FE"])} />{t("emailTemplate")}
                                    </td>
                                    <td className="text-nowrap text-end">
                                        <ul className="list-inline mb-0 d-flex justify-content-end align-items-center">
                                            <li className="list-inline-item me-2 me-xxl-4 me-lg-3">
                                            {/* <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("sendEmail.EmailDisplay")}</Tooltip>}>
                                                <span className="icon cursor-pe"> <img src="assets/images/icons/detail-search-primary.svg" alt="Detail" onClick={(e) => displayModalShow(e, row.ID_EMAIL_FE )} className="h-20" /></span>
                                            </OverlayTrigger> */}
                                            </li>
                                            <li className="list-inline-item">
                                            {/* <OverlayTrigger placement="bottom" data-gropname={row.ID_EMAIL_FE} onClick={handleGroupDeleteEvent} overlay={<Tooltip>{t("button.delete")}</Tooltip>}>
                                                <span className="icon cursor-pe"><img src="assets/images/icons/delete-danger.svg" alt="Delete" className="h-20" /></span>
                                            </OverlayTrigger> */}
                                            <Button variant="link" className="p-0" data-gropname={row.ID_EMAIL_FE} name={row.ID_EMAIL_FE} onClick={handleGroupDeleteEvent}>
                                                <span className="icon cursor-pe"><img className="h-20" src="./assets/images/icons/delete-danger.svg" alt="Delete" /></span>
                                            </Button>
                                            </li>
                                        </ul>
                                        
                                    </td>
                                </tr>
                            ))
                        } 
                    </tbody>
                    {
                        (totalpage && totalpage > 0) ?
                        <div className="d-flex justify-content-center mt-lg-4 mt-3">
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={totalpage}
                                pageSize={PageSize}
                                onPageChange={(page: any) => setCurrentPage(page)}
                            />
                        </div>
                        : ''
                    }
                </Table> 
            )}
            </Card.Body>
        </Card>


        <p className="my-lg-4 my-3">{t("formLabel.listing")} {sentEmailsList.length} {t("sendEmail.emailsBeing")} {totalpage} {t("sent")}.</p>
        {/* <!-- Nav listing start from here -->*/}
        <ul className="list-inline mb-0">
            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                <Dropdown>
                    <Dropdown.Toggle variant="light" size="sm">
                        {t("formLabel.mark")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={selectAllEmls} href="">
                            {t("formLabel.all")}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={unSelectAllEmls} href="">
                            {t("formLabel.none")}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </li>
            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2"> <span className="px-1">{t("formLabel.withTagged")}</span></li>
            <li className="list-inline-item mb-lg-0 mb-1 me-xl-3 me-2">
                <Button variant="light" onClick={handleAllDeleteEvent} size="sm">{t("button.delete")}</Button>
            </li>
        </ul>
        {/*<!-- /Nav listing  end -->*/}

        

        {/* Delet ethe Group */}
        <Modal show={DelGroupModel} onHide={closeGroupDeleteModel}>
            <Modal.Header closeButton>
                {/* <Modal.Title>.....</Modal.Title> */}
            </Modal.Header>
            <Form>
                <Modal.Body>
                    {t("users.deleteEmail")}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={closeGroupDeleteModel}>
                        {t("button.close")}
                    </Button>
                    <Button type="submit" variant="primary" name="deletegroup" onClick={deleteGroupNow} >
                        {t("button.delete")}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>


        {/* Email viewed modal start from here */}
        <Modal show={showEmailViewedModal} onHide={emailViewedModalModalClose}>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>{t("sendEmail.EmailViewed")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive="lg" className="table-custom table-custom-bordered mb-0">
                        <tbody>
                            <tr>
                                <td className="px-2"><img src="assets/images/icons/eye.svg" alt="Eye" className="cursor-pe h-12 me-1" /></td>
                                <td className="px-2">14 {t("sendEmail.hoursAgo")}</td>
                                <td className="px-2"><img src="assets/images/icons/clock-light.svg" alt="Time" className="cursor-pe h-14 me-1" />01:26:57</td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={emailViewedModalModalClose}>
                    {t("button.close")}
                    </Button>
                    <Button variant="primary" onClick={emailViewedModalModalClose}>
                    {t("button.save")}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
        {/* /Email viewed modal end */}


        {/* Display modal start from here */}
        {/* <Modal show={showDisplayModal} onHide={displayModalClose}>
            <Form>
            <Modal.Header closeButton>
                <Modal.Title>{t("formLabel.email")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <embed src="./assets/pdf/preview.pdf" width="100%" height="350px" />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={displayModalClose}>
                {t("button.close")}
                </Button>
                <Button variant="primary" onClick={displayModalClose}>
                {t("button.save")}
                </Button>
            </Modal.Footer>
            </Form>
        </Modal> */}
        {/* /Display modal end */}


        <div>
            <Modal className='hthththththththth' show={hisemailModel} onHide={closeEmailHisModel}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Row className="h-100 gy-3 gy-lg-4">
                        <Col lg={12} style={{ margin: "30px 0px" }} id="emailhtmlPart">
                            {/* Email content appear here */}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={closeEmailHisModel}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
        
        </>
    )
}
export default SendEmailListing
