import React, { useEffect, useState, useTransition } from 'react'
import { Col, Row, Form, Button, Card, OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import { handleAction, handleSubmitData } from './notification';
import { useForm, } from "react-hook-form";
import { modal_reducer, welcome_reducer, insert_update_notifiy } from "../../../../redux/slice/notificationSlice";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const Welcome = (props: any) => {
	const inputElement: any = React.useRef(null);

	let { dispatch, stateData, notificationData } = props;
	// The useForm hook is used to handle form registration, submission, and validation.
	const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({});
	const [object, setObject] = useState([]);
	const [resetForm, setResetForm] = useState<boolean>(false);
	const navigate = useNavigate();
	// Initialize the useTranslation hook for internationalization
	const [t] = useTranslation()
	let { welcome } = stateData;
	let basicdata = {
		dispatch,
		stateData,
		modal_reducer,
		welcome_reducer
	}
	useEffect(() => {
		setObject(welcome.basic_data);
	}, [])

	useEffect(() => {
	}, [modal_reducer, welcome_reducer, insert_update_notifiy])

	useEffect(() => {
		// Get the fields from the 'birthday.basic_data' object
		let fields: string[] = Object.keys(welcome.basic_data);
		fields.forEach((field: any) => setValue(field, welcome?.basic_data?.[field]));
	}, [object, stateData])

	let page = "welcome";
	// Function to handle form submission
	const onSubmit = async (data: any) => {
		let checkSubmition = true;
		// Check if any input value includes a negative number
		document.querySelectorAll(`.${page}.removeInsertDisable`).forEach(item => {
			if (item["value"].includes('-')) {
				checkSubmition = false;
			}
		})
		if (checkSubmition) {
			// If all input values are valid, submit the data
			handleSubmitData(data);
		} else {
			// Show an error message
			Swal.fire({
				position: 'center',
				title: `${t('Sweetalert.Error')}`,
				text: `${t('Sweetalert.negativeNumberNotAllowed')}`,
				showConfirmButton: true,
				timer: 2000
			})
		}
		inputElement.current?.click()
	}

	useEffect(() => {
		if (notificationData) {
			setValue("send_notification_start", Number(notificationData["welcome_send_notifications"]))
			setValue("days_start", Number(notificationData["welcome_no_of_days"]))
		}
	}, [notificationData, resetForm])

	const resetNoteSettingForm = () => { 
		setValue("send_notification_start", Number(notificationData["welcome_send_notifications"]))
		setValue("days_start", Number(notificationData["welcome_no_of_days"]))
	}

	const handleEmailName = (id, NOME, LABEL) => {
		document.querySelector('#root')?.setAttribute('st_nome_moh', NOME);
		document.querySelector('#root')?.setAttribute('st_label_moh', LABEL);
		localStorage.setItem("YhGUFY_hygft", id);
		let data = {
			notification: "notification"
		}
		navigate("/message", { state: data });
	}
	return (
		<>
			<Col lg={6}>
				<Card className="h-100">
					<Card.Header className="d-flex justify-content-between">
						<div className="me-2">{t("notifications.welcome")}
							<OverlayTrigger
                                placement="right"
                                overlay={<Tooltip id="tooltip-property-id">{t('notifiToolTip.welcome')}</Tooltip>}
                            >
							<img src="assets/images/icons/info-light.svg" alt="Info icon" className="h-18 ms-2" />
							</OverlayTrigger>
						</div>
						<span className='d-block TOEDIT'>
							<span>
								<span>
									<Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "toedit", "welcome", basicdata)}>{t("button.edit")}</Button>
								</span>
							</span>
						</span>
						<div className="d-none ACTION">
							<Row>
								<Col xl={4} sm={4}>
									<Button variant="link" className="p-0 fw-bold text-nowrap" onClick={(e) => handleAction(e, "save", "welcome", basicdata)} >{t("button.save")}</Button>
								</Col>
								<Col xl={4} sm={4}>
									<Dropdown className="radio--custom" autoClose="outside">
                                        <Dropdown.Toggle className='p-0 text-nowrap' variant='link' size="sm">
                                            <b>{t("button.edit")}</b>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <label className="d-block cursor-pe" htmlFor="ft1" onClick={() => handleEmailName("14","Seu contrato de locação vence em breve", "notificacoescontratuais")}>
                                                <input type="radio" name="statusFilter" id="ft1" />
                                                <div className="radio-inner px-4 py-2">
                                                    {t("notifications.tenant")} {t("notifications.welcome")}
                                                </div>
                                            </label>
                                            <label className="d-block cursor-pe" htmlFor="ft2" onClick={() => handleEmailName("1000000001","Seu contrato de locação venceu", "notificacoescontratuais")}>
                                                <input type="radio" name="statusFilter" id="ft2" />
                                                <div className="radio-inner px-4 py-2">
                                                    {t("notifications.owner")} {t("notifications.welcome")}
                                                </div>
                                            </label> 
                                        </Dropdown.Menu>
                                    </Dropdown>
{/* 									
									<Button variant="link" className="p-0 fw-bold text-nowrap" onClick={() => handleEmailName("14", "Seja bem vindo", "notificacoescontratuais")}>{t("button.edit")}</Button> */}
								</Col>
								<Col xl={4} sm={4}>
									<Button variant="link" className="p-0 fw-bold text-nowrap" ref={inputElement} onClick={(e) => ( resetNoteSettingForm() , handleAction(e, "cancel", "welcome", basicdata) ) } >{t("button.cancel")}</Button>
								</Col>
							</Row>
						</div>
					</Card.Header>
					<Card.Body>
						<Form onSubmit={handleSubmit(onSubmit)} >
							<Form.Check className="mb-3 mb-lg-4 removeInsertDisable" type="checkbox" {...register("send_notification_start")} id={`default_10`} label={t("notifications.checkNotification")} disabled />
							<Row className="gx-3 gy-xl-4 gy-3 align-items-center">
								<Col xl={2} sm={3}>
									<Form.Control type="number" className={`${page} removeInsertDisable`} {...register("days_start")} aria-label="days" min="0" disabled />
								</Col>
								<Col sm>{t("notifications.welcomeDayAfter")}</Col>
							</Row>
							<Button className='submitForm' type="submit" variant="primary" hidden>{t("button.toSave")}</Button>
						</Form>
					</Card.Body>
				</Card>
			</Col>
		</>
	)
}
export default Welcome