import { config } from "../../../../services/apiConfig";

type RecipePayload = {
  vencimento: string;
  emitida: string;
  contaBancaria: string;
  valores: { categoria: string; complemento: string; valor: string }[];
  descontos: { categoria: string; complemento: string; valor: string }[];
  observacoes: string;
  valorTotal: string;
  historico: string;
  jaFoiPago: boolean;
};

export async function createNewRecipe({ ...recipePayload }: RecipePayload) {
  const connectionName = localStorage.getItem("connectionName") ?? "";
  const token = localStorage.getItem("tokenV2") ?? "";

  let recipeSend = {
    dueDate: recipePayload.vencimento,
    issueDate: recipePayload.emitida,
    bankAccount: Number(recipePayload.contaBancaria),
    paymentMethodId: 8,
    history: recipePayload.historico,
    compositions: recipePayload.valores.map((item) => ({
      account: {
        category: item.categoria,
        planCategoryId: 2, // Ajuste conforme necessário
      },
      value: parseFloat(item.valor),
      complement: item.complemento,
    })),
    discount: {
      account: {
        category: recipePayload.descontos[0].categoria,
        planCategoryId: 2,
      },
      value: recipePayload.descontos[0].valor,
      complement: recipePayload.descontos[0].complemento,
    },
    additionalFields: {
      internalObservations: recipePayload.observacoes,
    },
    totalValue: recipePayload.valorTotal,
    alreadyPaid: recipePayload.jaFoiPago,
  };

//   const urlTeste = "http://localhost:7002/api/v1/income";
  const urlProd = `${config.companyV2_URL}/income`;

  const response = await fetch(urlProd, {
    method: "POST",
    headers: {
      "x-auth-token": token,
      "Content-type": "application/json",
      "connection-name": connectionName,
    },
    body: JSON.stringify(recipeSend),
  });

  const data = await response.json();

  if (data.resCode === 201 || data.resCode === 200) return data.data;
  throw new Error(data.data?.message || data.data);
}
